import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { AppBar, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: { xs: "40%", sm: "50%" },
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  height: "fit-content",
  borderRadius: "10px",
  boxShadow: 24,
  height: { xs: "fit-content", md: "90vh" },
  overflow: "auto",
};

export default function AdmissionModal({ open, setOpen, src }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 15,
      }}
    >
      <Box sx={style}>
        <AppBar position="sticky" sx={{ backgroundColor: "background.paper" }}>
          <IconButton
            sx={{ float: "right", width: "fit-content" }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </AppBar>
        <Box
          sx={{
            width: { xs: "fit-content", md: "50vw" },
            borderRadius: "10px",
          }}
        >
          <img src={src} alt="Admission Popup" width="100%" height="100%" />
        </Box>
      </Box>
    </Modal>
  );
}
