import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Images, Paragraphs } from './ImageAndParagraphsComponent';

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

const TabsComponent = ({ tabData }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' , padding: 0 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} textColor='secondary' indicatorColor='secondary' variant="scrollable"
          sx={{ backgroundColor:'white' }}
          // scrollButtons   //Un-Comment When tabs are more
          allowScrollButtonsMobile aria-label="scrollable force tabs example">
          {tabData.map((tab, index) => (
            <Tab sx={{fontSize:'12px'}} key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      <Paper elevation={1} sx={{backgroundColor:"ButtonFace", padding:"10px" , margin:'0%'}}  >
          {tabData.map((tab, index) => (
              <CustomTabPanel key={index} value={value} index={index}>
                <Typography variant="h5" gutterBottom textAlign={'center'}>
                           {tab.cardTitle}
                </Typography>
                <Typography>
                    <Images images={tab.cardImages} />       {/*import from Img&ParagraphComponent */}
                </Typography>
                <Typography className='mt-2'>
                       <Paragraphs description={tab.cardDescription} />   {/*import from Img&ParagraphComponent */}
                </Typography>
              </CustomTabPanel>
          ))}
      </Paper>
    </Box>
  );
};

export default TabsComponent;

