import React from 'react';
import { Button, Paper } from "@mui/material";
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';  

export default function NoFoundPage() {
  return (
    <div className='bg-light py-3'>
  
    <Paper elevation={3} className="my-5  mx-5  px-5  py-5 text-justify backgroundNoFound" >
      <div className='text-bold' >
        
      <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '70vh',
        textAlign: 'center',
      }}
    >
     
      {/* <Typography variant="h3" sx={{color:'#A70606'}} gutterBottom>
        Page Not Found !
      </Typography>
      <Typography variant="subtitle1">
        Sorry, the page you are looking for does not exist.
      </Typography> */}
      <Button variant="contained" color='primary' component={Link} to="/" style={{fontWeight:600 }}>
        Go to Home
      </Button>
    </div>
      </div>
    </Paper>
    </div>
  )
}


