import React from 'react';
import ImageCarousel from '../ReusableComponent/ImageCarousel';
import { Paper } from '@mui/material';

const HomeCarouselComp = ( ) => {
    const images = [ 
         // Add more image URLs here
    './assets/images/HomeDesk/homeScroll1.png',
    './assets/images/HomeDesk/homeScroll2.png',
    './assets/images/HomeDesk/homeScroll3.png',
    './assets/images/HomeDesk/homeScroll4.png',
    './assets/images/HomeDesk/homeScroll5.png',
    './assets/images/HomeDesk/homeScroll6.png',
    './assets/images/HomeDesk/homeScroll7.png',
    './assets/images/HomeDesk/homeScroll8.png',
    './assets/images/HomeDesk/homeScroll9.png',
    './assets/images/HomeDesk/homeScroll10.png',
    './assets/images/HomeDesk/homeScroll11.png',
    './assets/images/HomeDesk/homeScroll12.png',
    './assets/images/HomeDesk/homeScroll13.png',
    './assets/images/HomeDesk/homeScroll14.png',
    './assets/images/HomeDesk/homeScroll15.png',
    './assets/images/HomeDesk/homeScroll16.png',
    './assets/images/HomeDesk/homeScroll17.png',
   
    ];

    return (
        <div className='forMobileView'>
            <Paper elevation={5}>
            <ImageCarousel images={images}/>
            </Paper>
        </div>
    );
};

export default HomeCarouselComp;


