import Navbar from "./Navbar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import { Link } from "react-router-dom";
import logo from "../assets/logo/logol144.png";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Stack, Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Header() {
  return (
    <>
      {/* <header>
        <Stack
          direction="row"
          mx={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          my={4}
          width={"90vw"}
        >
          <div className="d-flex align-items-center vendor-name">
              <p className="m-0">PROGRESSIVE EDUCATION SOCIETY'S</p>
              <h5 className="m-0 fw-bold title-text">
                MODERN INSTITUTE OF BUSINESS MANAGEMENT
              </h5>
              <p className="m-0">
                (Approved by AICTE. New Delhi , Affiliated to Savitribai Phule
                Pune University)
              </p>
              <p className="m-0">NAAC Accredited</p>
            </div>
          </div>
          <Stack
            spacing={1}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack flexWrap={"nowrap"}>
              <Link
                className="AdmissionEnquiry btn"
                to="https://docs.google.com/forms/d/e/1FAIpQLSefJkO8hsjXcobfxWUqsrRWeSnxk_hifZloabzxwwaFsPFoOQ/viewform"
              >
                <WhatsAppIcon /> Admission Enquiry
              </Link>
            </Stack>
            <Stack flexWrap={"nowrap"}>
              <Link to="/Contact" className="btn contactBtn">
                <CallIcon /> Contact
              </Link>
            </Stack>
            {/* <Grid xs={2}  md={2}>
                  <Link to='https://mibm.akronsystems.com/pLogin.aspx' target='_blank' className='btn erpBtn'>ERP Login</Link>
                  </Grid> */}
      {/* </Stack>
        </Stack>

        <Navbar />
      </header> */}
      <header>
        <Grid
          container
          columns={{ xs: 1, md: 2 }}
          alignItems={"center"}
          justifyContent={"space-between"}
          my={{ xs: 1, md: 2 }}
          mx={1}
        >
          <Grid item xs={1} md={1.3}>
            <Stack direction={"row"} flexGrow={1}>
              <Box height={{ xs: "10vh", md: "15vh" }} m={"auto"}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ margin: "auto" }}
                  height="100%"
                />
              </Box>
              <Box>
                <Stack
                  height={"100%"}
                  direction={"column"}
                  className="vendor-name"
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                >
                  <Typography variant="body2" color="text.secondary">
                    PROGRESSIVE EDUCATION SOCIETY'S
                  </Typography>
                  <Typography
                    color="customColors.clr1"
                    variant="h6"
                    className=" title-text"
                    sx={{ fontWeight: "bold", fontSize: "22px" }}
                  >
                    MODERN INSTITUTE OF BUSINESS MANAGEMENT
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    An Autonomous Institute Affiliated to Savitribai Phule Pune
                    University, Approved by AICTE. New Delhi.
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid xs={1} md={0.7}>
            <Stack
              direction="column"
              sx={{ width: "100%" }}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={"flex-end"}
                justifyContent={"center"}
                width="100%"
                flexWrap={"wrap"}
                spacing={1}
              >
                <Link
                  className="AdmissionEnquiry btn"
                  to="https://docs.google.com/forms/d/e/1FAIpQLSefJkO8hsjXcobfxWUqsrRWeSnxk_hifZloabzxwwaFsPFoOQ/viewform"
                >
                  <WhatsAppIcon /> Admission Enquiry
                </Link>
                <Link to="/Contact" className="btn contactBtn">
                  <CallIcon /> Contact
                </Link>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Navbar />
      </header>
    </>
  );
}
