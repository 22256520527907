import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import * as React from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Soon from "../assets/images/ComingSoon.png";
import certificate from "../assets/images/IQAC/NAAC/AccreditionCertificate.jpg";
import { CompositionOfIQAC, Initiatives } from "../Data/IQAC/NaacData.js";
import ImageViewer from "react-simple-image-viewer";
import {
  AQAR_21_22,
  AQAR_20_21,
  AQAR_19_20,
  AQAR_22_23,
  AQAR_23_24,
} from "../Data/IQAC/AqarData.js";
import TabComponent from "../Components/ReusableComponent/TabComponent.js";

const sidemenus = [
  {
    title: "NAAC",
    url: "/IQAC/NAAC",
  },
  {
    title: "AQAR",
    url: "/IQAC/AQAR",
  },
];

export default function IQAC(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    // window.scrollTo(0, 0);
    window.scrollTo(0, 420);
  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {" "}
                    {menu.title}{" "}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function NAAC() {
  const tabData = [
    { label: "About IQAC", content: <AboutIQAC /> },
    // { label: 'NAAC Peer Team Visit', content: <NaacPeerTeamVisit /> },
    { label: "Accreditation Certificate", content: <Accreditation /> },
  ];

  return (
    <div className="box">
      <Paper elevation={2}>
        <TabComponent
          tabData={tabData}
          tabName={sessionStorage.getItem("menuName")}
        />
      </Paper>
    </div>
  );
}
export function AboutIQAC() {
  return (
    <>
      <div>
        <h5 className="my-1 title-text text-bold decorated">ABOUT IQAC CELL</h5>
        <p className="my-3 P-tag mx-3" data-aos="fade-left">
          The Internal Quality Assurance Cell was established in Year 2016 The
          objective of IQAC is to frame guidelines for effective, consistent and
          continuous academic and administrative performance and growth of the
          Institute. Significant contributions will be obtained from IQAC during
          the Accreditation period. The IQAC will work towards the goals of
          quality enhancement and sustainability and growth of the Institute.
        </p>
      </div>
      <Divider sx={{ border: 1 }} />
      <div>
        <h5 className="my-3 title-text text-bold">Functions of IQAC Cell</h5>
        <ul className="ul-tag" data-aos="fade-left">
          <li>
            Keeping in mind the overall Vision of the Society to be Progressive
            and Modern by using Innovative and modern teaching and learning
            practices with latest ICT
          </li>
          <li>
            Planning and ensuring effective implementation of Academic, co
            curricular and extracurricular activities and sports, that will help
            recognize and encourage talent among the students for Holistic
            Development.
          </li>
          <li>
            Encouraging research approach among the faculty members by ensuring
            participation and organizing seminars, conferences, workshops and
            encourage students by participating in research based competitions
          </li>
          <li>
            To Ensure planning, implementation of various activities thereby
            ensuring to follow finer and good practices at the Institute.
          </li>
        </ul>
      </div>
      <Divider sx={{ border: 1 }} />
      <div>
        <h5 className="my-3 title-text text-bold">Quality Policy</h5>
        <p className="my-3 P-tag mx-3" data-aos="fade-left">
          To strive to impart quality education and skills through innovative
          learning methodologies for holistic development of responsible
          professionals, making them accountable globally.
        </p>
      </div>
      <Divider sx={{ border: 1 }} />
      <div>
        <CompositionOfIQAC />
      </div>

      <div className="mt-5">
        <Initiatives />
      </div>
    </>
  );
}

// export function NaacPeerTeamVisit(){
//     return(
//         <>
//         <img src={Soon} height={400} width={400} className="mx-5" />
//        </>
//     )
// }

export function Accreditation() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = ["/assets/images/HomeDesk/AccreditionCertificate.jpg"];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <div
        className="my-3 d-flex justify-content-center flex-wrap"
        data-aos="fade-left"
      >
        {images.map((src, index) => (
          <img
            className="galleryImgBorder"
            src={certificate}
            onClick={() => openImageViewer(index)}
            width="350"
            key={index}
            style={{ margin: "8px" }}
            alt=""
          />
        ))}
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
}

export function AQAR() {
  const tabData = [
    { label: "AQAR-2019-20", content: <AQAR_19_20 /> },
    { label: "AQAR-2020-21", content: <AQAR_20_21 /> },
    { label: "AQAR-2021-22", content: <AQAR_21_22 /> },
    { label: "AQAR-2022-23", content: <AQAR_22_23 /> },
    { label: "AQAR-2023-24", content: <AQAR_23_24 /> },
  ];

  return (
    <div className="box">
      <Paper elevation={2}>
        <TabComponent
          tabData={tabData}
          tabName={sessionStorage.getItem("menuName")}
        />
      </Paper>
    </div>
  );
}
