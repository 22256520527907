import { newsData } from "../../Data/NewsAnnouncementData";
import Paper from "@mui/material/Paper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Blink from "react-blink-text";
import Breadcrumb from "../../Components/Breadcrumb";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Grid } from "@mui/material";
import { NewsIcon } from "../../Components/SvgIcons";

const data = newsData;

export default function NewsAnnouncementPage() {
  window.scrollTo(0, 270);

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb
          pageTitle={
            <h3 className="fs-3 mt-0 text-bold decorated text-light">
              News Announcement
            </h3>
          }
        />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb
          pageTitle={
            <h3 className="fs-3 mt-0 text-bold decorated text-light">
              News Announcement
            </h3>
          }
        />
      </div>
      <div className="bg-light m-0 py-3">
        <Paper elevation={0} className="mx-5 my-5 bg-light">
          {data.map((items, index) => (
            <div className="upcoming-texts" key={index}>
              <div className="home-card-content">
                <Grid container>
                  <Grid xs={0.5}>
                    <NewsIcon />
                  </Grid>
                  <Grid xs={11.5}>
                    <h5 className="home-card-title g-2 fs-5">{items.title}</h5>
                    <div className="fs-5 g-3 mt-2">
                      {items.Doc.pdf.length !== 0 && (
                        <a
                          href={items.Doc.pdf}
                          target="_blank"
                          className="fs-6 g-3 mt-2 mx-lg-4"
                          rel="noreferrer"
                        >
                          <PictureAsPdfIcon className="pdficon" /> view more...
                        </a>
                      )}
                    </div>
                    <div className="d-flex flex-wrap">
                      <ImageList
                        sx={{ width: 700 }}
                        cols={3}
                        gap={8}
                        className="mt-3 mb-2 mx-lg-4"
                      >
                        {items.Doc.image.map((image, imageIndex) => (
                          <ImageListItem key={imageIndex}>
                            <a href={items?.link} target="_blank" rel="noreferrer">
                              <img
                                className="rounded"
                                src={image}
                                alt={`${imageIndex + 1}`}
                              />
                            </a>
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </div>
                    <div className="home-card-body fs-6 mt-3 mx-lg-4 text-justify">
                      {items.description}
                    </div>{" "}
                    {/* Render news body */}
                    <div className="Application home-card-Footer mt-3 mx-3">
                      <Blink
                        color="gray"
                        text={items.updatedDate}
                        fontSize={15}
                      ></Blink>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          ))}
        </Paper>
      </div>
    </>
  );
}
