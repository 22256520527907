import * as React from "react";
import { useState } from "react";
import { Button, Input, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import tree1 from "../assets/images/StudentChapter/ActivitiesRejoice/treePlantation1.jpg";
import tree2 from "../assets/images/StudentChapter/ActivitiesRejoice/treePlantation2.jpg";
import hill1 from "../assets/images/StudentChapter/ActivitiesRejoice/swachhHill1.jpg";
import hill2 from "../assets/images/StudentChapter/ActivitiesRejoice/swachhHill2.jpg";
import abhiyan1 from "../assets/images/StudentChapter/ActivitiesRejoice/swachhShaniwarVada1.jpg";
import abhiyan2 from "../assets/images/StudentChapter/ActivitiesRejoice/swachhShaniwarVada2.jpg";
import food1 from "../assets/images/StudentChapter/ActivitiesRejoice/foodDonation1.jpg";
import food2 from "../assets/images/StudentChapter/ActivitiesRejoice/foodDonation2.jpg";
import Traffic1 from "../assets/images/StudentChapter/ActivitiesRejoice/trafficAwarness1.jpg";
import Traffic2 from "../assets/images/StudentChapter/ActivitiesRejoice/trafficAwarness2.jpg";

import BookReview2021_22_1 from "../assets/images/StudentChapter/Book Review/2021_22/BookReview2021_22_1.jpeg";
import BookReview2021_22_2 from "../assets/images/StudentChapter/Book Review/2021_22/BookReview2021_22_2.jpeg";

import BookReview2022_23_1 from "../assets/images/StudentChapter/Book Review/2022_23/BookReview2022_23_1.jpeg";
import BookReview2022_23_2 from "../assets/images/StudentChapter/Book Review/2022_23/BookReview2022_23_2.jpeg";
import BookReview2022_23_3 from "../assets/images/StudentChapter/Book Review/2022_23/BookReview2022_23_3.jpeg";

import TradeFair2021_1 from "../assets/images/TradeFair/TradeFair2021/TradeFair1.jpeg";
import TradeFair2021_2 from "../assets/images/TradeFair/TradeFair2021/TradeFair2.jpeg";

import TradeFair2022_1 from "../assets/images/TradeFair/TradeFair2022/TradeFair1.jpeg";
import TradeFair2023_24_1 from "../assets/images/TradeFair/TradeFair2023_24/TradeFair1.jpeg";
import TradeFair2023_24_2 from "../assets/images/TradeFair/TradeFair2023_24/TradeFair2.jpeg";

import cycle_23_1 from "../assets/images/StudentChapter/EcoClub/2023/cycle1.jpeg";
import cycle_23_2 from "../assets/images/StudentChapter/EcoClub/2023/cycle2.jpeg";
import envo_23_1 from "../assets/images/StudentChapter/EcoClub/2023/envo1.jpeg";
import envo_23_2 from "../assets/images/StudentChapter/EcoClub/2023/envo2.jpeg";
import plant_23_1 from "../assets/images/StudentChapter/EcoClub/2023/plant1.jpeg";
import plant_23_2 from "../assets/images/StudentChapter/EcoClub/2023/plant2.jpeg";
import waste_23_1 from "../assets/images/StudentChapter/EcoClub/2023/waste1.jpeg";
import waste_23_2 from "../assets/images/StudentChapter/EcoClub/2023/waste2.jpeg";

import envo_22_1 from "../assets/images/StudentChapter/EcoClub/2022/envo_1.jpeg";
import envo_22_2 from "../assets/images/StudentChapter/EcoClub/2022/envo_2.jpeg";

import final_1 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Final_1.jpeg";
import final_2 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Final_2.jpeg";
import final_3 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Final_3.jpeg";
import final_4 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Final_4.jpeg";

import retro_1 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Retro_1.jpeg";
import retro_2 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Retro_2.jpeg";
import retro_3 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Retro_3.jpeg";
import retro_4 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Retro_4.jpeg";
import retro_5 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Retro_5.jpeg";

import traditional_1 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Traditional_1.jpeg";
import traditional_2 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Traditional_2.jpeg";
import traditional_3 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Traditional_3.jpeg";
import traditional_4 from "../assets/images/StudentChapter/Darpan/Darpan_2022/Traditional_4.jpeg";

import darpan_2023_1 from "../assets/images/StudentChapter/Darpan/Darpan2023_1.jpeg";
import darpan_2023_2 from "../assets/images/StudentChapter/Darpan/Darpan2023_2.jpeg";

import darpan_2024_pdf from "../assets/pdf/Darpan_2024_Report.pdf";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import campus from "../assets/images/StudentChapter/Infrastructure/campus.jpg";
import classroom from "../assets/images/StudentChapter/Infrastructure/classroom.jpg";
import researchLab from "../assets/images/StudentChapter/Infrastructure/researchLab.jpg";
import seminarHall from "../assets/images/StudentChapter/Infrastructure/seminarHall.jpg";
import GalleryComponent from "../Components/ReusableComponent/GalleryComponent";
import TabComponent from "../Components/ReusableComponent/TabComponent";
import TableComponent from "../Components/ReusableComponent/tableComponent";
import { Form, Link } from "react-router-dom";
import {
  Images,
  Paragraphs,
} from "../Components/ReusableComponent/ImageAndParagraphsComponent";

import mangalya_8 from "./mangalta_8.json";
import mangalya_9 from "./mangalya_9.json";
import mangalya_2023_24_img_1 from "../assets/images/StudentChapter/Mangalya/2023-24/Mangalya1.jpeg";
import mangalya_2023_24_img_2 from "../assets/images/StudentChapter/Mangalya/2023-24/Mangalya2.jpeg";
import { ClassroomPhotos } from "./IQAC/AqarData";

// -------------------------------------------MBA Induction ---------------------
export function Induction2023_24() {
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2023-24</h6>
        <p>
          <b>The MBA Induction Program</b> at{" "}
          <b>Modern Institute of Business Management,</b> for welcoming newly
          admitted MBA students was arranged from{" "}
          <b>4th Sept. to 9th Sept., 2023.</b> It was an enriching experience,
          featuring a lineup of diverse guest speakers.
        </p>

        <p>
          On the first day, <b>Mr. Pradeep Lokhande</b>, Founder – Rural
          Relations and <b>Mr. Siddharth Rahalkar</b>, Founder - Asymmetrical.ai
          inaugurated the Induction Program. Both of them motivated students and
          emphasized Personal Growth. The Inauguration Program took an
          interesting turn with <b>Mr. Sarang Bhoirkar</b> and{" "}
          <b>Mr. Sarang Mandke</b> who portrayed{" "}
          <b>Chhatrapati Shivaji Maharaj’s Managerial Skills.</b>
        </p>

        <p>
          <b>Dr. Prapti Mutha</b>, Global HR Business Lead – Amdocs delivered
          the session on “<b>An Eye For I</b>” wherein she focused on
          self-awareness, while <b>Mr. Prantik Panigrahi</b>, Global
          Psycho-Geometrics Facilitator explored on “
          <b>Complex Global Business Landscape</b>”.
        </p>

        <p>
          <b>Ms. Pallavi Kaushik</b>, International Grooming Expert expressed
          views on “
          <b>Corporate Grooming, Personal Branding and Career Development</b>”.{" "}
          <b>Mr. Jashan Joshi</b>, Head - Talent Sourcing & Talent Intelligence
          - ZS spoke on “
          <b>Navigating The Path Ahead Through The Talent Leads</b>” and
          provided strategic career guidance. <b>Mr. Ashitosh Prachand</b>,
          Director- KnottyIP emphasized on “
          <b>Innovation, Intellectual Property and Entrepreneurship</b>” and
          inspired students to think creatively.
        </p>

        <p>
          <b>Mr. Pranav Mantri</b>, Practicing CA & Corporate Trainer,
          practically advised students on the “<b>Time and Stress Management</b>
          ”. He highlighted the importance of balancing productivity. Further,{" "}
          <b>Prof. Dr. Ambar Beharay</b>, Programme Coordinator – Dept. of
          Mgmt., TMV, focused on “<b>Ethical Leadership</b>” and{" "}
          <b>Mr. Chandrakant Turare</b>, Financial Advisor & Corporate Coach
          shared insight on “<b>Balancing Personal and Corporate Success</b>”.
        </p>

        <p>
          <b>Rtd ACP Bhanupratap Barge</b> emphasized on “
          <b>Ethical Decision Making</b>” through the session{" "}
          <b>Dare with Care</b> and <b>Mr. Pawan Lalwani</b>, Director – L & D,
          HR Cipla Ltd discussed about Positive Attitude and different
          Personalities.
        </p>

        <p>
          <b>Ms. Meghna Kotecha</b>, Director, Maersk Global Service Centers
          provided a framework for “<b>SWOT Analysis in Corporate World</b>” and
          shared valuable framework for personal and organizational development.
        </p>

        <p>
          <b>Dr. Rajendra Mane</b>, Founder & Director – WWClinics Pvt. Ltd
          delivered session on “<b>How to life a Happy Life for 100 Years</b>”
          He left student with a heartfelt reminder that success encompasses
          both professional achievements and personal fulfilment.
        </p>

        <p>
          Overall, The MBA Induction Programme was a Comprehensive and
          Enlightening Experience that equipped students with a diverse set of
          skills required in Corporate World, perspectives and motivations. The
          wisdom shared by these esteemed speakers will undoubtedly guide newly
          admitted students on journey towards success in the corporate world
          and beyond.
        </p>

        <p>
          <b>Induction Team</b>
        </p>
      </div>
    </>
  );
}

export function Induction2022_23() {
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2022-23</h6>
        <p>
          <b>
            For formally welcoming newly admitted students, MBA Induction
            Program
          </b>{" "}
          was scheduled from <b>28th Nov. 2022 to 6th Dec. 2022.</b> It was the{" "}
          <b>First Formal Function of the Academic Year 2022-23.</b> Experienced
          and well-known{" "}
          <b>Corporate Trainers, HR Representatives, Industry Experts</b> and{" "}
          <b>soft skills Trainers</b> were invited to guide and welcome new
          batch.
        </p>

        <p>
          On the first day, <b>Hon. Dr. Ravindra Utgikar</b>, Vice President –
          Corporate Strategy & Marketing, Praj Industries Ltd, inaugurated
          program as a chief guest. He enlightened students on current trends in
          industry and expectations from students. He suggested students that
          how one can effectively survive in corporate in VUCA situation.
        </p>

        <p>
          <b>Mr. Pawan Lalwani</b>, Lead Training, HR Cipla Pharmaceutical,
          conducted an amazing session on <b>Attitude and Personality.</b> He
          involved students in various activities to make the them understand
          about the four dimensions of an individual's Personality i.e. Thinking
          or Feeling, Introvert or Extrovert, Action or Reflection and Detail
          Orientated or Big Picture Oriented. Also he gave key points on how
          positive attitude is important and how it affects our life be it
          personal or professional. Overall it was a very informative and eye
          opening session for the students.
        </p>

        <p>
          <b>Mr. Sujit Shinde</b>, Corporate Trainer and Co-Founder of Training
          India, delivered session on <b>Goal Setting</b> wherein he emphasized
          on short term as well as long term goals and ways to achieve them.
        </p>

        <p>
          <b>Adv. CA CS Kunal Kamath Sarpal</b>, Founder, White Collar Legal
          LLP, delivered session on <b>Innovation and Entrepreneurship.</b> He
          introduced the topic by explaining several factors responsible for an
          innovative environment and difference between invention and
          innovation. He encouraged students to start thinking out of the box
          about various ways simple problems can be solved in order to inculcate
          innovative thinking process in themselves. He provided a broader
          perspective on the need for innovation in entrepreneurship.
        </p>

        <p>
          <b>CA Pranav Mantri</b>, Corporate Trainer, highlighted students on{" "}
          <b>Time & Stress Management</b> by giving simple tips and through in
          door games, how students can effectively manage time & stress at
          current student life as well as future corporate life.
        </p>

        <p>
          <b>Dr. Shilpa Khandelwal</b>, Prominent and Registered Dietician /
          Nutritionist conducted a Quiz for all students which made all students
          know that "<b>Health is Wealth</b>". She briefed the students on
          significance of healthy lifestyle. She focused on the unhealthy
          foodstuff which need to be avoided.
        </p>

        <p>
          A daylong session on{" "}
          <b>Experiential Learning through Out-Door Management Games</b> was
          arranged in association with Abhanti Consultancy Services. Certain
          in-door as well as out-door activities / games were conducted through
          which students learnt management functions like planning,
          coordination, team work, group dynamics and leadership etc.
        </p>

        <p>
          <b>Mr. Prantik Panigrahi</b>, Global Psycho- Geometrics Facilitator,
          conducted activity based half day work shop, namely{" "}
          <b>Khulja Sim Sim</b> wherein he discussed about the two powerful
          minds. He guided students on how to develop growth mindset and how to
          create the life of success.
        </p>

        <p>
          <b>Ms. Meghna Kotecha</b>, General Manager , Maersk Global Service
          Centres, delivered session on <b>SWOT Analysis,</b> relating it with
          corporate world. The said analysis will surely make corporate life
          easier.
        </p>

        <p>
          <b>Mr. Alok Sharma</b> , Director F & A Practice, Accenture and{" "}
          <b>Mr. Sangram Kadam</b>, Vice President , Birlasoft , guided students
          on <b>Industry Expectations</b> and how students should prepare
          accordingly. <b>Mr. Harendra Deshpande</b>, Transformation Coach &
          Director, Creative Training Solutions, delivered session on{" "}
          <b>Impact of your limiting beliefs on your professional success.</b>{" "}
          Further, <b>Dr. Prasanna Gadre</b>, Medical Practitioner and Corporate
          Trainer narrated importance of effective communication.{" "}
          <b>Ms. Trupti Jadhav</b> & <b>Ms. Uma Dabir</b>, free lancers, also
          engaged students at indoor games in their respective sessions. Session
          namely, <b>Love You Jindagi</b> was conducted by{" "}
          <b>Mr. Sanket Khule</b>, the Motivational Speaker. He gave the basic
          formula to make life easier, i.e. the 3 E's i.e. Explore, Experiment
          and Enjoy by sharing ideas which can be easily implemented in one’s
          life. He engaged students with wonderful in door games to understand
          about the basic concept of living life.
        </p>

        <p>
          Entire Induction Program gave students confidence, new hopes and
          positive mind set to make their MBA life brighter. Students also gave
          assurance about hard work and got ready for accepting new challenges.
        </p>
        <p>
          <b>Induction Team</b>
        </p>
      </div>
    </>
  );
}

export function Induction2021_22() {
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2021-22</h6>
        <p>
          <b>The First Formal Function of the Academic Year 2021-22</b> i.e.{" "}
          <b>MBA Induction Program</b> to welcome newly admitted students, was
          scheduled on <b>11th, 12th and 13th January 2022.</b> Renowned{" "}
          <b>
            Corporate Trainers, Industry Experts, HR Representatives and Soft
            Skills Trainers
          </b>{" "}
          were invited to aware students about industry expectations and skills
          required to survive efficiently in the corporate world.
        </p>

        <p>
          On the first day, <b>Hon. Mr. Akhil Gupta</b>, Vice President - Treebo
          Hotels, inaugurated program as a chief guest. He enlightened students
          on importance of{" "}
          <b>Value System, Social Skills, Oral & Written Communication.</b> He
          suggested students on trying very hard in academics, importance of
          practice etc. Further <b>Mr. Rajesh Borde</b>, Country Manager -Inside
          Sales , HPE delivered session on “<b>Crossing Cham</b>” wherein he
          shared his corporate experiences, corporate expectations etc.
        </p>

        <p>
          On the second day of induction <b>Ms. Arati Garg</b>, Certified
          Freelancer, Behavioural & Emotional Corporate Trainer, conducted
          activity-based session namely “<b>Ice Breaker into Corporate World</b>
          ”. Activities like <b>Bingo, Treasure Hunt, Number Counting</b> and
          drawing <b>Emojis</b> made ice breaking. Students learnt{" "}
          <b>Teamwork, Coordination</b> etc. <b>Dr. Shilpa Khandelwal</b> who is
          registered dietician and nutritionist enlighten students about healthy
          life style especially in pandemic period. She gave several tips for
          improving immunity. The objective of the session namely “
          <b>Health is Wealth</b>” was to notify students about healthy diet
          which will enhance their capacity.
        </p>

        <p>
          <b>Mr. Sagar Jha</b>, Soft Skills Trainer delivered session on “
          <b>Interpersonal & Communication Skills</b>” wherein he explained
          importance of <b>Interpersonal & Communication Skills</b> and how to
          enhance network. He described dos and don’ts while making clear
          communication to avoid misperception. He taught few etiquettes while
          making formal communication. <b>Ms. Uma Dabir</b> and{" "}
          <b>Ms Trupti Jadhav</b>, both Corporate Trainers, spoke on{" "}
          <b>
            Personality Development, Corporate Etiquettes, Effective
            Communication
          </b>{" "}
          etc.
        </p>

        <p>
          On the third day, <b>Mr. Pawan Lalwani</b>, Sr. L & D Head-HR, Cipla
          Pharmaceutical, delivered session on “
          <b>Time Management and Attitude</b>” wherein he emphasized on the
          significance of having a positive attitude and how time management can
          be done in life with real life corporate examples.
        </p>

        <p>
          Induction Program gave students new hopes and positive mind set to
          make their MBA life brighter. Students also gave assurance about hard
          work and got ready for accepting new challenges.
        </p>
      </div>
    </>
  );
}

export function Induction2020_21() {
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2020-21</h6>
        <p>
          <b>Induction Program for A.Y. 2020-21 for new batch of MBA</b> was
          scheduled on <b>17th and 18th Feb. 2021.</b> A range of well known
          experts from industries and experienced academicians & corporate
          trainers were invited to motivate and guide new entered students.{" "}
          <b>
            Hon. Prof. Parag Kalkar- Dean, Faculty of Commerce & Management,
            SPPU
          </b>{" "}
          and <b>Hon. Prof. Shamkant Deshmukh, Secretary, P.E. Society</b> was
          also present as a Guest of Honour. The entire Induction Program was
          arranged through Online Portal i.e. <b>MS Teams.</b>
        </p>

        <p>
          <b>Prof. Dr. Parag Kalkar</b> motivated newly admitted students for
          successfully completing MBA course within stipulated time. He spoke
          about significance of communication skills, capacity building etc. He
          also elucidated effective Teaching- Learning Process.{" "}
          <b>Dr. Shilpa Khandelwal</b> who is registered dietician and
          nutritionist enlighten students about healthy life style especially in
          pandemic period. She gave several tips for improving immunity. The
          objective of the session namely “Health is Wealth” was to notify
          students about healthy diet which will enhance their capacity.
        </p>

        <p>
          <b>Prof. Dr. Bhooshan Kelkar</b>, Director MobiuSutra directed
          students on “Career Planning”. In the context of lockdown situation
          and Work from Home Culture and thereby changed scenario of industry
          expectations, he explained various computer skills which especially
          MBA students must learn for getting placements. His entire focus was
          on Artificial Intelligence and Machine Learning. He also advised
          students on short term certificate or ad-on courses to be completed
          for enhancing employability.
        </p>

        <p>
          <b>Mr. Pawan Lalwani</b>, Sr. L & D Head-HR, Cipla Pharmaceutical,
          delivered session on “Attitude and Personality” wherein he emphasized
          on the significance of having a positive attitude. It was activity
          based session where students could identify their personality type. He
          gave a few illustrations which helped students to understand their
          personality in a better way.
        </p>

        <p>
          <b>Mr. Siddharth Rahalkar</b>, CEO Bellcurve Labs, delivered session
          on “Entrepreneurship Way Ahead for MBAs”. The objective of the session
          was to develop entrepreneurship skills among students. He shared own
          experiences and narrated qualities required for successful
          entrepreneurship.
        </p>

        <p>
          <b>Mr. Amit Bivalkar</b>, Managing Director & CEO –Sapient Wealth
          Advisors & Brokers Pvt. Ltd. discussed Industry Expectations from MBA
          students. He advised various skills which students must learn and gain
          the knowledge of certain stock market activities within the span of
          two years of MBA.
        </p>

        <p>
          Induction Program gave students new hopes and positive mind set to
          make their MBA life brighter. Students also gave assurance about hard
          work and got ready for accepting new challenges.
        </p>
      </div>
    </>
  );
}

export function Induction2019() {
  const heading = ["Sr No.", "Date", "Time", "Speaker", "Topic"];

  const data = [
    {
      col1: "18/9/2019",
      col2: "9.30 am",
      col3: "Mr. Rajendra Bhide (Group-  Finance Controller, Forbes Marshall, Pune)",
      col4: "Motivational Speech",
    },
    {
      col1: "18/9/2019",
      col2: "11.30 am",
      col3: "Dr. Shilpa Khandelwal (Practicing Nutritionist )",
      col4: "Health is Wealth",
    },
    {
      col1: "18/9/2019",
      col2: "2.15 pm",
      col3: "Ms. Prapti Mutha(Corporate Trainer- Synectics Academy)",
      col4: "Power to Transformation",
    },
    {
      col1: "19/9/2019",
      col2: "9.30 am",
      col3: "Mr. Ashish Patil(Founder – Mind Shift Mentoring, Motivational Speaker)",
      col4: "Brain Hacking for Mgmt Students",
    },
    {
      col1: "19/9/2019",
      col2: "11.30 am",
      col3: "Mr. Pawan Lalwani(Sr. L & D Head-HR, Cipla Pharmaceutical)",
      col4: "Attitude & Personality",
    },
    {
      col1: "19/9/2019",
      col2: "2.00 pm",
      col3: "Ms. Deepa Mathur(V.P. Finance, Bilcare Research Ltd)",
      col4: "Realities of Corporate World",
    },
    {
      col1: "20/9/2019",
      col2: "9.30 am",
      col3: "Respective Trainers from Abhanti Consultancy Services",
      col4: "Out Door Mgmt Games",
    },
    {
      col1: "21/9/2019",
      col2: "9.30 am",
      col3: "Ms. Soudamini Bhavsar(Founder – Meridian Learning Solutions)",
      col4: "ABC of being a Professional",
    },
    {
      col1: "21/9/2019",
      col2: "11.30 am",
      col3: "Representative Students of MBA-II, MIBM",
      col4: "Ice-Breaking Activity",
    },
  ];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2019</h6>
        <p>
          Inauguration Function – “MBA INDUCTION 2019” will be held on{" "}
          <b>18th Sept. 2019 at 9.30 am </b>at MIBM’s Seminar Hall and
          inauguration will be done at the auspicious hands of{" "}
          <b>
            {" "}
            Hon. Mr. Rajendra Bhide (Group- Finance Controller, Forbes Marshall,
            Pune) as Chief Guest.
          </b>
        </p>
        <Paper elevation={1}>
          <TableComponent tableHeading={heading} tableData={data} />
        </Paper>
      </div>
    </>
  );
}

export function Induction2018() {
  const heading = ["Sr No.", "Date", "Time", "Speaker", "Topic"];

  const data = [
    {
      col1: "16/8/18",
      col2: "9.30 am",
      col3: "Inauguration Function, Hon. Mr. Gajanan More (Vice President – HR  TATA Autocomp Systems Ltd)",
      col4: "Motivational Speech",
    },
    {
      col1: "16/8/18",
      col2: "1.30 pm",
      col3: "Ms. Prapti Mutha(Corporate Trainer- Synectics Academy)",
      col4: "Corporate Communication",
    },
    {
      col1: "17/8/18",
      col2: "9.30 am",
      col3: "Dr. Bhooshan Kelkar (Director  MoebiuSutra Consulting Pvt. Ltd)",
      col4: "Career Planning",
    },
    {
      col1: "17/8/18",
      col2: "11.30 am",
      col3: "Dr. Shilpa Khandelwal(Practicing Nutritionist )",
      col4: "Health is Wealth",
    },
    {
      col1: "17/8/18",
      col2: "2.00 pm",
      col3: "Representative Students of MBA-II, MIBM",
      col4: "Ice-Breaking Activity",
    },
    {
      col1: "17/8/18",
      col2: "3.00 pm",
      col3: "Mr. Sughosh Tembre(Unit Manager –HR- Infosys)",
      col4: "Corporate Etiquette",
    },
    {
      col1: "18/8/18",
      col2: "9.30 am",
      col3: "Mr. Pawan Lalwani(Sr. L & D Head-HR, Cipla Pharmaceutical)",
      col4: "Attitude & Personality",
    },
    {
      col1: "18/8/18",
      col2: "11.30 am",
      col3: "Respective Trainers from Abhanti Consultancy Services",
      col4: "Out Door Management Games",
    },
  ];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2018</h6>
        <p>
          Inauguration Function – “MBA INDUCTION 2018” will be held on
          <b>16th August 2018 at 9.30 am </b>at MIBM’s Seminar Hall and
          inauguration will be done at the auspicious hands of{" "}
          <b>
            {" "}
            Hon. Mr. Gajanan More (Vice President – HR TATA Autocomp Systems
            Ltd) as Chief Guest.
          </b>
        </p>
        <Paper elevation={1}>
          <TableComponent tableHeading={heading} tableData={data} />
        </Paper>
      </div>
    </>
  );
}

export function Induction2017() {
  const heading = ["Sr No.", "Date", "Time", "Speaker", "Topic"];

  const data = [
    {
      col1: "17/08/2017",
      col2: "1.30 pm",
      col3: "Organization -“Bumble Bee”",
      col4: "In door Activities",
    },
    {
      col1: "18/08/2017",
      col2: "9.00 am",
      col3: "Dr Bhooshan Kelkar(Director at MoebiuSutra Consulting Pvt Ltd, Ex-Country Manager-IBM)",
      col4: "Career Planning",
    },
    {
      col1: "18/08/2017",
      col2: "11.00 am",
      col3: "Mr. Vikrant Bhujbalrao(Public Speaker,  Startup Mentor, Entrepreneur)",
      col4: "Motivation and Inspiration",
    },
    {
      col1: "18/08/2017",
      col2: "2.00 pm",
      col3: "Organization “Bumble Bee”",
      col4: "Our Door Management Games",
    },
    {
      col1: "19/08/2017",
      col2: "10.00 am",
      col3: "Mr Binesh Menon(Head of Learning and Development, Emerson)",
      col4: "Life Skills and Corporate Expectations",
    },
  ];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2017</h6>
        <p>
          Inauguration Function – <b>“MBA INDUCTION 2017” </b> will be held on
          17th August 2017 at 11.00 AM at MIBM’s Seminar Hall and inauguration
          will be done at the auspicious hands of{" "}
          <b> Hon. Dr. Anant Sardeshmukh </b>(Director General, Mahratta Chamber
          of Commerce Industries and Agriculture) as Chief Guest , Prof.
          Shamkant Deshmukh (Secretary – P. E. Society)
        </p>
        <Paper elevation={1}>
          <TableComponent tableHeading={heading} tableData={data} />
        </Paper>
      </div>
    </>
  );
}

export function Induction2016() {
  const heading = ["Sr No.", "Date", "Time", "Speaker", "Topic", "Venue"];

  const data = [
    {
      col1: "19/08/2016",
      col2: "2.30 PM",
      col3: "Dr. Prasanna Gadre (Medical practitioner & Free Lancer)",
      col4: "Communication Skills",
      col5: "Seminar Hall-MIBM",
    },
    {
      col1: "20/08/2016",
      col2: "10.00 AM",
      col3: "Ms Simrat Gahlot (V.P.- HR & Training Deutsche Bank)",
      col4: "Guidance on making MBA Journey Fruitful",
      col5: "Seminar Hall-MIBM",
    },
  ];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2016</h6>
        <p>
          Inauguration Function – <b> “MBA INDUCTION 2016”</b> will be held on
          19th August 2016 at 10.15 AM at P.E. Society’s Auditorium and
          inauguration will be done at the auspicious hands of Hon. Dr. K.H.
          Sancheti (Founder & President – Sancheti Group of Hospitals, Pune) as
          Chief Guest , Hon. Prof. Dr. G. R. Ekbote (Chairman – Progressive
          Education Society, Pune) as Guest of Honour , Prof. Shamkant Deshmukh
          (Secretary -Progressive Education Society) and Prof. Jyotsna Ekbote
          (Joint Secretary – Progressive Education Society) will preside over
          the function.
        </p>
        <h6 className="text-bold mb-4">Other Details of Induction Program</h6>
        <Paper elevation={1}>
          <TableComponent tableHeading={heading} tableData={data} />
        </Paper>
      </div>
    </>
  );
}

export function Induction2015() {
  const heading = ["Sr No.", "Date", "Time", "Speaker", "Topic", "Venue"];

  const data = [
    {
      col1: "19/08/2015",
      col2: "2.15 PM",
      col3: "Dr. Prasanna Gadre",
      col4: "Communication Skills",
      col5: "Seminar Hall-MIBM",
    },
    {
      col1: "20/08/2015",
      col2: "9.45 AM",
      col3: "Mr. Varun Menghani",
      col4: "Guidance on making MBA Journey Fruitful",
      col5: "Seminar Hall-MIBM",
    },
    {
      col1: "20/08/2015",
      col2: "2.15 PM",
      col3: "-",
      col4: "Learning through Management Games by Bumble Bee(Outdoor Management Games)",
      col5: "Seminar Hall-MIBM",
    },
    {
      col1: "21/08/2015",
      col2: "9.45 AM",
      col3: "Dr. Aninash Vagha",
      col4: "Group Activity for developing Managerial Skills",
      col5: "Seminar Hall-MIBM",
    },
    {
      col1: "21/08/2015",
      col2: "2.15 PM",
      col3: "Ms. Renuka Krishna",
      col4: "Qualities required for getting into corporate.",
      col5: "Seminar Hall-MIBM",
    },
  ];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2015</h6>
        <p>
          Inauguration Function of <b> “MBA INDUCTION 2015” </b> was held on
          19th August 2015 at 11. 30 AM at MIBM’s Seminar Hall and inauguration
          was done at the auspicious hands of Dr. Santosh Bhave (Sr. Vice
          President – HR, IR and Admin-Bharat Forge Ltd, Pune) as Chief Guest ,
          Prof. Shamkant Deshmukh (Secretary -Progressive Education Society) and
          Prof. Jyotsna Ekbote (Joint Secretary – Progressive Education Society)
          were preside over the function as guest of honour.
        </p>
        <h6 className="text-bold mb-4">Other Details of Induction Program</h6>
        <Paper elevation={1}>
          <TableComponent tableHeading={heading} tableData={data} />
        </Paper>
      </div>
    </>
  );
}

export function Induction2014() {
  const heading = ["Sr No.", "Date", "Time", "Speaker", "Topic", "Venue"];

  const data = [
    {
      col1: "20/08/2014",
      col2: "2.30 PM",
      col3: "Dr. Prasanna Gadre",
      col4: "Communication Skills",
      col5: "Seminar Hall-MIBM",
    },
    {
      col1: "21/08/2014",
      col2: "10.00 AM",
      col3: "Dr. Bhooshan Kelkar",
      col4: "Career Building",
      col5: "Seminar Hall-MIBM",
    },
    {
      col1: "21/08/2014",
      col2: "2.30 PM",
      col3: "Mr. Amarendra Chitale",
      col4: "Setting Goals in all areas of Life and Building Rapport to achieve networking excellence",
      col5: "Seminar Hall-MIBM",
    },
  ];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4">MBA Induction 2014</h6>
        <p>
          Inauguration Function of <b> “MBA INDUCTION 2014” </b>was held on 20th
          August 2014 at 11.00 AM at Progressive Education Society’s Auditorium
          and inauguration was done by{" "}
          <b> Honorable Shree Avinash Dharmadhikari </b>(Ex-IAS & Founder –
          Chanakya Mandal) as Chief Guest and Honorable Prof. Dr. Gajanan Ekbote
          (Chairman- Progressive Education Society) as Guest of Honour.
        </p>
        <h6 className="text-bold mb-4">Other Details of Induction Program</h6>
        <Paper elevation={1}>
          <TableComponent tableHeading={heading} tableData={data} />
        </Paper>
      </div>
    </>
  );
}

// -------------------------------------------MBA Induction ---------------------
export function Collaborations() {
  const heading = [
    "Sr No.",
    "List of Organization with which MoU is signed for Collaboration",
    "List the  actual  activities under each MoU",
  ];

  const data = [
    {
      col1: "Abhanti Consultants, Pune",
      col2: "Expert Sessions, Certificate Courses",
    },
    {
      col1: "Pace Career Academy, Pune",
      col2: "Expert Sessions, Certificate Courses",
    },
    {
      col1: "DCRK and Associates, Pune",
      col2: "Placement Assistance",
    },
    {
      col1: "Pro-F Consultants, Pune",
      col2: "Expert Sessions, Certificate Courses",
    },
    {
      col1: "Punekar Edutours Forum, Pune",
      col2: "Educational Tours, Industrial Visit",
    },
    {
      col1: "All India Management Association (AIMA)",
      col2: "Learning Resource",
    },
    {
      col1: "DELNET",
      col2: "Learning Resource",
    },
    {
      col1: "EBSCO",
      col2: "Learning Resource",
    },
    {
      col1: "J-Gate",
      col2: "Learning Resource",
    },
    {
      col1: "XL Comn Solutions, Pune",
      col2: "Expert Sessions, Certificate Courses",
    },
    {
      col1: "Modern College of Art Science and Commerce, Pune",
      col2: "Media Center",
    },
    {
      col1: "British Council Division, Pune",
      col2: "Learning Resource",
    },
    {
      col1: "e-Shodhsindhu",
      col2: "Learning Resource",
    },
    {
      col1: "National Digital Library",
      col2: "Learning Resource",
    },
    {
      col1: "PES’s Auditorium, Pune",
      col2: "Cultural Activities, Games",
    },
    {
      col1: "PES’s Modern Boys School, Pune",
      col2: "Yoga Sessions",
    },
    {
      col1: "Bennett Coleman and Company Ltd",
      col2: "Newspaper Subscription , Summer internship",
    },
    {
      col1: "Rotaract Club, Pune",
      col2: "Social Activities, Expert Sessions",
    },
    {
      col1: "Ekbote Hospital, Pune",
      col2: "Emergency Medical Services",
    },
  ];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">Collaborations</h6>

        <p data-aos="fade-left">
          MIBM offers various certified courses in collaborations with various
          companies. Certified course on MS-Excel, Word and PowerPoint are
          offered to improve analytical and presentation skills of students.
          Institute offers value-added courses to second-year students based on
          their specialization to bridge the academia-industry gap, viz.
          Technical Analysis in to understand share price valuation, Digital
          Marketing to know current trends in marketing and Payroll Management
          to understand salary structure and payment process in the industry.
          Institute also organizes the Employability Enhancement Program for the
          students to improve placement opportunities.{" "}
          <b>
            {" "}
            Abhanti Consultants, Pace Career Academy, Pro-F Consultants, XL Comn
            Solutions, Eighth Innovative Consultants Pvt Ltd, Globe Investment,
            Webclincher are some of the companies which are associated with the
            institute.
          </b>
        </p>
        <p>
          Following is the list of activities conducted by MIBM in collaboration
          with various companies.
        </p>
        <Paper elevation={1}>
          <TableComponent tableHeading={heading} tableData={data} />
        </Paper>
      </div>
    </>
  );
}

export function ManagementFest() {
  const img_mangalya_2023_24 = [mangalya_2023_24_img_1, mangalya_2023_24_img_2];
  const tabData = [
    {
      label: "Mangalya 9.0",
      content: (
        <Paper
          elevation={1}
          sx={{ backgroundColor: "ButtonFace", padding: "10px", margin: "0%" }}
        >
          <Images images={img_mangalya_2023_24} />
          <Paragraphs description={mangalya_9} />
        </Paper>
      ),
    },
    {
      label: "Mangalya 8.0",
      content: (
        <Paper
          elevation={1}
          sx={{ backgroundColor: "ButtonFace", padding: "10px", margin: "0%" }}
        >
          <Paragraphs description={mangalya_8} />
        </Paper>
      ),
    },
  ];
  return (
    <>
      <h6 className="text-bold title-text mb-4 decorated">MANAGEMENT FEST</h6>
      <div className="P-tag" data-aos="fade-left">
        <p>
          {" "}
          <b>“Mangalya” </b>is Flagship Event of PES’s Modern Institute of
          Business Management. It is an Annual Management Fest which consists of
          various management games, sports and most important fun for our
          students as well for participants.
        </p>
        <p>
          Mangalya Signifies piousness and purity. Piousness & Purity have
          always been foundation of Indian knowledge. Mangalya strives to
          establish the relevance of the teachings of Ancient Indian Scriptures
          in the practice of Modern Management.
        </p>
        <p>
          This annual fest gives hands on experience to our students in the form
          of planning, organizing, team work etc. Every year a particular unique
          theme is decided for the fest and based on the same various management
          games are planned & named and decoration is done accordingly.
          Management Games where institute gets maximum participation are Case
          Study Competition, New Product Development, Role Plays etc. Senior
          academicians or industry experts are appointed as Judge. Fun games
          include One Minute Games,Mad-Ad etc and Sports include few in-door as
          well as out-door games.
        </p>
        <p>
          It is most awaited fest of our institute where in students comes out
          of their watertight compartments and work together. Our students as
          well Participants get chance to showcase their hidden talents.
          Students show full enthusiasm and feel motivated. Lastly, at Prize
          Distribution Ceremony winners are rewarded with medals and Prestigious
          Rolling Trophy is given to the college or Institute from where maximum
          participation and winning happens.{" "}
        </p>
        <p>This fest creates life long memories for our students. </p>
      </div>
      <TabComponent tabData={tabData} />
    </>
  );
}

export function Cultural() {
  const culturalData = [
    { label: "Darpan", content: <Darpan /> },
    { label: "Kala Darpan", content: <Kaladarpan /> },
    { label: "Sports", content: <Sports /> },
  ];
  return (
    <>
      <div className="P-tag">
        <h6 className="text-bold title-text mb-4 decorated">
          CULTURAL AND SPORT ACTIVITIES
        </h6>
        <TabComponent tabData={culturalData} />
      </div>
    </>
  );
}

export function Darpan() {
  const darpanData = [
    { label: "Darpan 2024", content: <Darpan2024 /> },
    { label: "Darpan 2023", content: <Darpan2023 /> },
    { label: "Darpan 2022", content: <Darpan2022 /> },
    { label: "Darpan 2020", content: <Darpan2020 /> },
  ];
  return (
    <>
      <h6 className="text-bold title-text my-2">Darpan</h6>
      <p data-aos="fade-left">
        Every year Institute organises cultural fest- Darpan (mirror), it is a
        platform for students to reflect their inner talent, Institute believe
        in over all development of students and not just academics. Cultural
        fest is generally spread over 3 days. Various activities, games are
        organised throughout the event. At conclusion of event Institute
        acknowledges the hard work, dedication and commitment of first and
        second year students shown throughout the year. It is an awe-inspiring
        end to remarkable Cultural fest.
      </p>
      <TabComponent tabData={darpanData} />
      <Divider sx={{ border: 1 }} />
    </>
  );
}

export function Kaladarpan() {
  return (
    <>
      <h6 className="text-bold title-text my-2">Kala Darpan</h6>
      <p data-aos="fade-left">
        The Institute organizes intercollegiate Dance Competition under the
        Banner of “Kala Darpan” Kala Darpan is a Platform for the students to
        showcase their Dance skills. Kala Darpan comprises of inter collegiate
        competition of Solo and Group Dances whereas, we encourage overall
        development of students and leave no stone unturned in giving them
        platform to grow, the Event is yet another initiative from our side to
        encourage students to showcase their talent.
      </p>
      <Divider sx={{ border: 1 }} />
    </>
  );
}

export function Sports() {
  return (
    <>
      <h6 className="text-bold title-text my-2">Sports</h6>
      <p data-aos="fade-left">
        MIBM also organize intercollegiate sports competition in the Management
        Fest Mangalya. Various games such as Carom, Chess, Badminton, Box
        cricket, etc are organized to channelize sport spirit among the
        students.
      </p>
    </>
  );
}
//Darpan 2024
export function Darpan2024() {
  return (
    <div>
      <h6 className="text-bold title-text mb-4">Darpan 2024</h6>
      <p>
        "<b>Darpan - 2024</b>": Where Brilliance Meets Bollywood and Alumni
        Reunite!
      </p>

      <p>
        "<b>Darpan - 2024</b>" marked an iconic moment in the annual calendar of
        the Modern Institute of Business Management, Pune. Bringing together
        stakeholders from various walks of life, this event served as a beacon
        of celebration, talent, and community spirit. With meticulous planning
        and unwavering dedication, the day unfolded in three distinct parts,
        each adding to the vibrancy and success of the occasion. From honouring
        academic excellence to showcasing cultural prowess and fostering
        connections with alumni, "<b>Darpan - 2024</b>" embodied the institute's
        ethos of holistic growth and collective achievement.
      </p>

      <p>
        Distinguished guests graced the event, including Chief Guests Prof.
        Shamkant Deshmukh, Secretary, P. E. Society, Shivajinagar, Pune, whose
        presence added prestige and wisdom to the proceedings. Among the present
        dignitaries were Prof. Dr. Nivedita Ekbote, Coordinator of MIBM, and
        Prof. Dr. Vijayalakshmi Srinivas, Director of MIBM, whose leadership and
        vision have been instrumental in shaping the institute's journey towards
        excellence.
      </p>

      <p>
        As the sun rose on March 23, 2024, the Auditorium at Progressive
        Education Society, Shivaji Nagar, Pune, 5, came alive with anticipation,
        setting the stage for a day of inspiration, camaraderie, and
        unforgettable memories.
      </p>
      <a
        href={darpan_2024_pdf}
        target="_blank"
        className="mx-0 title-text text-bold pdf Brochure w-100"
      >
        <PictureAsPdfIcon className="mx-2" />
        Darpan 2024 Report
      </a>
    </div>
  );
}

//Darpan 2023
export function Darpan2023() {
  const darpan_images = [darpan_2023_1, darpan_2023_2];
  return (
    <div>
      <h6 className="text-bold title-text mb-4">Darpan 2023</h6>
      <p>
        <b>PES’S MODERN INSTITUTE OF BUSINESS MANAGEMENT DARPAN 2023</b>
      </p>
      <Images images={darpan_images} />
      <p>
        On <b>29th May 2023, Monday,</b> Modern institute of business management
        celebrated Darpan, their annual gathering function. Darpan was filled
        with exciting dance performances and soothing singing performances. This
        was held in the auditorium of Modern College of engineering. MIBM 1st
        and 2nd year students along with the teaching and non-teaching faculty
        collectively organised Darpan. The creative anchoring team kept the
        entire room entertained for the entire duration.
      </p>
      <p>
        The guest speaker for the event was{" "}
        <b>Honorable Jyostna Ekbote Madam</b> who encouraged all the students by
        her words. <b>Honorable Nivedita Ekbote Madam</b> was present for the
        event as well. And she shared a very important topic with everyone i.e.
        Autonomy.
      </p>
      <p>
        All the performances were judged by none other than{" "}
        <b>Dhanashree Deshmukh Madam</b>. The winners were declared close to the
        end of the evening, and they were:
      </p>
      <ul>
        <li>Poem – Atharva Karande</li>
        <li>Singing – Akash Gaikwad</li>
        <li>Solo dance – Pradnya Khandekar</li>
        <li>Group dance – Darshana Gire & Group</li>
        <li>One-act play - Siddheshwar Malabade, Gayatri Sonawane.</li>
      </ul>
      <p>
        The evening was perfectly ended with a ramp walk by the students and its
        theme was states of India.
      </p>
    </div>
  );
}

//Darpan 2022
export function Darpan2022() {
  const retro_images = [retro_1, retro_2, retro_3, retro_4, retro_5];
  const traditional_images = [
    traditional_1,
    traditional_2,
    traditional_3,
    traditional_4,
  ];
  const final_images = [final_1, final_2, final_3, final_4];
  return (
    <div>
      <h6 className="text-bold title-text mb-4">Darpan 2022</h6>
      <p>
        <b>PES’S MODERN INSTITUTE OF BUSINESS MANAGEMENT</b>
      </p>
      <p>
        <b>REPORT ON</b>
      </p>
      <p>
        <b>DARPAN 2022</b>
      </p>

      <p>
        The Annual Cultural Programme and Prize Distribution ceremony
        i.e.‘Darpan’ was held on 25th June 2022. It started with Traditional Day
        & ended with the dance competitions. Our cultural fest– Darpan, is a
        platform for students to reflect their inner talent, our Institute
        believes in over all development of the students along with academics
        hence, co-curricular activities play a very crucial role in making
        students an all-rounder.
      </p>

      <p>
        On 23rd June 2022 Modern Institute of Business Management had turned
        into sets of Bollywood because it was a Retro Day. The Decoration team
        had done a fabulous work of Retro theme decoration. Students were all
        clad in Bollywood filmy style attire of their favourite actors and
        actresses between 1950s and 1990s’. Many students performed dances and
        some also delivered famous dialogues of the movies of the good ol’ days.
        It was overall a fun-filled day which took us back to the era of amazing
        actors and actresses. All students performed Retro theme with big
        enthusiasm & students were appreciated with gifts for their best
        performances & being best dressed.
      </p>

      <p>Snap shot of the Retro Day :</p>
      <Images images={retro_images} />
      <p>
        On 24th June Traditional day was organised in the Seminar hall at the
        Institute. The response from students was phenomenal. Students came
        fully clad in n their traditional attires and looked graceful. The
        traditional look of all the students was amazing as they collectively
        displayed an array of colors that reflected the diversity of India. Ramp
        walk kept the audience enthralled.
      </p>

      <p>Here are the Snap Shot of the Traditional Day :</p>
      <Images images={traditional_images} />
      <p>
        On 25th June Institute organised annual gathering and dance competition
        was arranged in the auditorium, one of the main event of Darpan. Twenty
        four performances of group dances, solo dances, fashion show, solo
        singing & poetry were shortlisted. The occasion was graced by Mr.Atharva
        Sudame who is an Instagram influencer of the youth, and Mrs.Neha
        Deshpande who had been a contestant in Dancing queen, a TV show that was
        aired on Zee Marathi channel. The performing students looked vibrant in
        their colourful costumes. Different kinds of dance forms: classical,
        contemporary, western and folk etc. were performed by the participants.
        The vivacious dancers set the floor on fire with their energetic
        performances and this was a reflection of their passion and hard work.
        The choreography, rhythm, synchronization and presentation of the
        performing students left all the audience mesmerised. This opportunity
        helped the students to showcase their hidden talent. While performing
        the fashion show which was the last part of the event was a
        representation of regions, states, the students learned to respect the
        large cultural diversity of India and the programmes also showcased the
        harmonious relations amongst students.
      </p>

      <p>
        The day came to end with prize distribution ceremony awards wherein
        judges congratulated the winners of the dance competition and
        appreciated the performances and encouraged the students. Then there
        were awards for most enthusiastic students, trade fair competition
        winners, poster competition winners, student of the year and many more
        were awarded to first year as well as second year students, with the
        idea to encourage and motivate them.
      </p>

      <p>Here are the Snap Shot of the Final Darpan Day:</p>
      <Images images={final_images} />
      <p>Thus the event was a grand success and a day of joy for all ..</p>

      <p>
        <b>Darpan Faculty Members Team Darpan Student Representative</b>
      </p>
      <p>
        Prof. Rupali, Prof. Rohit, Prof. Prapti Vikrant Dhotre, Prassana Kale,
        Ajinkya Jogdand
      </p>
    </div>
  );
}

//Darpan 2021
export function Darpan2020() {
  return (
    <div>
      <h6 className="text-bold title-text mb-4">Darpan 2020</h6>
      <p>
        <b>
          PES’S MODERN INSTITUTE OF BUSINESS MANAGEMENT REPORT ON DARPAN 2020
        </b>
      </p>
      <p>
        The Annual Cultural Programme and Prize Distribution ceremony i.e.
        ‘Darpan’ was held on <b>29th February 2020.</b> Our cultural fest–
        Darpan, it’s a platform for students to reflect their inner talent,
        Institute believes in over all development of students along with
        academics. Co-curricular activities play a very crucial role in making
        students an all-rounder.
      </p>
      <p>
        On day one traditional day was organised at the Institute. The response
        from students was phenomenal. Students made it very clear that even
        though we are modern and progressing ahead but have not forgotten our
        culture, values and tradition. They made the beginning colourful and
        culture rich.
      </p>
      <p>
        On day two Institute organised annual gathering and dance competition.
        One cannot and does not know how much talent in what direction lies.
        This can only come out when he or she is given that opportunity, and
        this event is that opportunity for students to develop self-confidence,
        overcome stage fear and showcase their talent.
      </p>
      <p>
        Our students are completely involved in planning organising and
        implementation of the entire event. The cultural programme consisted of
        various performances inclusive of solo, duet and group dance
        performances, dramas and fashion shows wherein students showed their
        extra-ordinary talents. Dance has traditionally been an important part
        of religion and culture in India, dance is divine, it’s of great
        significance and so we had organized dance competition too.
      </p>
      <p>
        The occasion was graced by <b>Mrs. Meghana Deshmukh,</b> Director,
        talent acquisition, Pubmatic Pvt Ltd. The day came to end with prize
        distribution ceremony awards like Award for maximum attendance, best
        project, optimum utilization of library, maximum intercollegiate
        participation, toppers and many more were awarded to first year as well
        as second year students, with the idea to encourage and motivate them.
      </p>
      <p>
        The event was a grand success and we all thoroughly enjoyed cultural
        fest, it is indeed a platform for students to showcase their talent.
      </p>
    </div>
  );
}
// --------------------------------- for ActivitiesRejoice tabs -------------------------------------------
export function ActivitiesRejoice() {
  const tabData = [
    { label: "TREE PLANTATION", content: <Tree_Plantation /> },
    {
      label: "SWACHH BHARAT ABHIYAAN PARVATI HILL, PUNE",
      content: <PARVATI_HILL />,
    },
    {
      label: "SWACHH BHARAT ABHIYAAN, SHANIWAR WADA",
      content: <Shaniwar_Wada_Abhiyan />,
    },
    { label: "TRAFFIC AWARENESS", content: <TRAFFIC /> },
    { label: "THE FOOD DONATION CAMP", content: <FoodDonate /> },
  ];
  return (
    <>
      <div className="P-tag">
        <h6 className="text-bold title-text mb-4 decorated">
          EXTENSION ACTIVITIES-REJOICE
        </h6>
        <div data-aos="fade-left">
          <p>
            MIBM understands Institutional Social Responsibility (ISR) and
            undertakes various extension activities on regular basis. These
            activities have profound impact on sensitizing students towards
            social issues and developing their holistic personality.
          </p>
          <p>
            Institute organizes various activities under{" "}
            <b> ‘Rejoice-The Joy of Giving’.</b> These activities are organized
            with following broad objectives:
          </p>
          <ul className="ul-tag">
            <li>
              To sensitize the students towards community development issues
              such as women empowerment, social inequality, gender disparities
              etc.,
            </li>
            <li>To inculcate social values in students</li>
            <li>
              To lay emphasis on academic excellence as well as overall
              development of students through extension activities.
            </li>
          </ul>
        </div>
        <Divider sx={{ border: 1 }} />
        <h6 className="text-bold my-3">
          Following activities are conducted by MIBM
        </h6>
        <h5 className="text-bold my-2 P-tag">2021-22</h5>
      </div>
      <Paper sx={{ border: "2px solid grey" }}>
        <TabComponent tabData={tabData} />
      </Paper>
      <div>
        <h6 className="text-bold title-text my-3">2018-19</h6>
        <ul className="ul-tag" data-aos="fade-left">
          <li>Visit to Kamyani Vidya Mandir</li>
          <li>Visit to Orphanage</li>
          <li>Tree Plantation</li>
          <li>Anna-Daan( Food Donation)</li>
          <li>Blood Donation Camp</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h6 className="text-bold title-text my-3">2017-18</h6>
        <ul className="ul-tag" data-aos="fade-left">
          <li>Traffic Awareness</li>
          <li>Gratitude Day</li>
          <li>Visit To Old Age Home</li>
          <li>Blood Donation (Term Two)</li>
          <li>Visit to Blind School</li>
          <li>Visit To NGO</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h6 className="text-bold title-text my-3">2016-17</h6>
        <ul className="ul-tag" data-aos="fade-left">
          <li>Visit Modern Boys School, Pune</li>
          <li>Visit to Orphanage Home</li>
          <li>Traffic Awareness</li>
          <li>Tree Plantation</li>
          <li>Blind School Visit</li>
          <li>Blood Donation Camp</li>
          <li>NGO Visit</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h6 className="text-bold title-text my-3">2015-16</h6>
        <ul className="ul-tag" data-aos="fade-left">
          <li>Traffic Awareness</li>
          <li>Visit to Orphanage Home</li>
          <li>Literacy Program</li>
          <li>Blood Donation Camp</li>
          <li>Tree Plantation</li>
          <li>Blind School Visit</li>
          <li>NGO Visit</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h6 className="text-bold title-text my-3">2014-15</h6>
        <ul className="ul-tag" data-aos="fade-left">
          <li>Blind School Visit</li>
          <li>Literacy Program</li>
          <li>Blood Donation Camp</li>
          <li>NGO Visit</li>
          <li>Hygiene Awareness Program</li>
        </ul>
      </div>
    </>
  );
}
export function Tree_Plantation() {
  const treePlantation = [tree1, tree2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          REJOICE-THE JOY OF GIVING 7TH - 12TH FEBRUARY, 2022
        </h6>
        <p>
          MIBM organized a Tree Plantation program where the students of MBA I
          and MBA II participated by rooting various types of plants. This was
          held at ARAI Hills and Taljai Tekdi on 10th and 12th Feb 2022. This
          was an initiative to encourage afforestation.
        </p>
        <h6 className="my-4">Tree Plantation Programme - Arai Hills</h6>
        <GalleryComponent images={treePlantation} />
      </div>
    </>
  );
}
export function PARVATI_HILL() {
  const ParvatiHill = [hill1, hill2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          SWACHH BHARAT ABHIYAAN 11TH FEBRUARY, 2022 PARVATI HILL, PUNE
        </h6>
        <p>
          MIBM organised a Cleanliness Awareness Campaign, also known as “SWACHH
          BHARAT ABHIYAAN” under Rejoice - The Joy of Giving at Parvati Hill
          Pune. With prior permission from local authorities and precautionary
          measures with the pandemic, around 20 students gathered at the foot of
          Parvati Hill on 11th of February at 9.00am. Trekking those 108 steps
          up to the Parvati Hill Temple was so refreshing! Everyone wore gloves
          and scattered around the place to collect garbage i.e. plastic, papers
          etc. under the supervision of Prof. Dr.Harsha Thorve. All the garbage
          collected was then disposed at the common dumping ground. We got an
          insight into Pune’s history when we visited the Temple built during
          the Peshwa dynasty and the Peshwa Museum. Everyone had a unique
          experience participating in this event with the sense of
          responsibility towards the society.
        </p>
        <h6 className="my-4">MBA I</h6>
        <div>
          <GalleryComponent images={ParvatiHill} />
        </div>
      </div>
    </>
  );
}
export function Shaniwar_Wada_Abhiyan() {
  const abhiyan = [abhiyan1, abhiyan2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          SWACHH BHARAT ABHIYAAN 10TH FEBRUARY, 2022 SHANIWAR WADA
        </h6>
        <p>
          The Swachh Bharat Abhiyaan activity under Rejoice was conducted at
          'Shaniwar Wada' under the guidance of Prof. Dr. Harsha Thorve. This
          activity promotes cleanliness and has an impact on society. This
          awareness was held at 'Shaniwar Wada' – a prominent and historical
          landmark of Pune city. Students gathered and started collecting and
          bagging garbage which continued till 12 PM. Around 30 students
          contributed actively to this. The activity concluded with encouraging
          words from Dr. Rajesh Raut, Prof. Rohit Alandikar and Prof. Joshua
          Xavier. The activity was successfully executed while following all
          guidelines and COVID norms.
        </p>
        <div>
          <GalleryComponent images={abhiyan} />
        </div>
      </div>
    </>
  );
}
export function TRAFFIC() {
  const Traffic = [Traffic1, Traffic2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          TRAFFIC AWARENESS 9TH FEBRUARY, 2022
        </h6>
        <p>
          Road safety is one of the major concerns of the 21st century. To
          address this concern, a road safety week is celebrated all around the
          country. PES’s Modern Institute of Business Management recently
          organized Rejoice - The Joy Of Giving. A Traffic Awareness programme
          was conducted on 9th February, 2022 by representative PI Shubham Patil
          and under the guidance of our Prof. Dr. Harsha Thorve. Conducted on
          two places – Good Luck Chowk and Jhansi Rani Chowk, (J.M.Road) the
          students split into 2 groups at both places. This was supported by
          faculty members Prof. Rupali Mane, Dr. Shrawan Ovhal, Prof. Prasanna
          Puranik, Prof. Rohit Alandikar. The students prepared posters to
          spread awareness. Roads are an integral part of our life. Each one of
          us commute to school, offices, markets, hospitals, and return. Safe
          driving is an essential component of road safety. Every day, so many
          lose lives due to irresponsible and reckless driving. This campaign
          served its purpose to ensure road safety by sensitizing the concept of
          safe driving among people, especially youngsters and children.
        </p>
        <div>
          <GalleryComponent images={Traffic} />
        </div>
      </div>
    </>
  );
}
export function FoodDonate() {
  const foodDonate = [food1, food2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          THE FOOD DONATION CAMP 7TH – 12TH FEBRUARY, 2022
        </h6>
        <p>
          Conducted between 7th February to 12th February, an awareness spread
          the appeal to people to come forward and donate. A digital post was
          then circulated through social media which spread an awareness to many
          more people about food donation. Many students contributed to this on
          an individual level in their locality. This was arranged in front of
          the MIBM office supported by donation boxes that were decorated with a
          social message. Proper posters were placed behind the table so that
          everyone could locate places with ease.
        </p>
        <div>
          <GalleryComponent images={foodDonate} />
        </div>
      </div>
    </>
  );
}
// ------------------------ for ActivitiesRejoice tabs -------------------------------------------
export function AntiRagging() {
  const heading = ["Sr No.", "Name of Members", "Designation", "Email"];

  const data = [
    {
      col1: "Dr. Nivedita Ekbote",
      col2: "Coordinator",
      col3: "niveditae@gmail.com",
    },
    {
      col1: "Prof. Harsha Thorve",
      col2: "Assistant Professor",
      col3: "Harsha.mibm@gmail.com",
    },
    {
      col1: "Dr. Vijayalakshmi Srinivas",
      col2: "DIRECTOR",
      col3: "director mibm@gmail.com",
    },
    {
      col1: "Dr. Sachin Hatole",
      col2: "Assistant Professor",
      col3: "sachinhatole7@gmail.com",
    },
    {
      col1: "Ms. Sejal Yadav",
      col2: "MEMBER(PUBLIC)",
      col3: "khiregauri@gmail.com",
    },
    {
      col1: "Mrs. Monika Vaidya",
      col2: "MEMBER(PARENT)",
      col3: "os.mibm@gmail.com",
    },
    {
      col1: "Sarvesh Vaidya",
      col2: "MEMBER(STUDENT)",
      col3: "os.mibm@gmail.com",
    },
  ];
  return (
    <>
      <h5 className="title-text my-2 mb-4 decorated">Anti-Ragging Grievance</h5>
      <Paper
        elevation={3}
        className="p-3 text-justify"
        style={{ minHeight: "300px" }}
      >
        <div>
          <p data-aos="fade-left">
            Declaration is given that the following members are appointed as the
            Anti-Ragging Committee of PES Modern Institute of Business
            Management, Shivajinagar, Pune-05.
          </p>
          <div>
            <Paper elevation={1}>
              <TableComponent tableHeading={heading} tableData={data} />
            </Paper>
          </div>
        </div>
      </Paper>
    </>
  );
}

export function ComplaintCommittee() {
  const heading = [
    "Sr No.",
    "Name ,Designation and address of the committee members",
    "Contact Number",
    "Position in the committee",
  ];

  const data = [
    {
      col1: "Prof.Dr.Vijayalaxmi Srinivas",
      col2: "9011669393",
      col3: "Chairman",
    },
    {
      col1: "Prof.Dr.Harsha Thorve",
      col2: "9637952439",
      col3: "Member",
    },
    {
      col1: "Prof.Rohit Aalandikar",
      col2: "9561822966",
      col3: "Member",
    },
    {
      col1: "Mr.Nilesh Kachi",
      col2: "9665746442",
      col3: "Member",
    },
    {
      col1: "Mr.Prithviraj Patil",
      col2: "8888359817",
      col3: "Member",
    },
  ];

  return (
    <>
      <h5 className="title-text my-2 mb-4 decorated">
        Internal Complaint Committee
      </h5>
      <Paper
        elevation={3}
        className="p-3 text-justify"
        style={{ minHeight: "300px" }}
      >
        <div>
          <p className="P-tag" data-aos="fade-left">
            PES’Modern Institute of Business Management is committed to
            providing safe academic and working environment to all students and
            its women employees. As per the guidelines of Supreme Court, UGC,
            Sexual Harassment of Women at Workplace (Prevention, Prohibition &
            Redressal) Act, 2013, an Internal Complaints Committee has been
            established by the College.Institute revise the members of the
            committees as per the requirements
          </p>
          <Divider sx={{ border: 1 }} />
          <h6 className="text-bold my-3 title-text">OBJECTIVES :</h6>
          <p className="P-tag">
            The objectives of the Internal Complaint Committee to Prevent Sexual
            Harassment of Women at the Workplace are as follows:
          </p>
          <div data-aos="fade-left">
            <p>
              {" "}
              1. To develop a policy against sexual harassment of women at the
              Institute.
            </p>
            <p>
              2. To evolve a permanent mechanism for the prevention and
              redressal of sexual harassment cases and other acts of
              gender-based violence at the Institute.
            </p>
            <p>
              3. To ensure the implementation of the policy in letter and spirit
              through proper reporting of the complaints and their follow-up
              procedures.
            </p>
            <p>
              4. To uphold the commitment of the Institute to provide an
              environment free of gender-based discrimination.
            </p>
            <p>
              5. To create a secure physical and social environment to deter any
              act of sexual harassment.
            </p>
            <p>
              6. To promote a social and psychological environment to raise
              awareness on sexual harassment in its various forms.
            </p>
          </div>
          <div>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold my-3 title-text">MEMBERS : </h6>
            <Paper elevation={1}>
              <TableComponent tableHeading={heading} tableData={data} />
            </Paper>
          </div>
        </div>
      </Paper>
    </>
  );
}

export function GrievanceRedressal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const heading = ["Sr No.", "Name of Members", " Designation"];

  const data = [
    {
      col1: "Prof. Dr. Nivedita Ekbote",
      col2: "CHAIR PERSON",
    },
    {
      col1: "Prof. Dr. Harsha Thorve",
      col2: "MEMBER",
    },
    {
      col1: "Prof. Rupali Mane",
      col2: "MEMBER",
    },
    {
      col1: "Prof. Dr. Rohit Alandikar",
      col2: "MEMBER",
    },
    {
      col1: "Mr. Pintu Nivangune",
      col2: "MEMBER",
    },
    {
      col1: "Mr. Sandeep Shirole",
      col2: "MEMBER",
    },
    {
      col1: "Mr. Rushikesh Kale",
      col2: "STUDENT",
    },
    {
      col1: "Ms. Siddhi Pukale",
      col2: "STUDENT",
    },
  ];
  return (
    <>
      <h5 className="title-text my-2 mb-4 decorated">
        Grievance Redressal Committee
      </h5>
      <Paper
        elevation={3}
        className="p-3 text-justify"
        style={{ minHeight: "300px" }}
      >
        <div>
          <p data-aos="fade-left">
            Declaration is given that the following members are appointed as the
            Grievance Redressal Committee of PES Modern Institute of Business
            Management, Shivajinagar, Pune-05.
          </p>
          <div>
            <Paper elevation={1}>
              <TableComponent tableHeading={heading} tableData={data} />
            </Paper>
          </div>

          <div className="py-4">
            <h6 className="my-4 px-1 ">
              Enabling people to put their grievances in the given message box.
            </h6>
            <>
              <form>
                <TextField
                  id="outlined-basic"
                  className="w-100"
                  type="text"
                  label="Grievances Message.."
                  placeholder="Type Here.."
                  variant="outlined"
                />
                <input
                  type="reset"
                  value="Submit"
                  className="mt-3 btn btn-primary"
                  onClick={handleClickOpen}
                />

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Message has been Saved."}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose} type="reset">
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </>
          </div>
        </div>
      </Paper>
    </>
  );
}
//---------------------------------InfrastructureData-----------------------

export function Campus() {
  return (
    <>
      <div>
        <div className="mx-5">
          <h5 className="title-text text-bold fs-3">Campus</h5>
          <div className="my-2 d-flex justify-content-center flex-wrap">
            <div className="galleryImgBorders" data-aos="fade-left">
              <img
                className="galleryImgBorders"
                src={campus}
                width="500"
                maxHeight="350"
                style={{ margin: "8px" }}
                alt="Refresh"
              />
            </div>
            <div className="my-4 P-tag text-justify">
              <p>
                Progressive Education Society’s Modern Institute of Business
                Management (MIBM) encourages students from all faith backgrounds
                to find their God-designed potential for leadership, scholarship
                and service. At MIBM, students develop spiritual and
                professional gifts; find professors who care for and respect the
                individual; and procure opportunities to create, learn and
                connect.
              </p>
              <p>
                MIBM offers active learning in a vibrant atmosphere where
                students connect the classroom and their world. MIBM’s nurturing
                environment offers modern management education combined with
                practical experiences such as internships, academic and career
                counseling and summer internship projects.
              </p>
              <p>
                Progressive Education Society’s Modern Institute of Business
                Management (MBM), is centrally located institute in Shivaji
                Nagar, Pune. Institute has stand alone building in heart of the
                city with all the necessary facilities. It’s just 1.0 km from
                Shivaji Nagar bus stop and railway station, 650 meter from Pune
                Municipal Corporation bus stop, 3.0 km from Pune railway
                station, 5.0 km from Swargate bus stop and 12.0 km from Pune
                airport. MIBM is just besides J. M. Road (Jangli Maharaj Road),
                1.0 km from F. C. Road (Ferguson College). Overall connectivity
                is best for MIBM from any part of the city.
              </p>
              <p>
                Campus is well constructed and designed for MBA students in
                every aspect from classroom to parking area. It has everything
                which an MBA college should possess.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function Classroom() {
  return (
    <>
      <div>
        <div className="mx-5">
          <h5 className="title-text text-bold fs-3">Classroom</h5>
          <div className="my-2 d-flex justify-content-center flex-wrap">
            <div className="galleryImgBorders" data-aos="fade-left">
              <img
                className="galleryImgBorders"
                src={classroom}
                width="500"
                maxHeight="350"
                style={{ margin: "8px" }}
                alt="Refresh"
              />
            </div>
            <div className="my-4 P-tag text-justify">
              <p>
                MIBM has ample of classrooms for lectures, tutorials,
                discussions, and activities and separate common rooms for girls
                and boys. MIBM has separate classrooms for MBA first year for
                all the three divisions and MBA second for all specializations.
              </p>
              <p>
                Every classroom is consisting of high quality inbuilt projectors
                with projector screen, white boards well-furnished tables and
                benches. All the classrooms in MIBM are equipped with air
                conditioners.
              </p>
              <Link to="/ClassroomImages" target="_blank">
                View More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function Seminarhall() {
  return (
    <>
      <div>
        <div className="mx-5">
          <h5 className="title-text text-bold fs-3">Seminar Hall</h5>
          <div className="my-2 d-flex justify-content-center flex-wrap">
            <div className="galleryImgBorders" data-aos="fade-left">
              <img
                className="galleryImgBorders"
                src={seminarHall}
                width="500"
                maxHeight="350"
                style={{ margin: "8px" }}
                alt="Refresh"
              />
            </div>
            <div className="my-4 P-tag text-justify">
              <p>
                With the capacity of more than 300 students, MIBM has two fully
                equipped seminar halls. It includes, seating arrangement, AC,
                audio visual system, projector with screen, podium, executive
                chairs, etc. These seminar halls are used for guest lectures,
                small events, various student’s activities, seminars,
                conferences, presentations, tests, and role plays.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function ResearchLab() {
  return (
    <>
      <div>
        <div className="mx-5">
          <h5 className="title-text text-bold fs-3">Research Lab</h5>
          <div className="my-2 d-flex justify-content-center flex-wrap">
            <div className="galleryImgBorders" data-aos="fade-left">
              <img
                className="galleryImgBorders"
                src={researchLab}
                width="500"
                maxHeight="350"
                style={{ margin: "8px" }}
                alt="Refresh"
              />
            </div>
            <div className="my-4 P-tag text-justify">
              <p>
                With the capacity of more than 300 students, MIBM has two fully
                equipped seminar halls. It includes, seating arrangement, AC,
                audio visual system, projector with screen, podium, executive
                chairs, etc. These seminar halls are used for guest lectures,
                small events, various student’s activities, seminars,
                conferences, presentations, tests, and role plays.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// ---------------------------------InfrastructureData-----------------------

// ------------------------------------Book Review---------------------------

export const Book_Review_2021_22 = {
  cardTitle: "Book review competition held at MIBM on 5th July 2022.",
  cardImages: [BookReview2021_22_1, BookReview2021_22_2],
  cardDescription: [
    "Event Coordinators: Prof. Dr. Harsha Thorve, Prof. Sridevi Chennamsetti",
    "Books Categories: Fiction books, Self-help books, History books",
    "Intentions: Encourage book reading, knowledge sharing, improve communication skills, and stage confidence for students.",
    "Books Reviewed: The Secret, Chanakya Neeti, Think and Grow Rich, Chava (book on Chatrapati Sambhaji Maharaj), and many more.",
    "Winners:",
    "Winner: Mr. Ojas Mahajan (MBA second year),",
    "First Runner-up: Mr. Siddhant Shah,",
    "Second Runner-up: Ms. Nikita Bhagwat (MBA first year)",
    "Awards: Winners received medals and certificates from author Mr. Raghvan Kohli, writer of the book 'The Conqueror of Destiny,' and Dr. Vijayalakshmi Srinivas, Director, MIBM.",
    "Outcomes: The competition increased participants' interest in reading books and facilitated book exchanges among participants.",
  ],
};

export const Book_Review_2022_23 = {
  cardTitle:
    "Modern Institute of Business Management hosted its annual Book Review Competition on June 2nd, 2023, aimed at enriching both curricular and extracurricular knowledge among MBA I and MBA II students.",
  cardImages: [BookReview2022_23_1, BookReview2022_23_2, BookReview2022_23_3],
  cardDescription: [
    " The competition serves to promote reading and comprehension skills while encouraging students to delve into a variety of literary works.",
    "Event Coordinators: Prof. Dr. Harsha Thorve, Prof. Sridevi Chennamsetti",
    "Participants: MBA first year students, MBA second year students",
    "Review Criteria: Reason for choosing the book, Introduction to the book, Outline of book contents, Highlights of the book (strengths and weaknesses), Learning outcomes from the book",
    "Judge:",
    "Name: Dr. Shantashree Sengupta,",
    "Affiliation: Modern Institute of Arts and Commerce,",
    "Role: Librarian, Expertise: Tremendous knowledge about books and book reviewing",
    "Venue: Seminar hall",
    "Outcomes: Fostered innovation and self-reliance among attendees, Inspired a habit of book reading and built stage confidence",
    "Winners:",
    "First Place: Miss Shivani Deshmukh,",
    "Second Place: Miss Harshada Nichale,",
    "Third Place: Nikita Bhagwat",
    "Award Ceremony: Winners and runners-up were felicitated during the competition.",
    "Overall: The competition was deemed successful, showcasing students' passion for reading and their ability to gain insights from the books they enjoy.",
    "Faculty Incharge: Dr. Harsha Thorve, Prof. Sridevi Chennamsetti",
  ],
};

export function EcoCLubActivityData() {
  const tabData_22 = [
    {
      label: "Poster Making Competition",
      content: <Poster_Making_2022 />,
    },
  ];
  const tabData_23 = [
    { label: "World Bicycle", content: <World_Bicycle_2023 /> },
    {
      label: "Poster Making Competition",
      content: <Poster_Making_2023 />,
    },
    {
      label: "Tree Plantation",
      content: <Tree_Plantation_2023 />,
    },
    {
      label: "BEST OUT OF WASTE COMPETITON",
      content: <Waste_Competition_2023 />,
    },
  ];
  return (
    <>
      <h5 className="text-bold my-2 P-tag">2023</h5>
      <Paper sx={{ border: "2px solid grey" }}>
        <TabComponent tabData={tabData_23} />
      </Paper>
      <Divider sx={{ border: 1 }} />
      <h5 className="text-bold my-2 P-tag">2022</h5>
      <Paper sx={{ border: "2px solid grey" }}>
        <TabComponent tabData={tabData_22} />
      </Paper>
    </>
  );
}

export function World_Bicycle_2023() {
  const cycle = [cycle_23_1, cycle_23_2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          WORLD BICYCLE DAY - 3RD JUNE, 2023
        </h6>
        <p>
          Modern Institute of Business Management organized bicycle rally on 3rd
          June 2023 on the occasion of world Bicycle Day. More than 50
          enthusiasts participants who have come together for this rally.The
          route of the rally was started from JM Road and covered FC road.This
          rally provided message for all the students and communities about
          sustainable transporation and health and fitness.All the participants
          reported at the institute at 8.30am and rally ended up at 9.30am. The
          core committee member for the rally was Mr.Vikrant Dhotre ,
          Miss.Nikita Bhagavat Mr.Ajinkya Jogdand and Miss.Bharati . This
          initiative was taken by Prof. Dr.Harsha Thorve and Prof. Rohit
          Alandikar under the guidance of Prof.Dr.Nivdita Ekbote and
          Prof.Dr.Vijayalaxmi Srinivas.
        </p>
        <h6 className="my-4">-Prof.Dr.Harsha Thorve </h6>
        <h6 className="my-4">Associate Professor </h6>
        <GalleryComponent images={cycle} />
      </div>
    </>
  );
}

export function Waste_Competition_2023() {
  const waste = [waste_23_1, waste_23_2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          BEST OUT OF WASTE COMPETITION - 14th JUNE, 2023
        </h6>
        <p>
          PES’Modern Institute of Business Mangement arranged ‘Best out of Waste
          competition’ on 14th June 2023. The competition was based on creative
          and innovative utilization of waste materials which normally being
          discarded in our homes. The aim of this activity was to encourage
          students to use their creativity and innovation to use the waste
          materials available at their home, where the final output must-have
          utility. The participants were judged by Dr. Shrawan Owhal . In total
          9 students had participated for the said event. On the basis of waste
          materials used and utility of the final product, 3 students were
          shortlisted. These 3 students further gave their presentations before
          the judges followed by a question and answer session. The winners of
          the competition were as follows:,Miss.Divya Tanpure and Miss .Prerna
          Jarad .Ist runner up is Miss.Gauri Dhavare and Miss.Prjakta Kanchan
          .Iind Runner up Mr.Gaurav Aadhav and Mr.Vivek Joshi.
        </p>
        <GalleryComponent images={waste} />
      </div>
    </>
  );
}

export function Poster_Making_2023() {
  const posterMaking = [envo_23_1, envo_23_2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          POSTER MAKING COMPETITION - 5TH JUNE, 2023
        </h6>
        <p>
          On the occasion of world environment day 5th June 2023 PES’Modern
          Institute of Business Management organised poster making competition
          .The theme for the competition was the ‘Save Earth’ .11 groups were
          ragistered for the same.The venue for the competition was PES’MIBM
          library.Winner of the competition Mr.Vaibhav Ingale ,Mr.Vaishanvi
          Darekar,Tanay Nalkande .First runner up Miss.Shreya Patil,Miss.Shreya
          Hore Mr.Ajit Hambir and Miss.Aishwarya Dadas,Miss.Pradnya
          Darekar,Miss.Sayali Darekar and Miss.Arpita Kadam .Second Runner up
          was with the Ms.Shrawasthi D.Students created awarness about the earth
          that there is no planet B .So we as a society needs to save the earth.
          Prof.Trupti Bhalerao was the judge for the event.This initiative was
          taken by Prof. Dr.Harsha Thorve and Prof. Rohit Alandikar under the
          guidance of Prof.Dr.Nivdita Ekbote and Prof.Dr.Vijayalaxmi Srinivas.
        </p>
        <h6 className="my-4">-Prof.Dr.Harsha Thorve</h6>
        <h6 className="my-4">Associate Professor </h6>
        <GalleryComponent images={posterMaking} />
      </div>
    </>
  );
}

export function Tree_Plantation_2023() {
  const treePlantation = [plant_23_1, plant_23_2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          TREE PLANTATION - 7TH JUNE, 2023
        </h6>
        <p>
          PES’Modern Institute of Business Mangement arranged Tree plantation
          Program on 7th June 2023 on the occasion of Environment Awarness
          week.The aim of tree plantation is to restoring and sustaining the
          green environment.This year Institute organized Tree Plantation
          program in collbration with Rayat shantidoot Sanstha.The location of
          the tree plantation was Ghera Sinhgad.More than 15 people from local
          village with 30 students and 8 teaching and non teaching members
          participated for this.Participants and Teachers also discussed the
          importance of tree plantation and human sustainability to the
          students.
        </p>
        <h6 className="my-4">Mr.Vikrant Dhotre</h6>
        <GalleryComponent images={treePlantation} />
      </div>
    </>
  );
}

export function Poster_Making_2022() {
  const posterMaking = [envo_22_1, envo_22_2];
  return (
    <>
      <div>
        <h6 className="text-bold title-text mb-4 decorated">
          POSTER MAKING COMPETITION - 5TH JUNE, 2022
        </h6>
        <p>
          Modern Institute of Business Management organized poster competition
          on 5th June 2022 on the occasion of environment day.The objectives of
          the competition are to spread awareness about the environment.The
          theme of the competition was “Save Environment”under the ECO Club.
          Students were asked to express their ideas through paintings. Students
          covered different kinds of ideas like better environment ,better
          tomorrow ,There is no planet B,Saviours of Ecosystem etc.Total 9
          Groups participated for the same.The overall event coordinated by
          Prof.Dr.Harsha Thorve and Prof.Rohit Aalandikar
        </p>
        <GalleryComponent images={posterMaking} />
      </div>
    </>
  );
}

export function TradeFair2020() {
  return (
    <div>
      <h6
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        <b>“Trade Fair 2020”</b>
      </h6>
      <p>
        <b>
          On 31st Jan, 2020 Modern Institute of Business Management had
          organized a “Trade Fair” for the MBA-1st year students.
        </b>
      </p>
      <p>
        <strong>This event was organized by MBA I and II Year.</strong>
      </p>
      <p>
        The “Trade Fair” started at morning <b>10:00am</b> and ended by{" "}
        <b>2:30pm</b> afternoon.
      </p>
      <p>
        There were total <b>7 stalls</b> and each one was its kind showing their
        talents (like: There were some students selling{" "}
        <em>homemade chocolates</em>, a group of girls were made some flavor{" "}
        <em>PaniPuri</em>, a group were drawing <em>temporary tattoos</em>, some
        students arranged <em>1 min games</em> etc.)
      </p>
      <p>
        The Students showing their talents like <em>marketing skills</em> (how
        to attract customer, their marketing strategies)
      </p>
      <p>
        The Teaching staff and non-teaching staff equally contributed in the
        event.
      </p>
      <p>
        There were two judges for judging stall presentation and deciding the
        winners (on the bases of <em>Hygiene, Food Taste etc.</em>)
      </p>
      <p>
        At the end of the day the Director{" "}
        <strong>Dr. Vijayalaxmi Srinivas</strong> announced the winners. The
        first runner-up was “<b>YP chocolates</b>” (<em>Homemade chocolates</em>
        ); Second runner-up were “<b>CHASKA PaniPuri</b>” (
        <em>flavor PaniPuri</em>): Third runner-up were “<b>Food o’clock</b>”
        (Multiple items like <em>samosa, bhel, water bottles, game</em>).
      </p>
      <p>
        Each and everyone participated equally and had a great time and everyone
        enjoyed during event. That was a something different experience for
        MBA-1st year students as a fresher.
      </p>
      <p>
        <strong>Event Coordinators:</strong>
      </p>
      <p>
        <b>Dr. Harsha A. Thorve</b>
      </p>
      <p>
        <b>Prof. Priyadarshani Zanjurne</b>
      </p>
    </div>
  );
}

export function TradeFair2022_23() {
  const t_21_images = [TradeFair2021_1, TradeFair2021_2];
  return (
    <div>
      <h6
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        <b>“TRADE FAIR A.Y 2022-23”</b>
      </h6>
      <Images images={t_21_images} />
      <p>
        Our college <strong>Modern Institute of Business Management</strong>{" "}
        organised a trade fair on <strong>22nd March, 2022</strong> with great
        enthusiasm. About one week prior registrations had started. Coordinator
        of the event was <strong>Dr. Harsha Thorve</strong>.
      </p>

      <p>
        There were <strong>8 groups</strong> who had participated in the trade
        fair with different food items and fun games. Group members started
        preparing and decorating the stalls and at <strong>10 a.m.</strong> they
        were ready to serve the customers. Stalls were selling{" "}
        <em>
          popcorns, fruit dishes, mattha, sweet corn, fire paani puri, vidarbh
          special puran poli, soda, ice gola,
        </em>{" "}
        etc. and the games like{" "}
        <em>hit and drop the glasses and burst the balloons</em>.
      </p>

      <p>
        <strong>Mrs. Lawly Das ma’am</strong> from Modern College of Business
        Administration were judging the trade fair. They were looking how groups
        had managed the stalls, hygiene maintained, is their anything for best
        out of waste, etc. and by asking these questions they were giving points
        to the groups. Faculty of MIBM also enjoyed the games and food items of
        stalls and motivated students by appreciating them.
      </p>

      <p>
        At last at around <strong>4:30 p.m.</strong> Winners were announced in
        the presence of all faculty members and students.{" "}
        <strong>3rd prize holder group</strong> was ‘<b>Chaska Maska</b>’ who
        sold different flavours of mouthwatering mattha.{" "}
        <strong>2nd prize holder</strong> was the group named ‘
        <b>Lassi Wassi</b>’ who sold lassi. And the{" "}
        <strong>1st prize holder</strong> was the group named ‘
        <b>The Song of Ice and Fire</b>’ who sold different types of paani puri.
        It was a successful event and great experience for the students, faculty
        members and other staff. Participants learned a lot through this
        experience and had great memories that they will carry with them
        forever.
      </p>
    </div>
  );
}

// export function TradeFair2022_23() {
//   const t_22_images = [TradeFair2022_1];
//   return (
//     <div>
//       <h6
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           fontSize: "20px",
//         }}
//       >
//         <b>“TRADE FAIR A.Y 2022-23”</b>
//       </h6>
//       <Images images={t_22_images} />
//       <p>
//         Modern Institute of Business Management organised{" "}
//         <strong>trade fair on 22nd March, 2022</strong> with great enthusiasm.
//       </p>
//       <p>
//         About one week prior registrations had started under the guidance of{" "}
//         <b>Dr. Harsha Thorve</b>.
//       </p>
//       <p>
//         Students were directed to make teams of <b>5 or 7 members</b> including
//         one group leader and had to register their names and registration amount
//         i.e. <b>Rs.200 for 7 members</b> and <b>Rs. 150 for 5 members</b> with
//         the volunteer <b>Mr. Vikrant Dhotre</b>.
//       </p>
//       <p>
//         Accordingly all the students registered themselves till{" "}
//         <b>21st March</b>. On <b>22nd March</b>, all the students started
//         arriving at the college at <b>9 a.m.</b> with excitement and motivation.
//       </p>
//       <p>
//         There were <b>8 groups</b> who had participated in the trade fair with
//         different food items and fun games. Group members started preparing and
//         decorating the stalls and at <b>10 a.m.</b> they were ready to serve the
//         customers. Stalls were selling{" "}
//         <em>
//           popcorns, fruit dishes, mattha, sweet corn, fire paani puri, vidarbh
//           special puran poli, soda, ice gola, etc.
//         </em>{" "}
//         and the games like{" "}
//         <em>hit and drop the glasses and burst the balloons</em>.
//       </p>
//       <p>
//         <strong>Mrs. Lawly Das and Mrs. Jasmeet Patil</strong> from Modern
//         College of Business Administration were judging the trade fair. They
//         were looking how groups had managed the stalls, hygiene maintained.
//         Judges were keen to know about <em>best out of waste practices</em>{" "}
//         followed by participants. Faculty of MIBM also enjoyed the games and
//         food items of stalls and motivated students by appreciating them.{" "}
//         <strong>
//           Director of MIBM <b>Prof. Vijayalaxmi Shrinivas</b> ma’am
//         </strong>{" "}
//         announced the winners in the presence of all faculty members and
//         students.
//       </p>
//       <p>
//         The group who sold <em>different variety of momos</em> and maintained
//         hygiene stood as winners. Members of the winning team were{" "}
//         <b>
//           Vikrant Dhotre, Aarti Bhosale, Nikita Bhagwat, Neha Lokhande, Krushna
//           Gawande, Prathamesh Dube and Ajinkya Jogdand.
//         </b>
//       </p>
//       <p>
//         It was a successful event and great experience for the students, faculty
//         members and other staff. Participants learned a lot through this
//         experience and had great memories that they will carry with them
//         forever.
//       </p>
//     </div>
//   );
// }

export function TradeFair2023_24() {
  const t_23_24_images = [TradeFair2023_24_1, TradeFair2023_24_2];
  return (
    <div>
      <h6
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        <b>PES’s Modern Institute of Business Management</b>
      </h6>
      <h6
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        <b>“Trade Fair A.Y 2023-24”</b>
      </h6>
      <Images images={t_23_24_images} />
      <p>
        <b>Modern Institute of Business Management</b> every year organises{" "}
        <strong>“Trade fair”</strong> with great enthusiasm. This year it was
        organised on <b>6th March 2024</b>. It is a daylong activity of food,
        fun and aimed to enhance students’ entrepreneurial skills. Students
        actively participate to portray their management skills.
      </p>
      <p>
        Various food, games and entertainment stalls are arranged by students.
        Students who had <em>maximum profits</em> and showcased their{" "}
        <em>entrepreneurial skills</em> stood <strong>First and second</strong>{" "}
        in the competition. The event was coordinated by <b>Dr.Harsha Thorve</b>{" "}
        and <b>Prof. Sridevi Chennamsetti</b>.
      </p>
      <p>
        Teaching and Non-teaching members also thoroughly enjoy the games and
        food items at the stalls and motivate students. Director of MIBM{" "}
        <strong>Prof. Vijayalaxmi Srinivas ma’am</strong> announced the winners
        in the presence of all faculty members and students. The winners for the
        event were team who sold <b>“Modak”</b>. The winners team members were{" "}
        <b>Mihir, Anushka, Shreyas</b>. The event concludes with a vote of
        thanks.
      </p>
    </div>
  );
}
