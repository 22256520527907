import { Link } from "react-router-dom";
import VerticalTextCarousel from "../Components/Home.component/VerticalTextCarousel";
import HomeCarouselComp from "../Components/Home.component/HomeCarouselImages";
import Aos from "aos";
import GalleryView from "../Components/Home.component/HomePageGallery";
import NewsAnnoucementText from "../Components/Home.component/Card.component/NewsAnnouncement";
import NoticeBoardText from "../Components/Home.component/Card.component/NoticeBoard";
import UpcomingEvents from "../Components/Home.component/Card.component/UpcomingEvents";
import admissionmodalpopupimage from "../assets/images/MBA_BBA_POPUP.jpg";
import { Grid } from "@mui/material";
import { useState } from "react";
import AdmissionModal from "../Components/AdmissionModal";

Aos.init({
  duration: 1000,
});

const Home = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      {/* <TransitionsModal /> */}
      <div className="backgroundImg pt-3 pb-3">
        {/* Image carousel & Desk*/}
        <Grid container className="px-5 my-3">
          <Grid md={8} xs={12} className="px-3" data-aos="fade-right">
            <HomeCarouselComp />
          </Grid>
          <Grid md={4} xs={12} className="px-3" data-aos="fade-left">
            <VerticalTextCarousel />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container className="newsCard my-5 px-5">
          <Grid md={4} xs={12} className="px-3 box" data-aos="fade-right">
            <h5 className="text-bold decorated mb-4">NEWS ANNOUNCEMENT</h5>
            <NewsAnnoucementText />
            <Link to={"/NewsAnnouncementPage"}>
              <h5 className="ViewMore">View All News</h5>
            </Link>
          </Grid>
          <Grid md={4} xs={12} className="px-3 box" data-aos="fade-up">
            <h5 className="text-bold decorated mb-4">NOTICE BOARD</h5>
            <NoticeBoardText />
            <Link to={"/NoticeBoardPage"}>
              <h5 className="ViewMore">View All Notices</h5>
            </Link>
          </Grid>
          <Grid md={4} xs={12} className="px-3 box" data-aos="fade-left">
            <h5 className="text-bold decorated mb-4">UPCOMING EVENT</h5>
            <UpcomingEvents />
            <Link to={"/UpcomingEventPage"}>
              <h5 className="ViewMore">View All Events</h5>
            </Link>
          </Grid>
        </Grid>
      </div>
      <div className="mt-5">
        <GalleryView />
      </div>
      <AdmissionModal
        open={open}
        setOpen={setOpen}
        src={admissionmodalpopupimage}
      />
    </>
  );
};

export default Home;
