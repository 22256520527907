import * as React from 'react';
import { ScrollableTable } from '../Components/ReusableComponent/tableComponent';

export default function StudentIndustryTable1() {
    const heading =[
      'Sr No.', 'Name of the Session','Date From – To','Name of Resource Person'
    ]
    const data = [
        {
            col1:'Guidance for Choosing Marketing as a specialization',  
            col2:'19-Mar-19',
            col3:'Dr. Pandit Mali'
        },
        {
            col1:'Entreprenuership Development', 
            col2:'18-Mar-19',
            col3:'Mr. Prathamesh Nirgudkar'
        },
        {
            col1:'Guidance for Choosing Finance as a specialization',
            col2:'15-Mar-19',
            col3:'Dr. Shriram Nerlekar'
        },
        {
            col1:'Guidance for Choosing HR as a specialization', 
            col2:'9-Mar-19',
            col3:'Dr. Santosh Bhave'
        },
        {
            col1:'Uses of IPR in Businesss and Industry',
            col2:'7-Mar-19',
            col3:'Dr. Ananya Bibave'
        },
        {
            col1:'Bee -The Lead-To Learn Leadrrship from Honey bees',
            col2:'23-Feb-19',
            col3:'Mr. Devendra Jani'
        },
        {
            col1:'Sessions on Soft Skills Development',
            col2:'25 Feb 2019-12 March 2019',
            col3:'Mr. Omkar Sampgaonkar'
        },
        {
            col1:'Effictively and Efficienly utlising EBSCO Host platform for Project and Research',
            col2:'16-Feb-19',
            col3:'Mr. Chandrakant Ganvir'
        },
        {
            col1:'Skill India',
            col2:'13-Feb-19',
            col3:'Mr. Panchal And Mr. Bhatnagar'
        },
        {
            col1:'Workshop on A Case Study as a Pedagogical Tool in Management Teaching',
            col2:'19-Jan-19',
            col3:'Dr Salim Shamsher'
        },
        {
            col1:'Workshop on -Formation of Rotaract Club',
            col2:'18-Jan-19',
            col3:'Mr. Devrath Shahane'
        },
        {
            col1:'Campus to Corporate- Change Management',
            col2:'12-Jan-19',
            col3:'Mr. Mahesh Haridas'
        },
        {
            col1:'Wealth Management and Impact of Early Savings',
            col2:'1-Sep-18',
            col3:'Mr. Pankaj Deshmukh'
        },
        {
            col1:'Recent Trends in Marketing and Sales',
            col2:'1-Sep-18',
            col3:'Mr. Anirudh Namjoshi'
        },
        {
            col1:'SWOT Analysis',
            col2:'11-Aug-18',
            col3:'Ms. Terene Breganza'
        },
        {
            col1:'Personality Development',
            col2:'11-Aug-18',
            col3:'Mr. Swami Buddhanand, Ramkrishna Mission'
        },
    ]
    return (
        <>
            <div>
            <h6 className="mb-4 title-text text-bold" >2018-2019</h6>
               <ScrollableTable tableHeading={heading} tableData={data}/>
            </div>
        </>
    );
}

export function StudentIndustryTable2() {  
    const heading =[
        'Sr No.', 'Name of the Session','Date From – To','Name of Resource Person'
    ]
    const data = [
        {
            col1:'Career Guidance and Counseling – Finance as a specialization', 
            col2:'17-Mar-18',
            col3:'Mr. Amol Charegaonkar'
        },
        {
            col1:'Career Guidance and Counseling – HRM  as a specialization',
            col2: '10-Mar-18',
            col3:'Mr. Santosh Bhave'
        },
          {
              col1:'Analytical Approch in Marketing',
              col2:'24-Feb-18', 
              col3:'Mr. Mandar Thosar'
          },
          {
              col1:'Neuro-Linguistic Program',
              col2:'17-Feb-18',
              col3:'Mr.Richa Das'
          },
          {
              col1:'Industry Expectation from B School',
              col2:'13-Jan-18',
              col3:'Mr. Vikrant Bhujbalrao'
          },
          {
              col1:'Corporate Communications',
              col2:'6-Jan-18',
              col3:'Mr. Terene Bregenza'
          },
          {
              col1:'Power of Authentic Communication, Confidence and Attitute building measures',  
              col2:'16-Sep-17',
              col3:'Mr. Raj Bhalwankar'
          },
          {
              col1:'Goal Setting',
              col2:'9-Sep-17',
              col3:'Mr. Ashish Bhave'
          },
          {
              col1:'Attitude of Success',  
              col2:'5-Aug-17',
              col3:'Mr. Ashish Patil'
          },
          {
              col1:'Session on Derivative Trading',
              col2:'16-Sep-17', 
              col3:'Mr. Ramkrishna Kashelkar'
          },

      ]
    return (
        <>
          <div>
             <h6 className="text-bold title-text mb-4" >2017-2018</h6>
             <ScrollableTable tableHeading={heading} tableData={data}/>
         </div>
        </>
    );
}

export function StudentIndustryTable3() { 
    const heading =[
        'Sr No.', 'Topics','Guest Name'
    ]
    const data = [
        {
            col1:'FE @ Campus',
            col2:'Mr. Rohit Kulkarni (Manager-Corporate Sales) at The Indian Express'
        },
        {
            col1:'Induction Program', 
            col2:'Dr. Gadre'
        },
        {
            col1:'Induction Program', 
            col2:'Simrat Gahlot, Deutsch Bank'
        },
        {
            col1:'Women security and cyber crime',
            col2: 'Vaishali Sajjan and Swati Deodhar, PSI'
        },
        {
            col1:'Emotional Intelligence at Workplace',
            col2: 'Mr.Himanshu Kakkar, DM at SKP'
        },
        {
            col1:'Buget 2017', 
            col2:'Dr. N. M Vechalekar, Dean Research at IndSearch'
        },
        {
            col1:'HR Spalization',
            col2: 'Mr. Girish Kanitkar, Manager Talent Acquisition at Fiserv'
        },
        {
            col1:'Finance Specialization',
            col2: 'Mr. Amol Charegaonkar,Director at Swift Solutions'
        },
        {
            col1:'Marketing Specialization',
            col2: 'Mr. Bharat Oswal, Organizer at TedX'
        }
    ]

    return (
        <>
           <div>
           <h6 className="title-text text-bold mb-4" >2016-2017</h6>
            <ScrollableTable tableHeading={heading} tableData={data}/>
            </div>

        </>
    );
}

export function StudentIndustryTable4() {  
    const heading =[
        'Sr No.', 'Topics','Guest Name',''
    ]
    const data = [
        {
            col1:'Stress management',
            col2: 'N.K.lokhande',
        },
        {
            col1:'Business Standard', 
            col2: 'Sumedh Gupte'
        },
        {
            col1: 'Concepts & convention in Accounting', 
            col2:'Prof.Rupali Gupte' 
        },
        {
            col1:'Budget 2016',
            col2: 'Mr.Rammkrishna Kashalkar'
        },
        {
            col1:'Choosing Finance as a Specialization” Opportunities, prospects and growth in career', 
            col2: 'Dr Nachiket Vechlekar'
        },
        {
            col1:'Choosing Marketing as a Specialization” Opportunities, prospects and growth in career', 
            col2:'Mr.Aanand Raghorte' 
        },
        {
            col1:'Choosing HR as a Specialization” Opportunities, prospects and growth in career', 
            col2: 'Mr Girish Kanitkar'
        },
        {
            col1: 'Choosing Marketing as a Specialization” Opportunities, prospects and growth in career', 
            col2: 'Mr.Aanad Wagh'
        },
    ]

    return (
        <>
                   <div>
                   <h6 className="text-bold title-text mb-4" >2015-2016</h6>
                   <ScrollableTable tableHeading={heading} tableData={data}/>
            </div>
        </>
    );
}

export function StudentIndustryTable5() {  
    const heading =[
        'Sr No.', 'Topics','Guest Name',''
    ]
    const data = [
        {
            col1:'Career opportunities in finance', 
            col2:'Dr.Padmini Sundaram',
        },
        {
            col1:'Economic Analysis', 
            col2: 'Kishore Kumar P.'
        },
        {
            col1:'Investment Avenues',
            col2: 'Sudhakar Kulkarni' 
        },
        {
            col1:'Business Standard', 
            col2: 'Sumedh Gupte'
        },
        {
            col1: 'Balance sheet schedule 6 analysis', 
            col2:'Mr.Praddep Thite' 
        },
        {
            col1: 'Merger & Acquisition', 
            col2:'Mr.Nishant' 
        },
        {
            col1:'Cluster Analysis', 
            col2:'Mr.Sandeep Chougule'
        },
        {
            col1: 'The Factories Act',
            col2:  'Mr.Arun Burve'
        },
        {
            col1:'Online Trading competition',
            col2: 'Mr.Nihal Khan'
        }
    ]
    return (
        <>
        <div>
        <h6 className="text-bold title-text mb-4" >2014-2015</h6>
          <ScrollableTable tableHeading={heading} tableData={data}/>

            </div>
        </>
    );
}
