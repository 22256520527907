
import NationalConferences from '../assets/pdf/UpcomingEvent/MIBM_National_Conference.pdf';




export const UpcomingData = [
    //doc can be anything image or pdf import that doc and give its name
        {
            title:'"Confluence-2023"',
            date:27,
            Doc:{
                image:[],
                pdf: [],}
            ,
            description2: <a href='https://forms.gle/AdisD2pX5mVs1tz87' target='_blank' style={{ color: 'steelblue'}}>Google Link</a>,
            description:'PES MIBM organizes Alumni Meet 2023 on Saturday, 27th May 2023',
            updatedDate: 'Upcoming on 27 May 2023 ',
        },
        {
            title: 'Darpan-Cultural Fest-2023',
            date:24,
            Doc:{
                image:[],
                pdf: [],}
            ,
            description: 'PES MIBM organizes Darpan - Cultural Fest on Monday, 29th May 2023',
            updatedDate: 'Upcomig on 29 May 2023',
    
        },
        {
            title: 'MIBM National Conference.',
            date:'6 & 7',
            Doc:{
                image:[],
                pdf: [NationalConferences],}
            ,
            description: 'MIBM is organizing 9th National conference on the theme, “National Education Policy – Role of Behavioral Sciences in Management and Implementation of Multidisciplinary Education” on 6 th and 7 th January from 10 am onwards at PES MIBM Seminar Hall, Shivajinagar, Pune 411005.',
            updatedDate: 'Upcomig on 6 & 7 January 2023',
        },
        {
            title: 'Registration form :',
            date:2,
            Doc:{
                image:[],
                pdf: [],}
            ,
            description: <a target='_blank' href='https://forms.gle/GDqP8bZvdPDaAwCq8' style={{ color: 'steelblue'}}>Click here to Registration form</a>,
            updatedDate: 'Upcomig on 2 January 2023',
    
        },
       
    ]