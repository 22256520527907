import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { MDBRipple } from 'mdb-react-ui-kit';
import library1 from '../../assets/images/HomeDesk/gallery/library1.png';
import library2 from '../../assets/images/HomeDesk/gallery/library2.png';
import infrastructure1 from '../../assets/images/HomeDesk/gallery/infrastructure1.png';
import infrastructure2 from '../../assets/images/HomeDesk/gallery/infrastructure2.png';
import Grid from '@mui/material/Grid';


function GalleryView() {
  return (
    // Gallery
    <>
      <div className="my-0 d-flex justify-content-center flex-wrap flex-column backgroundImg w-100">
        <div>
          <div className="default-padding-sm">
            <div className="mt-0 mb-5">
              <Grid container spacing={0} >
                <Grid item xs={12} md={6} data-aos="fade-right" className="box px-4" >
                  <Grid xs={12}>
                    <h3 className='mb-5 FtUnderline text-bold ms-1 mt-5'>Infrastructure</h3>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <div className="containers">
                        <Link to={'/students/Infrastructure'}>
                        <img src={infrastructure1} alt="Avatar" className="image" />
                        <div className="middle">
                          <div className="text">View More</div>
                        </div>
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="containers">
                      <Link to={'/students/Infrastructure'}>
                        <img src={infrastructure2} alt="Avatar" className="image" />
                        <div className="middle">
                          <div className="text">View More</div>
                        </div>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} data-aos="fade-left" className="box px-4">
                  <Grid xs={12}>
                    <h3 className='mb-5 FtUnderline text-bold ms-1 mt-5'>LIBRARY</h3>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <div className="containers">
                      <Link to={'/academics/library'}>
                        <img src={library1} alt="Avatar" className="image" />
                        <div className="middle">
                          <div className="text">View More</div>
                        </div>
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <div className="containers">
                      <Link to={'/academics/library'}>
                        <img src={library2} alt="Avatar" className="image" />
                        <div className="middle">
                          <div className="text">View More</div>
                        </div>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GalleryView;