// src/components/ImageCarousel.js

import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Paper } from '@mui/material';

const ImageCarousel = ({ images }) => {
  return (
    <div className='Image-Carousel'>
    <Carousel autoPlay infiniteLoop showArrows={true} showThumbs={false}>
      {images.map((image, index) => (
        <div key={index} style={{maxHeight:'464px',maxWidth:'auto'}}>
          <img src={image} alt={`Image ${index}`} />
        </div>
      ))}
    </Carousel>
    </div>
   
  );
};

export default ImageCarousel;
