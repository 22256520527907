import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const cardStyles = {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #ddd',
  borderRadius: '8px',
  padding: '10px',
  marginTop: '10px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  maxWidth: '100%',
  minHeight:'240px',
  flexDirection: 'column'
};

const imageStyles = {
  maxWidth: '100px',
  maxHeight: '220px',
  flex: '1',
};

const titleStyles = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  flex: '1',
  marginTop:'0.5rem'
};

const infoStyles = {
  fontSize: '0.9rem',
  marginTop:'0.1rem'
};

const headStyle = {
  fontSize: '1rem',
  fontWeight: 'bold',
  marginTop: "10px"
}
const ListStyles = {
  margin: '0%',
}


const IconCard = ({ imageSrc, title, info, info2, list1, list2, list3, list4, list5 }) => {
  return (
    <> 
      <div style={cardStyles} className='textWrap'>
   <Grid container spacing={2}>
      <Grid item xs={2}>
      <img src={imageSrc} alt={title} style={imageStyles} />
      </Grid>
      <Grid item xs={10}>
      <div className='mx-1' data-aos="fade-left">
          <h2 style={titleStyles}>{title}</h2>
          <p style={infoStyles}>{info}</p>
          <p style={infoStyles}>{info2}</p>
          <div style={infoStyles} >
            <div>{list1}</div>
            <div>{list2}</div>
            <div>{list3}</div>
            <div>{list4}</div>
            <div>{list5}</div>
            </div>
        </div>
      </Grid>
    </Grid>
      </div>
    </>

  );
};

export default IconCard;