import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import img1 from "../../assets/images/ResearchInnovation/IPR/Activity/IPR_activity_1.png";
import { Images, Paragraphs } from "./ImageAndParagraphsComponent";
import TabsComponent from "./TabChildComponent";
import Paper from "@mui/material/Paper";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function AccordianCardComponent(props) {
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div key={props}>
      {props.data.map((item, index) => {
        return (
          <>
            <Accordion
              key={index}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                key={index}
                sx={{ borderBottom: "2px solid grey" }}
              >
                <Typography className="researchAccordian">
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails key={index}>
                <Paper
                  elevation={1}
                  sx={{
                    backgroundColor: "ButtonFace",
                    padding: "10px",
                    margin: "0%",
                  }}
                >
                  <Typography variant="h5" gutterBottom textAlign={"center"}>
                    {item.cardTitle}
                  </Typography>
                  <Typography>
                    <Images images={item.cardImages} />{" "}
                    {/*import from Img&ParagraphComponent */}
                  </Typography>
                  <Typography className="mt-2">
                    <Paragraphs description={item.cardDescription} />{" "}
                    {/*import from Img&ParagraphComponent */}
                  </Typography>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </div>
  );
}

// tab under Accordian
export function AccordianTabsComponent(props, { tabData }) {
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {props.data.map((item, index) => {
        return (
          <>
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                key={index}
                sx={{ borderBottom: "2px solid grey" }}
              >
                <Typography className="researchAccordian">
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails key={index}>
                <TabsComponent tabData={item.tabData} />
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </div>
  );
}
