import * as React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { Paper } from "@mui/material";
import Breadcrumb from "../Components/Breadcrumb";
import Divider from "@mui/material/Divider";
import {
  Induction2019,
  Induction2018,
  Induction2017,
  Induction2016,
  Induction2015,
  Induction2014,
  Collaborations,
  ManagementFest,
  Cultural,
  ActivitiesRejoice,
  AntiRagging,
  ComplaintCommittee,
  GrievanceRedressal,
  Campus,
  Classroom,
  Seminarhall,
  ResearchLab,
  Book_Review_2022_23,
  Book_Review_2021_22,
  EcoCLubActivityData,
  Induction2020_21,
  Induction2023_24,
  Induction2022_23,
  Induction2021_22,
  TradeFair2023_24,
  TradeFair2022_23,
  TradeFair2020,
} from "../Data/StudentData";
import GalleryDesk from "../Data/mibmGallery";
import TabComponent from "../Components/ReusableComponent/TabComponent";
import ImageDivComponent from "../Components/ImageDivComponent";

const sidemenus = [
  {
    title: "MBA Induction",
    url: "/students/MbaInduction",
  },
  {
    title: "Industrial Visit",
    url: "/students/IndustrialVisit",
  },
  {
    title: "Students Participation & Events",
    url: "/students/ParticipationEvents",
    // submenu:[
    //       {
    //         title: 'Extension Activities Rejoice',
    //         url:'/students/Events/ExtensionActivitiesRejoice'
    //       },
    //       {
    //         title: 'Collaborations',
    //         url:'/students/Events/Collaborations'
    //       },
    //       {
    //         title: 'Management Fest',
    //         url:'/students/Events/Management Fest'
    //       },
    //       {
    //         title: 'Student Cultural & Sport Activities',
    //         url:'/students/Events/StudentCulturalAndSportActivities'
    //       },
    // ]
  },
  {
    title: "Student Newsletter",
    url: "/students/StudentNewsletter",
  },
  {
    title: "Student Achievement",
    url: "/students/StudentAchievement",
  },
  {
    title: "Infrastructure",
    url: "/students/Infrastructure",
  },
  {
    title: "Anti-Ragging Cell",
    url: "/students/AntiRaggingCell",
  },
  {
    title: "Internal Complaint Committee",
    url: "/students/InternalComplaintCommittee",
  },
  {
    title: "Book Review",
    url: "/students/BookReview",
  },
  {
    title: "Eco Club",
    url: "/students/EcoClubActivity",
  },
  {
    title: "Trade Fair",
    url: "/students/TradeFair",
  },
  {
    title: "Student Grievance Redressal Cell",
    url: "/students/StudentGrievanceRedressalCell",
  },
  {
    title: "Gallery",
    url: "/students/Gallery",
  },
];

export default function StudentAtMIBM(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");
    // scroll top
    //window.scrollTo(0, 0);
    window.scrollTo(0, 420);
  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {" "}
                    {menu.title}{" "}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function Induction() {
  const tabData = [
    { label: "2023-24", content: <Induction2023_24 /> },
    { label: "2022-23", content: <Induction2022_23 /> },
    { label: "2021-22", content: <Induction2021_22 /> },
    { label: "2020-21", content: <Induction2020_21 /> },
    { label: "2019", content: <Induction2019 /> },
    { label: "2018", content: <Induction2018 /> },
    { label: "2017", content: <Induction2017 /> },
    { label: "2016", content: <Induction2016 /> },
    { label: "2015", content: <Induction2015 /> },
    { label: "2014", content: <Induction2014 /> },
  ];

  return (
    <div className="box">
      <h5 className="title-text my-2 mb-4 decorated">MBA INDUCTION</h5>
      <Paper
        elevation={3}
        className="p-0 textWrap mb-1 w-100"
        style={{ minHeight: "300px" }}
      >
        <div className="p-3 text-justify">
          <p className="P-tag" data-aos="fade-left">
            Every year Induction of new batch at MIBM has always been vivacious
            event as it makes the introduction of fresh talent into MIBM’s
            Family. It is rightly said that first impression is considered as
            the best impression and Induction as a first formal function, serves
            a vital part in making this first impression of any institution in
            the minds of the incoming fresh batch. Every year our Induction
            Program is inclined towards making new students feel ease with
            learning place, with their classmates and also to know formal &amp;
            informal norms about the institution. Every year an eminent
            personality having strong academic background and industrial /
            corporate experience is invited as a Chief Guest to welcome and
            guide new batch of MBA.
          </p>
          <p data-aos="fade-left">
            Entire Induction Program always give students new hopes and positive
            mind set to make their MBA life brighter. Students also give
            assurance about hard work and get ready for accepting new
            challenges.
          </p>
        </div>
        <>
          <TabComponent tabData={tabData} />
        </>
      </Paper>
    </div>
  );
}
// ------------------Induction tabs functions -------------------------------------

export function Visit() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">INDUSTRIAL VISITS</h5>
        <Paper
          elevation={3}
          className="p-3 textWrap"
          style={{ minHeight: "300px" }}
        >
          <p className="P-tag" data-aos="fade-left">
            The Industry-Institute interface aims to provide industry exposure
            to the students and acquaint them with real time projects. Institute
            arranges industrial visits and field trips for the students.{" "}
            <b>
              Reserve Bank of India, Bombay Stock exchange, Bharat Forge
              Limited, Volkswagen Group India, Lear Automotive India Pvt. Ltd
              etc.
            </b>{" "}
            are some of the industries visited by the institute.
          </p>
          <p>Following is the details of industrial visits.</p>

          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text my-2">Year of Commencement 2018-19</h5>
            <h6 className="P-tag mb-3">
              Name of the partnering institution/ industry
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>Bombay Stock Exchange, Mumbai</li>
              <li>Reserve Bank of India, Monetary Museum, Mumbai</li>
              <li>Lear Automotive India Pvt. Ltd, Pune</li>
              <li>Kalyani Maxion Wheels Ltd, Pune</li>
              <li>Labour Court, Pune</li>
            </ul>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text my-2">Year of Commencement 2017-18</h5>
            <h6 className="P-tag mb-3">
              Name of the partnering institution/ industry
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>Indian Express, Pune</li>
              <li>Kalyani Maxion Wheels Ltd, Pune</li>
              <li>Genius Rubber Industries, Pune</li>
            </ul>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text my-2">Year of Commencement 2016-17</h5>
            <h6 className="P-tag mb-3">
              Name of the partnering institution/ industry
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>DSK Moto wheels Pvt. Ltd, Pune</li>
              <li>BSE Ltd., Mumbai</li>
              <li>RBI’s Monetary Museum, Mumbai</li>
            </ul>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text my-2">Year of Commencement 2015-16</h5>
            <h6 className="P-tag mb-3">
              Name of the partnering institution/ industry
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>Alfa Laval (India) Limited</li>
              <li>Maxion Wheels India Ltd, Pune</li>
              <li>Bharat Forge Limited, Pune</li>
              <li>Shiv Shaki Foods Pvt Ltd (Parle -G Franchise)</li>
              <li>Kamdhenu Pickles, Pune</li>
              <li>RBI’s Monetary Museum</li>
              <li>BSE Ltd., Mumbai</li>
            </ul>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text my-2">Year of Commencement 2014-15</h5>
            <h6 className="P-tag mb-3">
              Name of the partnering institution/ industry
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>Bajaj Auto Ltd </li>
              <li>Volkswagen India Pvt Ltd</li>
              <li>RBI’s Monetary Museum</li>
              <li>BSE Ltd., Mumbai</li>
            </ul>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function ParticipationEvents() {
  const tabData = [
    { label: "Extension Activities Rejoice", content: <ActivitiesRejoice /> },
    { label: "Collaborations", content: <Collaborations /> },
    { label: "Management Fest", content: <ManagementFest /> },
    { label: "Student Cultural & Sport Activities", content: <Cultural /> },
  ];
  return (
    <>
      <div className="box">
        <Paper elevation={2}>
          <TabComponent
            tabData={tabData}
            tabName={sessionStorage.getItem("menuName")}
          />
        </Paper>
      </div>
    </>
  );
}
export function Newsletter() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">Student Newsletter</h5>
        <Paper
          elevation={3}
          className="p-3 textWrap text-justify"
          style={{ minHeight: "400px" }}
        >
          <div>
            <p className="P-tag" data-aos="fade-left">
              Lakshya is a bi annual in-house newsletter of Progressive
              Education Society’s Modern Institute of Business Management. The
              name <b style={{ color: "black" }}>“Lakshya” </b>itself explains
              the focus of MIBM. It clearly states the goal of a person.{" "}
              <b style={{ color: "black" }}> Lakshya </b> provides a brief
              review of various activities, events and programs that were
              conducted during the semester. It is a comprehensive resource for
              preserving and presenting these precious moments.
            </p>
          </div>
        </Paper>
      </div>
    </>
  );
}
export function Achievement() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">
          STUDENTS ACHIEVEMENTS
        </h5>
        <Paper
          elevation={3}
          className="p-3 textWrap text-justify"
          style={{ minHeight: "300px" }}
        >
          <div>
            <h6 className="text-bold title-text my-3">
              MBA I & II Year Students (2018 - 2019)
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                Ashwin Nirmal, Sagar Jadhav, Viraj Sant, Vipin Sivaprasad,
                Shubhan Bhosale, Ajinkya Lipare, Shubham Kumar Pawar, Amole
                Singh : Participated in Karmanta 2018-19 at Marathwada Mitra
                Mandals IMERT college and secured Runner Up position in Tug of
                War.
              </li>
              <li>
                Soyal Inamdar: Participated in Karmanta 2018-19 at Marathwada
                Mitra Mandals IMERT and secured Winner position in Solo Dance.
              </li>
              <li>
                Kunal Chavan & Sanmati Lalwani: Participated in RAYS 2018-19 at
                Sadhu Vaswani Institute of Management Studies for Girls and
                secured Runner Up position in Slide Share competition.
              </li>
              <li>
                Aditya Ambedkar & Shubham Bhosale: Participated in RAYS 2018-19
                at Sadhu Vaswani Institute of Management Studies for Girls and
                Secured Runner Up position in Table Tennis competition.
              </li>
              <li>
                Amol Singh, Vipin Sivaprasad: Participated in Rays 2018-19 at
                Sadhu Vaswani Institute of Management Studies for Girls and
                Secure Runner Up position in Gully Cricket competition.
              </li>
              <li>
                Shivani Patil: Participated in Indian Commerce Association
                Parasmull Chordia Talent Award and secured 18th rank in All
                India Commerce and Management Talent Search Examination 2018.
              </li>
              <li>
                Devandra Bandishti, Purva Pinjarkar, Yugandhar More, Abhay
                Javeri, Rashmi Yadav, Shubham Pawar, Bhagyashree Deshmukh ,
                Aditi Mahetre, Shweta Patil, Sohel Inamdar, Savri Shrivastav:
                Participated in TROIKA 2018-19 at BMCC college & received
                consolation prize in Street Play.
              </li>
              <li>
                Kunal Chavan : Participated in Elocution compitation in Modern
                Engineering College and received consolation prize.
              </li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">
              MBA – II year students (2015 - 2017)
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                Ameya Joshi (Marketing specialization student) – Won ‘University
                Level Avishkar 2016 Research competition’ with titled “Nirmalyam
                Sadasukhi”. Also he was shortlisted for National Level
                competition ‘Anveshan’ in top 24 projects in SPPU
              </li>
              <li>
                Ameya Joshi (Marketing Specialization student) – Won consolation
                price (Rank 4) at Matrix Gelignites-SIP competition at Matrix
                Business School
              </li>
              <li>
                Lokesh Kate, Akshay Hanumante and Priya Dixit won 1st prize for
                NeuroActive game in Symbiosis (SCIT) management event Graffiti
                2016
              </li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">
              MBA – I & II year students (2013 - 2014)
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                1st Prize in “Bit to Win” at M-Pulse at Modern College of
                Engineering, Pune.
              </li>
              <li>
                1st Prize in “Mad- Add” at M-Pulse at Modern College of
                Engineering, Pune.
              </li>
              <li>2nd Prize in “Mad – Add” at MMCC, Pune.</li>
              <li>1st Prize in “Quiz” at MMCC, Pune. </li>
              <li>
                3rd Prize in “Live Project competition on HR issues” organized
                by MCCIA, Pune.
              </li>
              <li>1st Prize in “Competition on Stock Market” at MIT, Pune.</li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">Mr. Ananta Pawar</h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                1st Prize IIM-A Confluence, “National Business Olympiad 2011”
                held at GHRIMR, Pune
              </li>
              <li>
                2nd Prize At National Level “Summer Project Competition 2011”
                held at MMC Mgt, Pune
              </li>
              <li>
                1st Prize At National Level “Share Market – Mock Stock Event
                2011” held at VIM, Pune
              </li>
              <li>
                {" "}
                4th Prize At National Level “Share Market – Mock Stock Event
                2012” held at PCCOE, Pune
              </li>
              <li>
                1st Prize At District Level “Share Market – Mock Stock Event
                2012” held at MITSOM, Pune
              </li>
              <li>
                {" "}
                1st Prize At District Level “Share Market – Mock Stock Event
                2011” held at FC College, Pune
              </li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">
              Mr. Ketan Sagvekar & Mr. Nikhil Kataria
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>3rd Prize in the event “CLIXHIBIT” held at MIT-DMSR</li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">
              Mr. Ketan Sagvekar & Mr. Nikhil Kataria
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>3rd Prize in the event “CLIXHIBIT” held at MIT-DMSR</li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">Miss Sonali Jondhale</h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                Participated in National Level Mock Stock Event Organized by
                PCCOE, PUNE
              </li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">Miss Puja Patankar</h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                1st prize in an event organized by Modern College of Engg., (MBA
                Dept.), Pune. Event name: M-Pulse, Finance- Artharjan (Feb.2011)
              </li>
              <li>Participated in MOCK-STOCK competition at PCCOE, Pune</li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">Mr. Eeshan Deshpande</h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                Developed Software for “Digital Watermarking” which lies in
                security domain and is used for encrypted data transfer.
              </li>
              <li>
                {" "}
                Participated in ‘National Business Olympiad’conducted by Indian
                Institute of Management, Ahmadabad.
              </li>
            </ul>
            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">Miss Pooja Ghatkar</h6>
            <ul className="ul-tag" data-aos="fade-left">
              <li>
                Clinched the top position in women’s air rifle in second
                selection trials as well, at the Karni Singh range in
                Tughlakabad.
              </li>
              <li>
                Participated in ‘Management Students Program’ Confluence
                conducted by Indian Institute of Management, Ahmadabad.
              </li>
            </ul>

            <Divider sx={{ border: 1 }} />

            <h6 className="text-bold title-text my-3">
              MBA 1st year students participated in the street play competition
              and got 3rd price
            </h6>
            <ul className="ul-tag" data-aos="fade-left">
              {/* <li></li> */}
            </ul>
          </div>
        </Paper>
      </div>
    </>
  );
}
export function Infrastructure() {
  const tabData = [
    { label: "Campus", content: <Campus /> },
    { label: "Classroom", content: <Classroom /> },
    { label: "Seminar Hall", content: <Seminarhall /> },
    { label: "Research Lab", content: <ResearchLab /> },
  ];
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">Infrastructure</h5>
        <div>
          <Paper elevation={3} sx={{ minHeight: "350px" }} className="w-100">
            <TabComponent tabData={tabData} />
          </Paper>
        </div>
      </div>
    </>
  );
}
export function AntiRaggingCell() {
  return (
    <>
      <div className="box">
        <AntiRagging />
      </div>
    </>
  );
}
export function Committee() {
  return (
    <>
      <div className="box">
        <ComplaintCommittee />
      </div>
    </>
  );
}

export function Grievance() {
  return (
    <div className="box">
      <GrievanceRedressal />
    </div>
  );
}

export function Gallery() {
  return (
    <>
      <Paper
        elevation={3}
        className="p-3 textWrap"
        style={{ minHeight: "300px" }}
      >
        <GalleryDesk />
      </Paper>
    </>
  );
}

export function Book_Review() {
  const tabData = [
    {
      label: "Book Review 2022-23",
      content: <ImageDivComponent item={Book_Review_2021_22} />,
    },
    {
      label: "Book review 2021-22",
      content: <ImageDivComponent item={Book_Review_2022_23} />,
    },
  ];
  return (
    <div className="box">
      <h5 className="title-text my-2 mb-4 decorated">Book Review</h5>
      <div>
        <Paper elevation={3} sx={{ minHeight: "350px" }} className="w-100">
          <TabComponent tabData={tabData} />
        </Paper>
      </div>
    </div>
  );
}

export function Eco_Club_Activity() {
  return (
    <div className="box">
      <h5 className="title-text my-2 mb-4 decorated">
        Eco Club Activity Report
      </h5>
      <div>
        <Paper
          elevation={3}
          sx={{ minHeight: "350px", p: 2 }}
          className="w-100"
        >
          <EcoCLubActivityData />
        </Paper>
      </div>
    </div>
  );
}

export function Trade_Fair() {
  const tabData = [
    { label: "2023-24", content: <TradeFair2023_24 /> },
    { label: "2022-23", content: <TradeFair2022_23 /> },
    { label: "2020-21", content: <TradeFair2020 /> },
  ];
  return (
    <div className="box">
      <h5 className="title-text my-2 mb-4 decorated">Trade Fair</h5>
      <div>
        <Paper
          elevation={3}
          sx={{ minHeight: "350px", p: 2 }}
          className="w-100"
        >
          <>
            <TabComponent tabData={tabData} />
          </>
        </Paper>
      </div>
    </div>
  );
}
