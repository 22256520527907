import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function GalleryCard(props) {
    const scrollToTop = () => {
        window.scrollTo({
          top: 420,
          behavior: 'auto' // Optional: Adds a smooth scrolling effect
        });
    };
    return (
        <>
            <Card sx={{ maxWidth: 245, gap:2 }} elevation={3} className='my-2 mx-auto' > 
                <CardActionArea>
                    <CardContent >
                        <Typography className='fw-bold P-tag' variant="h6" sx={{ maxHeight: 25, textAlign:'center' }} fontSize={15}  padding={0}>
                            {props.data.title}
                        </Typography>
                    </CardContent>
                    <Typography>
                        <img src={props.data.image} className='px-2' height={170} />
                    </Typography>
                </CardActionArea>
                <CardActions>
                    <CardActionArea>
                    <Button size="small" color="primary" className='mx-auto onhover mobileViewType' sx={{ backgroundColor: '#a70606', color: 'white' }} onClick={scrollToTop}>{props.data.link}</Button>
                    </CardActionArea>
                </CardActions>
            </Card>
        </>
    );
}