import React, { useState } from 'react';
import './FlipCard.css';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

function FlipCard() {
  const [isFlipped, setFlipped] = useState(false);

  const toggleCard = () => {
    setFlipped(!isFlipped);
  };

  return (
    <>
      <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onMouseEnter={toggleCard} onMouseLeave={toggleCard}  cardZIndex={'auto'} flipSpeedBackToFront={10} flipSpeedFrontToBack={10}>
        <div className="flip-card-inner">

          <div className="flip-card-front mobileViewType">
            <h5 className="title-text mb-5 text-bold">CONTACT INFO</h5>
            <Grid container spacing={1} className='mx-0'>
              <Grid xs={4} md={3}>
                <LocationOnOutlinedIcon style={{ color: '#A70606' }} /> <b>Address:</b>
              </Grid>
              <Grid xs={8} md={8} sx={{marginBottom:'20px'}}> 
                1186, Shivaji Nagar, Modern College Road, Pune 411005.
              </Grid>
              <Grid xs={3} md={3}>
                <EmailOutlinedIcon style={{ color: '#A70606' }} /> <b>Email:</b>
              </Grid>
              <Grid xs={9} md={8} sx={{marginBottom:'20px'}}
              >
                os.mibm@gmail.com, mibmpune05@gmail.com
              </Grid>
              <Grid xs={4} md={3}>
                <CallOutlinedIcon style={{ color: '#A70606' }} /> <b>Phone:</b>
              </Grid>
              <Grid xs={8} md={8}>
                +91 8237084546 / 8237084547 / 8237084548
              </Grid>
            </Grid>
          </div>
          <div className="flip-card-back mobileViewType">
            <h5 className="mb-5 text-bold text-light">CONTACT INFO</h5>

            <Grid container spacing={1} className='mx-0'>
              <Grid xs={4} md={3}>
                <LocationOnOutlinedIcon /> <b>Address:</b>
              </Grid>
              <Grid xs={8} md={8} sx={{marginBottom:'20px'}}>
                1186, Shivaji Nagar, Modern College Road, Pune 411005.
              </Grid>
              <Grid xs={3} md={3} >
                <EmailOutlinedIcon /> <b>Email:</b>
              </Grid>
              <Grid xs={9} md={8} sx={{marginBottom:'20px'}}>
                os.mibm@gmail.com, mibmpune05@gmail.com
              </Grid>
              <Grid xs={4} md={3} >
                <CallOutlinedIcon /> <b>Phone:</b>
              </Grid>
              <Grid xs={8} md={8}>
                +91 8237084546 / 8237084547 / 8237084548
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlipCard;
