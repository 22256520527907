import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./pages/Home";
import Home2 from "./pages/Home2.js";

import AboutUs, {
  AboutMIBM,
  ChairmanDesk,
  CoordinatorsDesk,
  DirectorsDesk,
  Governance,
  MandatoryDisclosure,
  PESociety,
  CoreValues,
  EOA_AICTE,
} from "./pages/AboutUs";
import NoFoundPage from "./pages/NoFoundPage";
import NewsAnnouncementPage from "./pages/ViewMorePages/NewsAnnouncementPage";
import NoticeBoardPage from "./pages/ViewMorePages/NoticeBoardPage";
import UpcomingEventPage from "./pages/ViewMorePages/UpcomingEventPage";
import Contact from "./pages/Contact";
import Placement, {
  AboutTnPCell,
  EmployabilityEnhancement,
  OurRecruiters,
  PlacementBrochure,
  StudIndustryInitiatives,
  SummerIntern,
} from "./pages/Placements";
import Admission, {
  Procedure,
  Eligibility,
  RulesRegulation,
  FeeStructure,
  Documents,
} from "./pages/Admission";
import Academics, {
  Calendar,
  Courses,
  Publications,
  Library,
  ICT,
} from "./pages/Academics.js";
import Alumni, {
  AlumniCell,
  Registrations,
  Achievers,
  Downloads,
  Testimonials,
  AlumniMeet,
} from "./pages/Alumni";
import ResearchAndInnovation, {
  R_Center,
  Paridnya,
  Published,
  CodeOfEthics,
  IPR_Cell,
  Conferences,
  FDP,
  StateLevelSeminar,
  III_Cell,
} from "./pages/ResearchAndInnovation";
import IQAC, { AQAR, NAAC } from "./pages/IQAC";
import StudentAtMIBM, {
  Achievement,
  AntiRaggingCell,
  Book_Review,
  Committee,
  Eco_Club_Activity,
  Gallery,
  Grievance,
  Induction,
  Infrastructure,
  Newsletter,
  ParticipationEvents,
  Trade_Fair,
  Visit,
} from "./pages/StudentAtMIBM";
import {
  ManagementFest,
  Cultural,
  Conference,
  Industry,
  Sports,
  Social,
  TrainingPlacement,
} from "./pages/ViewMorePages/ViewMoreMibmGallery.js";
import {
  ClassroomPhotos,
  ComputerLab,
  CulturalProgram,
} from "./Data/IQAC/AqarData.js";
import UnderMaintanence from "./pages/UnderMaintanence.js";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home2 />} />
          <Route>
            <Route
              path="/NewsAnnouncementPage"
              element={<NewsAnnouncementPage />}
            />
            <Route path="/NoticeBoardPage" element={<NoticeBoardPage />} />
            <Route path="/UpcomingEventPage" element={<UpcomingEventPage />} />
            <Route path="/Undermaintanence" element={<UnderMaintanence />} />
            <Route path="/ClassroomImages" element={<ClassroomPhotos />} />
            <Route path="/CulturalProgram" element={<CulturalProgram />} />
            <Route path="/ComputerLab" element={<ComputerLab />} />
          </Route>

          <Route>
            <Route
              path="/aboutUs"
              element={
                <AboutUs
                  pageId={0}
                  pageName={`About Us`}
                  pageContent={<AboutMIBM />}
                />
              }
            />
            <Route
              path="/aboutUs/PESociety"
              element={
                <AboutUs
                  pageId={1}
                  pageName={`P. E. Society`}
                  pageContent={<PESociety />}
                />
              }
            />
            <Route
              path="/aboutUs/CoreValues"
              element={
                <AboutUs
                  pageId={2}
                  pageName={`Core-Values`}
                  pageContent={<CoreValues />}
                />
              }
            />
            <Route
              path="/aboutUs/ChairmansDesk"
              element={
                <AboutUs
                  pageId={3}
                  pageName={`Chairman's Desk`}
                  pageContent={<ChairmanDesk />}
                />
              }
            />
            <Route
              path="/aboutUs/DirectorsDesk"
              element={
                <AboutUs
                  pageId={4}
                  pageName={`Director's Desk`}
                  pageContent={<DirectorsDesk />}
                />
              }
            />
            <Route
              path="/aboutUs/CoordinatorsDesk"
              element={
                <AboutUs
                  pageId={5}
                  pageName={`Coordinator's Desk`}
                  pageContent={<CoordinatorsDesk />}
                />
              }
            />
            <Route
              path="/aboutUs/Governance"
              element={
                <AboutUs
                  pageId={6}
                  pageName={`Governance`}
                  pageContent={<Governance />}
                />
              }
            />
            <Route
              path="/aboutUs/MandatoryDisclosure"
              element={
                <AboutUs
                  pageId={7}
                  pageName={`Mandatory Disclosure`}
                  pageContent={<MandatoryDisclosure />}
                />
              }
            />
            <Route
              path="/aboutUs/EOA-AICTE"
              element={
                <AboutUs
                  pageId={8}
                  pageName={`EOA AICTE`}
                  pageContent={<EOA_AICTE />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/admission/Eligibility"
              element={
                <Admission
                  pageId={0}
                  pageName={`Eligibility Criteria`}
                  pageContent={<Eligibility />}
                />
              }
            />
            <Route
              path="/admission/AdmissionProcedure"
              element={
                <Admission
                  pageId={1}
                  pageName={`Admission Procedure`}
                  pageContent={<Procedure />}
                />
              }
            />
            <Route
              path="/admission/RulesAndRegulation"
              element={
                <Admission
                  pageId={2}
                  pageName={`Rules and Regulation`}
                  pageContent={<RulesRegulation />}
                />
              }
            />
            <Route
              path="/admission/DocumentsRequired"
              element={
                <Admission
                  pageId={3}
                  pageName={`Documents Required`}
                  pageContent={<Documents />}
                />
              }
            />
            <Route
              path="/admission/FeeStructure"
              element={
                <Admission
                  pageId={4}
                  pageName={`Fee Structure`}
                  pageContent={<FeeStructure />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/academics/Courses"
              element={
                <Academics
                  pageId={0}
                  pageName={`Courses Offered`}
                  pageContent={<Courses />}
                />
              }
            />
            <Route
              path="/academics/FacultyAndPublications"
              element={
                <Academics
                  pageId={1}
                  pageName={`Faculty and Publications`}
                  pageContent={<Publications />}
                />
              }
            />
            <Route
              path="/academics/AcademicCalendar"
              element={
                <Academics
                  pageId={2}
                  pageName={`Academic Calendar`}
                  pageContent={<Calendar />}
                />
              }
            />
            <Route
              path="/academics/Library"
              element={
                <Academics
                  pageId={3}
                  pageName={`Library`}
                  pageContent={<Library />}
                />
              }
            />
            <Route
              path="/academics/ICT"
              element={
                <Academics
                  pageId={4}
                  pageName={`Interactive Teaching and Learnig (ICT)`}
                  pageContent={<ICT />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/placement/TrainingPlacementCell"
              element={
                <Placement
                  pageId={0}
                  pageName={`About Training & Placement Cell`}
                  pageContent={<AboutTnPCell />}
                />
              }
            />
            <Route
              path="/placement/SummerInternshipProgram"
              element={
                <Placement
                  pageId={1}
                  pageName={`Summer Internship Program`}
                  pageContent={<SummerIntern />}
                />
              }
            />
            <Route
              path="/placement/OurRecruiters"
              element={
                <Placement
                  pageId={2}
                  pageName={`Our Recruiters`}
                  pageContent={<OurRecruiters />}
                />
              }
            />
            <Route
              path="/placement/PlacementBrochure"
              element={
                <Placement
                  pageId={3}
                  pageName={`Placement Brochure`}
                  pageContent={<PlacementBrochure />}
                />
              }
            />
            <Route
              path="/placement/EmployabilityEnhancementProgram"
              element={
                <Placement
                  pageId={4}
                  pageName={`Employability Enhancement Program`}
                  pageContent={<EmployabilityEnhancement />}
                />
              }
            />
            <Route
              path="/placement/StudentIndustryAcademiaInitiatives"
              element={
                <Placement
                  pageId={5}
                  pageName={`Student Industry Academia Initiatives`}
                  pageContent={<StudIndustryInitiatives />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/alumni/AlumniCell"
              element={
                <Alumni
                  pageId={0}
                  pageName={`About Alumni Cell`}
                  pageContent={<AlumniCell />}
                />
              }
            />
            <Route
              path="/alumni/AlumniAchievers"
              element={
                <Alumni
                  pageId={1}
                  pageName={`Alumni Achievers`}
                  pageContent={<Achievers />}
                />
              }
            />
            <Route
              path="/alumni/Registrations"
              element={
                <Alumni
                  pageId={2}
                  pageName={`Alumni Registrations`}
                  pageContent={<Registrations />}
                />
              }
            />
            <Route
              path="/alumni/Downloads"
              element={
                <Alumni
                  pageId={3}
                  pageName={`Alumni Downloads`}
                  pageContent={<Downloads />}
                />
              }
            />
            <Route
              path="/alumni/AlumniMeet"
              element={
                <Alumni
                  pageId={4}
                  pageName={`Alumni Meet`}
                  pageContent={<AlumniMeet />}
                />
              }
            />
            <Route
              path="/alumni/Testimonials"
              element={
                <Alumni
                  pageId={5}
                  pageName={`Testimonials`}
                  pageContent={<Testimonials />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/research/Center"
              element={
                <ResearchAndInnovation
                  pageId={0}
                  pageName={`Research Center`}
                  pageContent={<R_Center />}
                />
              }
            />
            <Route
              path="/research/Paridnya"
              element={
                <ResearchAndInnovation
                  pageId={1}
                  pageName={`Paridnya`}
                  pageContent={<Paridnya />}
                />
              }
            />
            <Route
              path="/research/PaperPublished"
              element={
                <ResearchAndInnovation
                  pageId={2}
                  pageName={`Research Paper Published`}
                  pageContent={<Published />}
                />
              }
            />
            <Route
              path="/research/CodeOfEthics"
              element={
                <ResearchAndInnovation
                  pageId={3}
                  pageName={`Code Of Ethics`}
                  pageContent={<CodeOfEthics />}
                />
              }
            />
            <Route
              path="/research/IPRCell"
              element={
                <ResearchAndInnovation
                  pageId={4}
                  pageName={`IPR Cell`}
                  pageContent={<IPR_Cell />}
                />
              }
            />
            <Route
              path="/research/NationalConferences"
              element={
                <ResearchAndInnovation
                  pageId={5}
                  pageName={`National Conferences`}
                  pageContent={<Conferences />}
                />
              }
            />
            <Route
              path="/research/FDP"
              element={
                <ResearchAndInnovation
                  pageId={6}
                  pageName={`FDP`}
                  pageContent={<FDP />}
                />
              }
            />
            <Route
              path="/research/StateLevelSeminar"
              element={
                <ResearchAndInnovation
                  pageId={7}
                  pageName={`State Level Seminar`}
                  pageContent={<StateLevelSeminar />}
                />
              }
            />
            {/* <Route path="/research/IIICell" element={<ResearchAndInnovation pageId={8} pageName={`III Cell`} pageContent={<III_Cell />} />} /> */}
          </Route>

          <Route>
            <Route
              path="/IQAC/NAAC"
              element={
                <IQAC pageId={0} pageName={`NAAC`} pageContent={<NAAC />} />
              }
            />
            <Route
              path="/IQAC/AQAR"
              element={
                <IQAC pageId={1} pageName={`AQAR`} pageContent={<AQAR />} />
              }
            />
          </Route>

          <Route>
            <Route
              path="/students/MbaInduction"
              element={
                <StudentAtMIBM
                  pageId={0}
                  pageName={`MBA Induction`}
                  pageContent={<Induction />}
                />
              }
            />
            <Route
              path="/students/IndustrialVisit"
              element={
                <StudentAtMIBM
                  pageId={1}
                  pageName={`Industrial Visit`}
                  pageContent={<Visit />}
                />
              }
            />
            <Route
              path="/students/ParticipationEvents"
              element={
                <StudentAtMIBM
                  pageId={2}
                  pageName={`Students Participation & Events`}
                  pageContent={<ParticipationEvents />}
                />
              }
            />
            <Route
              path="/students/StudentNewsletter"
              element={
                <StudentAtMIBM
                  pageId={3}
                  pageName={`Student Newsletter`}
                  pageContent={<Newsletter />}
                />
              }
            />
            <Route
              path="/students/StudentAchievement"
              element={
                <StudentAtMIBM
                  pageId={4}
                  pageName={`Student Achievement`}
                  pageContent={<Achievement />}
                />
              }
            />
            <Route
              path="/students/Infrastructure"
              element={
                <StudentAtMIBM
                  pageId={5}
                  pageName={`Infrastructure`}
                  pageContent={<Infrastructure />}
                />
              }
            />
            <Route
              path="/students/AntiRaggingCell"
              element={
                <StudentAtMIBM
                  pageId={6}
                  pageName={`Anti-Ragging Cell`}
                  pageContent={<AntiRaggingCell />}
                />
              }
            />
            <Route
              path="/students/InternalComplaintCommittee"
              element={
                <StudentAtMIBM
                  pageId={7}
                  pageName={`Internal Complaint Committee`}
                  pageContent={<Committee />}
                />
              }
            />
            <Route
              path="/students/StudentGrievanceRedressalCell"
              element={
                <StudentAtMIBM
                  pageId={8}
                  pageName={`Student Grievance Redressal Cell`}
                  pageContent={<Grievance />}
                />
              }
            />
            <Route
              path="/students/Gallery"
              element={
                <StudentAtMIBM
                  pageId={9}
                  pageName={`Gallery`}
                  pageContent={<Gallery />}
                />
              }
            />
            <Route
              path="/students/BookReview"
              element={
                <StudentAtMIBM
                  pageId={10}
                  pageName={`Book Review`}
                  pageContent={<Book_Review />}
                />
              }
            />
            <Route
              path="/students/EcoClubActivity"
              element={
                <StudentAtMIBM
                  pageId={11}
                  pageName={`Eco Club`}
                  pageContent={<Eco_Club_Activity />}
                />
              }
            />
            <Route
              path="/students/TradeFair"
              element={
                <StudentAtMIBM
                  pageId={11}
                  pageName={`Trade Fair`}
                  pageContent={<Trade_Fair />}
                />
              }
            />
          </Route>

          <Route
            path="/students/Gallery/managementFest"
            element={<ManagementFest />}
          />
          <Route path="/students/Gallery/conference" element={<Conference />} />
          <Route path="/students/Gallery/cultural" element={<Cultural />} />
          <Route
            path="/students/Gallery/industrialVisit"
            element={<Industry />}
          />
          <Route path="/students/Gallery/sports" element={<Sports />} />
          <Route path="/students/Gallery/socialwork" element={<Social />} />
          <Route
            path="/students/Gallery/trainingPlacement"
            element={<TrainingPlacement />}
          />

          <Route path="/contact" element={<Contact />} />

          <Route path="*" element={<NoFoundPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
