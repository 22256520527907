import IPR_activity_1 from "../../assets/images/ResearchInnovation/IPR/Activity/IPR_activity_1.png";
import IPR_activity_2 from "../../assets/images/ResearchInnovation/IPR/Activity/IPR_activity_2.png";
import IPR_activity_3 from "../../assets/images/ResearchInnovation/IPR/Activity/IPR_activity_3.png";
import IPR_activiyty_24_1 from "../../assets/images/ResearchInnovation/IPR/Activity/IPR_activity_24_1.jpeg";
import IPR_activiyty_24_2 from "../../assets/images/ResearchInnovation/IPR/Activity/IPR_activity_24_2.jpeg";

import seminar_Day1_1 from "../../assets/images/ResearchInnovation/StateLevelSeminar/Seminar/Day1_seminar-1.png";
import seminar_Day1_2 from "../../assets/images/ResearchInnovation/StateLevelSeminar/Seminar/Day1_seminar-2.png";
import seminar_Day1_3 from "../../assets/images/ResearchInnovation/StateLevelSeminar/Seminar/Day1_seminar-3.png";
import seminar_Day2_1 from "../../assets/images/ResearchInnovation/StateLevelSeminar/Seminar/Day2_seminar-1.png";
import seminar_Day2_2 from "../../assets/images/ResearchInnovation/StateLevelSeminar/Seminar/Day2_seminar-2.png";

export const TableInfo = [
  {
    TableTitle: "Year of publication - 2018-19",
    tableHeading: [
      "Sr No",
      "Title of paper",
      "Name of the author/s",
      "Name of Journal",
      "ISBN/ISSN number",
    ],
    tableData: [
      {
        col1: "A critical analysis of Global Inequality",
        col2: "Harsha Thorve and Kishor Kumar Parganiha",
        col3: "International Journal of 360 Management Review",
        col4: "ISSN 2320-7132",
      },
      {
        col1: "Growth of SME Sector in India: The Role of Private Sector Banks",
        col2: "Monika Gorkhe and Dr Vijayalakshmi Srinivas",
        col3: "International Journal of Management IT & Engineering",
        col4: "ISSN 2249-0558",
      },
      {
        col1: "Growth and Future Prospects of MSME in India",
        col2: "Priyadarshani Zanjurne",
        col3: "International Journal of Advanced Engineering, Management and Science",
        col4: "ISSN: 2454-1311",
      },
      {
        col1: "Modeling Time Series Data in Private Sector Banks in India",
        col2: "Vidula Adkar",
        col3: "WEALTH International Journal of Money, Banking and Finance",
        col4: "ISSN:2277-9388",
      },
      {
        col1: "Use of innovative technology in education sector",
        col2: "Ashish Mohture",
        col3: "Ajanta",
        col4: "ISSN 2277-5730",
      },
      {
        col1: "GST and Human Resource",
        col2: "Ashish Mohture",
        col3: "Ajanta",
        col4: "ISSN 2277-5730",
      },
      {
        col1: "Study of Lending by Commercial Banks to Priority Sector with special reference to MSE",
        col2: "Monika Gorkhe and Dr Vijayalakshmi Srinivas",
        col3: "International Journal of Management IT & Engineering",
        col4: "ISSN 2249-0558",
      },
    ],
  },
  {
    TableTitle: "Year of publication - 2017-18",
    tableHeading: [
      "Sr No",
      "Title of exam",
      "Name of the author/s",
      "Name of Journal",
      "ISBN/ISSN number",
    ],
    tableData: [
      {
        col1: "Role of medical representative in pharmaceutical industry’s realities and challenges : A review of literature",
        col2: "Ashish Mohture",
        col3: "Synphony Zimca’s Management Journal",
        col4: "ISSN 2277-4661",
      },
      {
        col1: "Study of women’s involvement as a Medical representatives in pharmaceutical industry : Realities & Challenges",
        col2: "Ashish Mohture",
        col3: "Dattakala Group of Institutions- Conferenec Proceeding 2017",
        col4: "ISSN 2320-8821",
      },
      {
        col1: "To study the effect of numerous advertising on brand recognisation of Cadbury product",
        col2: "Sachin Hatole",
        col3: "Dyanamay Research Journal",
        col4: "ISSN 2395-7484",
      },
      {
        col1: "After effect of demonetization on Indian Economy",
        col2: "Vinita Choudhary/ Dr Vijayalashmi Sriniwas",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN 2347-2405",
      },
      {
        col1: "A study on various methods of payment in online transaction",
        col2: "Sachin Hatole",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN 2347-2405",
      },
      {
        col1: "Digital Transformation: The next big Leap in Microfinance",
        col2: "Dr. Vijayalakshmi Srinivas and Ruski Maha",
        col3: "Paridnya- The Mibm Research Journal",
        col4: "ISSN: 2457-0281",
      },
      {
        col1: "Modeling Time Series Data in Public Sector Banks in India",
        col2: "Vidula Adkar",
        col3: "KHOJ Journal of Indian Management Research and Practices",
        col4: "ISSN:0976-8282",
      },
      {
        col1: "Business Planning for Villages in Amravati Region using Industry Attractiveness-Business Strength Matrix.",
        col2: "Rajesh Raut and Vijayalakshmi Srinivas",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN: 2347-2405",
      },
      {
        col1: "Designing Businesses for High Potential Villages in Amravati District. Academic Social research",
        col2: "Rajesh Raut and Vijayalakshmi Srinivas",
        col3: "Academic Social Research",
        col4: "	ISSN: 2456-2645",
      },
      {
        col1: "Demonetisation: The new Tsunami in Indian Microfinance Industry.",
        col2: "Dr. Vijayalakshmi Srinivas and Ruski Mahal",
        col3: "Sankalan",
        col4: "ISSN: 2454-9266",
      },
    ],
  },
  {
    TableTitle: "Year of publication - 2016-17",
    tableHeading: [
      "Sr No",
      "Title of exam",
      "Name of the author/s",
      "Name of Journal",
      "ISBN/ISSN number",
    ],
    tableData: [
      {
        col1: "A study on mediating effect of Facebook and Twitter on selected brand equity dimensions of Bollywood celebrities present on these sites",
        col2: "Atul Fagade",
        col3: "Sankalan",
        col4: "ISSN No-2454-9266",
      },
      {
        col1: "An Analysis of Efficiency Performance of Private Life Insurance",
        col3: "Dr Philomena Fernandes",
        col4: "International Journal of Recent Research in Commerce , Economics and Management, Volume 3 issue 3",
        col2: "ISSN 2349-7809",
      },
      {
        col1: "A Study on Sickness in Small Scale Industrial Sector and Its Causes",
        col2: "Priyadarshani Zanjurne",
        col3: "International Journal of Advance Research",
        col4: "ISSN:2320-5407",
      },
      {
        col1: "Impact of Non-Perfroming Assets on Profitability and Productivity with Special Reference to Janata Sahakari Bank Ltd",
        col2: "Vidula Adkar",
        col3: "KHOJ Journal of Indian Management Research and Practices",
        col4: "ISSN:0976-8282",
      },
      {
        col1: "Trends and issues of private and public sector banks towards priority sector lendings",
        col2: "Harsha Thorve",
        col3: "Journal of Social Science ,Commerce and Comuter Technology",
        col4: "ISSN:2394-1774",
      },
      {
        col1: "A study on work behavior of HR personnel towards environment to endorse Green HRm Practices at workplace",
        col2: "Bhagyashree Pande",
        col3: "Dyanamay Research Journal",
        col4: "ISSN 2395-7484",
      },
      {
        col1: "A Study of enterprise group loan by Paras Capfin Co. Pvt Ltd",
        col2: "Harsha Thorve",
        col3: "Synphony Zimca’s Management Journal",
        col4: "ISSN 2349-7809",
      },
      {
        col1: "Taxonomy of literature based on studies of retail development at traditional marketplaces and high streets in India and foreign countries",
        col2: "Shubhada Anant Athavale",
        col3: "Vishwakarma Business Review",
        col4: "ISSN: 2229-6514",
      },
    ],
  },
  {
    TableTitle: "Year of publication - 2015-16",
    tableHeading: [
      "Sr No",
      "Title of exam",
      "Name of the author/s",
      "Name of Journal",
      "ISBN/ISSN number",
    ],
    tableData: [
      {
        col1: "Influence Of Advertisement Believability, Persuasiveness And Buying Behavior On Consumer Attitude Towards Advertisements Of Mobile Cellular Services",
        col2: "Owhal Shrawan Bhikaji",
        col3: "Journal of Commerce and Management Thought",
        col4: "ISSN: 0975-623x, (Online ISSN: 0976-478x)",
      },
      {
        col1: "Celebrity Endorsement And Non-Celebrity Advertisement: A Study Of Consumer Purchase Intention, Consumer Perception and Spokespersons Attributes",
        col2: "Owhal Shrawan Bhikaji",
        col3: "Novel Research Kaleidoscope",
        col4: "ISSN:2277-1263",
      },
      {
        col1: "Financing to priority sector lending by Bank of Maharashtra under LBS",
        col2: "Harsha Thorve",
        col3: "IRJCBSS",
        col4: "ISSN 2277-9310",
      },
      {
        col1: "Role of managers in cost saving through retention of medical representatives in Pharmaceutical industry",
        col2: "Ashish Mohture",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN: 2347-2405",
      },
      {
        col1: "Analytical Study of Priority and Non priority sector lending with reference to Nationalized bank in India",
        col2: "Harsha Thorve",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN: 2347-2405",
      },
      {
        col1: "Evaluating the Applicability of Maslow’s Theory of Motivation to Education",
        col2: "Lawly Das",
        col3: "Paridnya – MIBM Research Journal, Volume 3, Issue 1",
        col4: "ISSN No. 2347 – 2405",
      },
      {
        col1: "Study of case studies based on innovative marketing methods followed by companies and retailers globally",
        col2: "Shubhada Anant Athavale",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN: 2347-2405",
      },
    ],
  },
  {
    TableTitle: "Year of publication - 2014-15",
    tableHeading: [
      "Sr No",
      "Title of exam",
      "Name of the author/s",
      "Name of Journal",
      "ISBN/ISSN number",
    ],
    tableData: [
      {
        col1: "A critical analysis of Global Inequality",
        col2: "Allana Institute of Management Journal of Research",
        col3: "Dr Vijayalakshmi Srinivas",
        col4: "ISSN No. 2231-0290",
      },
      {
        col1: "Indian Banking Industry : Growth and Challenges",
        col2: "Dr Vijayalakshmi Srinivas",
        col3: "Dyanamay Research Journal",
        col4: "ISSN No. 2395-7484",
      },
      {
        col1: "Crowd Funding 3.0",
        col2: "Rajesh Raut and Vijayalakshmi Srinivas",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN: 2347-2405",
      },
      {
        col1: "Problems in retention of Medical Representatives in selected Pharmaceutical companies in India",
        col2: "Ashish Mohture",
        col3: "Paridnya The MIBM Research Journal",
        col4: "ISSN: 2347-2405",
      },
      {
        col1: "The Role Played by Private Life Insurance through Micro Insurance Schemes in Rural and Social Sector in India",
        col2: "Philomena Rudolf Fernandes",
        col3: "International Journal of Commerce,Management and Social Sciences",
        col4: "ISSN -2321-9831",
      },
      {
        col1: "Retail history of Downtowns, Business districts, City Centers and streets: A literature review, Khoj-Journal of Indian Management Research and Practices",
        col2: "Shubhada Anant Athavale",
        col3: "Khoj-Journal of Indian Management Research and Practices,",
        col4: "ISSN  0976 – 8262",
      },
      {
        col1: "An Up-Swing of M-Commerce in India",
        col2: "Dr Nivedita Ekbote",
        col3: "Dnyanamay Research Journal",
        col4: "ISSN 2395-6898",
      },

      {
        col1: " A collaborative Model of Business and Business schools – A qualitative study",
        col2: "Dr Nivedita Ekbote",
        col3: "Dnyanamay Research Journal",
        col4: "ISSN 2395-7484",
      },
      {
        col1: "A quest for Excellence in Higher Education Sector – Teachers’ Perspective and the role of Spiritual Quotient",
        col2: "Dr Nivedita Ekbote",
        col3: "Paridnya –The MIBM research Journal",
        col4: "ISSN 2347-2405",
      },
      {
        col1: "Spiritual Quotient – The ultimate quality of a leader",
        col2: "Dr Nivedita Ekbote",
        col3: "Exponent Journal of Management",
        col4: "ISSN 2320-2149",
      },
      {
        col1: "Management Education in India – an empirical perspective",
        col2: "Dr Nivedita Ekbote",
        col3: "Yashomanthan",
        col4: "ISSN 2347-8039",
      },
    ],
  },
];

// -----------------------------reserach center table data-------------------------------------------
export const heading = ["Sr No.", "Name", "Field"];

export const data = [
  {
    col1: "Prof Dr Vijayalakshmi Srinivas",
    col2: "Financial Management",
  },
  {
    col1: "Prof Dr Nivedita Ekbote",
    col2: "Organisation Management",
  },
  {
    col1: "Prof Dr Kalyani Srinivas - Associate Guide",
    col2: "General Management",
  },
];
// -----------------------------reserach center table data-------------------------------------------
export const InfoIPR23 = [
  {
    title: "Year 2022 - 2023",
    cardTitle: "A session on Intellectual property ",
    cardImages: [IPR_activity_1, IPR_activity_2, IPR_activity_3],
    cardDescription: [
      "A session on Intellectual property rights was conducted on 16th December 2022 as part of intellectual property rights cell.",
      "The intention of IPR cell is to create awareness about IPR acts and procedures among students and faculty members.",
      "Resource person of the session was Mr. Arpit Jain, Examiner of Patents and Designs  [Group- A Gazetted],Patent office, Mumbai, DPIIT, Ministry of Commerce and Industry, Govt of India.",
      "At the beginning of the session Mr. Arpit Jain was welcomed and felicitated by Dr.Vijayalakshmi Srinivas, Director - MIBM.",
      "During the Session Mr. Jain has emphasized the importance of Intellectual property rights and why it is important to have copy rights and patents of one’s work. Why there is a need for protection? Categories of Intellectual property rights such as patent- design, trademarks- logos, symbols. Geographical indicators, copyrights ©– theoretical work etc. Mr. Jain had given detailed description of items and categories and elaborated their importance. While describing various categories he did mention about the duration of protection. He further elucidated about how a company can be a registered company and its indication -®.",
      "He insisted on importance of geographical indications such as Basmati rice, Nagpur Orange, Alphonso Mangoes, Agra Pettah etc. He discussed about Patents and he mentioned that it’s for inventions.",
      "Overall, the session was very informative and knowledgeable for students who were not aware about latest amendments and concepts related to Intellectual Property Rights.",
      "IPR Cell",
      "Faculty Incharge",
      "Dr.Harsha Thorve & Prof. Sridevi Chennamsetti",
      // Add more paragraphs as needed
    ],
  },
];

export const InfoIPR24 = [
  {
    title: "Year 2023 - 2024",
    cardTitle: "IPR Cell -Session",
    cardImages: [IPR_activiyty_24_1, IPR_activiyty_24_2],
    cardDescription: [
      "A session on Intellectual property rights was conducted on 6th February 2024 as part of intellectual property rights cell. The intention of IPR cell is to create awareness about IPR acts and procedures among students and faculty members.",
      "The speaker of the session was Mr. Kunal Sarpal. During the Session Mr. Kunal has emphasized the importance of Intellectual property rights. Mr. Kunal has given several examples of Intellectual property rights such as patent- design, trademarks- logos, symbols. Geographical indicators, copyrights ©– theoretical work etc.",
      "Narrated his own experience and other incidents of various companies how intellectual property right conflicts occur. Mr. Sarpan had discussed various examples such as T series, Disney case and how legal issues related to patents had occurred and resolved.",
      "Overall, the session was very informative and knowledgeable for students who were not aware about latest amendments and concepts related to Intellectual Property Rights.",
      "Overall, the session was enlightening and students could relate to various examples to the core concepts. The session was very essential to students who will venture into corporate world.",
      "IPR Cell",
      "Faculty Incharge",
      "Dr. Harsha Thorve & Prof. Sridevi Chennamsetti",
    ],
  },
];
//  different array
export const InfoStateSeminar = [
  {
    title: "Year 2022 - 2023",
    tabData: [
      {
        label: "Day 1 Seminar",
        cardTitle:
          "Report on State Level Seminar held on 2nd and 3rd December 2022 ",
        cardImages: [seminar_Day1_1, seminar_Day1_2, seminar_Day1_3],
        cardDescription: [
          "Inauguration:",
          "State Seminar was inaugurated by the chief guest Dr. Anil Kashyap, VC and Chancellor, NICMAR, Pune. Director Dr. Vijayalakshmi Srinivas’s has welcomed the chief guest and explained the theme of the conference. She further spoke about the importance of research in academics. Dr. Nivedita Ekbote emphasised the value of research in educational institutions and further requested the chief guest Dr. Anil Kashyap to share views on how to enhance academic faculty and student research orientation.Dr. Kashyap acknowledged Dr. Nivedita Ekbote Maams request during their conversation and gave several strategies for establishing research practises, such as staff writing groups. writing in reflection in connection with assignments at the Institute level on various subjects. He went on to say that these techniques will encourage students to pursue research.",
          "Session 1:",
          "First session of the day was conducted by Dr.Vasant Bang, CEO Practman, Consultant and Visiting Professor at PUMBA. Dr.Vasant Bang during his interaction started with questions like What happens when a mango falls from tree? Does sun revolves around earth ?Does God exists? Is Ravan a villain? With such thought provoking questions he lead towards the  topics like paradigms of research, realities in Management, Cognition, Intention and action. How brain functions ? These topics acted as basis for the topic of the session qualitative research. His deliberations included importance of qualitative research. how to select population? How to decide quality of quantitative research? Towards the end of session Dr.Bang has discussed Elicitation techniques. Session was quite enriching and participants actively involved in question-and-answers.",
          "Session 2:",
          "Dr. Ranjeet Chitale, Faculty member at PUMBA is a renowned person in the areas of quantitative research. During his address Dr. Chitale explained the importance of quantitative research. Started with simple mean and standard deviation calculations. He elucidated how data can be divide the simple into subgroups? Dr.Chitale had discussed tabular data, frequency distribution , ordinal data, redundancy and skewness and various methods and measures while dealing with the data. Session was quite informative and knowledgeable for audience who are keen on learning quantitative techniques. With suitable examples Dr.Chitale made quantitative research simple and fun to learn.",
        ],
      },
      {
        label: "Day 2 Seminar",
        cardTitle:
          "Report on State Level Seminar held on 2nd and 3rd December 2022 ",
        cardImages: [seminar_Day2_1, seminar_Day2_2],
        cardDescription: [
          "Session 1:",
          "Dr. Hoshair Mal, Faculty at Flame University, Pune has conducted the first session of second.  Dr. Hoshair has started his session by sharing his own research paper on screen and explained it to seminar participans how to write a research paper. He also explained why it is important to choose the latest topic for research ?  Dr.Hoshair is having efficiency in use of excel. He had explained different calculation mehtods in excel and to use excel in an  effective way. He used qualitative research in his research paper and included the questions and answers in the inferences. Additionally he dicussed the format of a research paper and elucidated where to highlight and how to write a good research paper. The session was very enlightening.",
          "Session 2:",
          "During the second session was conducted by Dr. Archana Singh on how to go for publications in research. Dr.Archana had discussed about research paper format ? given various sources of research publications. Explained description of abstract and where to check authenticity of publications.",
          "How journal rankings can be check using scimago?  Why it is important to publish papers in valid and authentic research publications.",
          "overall, state-level seminar was successfully conducted, and the goal of speakers and attendees sharing knowledge was accomplished. All attendees actively engaged in the seminar`s learning process and enjoyed their participation.",
          "All sessions were enlightening and knowledgeable",

          // Add more paragraphs as needed
        ],
      },
    ],
  },
];
