import image1 from '../assets/images/HomeDesk/events/newsAnnouncement/images/WomensDay-1.jpg'
import image2 from '../assets/images/HomeDesk/events/newsAnnouncement/images/Womens_Day-2.jpg'
import Mangalya from '../assets/images/HomeDesk/events/newsAnnouncement/images/Mangalya.png'
import PhD from '../../src/assets/pdf/NewsAnnouncement/List of Students selected for PhD Admission 2021-2022.pdf'

export const newsData = [

    {
      title: 'Mangalya 9.0',
      Doc:{
        image:[Mangalya],
        pdf: [],
      },
      description: `Mangalya, 9.0  started on 28 th Feb and 29 th Feb 2024 with huge participation. 
      It was inaugurated by Prof.Dr.Nivedita Ekbote coordinator and Prof.Dr.Vijayalaxmi Srinivas, 
      Director of PES Modern Institute of Business Management. 
      The Rocking Flash Mob of our students was the point of attraction at inauguration. 
      Mangalya is the flagship management event of MIBM which consists of management games, 
      sports and most importantly is enormous fun.  Mangalya, today marks as one mile stone in the journey of MIBM. 
      This year's event was scheduled on 28 th Feb and 29 th Feb 2024.`,
      link: 'https://youtu.be/8GScjZAb2e8',
      updatedDate: 'Updated on 15 April 2024',
    },
    {
      title: 'Ph.D Interviews',
      Doc:{
        image:[ ],
        pdf: [PhD],}
    ,
      description: '',
      updatedDate: 'Updated on 19 May 2023',
    },
    {
        title: 'We celebrate International Women\'s Day today at MIBM',
        Doc:{
          image:[image1,image2],
          pdf: [],}
      ,
        description: 'And absolutely fitting to the occasion of today`s day, we had entrepreneurs, sportswomen, Chatrapati awardee and founders of Alacrity Gymnastics Academy, Madhura Tambe and Kalyani Tambe. The program started with a beautiful demo of gymnastics of their student and a national player. They moved on to discuss their journey, their challenges l, successes and failures and owning it all to move forward. Followed by a question-answer round, we are filled with a powerful, positive, and hopeful tomorrow with our collective participation.',
        updatedDate: 'Updated on 8 Mar 2022',
      },
    
  
  ];