import MenuItems from './MenuItems';


const Dropdown = ({ submenus, depthLevel}) => {

  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 2 ? 'dropdown-menu submenu show' : 'dropdown-menu';
  return (
    <ul className={`${dropdownClass}`} >
      {submenus.map((submenu, index) => ( 
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
