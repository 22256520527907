import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import chairman from '../../assets/images/HomeDesk/chairman.png';
import director from '../../assets/images/HomeDesk/Directors_desk.jpg'
import coordinator from '../../assets/images/HomeDesk/Prof.Dr.NiveditaEkboteCo-ordinator.jpg'



export default function MediaControlCard() {
  const theme = useTheme();
  return (
    <>
      <Card  sx={{ display: 'flex', marginBottom: 2 }} elevation={3} className='cardMobileView box'>
        <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: 146, lineHeight: 1.3}}>
          <CardContent sx={{ flex: '1 0 auto', padding: 1 }} className='textMobileView'>
            <h6 className='decorated mb-1' >CHAIRMAN'S MESSAGE</h6>
            <div className='P-tag text-justify mt-0 vertical-texts'>
              It is our Commitment to The Students and Parents that Modern Institute of Business Management will definitely Honor two Words Which are Important...
              <Link to={'/aboutUs/ChairmansDesk'} style={{ color: 'steelblue', fontSize: 13 }}> Read More...</Link>
            </div>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 130, padding: 1 }}
          image={chairman}
          alt="chairman"
        />
      </Card>
      <Card sx={{ display: 'flex', marginBottom: 2 }} elevation={3}  className='cardMobileView box'>
        <CardMedia
          component="img"
          sx={{ width: 130, padding: 1 }}
          image={director}
          alt="director"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 145, lineHeight: 1.3}}>
          <CardContent sx={{ flex: '1 0 auto', padding: 1 }}  className='textMobileView'>
            <h6  className='decorated mb-1'>DIRECTOR'S DESK</h6>
            <div className='P-tag text-justify mt-0 vertical-texts'>MIBM nurtures its Students to develop a Holistic approach, a curious mindset and the ability to think differently and innovatively to harness...
              <Link to={'/aboutUs/DirectorsDesk'} style={{ color: 'steelblue', fontSize: 13 }}> Read More... </Link>
            </div>
          </CardContent>
        </Box>

      </Card>
      <Card sx={{ display: 'flex', marginBottom: 2 }} elevation={3}  className='cardMobileView box'>
        <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: 140, lineHeight: 1.3}} sm={{maxHeight:150}}>
          <CardContent sx={{ flex: '1 0 auto', padding: 1 }}  className='textMobileView'>
            <h6 className='decorated mb-1'>COORDINATOR'S DESK</h6>
            <div className='P-tag text-justify mt-0 vertical-texts'> 
                         The foundation of MIBM is its highly qualified and motivated faculties with their expertise in various areas like Economics, Finance...
              <Link to={'/aboutUs/CoordinatorsDesk'} style={{ color: 'steelblue', fontSize: 13 }}> Read More... </Link>
            </div>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 130, padding: 1 }}
          image={coordinator}
          alt="coordinator"
        />
      </Card>
      
    </>
  );
}