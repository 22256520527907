import * as React from "react";
import { Paper } from "@mui/material";
import TableComponent from "../../Components/ReusableComponent/tableComponent";

export function CompositionOfIQAC() {
  const tableHeading = [
    "Sr No.",
    "Designation",
    "Names of IQAC Committee Members",
  ];
  const tableData = [
    {
      col1: "Chairperson: Head of the Institution",
      col2: "Dr Vijayalakshmi Srinivas",
    },
    {
      col1: "One of the senior teachers as the coordinator/Director of the IQAC",
      col2: "Prof Dr Harsha Thorve",
    },
    {
      col1: "Three to eight teachers",
      col2: "Dr. Shrawan Owhal,  Prof. Sampada Joshi , Prof. Rohit Alandikar",
    },
    {
      col1: "One member from the Management",
      col2: "Prof. Dr. Nivedita Ekbote, Dr. Anjali Sardesai",
    },
    {
      col1: "A few senior administrative officers",
      col2: "Mr. Pruthviraj Patil Mr Nilesh Kachi",
    },
    {
      col1: "One/two nominees from local society, Students and Alumni",
      col2: "Local Society Member : Mr Ajay Awasarikar, Student  :Mr Krishna Thombare, Alumnus:Mr Rohan Jain Alumni",
    },
    {
      col1: "One/two nominees from Employers /Industrialists/stakeholders",
      col2: "Chairman  of the Business Council :Dr. Gajanan R. Ekbote, Secretary Business Council : Prof S S Deshmukh, Industry Member : Mr Bhushan Joshi (24 Media & Communications Pvt. Ltd.)",
    },
    {
      col1: "Mr. Sandip Walunj Assistant Librarian",
      col2: "Member",
    },
    {
      col1: "Mr. Santosh Bagav Librarian",
      col2: "Secretary",
    },
  ];
  return (
    <div>
      <h5 className="my-3 title-text text-bold">
        COMPOSITION OF IQAC (LIST OF MEMBERS)
      </h5>
      <Paper elevation={1} className="mb-1">
        <TableComponent tableHeading={tableHeading} tableData={tableData} />
      </Paper>
    </div>
  );
}

export function Initiatives() {
  // const tableHeading = [
  //   'Sr No.','Initiative','Purpose','Action Taken'
  // ]
  // const tableData = [
  //   {
  //     col1:'Mentoring System' ,
  //     col2:'Counseling and provide guidance relating to their career, specialization etc',
  //     col3:'1. Mentors were appointed , 2. Guidance sessions on choosing specializations were organized'
  //   },
  //   {
  //     col1: 'Feedback system' ,
  //     col2:'Feedback to be taken from all the stakeholders such as students, alumni, employers and parents for ensuring effective functioning of the institute',
  //     col3:'1. Feedback form designed, circulated and taken regular intervals , 2. Action taken on various suggestions received.'
  //   },
  //   {
  //     col1: 'Skill development' ,
  //     col2:'Due to admission of diverse profile of candidates need was felt to organized skill development courses, personality development, confidence building programmes.',
  //     col3:'1. Certification programmes on skill development were introduced. , 2. Language lab set up'
  //   },
  //   {
  //     col1: 'Certification Programme' ,
  //     col2:'Enhance their employability skills and add value to their career prospects',
  //     col3:'Value addition certification programmes related to different functional areas such as digital marketing. Finance etc were introduced and implemented'
  //   },
  //   {
  //     col1:  'Research' ,
  //     col2:'Encourage faculty and students to participate in research activities',
  //     col3:'Faculty members encouraged to participate in various seminars, conferences and workshops. They are also encouraged to pursue their Research work. Students are encouraged to participate in Various research based competitions and in writing research papers.'
  //   },

  // ]
  const tableHeading = ["Sr No.", "Initiative", "Purpose", "Action Taken"];

  const tableData = [
    {
      col1: "Academic calendar Preparation",
      col2: "For achieving the aspects of students performance and institutional efficiency",
      col3: "Determine Key weeks, Start and end of academic terms (semesters), Event details. Regularly review the calendar throughout the year to ensure it is being followed.",
    },
    {
      col1: "Academic Committees",
      col2: "For roles and responsibilities of academic year and improved academic system",
      col3: "Assign specific tasks and responsibilities to individual members or sub-committees. Follow-Up: Monitor progress and ensure follow-up on assigned tasks.",
    },
    {
      col1: "Orientation and Induction Program MBA I and MBA II",
      col2: "For creating harmony and team building with support of each other to succeed in academic pursuits",
      col3: "Include ice-breaker activities, group discussions, and team-building exercises to encourage interaction among students.",
    },
    {
      col1: "Community Outreach Program and Institutional Social Responsibility",
      col2: "Rejoice-The Joy of Giving, We Care ( club for Activities for Social Development)",
      col3: "Organize visits and run campaigns on campus about issues like health, and environmental sustainability.",
    },
    {
      col1: "Employability Enhancement Program",
      col2: "Apperaring for Personal Interview Training, Training on Microsoft Excel and PowerPoint",
      col3: "Developed program on Skill Development, Personal Interview Training, Training on Microsoft Excel and PowerPoint.",
    },
    {
      col1: "Certificate courses",
      col2: "For building the specific domain knowledge",
      col3: "Curriculum Design, MOU with the Agency, Expert session on specific areas.",
    },
    {
      col1: "Skill Development session",
      col2: "For enhancing the skills of students",
      col3: "Organize expert session on soft skills.",
    },
    {
      col1: "Eco club Activities",
      col2: "Creating environmental awareness amongst the students",
      col3: "Formation of eco club and awareness about Rethink, Reduce, Reuse, Recycle.",
    },
    {
      col1: "Faculty Development Program",
      col2: "To train the faculty for effective delivery of curriculum with different teaching methods",
      col3: "FDP- prepare teachers with the latest teaching methods.",
    },
    {
      col1: "Movie club activities",
      col2: "To learn new things",
      col3: "To enrich, educate, entertain, with movie shows.",
    },
    {
      col1: "Book Review competition",
      col2: "For developing reading habits amongst students",
      col3: "Arrangement of Book Review competition.",
    },
    {
      col1: "Guest session",
      col2: "For industry interaction and sharing subject knowledge",
      col3: "Arrangement of expert session on latest topic.",
    },
    {
      col1: "Workshop on IPR",
      col2: "For creating awareness about Intellectual property Rights",
      col3: "One workshop every year.",
    },
    {
      col1: "Trade Fair competition",
      col2: "To build entrepreneurial quality, activities to encourage budding entrepreneurs",
      col3: "Trade fair competition -once in a year.",
    },
    {
      col1: "Industrial Visit",
      col2: "For learning how industries work, practical learning",
      col3: "Industrial Visit -BSE, NSE (Finance students), Other Industries (For All students) Labor court visit (HR Students.",
    },
    {
      col1: "Prominent Day celebration",
      col2: "To create awareness for important days",
      col3: "All important day celebration on campus with relevant information sharing.",
    },
    {
      col1: "Specialization club activities",
      col2: "Group Based activities",
      col3: "Different activities planned and implemented as per specialization.",
    },
    {
      col1: "Mentor-Mentee Program",
      col2: "To provide individual counseling to students",
      col3: "Faculty-wise students allocation, Meetings, counseling.",
    },
    {
      col1: "Darpan",
      col2: "To create a platform for showcasing talents",
      col3: "Organize Darpan-Talent showcase program-Ones in a year.",
    },
    {
      col1: "Managyala",
      col2: "Annual Management fest for developing qualities like event management, team handling, leadership",
      col3: "Organize Management fest-Mangalya.",
    },
    {
      col1: "Alumini Meet",
      col2: "For repo building and expert knowledge contribution",
      col3: "Engage alumni to mentor current students, share job opportunities, and support the program.",
    },
    {
      col1: "Feedback system",
      col2: "Feedback to be taken from all the stakeholders such as students, alumni, employers, and parents for ensuring effective functioning of the institute",
      col3: "Feedback from all the stakeholders.",
    },
    {
      col1: "Research",
      col2: "Encourage faculty and students to participate in research activities",
      col3: "Faculty members encouraged to participate in various seminars, conferences, and workshops. They are also encouraged to pursue their Research work. Students are encouraged to participate in Various research-based competitions and in writing research papers.",
    },
  ];

  return (
    <>
      <div>
        <h5 className="my-3 title-text text-bold">
          Some of Initiatives of IQAC are as follows:{" "}
        </h5>

        <Paper elevation={1} className="mb-1">
          <TableComponent tableHeading={tableHeading} tableData={tableData} />
        </Paper>
      </div>
    </>
  );
}
