import React from 'react';
import { NoticeData } from '../../Data/NoticeBoardData';
import Paper from '@mui/material/Paper';
import Blink from 'react-blink-text';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import imgH from '../../assets/images/Web1920.jpg'
import { Grid, ImageList, ImageListItem } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Breadcrumb from '../../Components/Breadcrumb';
import { NoticeIcon } from '../../Components/SvgIcons';


const data = NoticeData;

const NoticeBoardPage = () => {
  window.scrollTo(0, 270);
  return (
    <>

      <div className="bredDivMob">
        <Breadcrumb pageTitle={<h3 className='fs-3 mt-0 text-bold decorated text-light'>Notice Board</h3>} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={<h3 className='fs-3 mt-0 text-bold decorated text-light'>Notice Board</h3>} />
      </div>
     <div className='bg-light py-3'>
      <Paper elevation={0} className='bg-light mx-5 my-5'>
        {data.map((items, index) => (
          <div className="upcoming-texts" key={index}>
            <div className="home-card-content">
              <Grid container>
                <Grid xs={0.5}>
                  <NoticeIcon />
                </Grid>
                <Grid xs={11.5}>
                  <h5 className="home-card-title g-2 fs-5"> {items.viewTitle}</h5>
                  <div className="home-card-body fs-6 mt-3 mx-lg-4 " >{items.description}</div> {/* Render news body */}
                  <div className='fs-5 g-3 mt-2'>
                    {
                      items.Doc.pdf.length !== 0 &&
                      <a href={items.Doc.pdf} target='_blank' className='fs-6 g-3 mt-2 mx-lg-4'> <PictureAsPdfIcon className='pdficon' />   view more...</a>}
                  </div>
                  <div className="Application home-card-Footer mt-3">
                    <Blink color="gray" text={items.updatedDate} fontSize={15}></Blink>
                  </div>
                  <div className="d-flex flex-wrap">
                    <ImageList sx={{ width: 500 }} cols={3} gap={8} className='mt-3 mb-2 mx-lg-4'>
                      {items.Doc.image.map((image, imageIndex) => (
                        <ImageListItem key={imageIndex} >
                          <img className='rounded'
                            src={image}
                            alt={`Image ${imageIndex + 1}`}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </div>
                 
                </Grid>
              </Grid>
            </div>
          </div>
        ))}
      </Paper>
      </div>
    </>

  )
}

export default NoticeBoardPage;
