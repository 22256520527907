import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import { Box, IconButton } from "@mui/material";

const Breadcrumb = (props) => {
    const Click = (()=>{
        return(
            <Link to='/'></Link>
        )
    })
    // let pageTitle = 'Page Title';
    return(
        <>
            <div className="position-relative">
                <div className="overlayDiv"/>
                <div className="backgroundDiv">
                    <div className="container d-flex align-items-center box">
                        <h4 id="page-title" className="text-white opacity-75 mx-5">
                            {props.pageTitle}
                        </h4>                    
                    </div>
                  
                </div>
            </div>
        </>

    );
}

export default Breadcrumb;