import React from 'react';
const cardStyles = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '10px',
    margin: '16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  
  const imageStyles = {
    width: '200px',
    maxWidth: '100%',
    maxHeight: '100%',
    marginRight: '10px',
  };
  
  const titleStyles = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  };
  
  const infoStyles = {
    fontSize: '0.9rem',
  };
   
  const headStyle = {
    fontSize :'1rem',
    fontWeight:'bold',
    marginTop:"10px"
  }
  
  
  const InfoCard = ({ imageSrc, title, info , info2, head }) => {
    return (
      <div style={cardStyles} className='textWrap'>
        <img src={imageSrc} alt={title} style={imageStyles} />
        <div data-aos="fade-left">
          <h2 style={titleStyles}>{title}</h2>
          <h3 style={headStyle}>{head}</h3>
          <p style={infoStyles}>{info}</p>
          <p style={infoStyles}>{info2}</p>

        </div>
      </div>
    );
  };
  
  export default InfoCard;