import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ChairmanImg from '../assets/images/AboutUs/GajananREkbote.png';
import CoordinatorsImg from '../assets/images/AboutUs/Prof.Dr. Nivedita Ekbote.jpg';
import BgImgMIBM from '../assets/images/AboutUs/MIBM_bgImg.jpg'
import PEsociety from '../assets/images/PESociety/PE-Society.png';
import AboutUsMIBM, { Vision, PESocietyCard } from '../Data/AboutMIBM';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Paper } from "@mui/material";
import Divider from '@mui/material/Divider';
import Breadcrumb from "../Components/Breadcrumb";
import Directors from '../assets/images/AboutUs/VijayalakshmiShrinivas.png';
import { RectangleBlock, FacebookIcon, TwitterIcon, LinkedInIcon, FbIcon, InstaIcon, TwitIcon, LnIcon, SquareBlock } from "../Components/SvgIcons"
import Mandatory from '../assets/pdf/AboutUs/Mandatory-Disclosure.pdf';
import  EOAAICTE from '../assets/pdf/AboutUs/EOA-AICTE-2019-2023.pdf';
import computationFee  from '../assets/pdf/AboutUs/Computation_14-15.pdf';
import  CommonInformation  from '../assets/pdf/AboutUs/Proforma_of_Common_Info_14-15.pdf';
import Tooltip from '@mui/material/Tooltip';

const sidemenus = [
  {
    title: "About Us",
    url: "/aboutUs",
  },
  {
    title: "P. E. Society",
    url: "/aboutUs/PESociety",
  },
  {
    title: `Core-Values`,
    url: "/aboutUs/CoreValues",
  },
  {
    title: `Chairman's Desk`,
    url: "/aboutUs/ChairmansDesk",
  },
  {
    title: `Director's Desk`,
    url: "/aboutUs/DirectorsDesk",
  },
  {
    title: `Coordinator's Desk`,
    url: "/aboutUs/CoordinatorsDesk",
  },
  {
    title: "Governance",
    url: "/aboutUs/Governance",
  },
  {
    title: `Mandatory Disclosure`,
    url: "/aboutUs/MandatoryDisclosure",
  },
  {
    title: `EOA AICTE`,
    url: "/aboutUs/EOA-AICTE",
  },
];

export default function AboutUs(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    // window.scrollTo(0, 0);
    window.scrollTo(0, 420);

  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));
    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {menu.title}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {/* <h5 className="title-text my-4 mt-2">{menu.title}</h5> */}
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// sub-pages below of about us tab

export function AboutMIBM() {
  return (
    <>
      <div className="box text-justify">
        <h5 className="title-text my-4 mt-1 decorated">
          <div className="mb-1">Modern Institute of Business Management (MIBM)</div>
        </h5>
        <Paper elevation={3} className="p-3 text-justify">
          <div className="P-tag" >
            <img data-aos="fade-right" style={{ minHeight: '250px', maxHeight: '300px', maxWidth: 'auto', minWidth: '100%' }} src={BgImgMIBM} alt="" srcset="" />
            <div data-aos="fade-left">
              <p className="mt-3">
                Progressive Education Society’s Modern Institute of Business Management came into existence in 2010. Instituted with the avowed aim of imparting quality education to students hailing from diverse socio-economic background and those living in and around Pune city.
              </p>
              <p>The purpose of education has been to prepare young minds for meeting life’s challenges, to find gainful employment, to make way for pure intellectual pursuits and be sensitive citizens contributing to the growth and development of our society.
              </p>
              <p>MIBM aims to provide our students with all the essential skills needed to transform them into peak performers in the journey of lifelong excellence. Learning here is a constant source of fascination, discovery and delight. A fabulous range and richness of talent and ideas bring with them the challenge for leadership to harness human energy into effective action in today’s dynamic business environment.
              </p>
              <p>The Institute is committed to providing a strong foundation and building competencies to make complete business leaders out its students.
              </p>
            </div>
          </div>
        </Paper>

        <div className="mb-5">
          <Vision />  {/* vision mission  */}
        </div>
        <Divider sx={{ border: 1 }} />

        <h5 className="title-text mt-5 decorated text-bold">Soaring To Perfection</h5>
        <div className="blockquote mx-4 mb-5" data-aos="fade-up">
          <blockquote className="p-3 mt-4 ps-5 fs-6">
            Our goal is to create and develop “Modern” youth as responsible citizens with multi-dimensional personalities by inculcating among students a blending of cultural awareness, compassionate and progressive attitude, scientific insights and time-tested traditional values.
          </blockquote>
        </div>
        <div className="px-5">
        <a className="mx-0 title-text text-bold pdf Brochure w-100"><PictureAsPdfIcon className="mx-2" />NIRF</a>

        </div>

        <Divider sx={{ border: 1 }} className="mt-3" />

        <AboutUsMIBM />
      </div>
    </>
  );
}

export function PESociety() {
  return (
    <>
      <div className="box text-justify">
        <h5 className="title-text my-4 mt-2 decorated">Progressive Education Society</h5>
        <Paper elevation={3} className="p-3 mb-5">
          <div className="P-tag" data-aos="fade-up">
            <div className="row" >
              <div className="col-3">
                <img src={PEsociety} style={{ maxHeight: 'auto', maxWidth: 'auto' }} />
                {/* <p className="mt-3 text-center"><b>Hon. Shri. Shankarrao Kanitkar</b><br />
              Founder of P.E. Society</p> */}
              </div>
              <div className="col-9">
              <p>
                Progressive Education Society is a reputed and distinguished educational
                institution in Maharashtra. Honorable, Late Shankarrao Kanitkar,
                along with his selfless dedicated team of teachers known for the highest moral values,
                laid the foundation of the Society on the auspicious day of 'Akshay Tritiya' on 16th May 1934 in Pune.
              </p>
            </div>
            </div>
            <p>Progressive Education Society has been making noteworthy progress for the last 83 years
              along with the noble path and principles laid down by the eminent predecessors such as
              Guruwarya Late S. R. Kanitkar, Late V. T. Tatke and Late M. G. Chaphekar, who were illustrious
              incarnations of ceaseless hard work and sacrifice.
            </p>
            <p>Late S. R. Kanitkar was ably assisted by Late V. T. Tatke, Late M. G. Chaphekar
              and Late Koparkar. Progressive Education Society is proud to possess the glowing
              tradition of these stalwarts.
            </p>
            <p>The two remarkable words that appear in the names of the Society and its educational institutions are
              "Progressive" and "Modern". The inclusion of these words was certainly with some noble intentions,
              which serves as a bright beacon for Progressive Education Society to follow with determination.
            </p>
            <p>'Progressive' means keeping with the spirit of times and innovation while 'Modern' means Modern in outlook,
              up-to-date in all matters whether it is an academic, sports or cultural activities. It was resolved to use
              modern equipment and modern methodology to teach the students.
            </p>
            <p>The aims and objectives of the Progressive Education Society as laid down in its Constitution are to impart
              generally to the rising generations of this country and particularly to the residents of Maharashtra, an
              efficient Pre-primary, Primary, Secondary, Higher Secondary and Collegiate education, embodying a literacy,
              scientific, technical, commercial and industrial training and the training of teachers by the establishment
              at Pune and other places in the Maharashtra State, as circumstances permit, of Pre-primary, Primary, Secondary,
              Higher Secondary Schools and Colleges or by such other means as may be most conducive to the attainment of
              the aims and objects of the Society.
            </p>
            <p>For more information visit our website, <a target="_blank">www.pespune.com</a> </p>
          </div>
        </Paper>
        <PESocietyCard />
      </div>
    </>
  );
}

export function CoreValues() {
  return (

    <>
      <div className="box text-justify">
        {/* Core Values */}
        <h5 className="title-text my-2 mb-4 decorated">Core Values</h5>
        <Paper elevation={3} className="p-3" style={{ minHeight: '450px' }}>
          <div  data-aos="fade-down">
            <ul className="ul-tag">
              <li>Focused Learning</li>
              <li>Research oriented</li>
              <li>Quality Education</li>
              <li>Ethical Business Leaders</li>
              <li>Responsible and Accountable</li>
              <li>Social Responsibility</li>
              <li>Holistic Development</li>
            </ul>

            {/* <object
          data="https://mibspune.in/pdf/FRA.pdf"
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>Instead you can <Link to="https://mibspune.in/pdf/FRA.pdf"> click here to download the PDF file.</Link></p>
        </object> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

export function ChairmanDesk() {
  return (
    <>
      <div className="text-justify box">

        <h5 className="title-text mb-3 decorated">Chairman's Message</h5>

        <Paper elevation={3} className="p-3">

          <div className="text-justify">
            <div className="row mt-2">
              {/* Message  */}
              <div className="blockquote"  data-aos="fade-up">
                <blockquote className="pb-1 pt-3 px-2 ps-5 fs-6">
                  <p>I have always believed that the answer to my destiny lies in creating the results, I desire!!</p>
                  <p className="text-bold">Prof. Dr. Gajanan R. Ekbote</p>
                </blockquote>
              </div>

              {/* Profile  */}
              <div className="col-lg-4 text-center profile-info" >
                <img src={ChairmanImg} alt="userImage" className="opacity-100 img-fluid" />
                <h5 className="my-3 subtitle-text">Gajanan R. Ekbote</h5>
                <p className="m-1">Chairman Desk</p>
                <p className="m-1">Email</p>
                {/* social icons */}
                <div className="d-flex gap-3 justify-content-center social-icons forMobileView">
                <Tooltip title="Facebook">
                  <Link className="facebook">
                    <FbIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Twitter">
                  <Link className="twitter">
                    <TwitIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Linkedin">
                  <Link className="linkedin">
                    <LnIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Instagram">
                  <Link className="instagram">
                    <InstaIcon />
                  </Link>
                  </Tooltip>
                </div>
              </div>
              <div className="col-lg-8 P-tag"  data-aos="fade-left">
                <p className="fw-bold">Dear Students and Well - Wishers</p>
                <p>
                  Technology is advancing day by day. New institutions are launched every year in and around Pune City. Every institute is trying its best to give the best possible facilities to the students. However it is our commitment to the students and parents that Modern Institute of Business Management will definitely honor two words which are important to the management of “Progressive” and “Modern”.
                </p>
                <p>
                  The future probably belongs to people who dare to dream big. It belongs to those who make way through the clouds of oblivion with the force of their determination.
                </p>
                <p>
                  Modern Institute of Business Management is recently started in 2010. The Fee Structure of MIBM is well within the reach of poor and middle class students. Our mission is to develop competent, committed and compassionate leaders who will make a difference to the people among whom they work. It constantly scans the developments in business and in society and tries proactively to meet the challenges.
                </p>
                <p>
                  Best wishes,<br />
                  <b>Prof. Dr. G. R. Ekbote</b><br />
                  Chairman,<br />
                  Progressive Education Society.
                </p>
                {/* <div className="blockquote d-none">
                <blockquote className="p-3 mt-4 ps-5 fs-6">
                  I have always believed that the answer to my destiny lies in creating
                  the results, I desire!
                </blockquote>
              </div> */}
              </div>

            </div>
            <Divider sx={{ border: 1 }} />
            {/* About & Career Path  */}
            <div className="my-5 my-lg-0" style={{ textAlign: "justify" }}>
              <h5 className="title-text mb-3 mt-3">About Dr Gajanan R. Ekbote</h5>
              <p className="P-tag">
                A surgeon by profession, Dr. Gajanan R. Ekbote made his foray into the
                world of administration in 1985. Fuelled by the desire for pedagogical
                pursuits, he accepted the challenging responsibility of leading the
                Progressive Education Society, Pune as its chairman. In order to keep pace
                with the changing scenario of education, he has introduced emerging and
                need-based courses in the institutions run by Progressive Education
                Society. It is an account of his pro-active approach and foresightedness
                that he has been successfully leading 56 educational institutions in Pune
                City. Dr. G. R. Ekbote was granted an equivalence of Ph.D degree by
                Maharashtra University of Health Sciences, Nashik for his outstanding
                academic qualifications, research work and eminence achieved in his
                profession. Presently, he is working as the Pro-Vice Chancellor of the
                same University.
              </p>
              <Divider sx={{ border: 1 }} />

              <h5 className="title-text mb-3 mt-3">Career Profile</h5>
              <ul className="ul-tag">
                <li>Professor of Surgery - B. J Medical College, Pune</li>
                <li>
                  Dean, Faculty of Medicine - Savitribai Phule Pune University, Pune
                </li>
                <li>
                  Appointed as a Member of Consultative Group on 'Education' by planning
                  commission, Govt. of India.
                </li>
                <li>
                  Appointed as a Member of Consultative Group on 'Teacher's Education' by
                  planning commission, Govt. of India.
                </li>
                <li>
                  Member, Senate and Board of College and University Development -
                  Savitribai Phule Pune University
                </li>
                <li>
                  Member, Management Council (Chancellor's Nominee) - Senate and Academic
                  Council Maharashtra University of Health Sciences, Nashik
                </li>
                <li>
                  Member, Board of Governors - National Institute of Technology,
                  Surathkal, Karnataka.
                </li>
                <li>
                  Member, Academic Council (UGC Nominee) - West Bengal University of
                  Health Sciences, Kolkata.
                </li>
              </ul>
            </div>
          </div>
        </Paper>
      </div>
    </>
  )
}

export function DirectorsDesk() {
  return (
    <>
      <div className="box text-justify">

        <h5 className="title-text mb-3 decorated">Director's Message</h5>

        <Paper elevation={3} className="p-3">

          <div className="row mt-2 text-justify" >

            {/* Profile */}
            <div className="col-lg-4 text-center profile-info">
              <img src={Directors} alt="userImage" className="opacity-100 img-fluid" />
              <h5 className="my-3 subtitle-text">Dr. Vijayalakshmi Shrinivas</h5>
              <p className="m-1">Director</p>
              <p className="m-1">Email</p>
              {/* social icons */}
              <div className="d-flex gap-3 justify-content-center social-icons forMobileView">
                <Tooltip title="Facebook">
                  <Link className="facebook">
                    <FbIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Twitter">
                  <Link className="twitter">
                    <TwitIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Linkedin">
                  <Link className="linkedin">
                    <LnIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Instagram">
                  <Link className="instagram">
                    <InstaIcon />
                  </Link>
                  </Tooltip>
                </div>
            </div>
            {/* Message */}
            <div className="col-lg-8"  data-aos="fade-up">
              <p>
                P.E. Society Modern Institute of Business Management (MIBM) was established on 27th September 2010 under the able guidance of our Hon. Chairman Prof Dr Gajanan R. Ekbote. Our vision is “To impart quality based education to be self reliant and self sustaining person through inventive systematic education. Its strength lies in the integration of different paths, design thinking, experiencing the traditional teaching also engaging in real time corporate challenges”.
              </p>
              <p>
                MIBM nurtures its students to develop a holistic approach, a curious mindset and the ability to think differently and innovatively to harness the opportunities emerging from challenges. In the recent year, MIBM has taken several steps in its pursuit of excellence. Some of the specific steps taken during the last one year : Recruitment of new faculty, Industry Institute Interface through regular guest lectures, conducting small group activities which enhance the communication skill and confidence of students. MIBM students are provided with the right skills to deal with changing Business scenario to face future challenges.
              </p>
              <p>
                We Faculty and staff of MIBM are engaged in a continuous search to strive for excellence by putting dedicated and continuous efforts. I invite you to browse through the website for an enriching experience of learning!!!
              </p>
              <p>
                Best wishes,<br />
                <b>Dr. Vijayalakshmi Shrinivas</b><br />
                (Director, Modern Institute of Business Management)
              </p>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function CoordinatorsDesk() {
  return (
    <>
      <div className="text-justify box">

        <h5 className="title-text mb-3 decorated">Coordinator's Message</h5>
        <Paper elevation={3} className="p-3">

          <div className="row mt-2 text-justify">

            {/* Profile */}
            <div className="col-lg-4 text-center profile-info">
              <img src={CoordinatorsImg} alt="userImage" className="opacity-100 img-fluid" />
              <h5 className="my-3 subtitle-text">Prof. Dr. Nivedita G Ekbote</h5>
              <p className="m-1">Coordinator</p>
              <p className="m-1">Email</p>
              {/* social icons */}
              <div className="d-flex gap-3 justify-content-center social-icons forMobileView">
                <Tooltip title="Facebook">
                  <Link className="facebook">
                    <FbIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Twitter">
                  <Link className="twitter">
                    <TwitIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Linkedin">
                  <Link className="linkedin">
                    <LnIcon />
                  </Link>
                  </Tooltip>
                  <Tooltip title="Instagram">
                  <Link className="instagram">
                    <InstaIcon />
                  </Link>
                  </Tooltip>
                </div>
            </div>
            {/* Message */}
            <div className="col-lg-8"  data-aos="fade-up">
              <p>Warm Greetings and Hearty Welcome to MIBM Website!!!!</p>
              <p>
                India has now been able to occupy the centre stage in the world’s
                economic scenario. Businesses are becoming global and fast-paced
                changes are taking place at the technological front, hence
                businesses are becoming more volatile in nature. The role of the
                manager, now-a-days, is getting complicated and they are required
                to have with innovative skills and greater knowledge base.
              </p>
              <p>
                Progressive Education Society’s Modern Institute of Business
                Management is committed to provide the necessary skill sets to
                bridge the supply and demand gap of professionally trained
                managers. MIBM believes in providing high-standard teaching
                learning process. The foundation of MIBM is its highly qualified
                and motivated faculties with their expertise in various areas like
                Economics, Finance, Marketing, Human Resource Management, IT and
                Operations.
              </p>
              <p>
                Progressive Education Society’s Modern Institute of Business
                Management has an intake capacity of 180 students for MBA course
                affiliated to Savitribai Phule Pune University.
              </p>
              <p>We aim at continuous growth and progress with quality
                education as the top priority, hence we are planning to start
                research center at MIBM to strengthen the knowledge base and
                achieve excellence in the field of education.</p>
              <p>
                <p>We look forward to your continuous and life-time association with MIBM.</p>
                All the very best,<br />
                <b>Prof. Dr. Nivedita G Ekbote</b><br />
                (Coordinator)
              </p>
            </div>
          </div>
        </Paper>
      </div>
    </>
  )
}

export function Governance() {
  return (
    <>
      <div className="box text-justify">
        <h5 className="title-text my-2 mb-4 decorated">College Development Committee (CDC)</h5>

        <Paper elevation={3} className="p-3">

          <div>
            <div  data-aos="fade-down">
              <ul className="ul-tag">
                <li>Prof. Dr. G.R.Ekbote – Chairman, Business Council</li>
                <li>Prof. S.S.Deshmukh – Visitor, MIBM</li>
                <li>Prof. Mrs. Jyotsna Ekbote – Social Service</li>
                <li>Prof. K. R. Joshi – Research</li>
                <li>Prof. Dr. M.D. Alandikar – Industry</li>
                <li>Prof. Dr. R.S. Zunjarrao – Special Invitee</li>
                <li>Prof. S. S. Kharat – Special Invitee</li>
                <li>Prof. A. G. Gosavi – Special Invitee</li>
                <li>Prof. S.S. Thengdi – Special Invitee</li>
                <li>Prof .Dr .Vijayalakshmi Srinivas – Director, MIBM</li>
                <li>Prof. Dr. Nivedita Ekbote – Coordinator, MIBM</li>
                <li>Mr. Nilesh Kachi – Non Teaching Representative</li>
              </ul>
            </div>
            <Divider sx={{ border: 1, marginBottom: 1 }} />

            <div  data-aos="fade-up">
              <h5 className="title-text my-2 mb-4 text-bold" >Information Officer</h5>
              <h5 className="mx-4 my-2 mb-4 text-bold fs-6">Prof. Dr. Nivedita Ekbote</h5>
              <p className="mx-4 my-2 mb-4 P-tag">Contact Number – 9922007326</p>
            </div>
            <Divider sx={{ border: 1, marginBottom: 1 }} />

            <div  data-aos="fade-up">
              <h5 className="title-text my-2 mb-4 text-bold">Downloads</h5>
              <Link to={computationFee} target="_blank" className="pdf Brochure"><PictureAsPdfIcon /> Computation of fees</Link>
              <Link to={CommonInformation} target="_blank" className="pdf Brochure"><PictureAsPdfIcon /> Proforma of Common Information</Link>
            </div>

          </div>
        </Paper>
      </div>
    </>
  );
}

export function MandatoryDisclosure() {
  return (
    <>
      <div className="box text-justify">
        <Paper elevation={3} className="p-3" style={{ minHeight: '500px' }}>
          <h5 className="title-text my-2 mb-4 decorated">Downloads</h5>
          <div  data-aos="fade-up">
            <a href={Mandatory} target="_blank" className="pdf Brochure"><PictureAsPdfIcon /> Mandatory Disclosure</a>
          </div>
        </Paper>
      </div>
    </>
  )
}

export function  EOA_AICTE() {
  return (
    <>
      <div className="box text-justify">
        <Paper elevation={3} className="p-3" style={{ minHeight: '500px' }}>
          <h5 className="title-text my-2 mb-4 decorated">Downloads</h5>
          <div  data-aos="fade-up">
            <a href={EOAAICTE} target="_blank" className="pdf Brochure"><PictureAsPdfIcon /> EOA AICTE</a>
          </div>
        </Paper>
      </div>
    </>
  )
}
