import * as React from 'react';

import { Paper } from "@mui/material";
import Divider from '@mui/material/Divider';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import libraryPdf from '../../assets/pdf/Library/Library_Download.pdf';
import { Link } from "react-router-dom";
import TabComponent from '../../Components/ReusableComponent/TabComponent';
import { Images, ListOfDailyNewspaper, ListOfInterNationalJournals18_19, ListOfNationalJournals18_19, ListOfMagazines18_19 } from './LibraryAllData';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import ImageCarouselComponent from '../../Components/ReusableComponent/CarouselComponent';
import TableComponent from '../../Components/ReusableComponent/tableComponent';

export default function LibraryData() {
  const tabData = [
    { label: 'About MIBM Library', content: <AboutMIBMLibrary /> },
    { label: 'Library Rules', content: <LibraryRule /> },
    { label: 'Library Resources', content: <LibraryResources /> },
    { label: 'Associate Memberships', content: <AssociateMembership /> },
    { label: 'Library Committee', content: <Committee /> },
    { label: 'E-Database', content: <EDatabase /> },
    { label: 'Downloads', content: <Download /> },
  ];
  return (
    <>
      <Paper elevation={3} sx={{ minHeight: '350px' }} className='w-100  '>
        <TabComponent tabData={tabData} tabName={sessionStorage.getItem('menuName')} />
      </Paper>
    </>
  );
}
export function AboutMIBMLibrary() {
  return (
    <div className='Image-Carousel'>
      <div className='forMobileView' >
        <Paper elevation={3}>
          <ImageCarouselComponent images={Images} />
        </Paper>
      </div>
      <div className="my-4 P-tag" data-aos="fade-left">
        <p>PES Modern Institute of Business Management – Library was established in 2010 with the inception of the Institute. Library is situated on the Third floor of the Institute building and total area of the library is 1000 sq. meters. Including Reading Hall. MIBM Library divided In various section such as : Circulation Section,  Newspaper Section, Periodical section, Research Lab, Digital Library Section, Acquisition Section,  Stacking section, Spacious Reading Hall.</p>
        <p>The college library is well equipped with Text Books and reference Books, e-books. The library has a vast collection magazines, and A large number of National and International Print/Online Journals are subscribed covering all disciplines, which are available for reference. The internet facility and reading hall facility is available in library.</p>
        <p>The Strength of the library is its digital collection of six databases accessible from any networked computer within the campus which provides scholarly, company, Research papers, articles, Industrial information.</p>
      </div>
    </div>

  );
};

export function LibraryRule() {
  return (
    <>
      <>
        <h5 className="my-3 title-text decorated text-capitalize">Library Rules, Regulation and Information for students and members</h5>
        <ul className='list-group-numbered ul-tag' data-aos="fade-left">
          <li className="list-group-item">Every student must have his/her Identity Card/Library Card while entering in library.</li>
          <li className="list-group-item">Keep Silence in the reading hall.</li>
          <li className="list-group-item">Eatables are not allowed.</li>
          <li className="list-group-item">Take proper care of all library resources.</li>
          <li className="list-group-item">Write your name in the register the counter while entering in the library.</li>
          <li className="list-group-item">Kindly switch off/ Silence the mobile phone while you visit the library.</li>
          <li className="list-group-item">Silence is required in library and reading hall, loud conversation, standing in groups and discussions etc. not allowed.</li>
          <li className="list-group-item">Any personal belongings are not permitted into the library.</li>
          <li className="list-group-item">Eatables are not allowed inside the library & reading hall.</li>
          <li className="list-group-item">Use students’ computer for searching required book.</li>
          <li className="list-group-item">Students should return their book in given time period.</li>
          <li className="list-group-item">Students should preserve their library copy of deposit chalan for deposit refund.</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h5 className="my-3 title-text" >A- Membership Eligibility</h5>
        <ul className='list-group-numbered ul-tag' data-aos="fade-left">
          <li className="list-group-item">Individual Members: Faculty, Staff and Students of the Institute.</li>
          <li className="list-group-item">Outside Members: Alumni of Institute, Retired Faculty of Institute</li>
          <li className="list-group-item">Special Members : Visiting Faculty</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h5 className="my-3 title-text" >B- Membership Eligibility</h5>
        <ul className='list-group-numbered ul-tag' data-aos="fade-left">
          <li className="list-group-item">ID card must be presented on demand.</li>
          <li className="list-group-item">Icard is not transferable</li>
          <li className="list-group-item">If Icard lost, duplicate icard will be charges Rs.150/-</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h5 className="my-3 title-text" >C- Circulation of study Material</h5>
        <ul className='list-group-numbered ul-tag' data-aos="fade-left">
          <li className="list-group-item">All books must be returned on or before the due date.</li>
          <li className="list-group-item">Book will be renewed only if there is no demand from other members.</li>
          <li className="list-group-item">Book will issued for 10 days.</li>
          <li className="list-group-item">Books should be presented physically at the counter for renewal.</li>
          <li className="list-group-item">Late fee fine will be charged Rs.2/- per book per day.</li>
          <li className="list-group-item">Single copy of any publication is not issued on long term.</li>
          <li className="list-group-item">Members are requested to check the condition of the reading material before getting issued.</li>
          <li className="list-group-item">Members are requested to collect the receipts for the overdue charges paid by them.</li>
          <li className="list-group-item">Reference Books will not be issued only read in Library.</li>
          <li className="list-group-item">Kindly Check your emails when you issued books the system software given always reminder to you due date mails.</li>

        </ul>
        <Divider sx={{ border: 1 }} />

        <h5 className="my-3 title-text" >D- Loss of Books</h5>
        <ul className='list-group-numbered ul-tag' data-aos="fade-left">
          <li className="list-group-item">Loss of any book/ reading material borrowed must be reported immediately to the library counter.</li>
          <li className="list-group-item">All members must replace the book if it is lost the latest edition along with overdue charges, if any</li>
        </ul>
        <Divider sx={{ border: 1 }} />

        <h5 className="my-3 title-text" >E- Reservation / Renewal for Publication</h5>
        <ul className='list-group-numbered ul-tag' data-aos="fade-left">
          <li className="list-group-item">Only one reservation per member per book is allowed per day</li>
          <li className="list-group-item">Members can reserve the book through online reservation system. Intimation of reserved books is sent to the members through email. (librarymibm@gmail.com)</li>
          <li className="list-group-item">The reserved book must be collected within 7 days of the date of intimation, failing which the reservation is deemed to be cancelled and book may be issued to next member.</li>
          <li className="list-group-item">Reservation slip will be available at counter and slip can be submitted only during the working hours.</li>

        </ul>
        <Divider sx={{ border: 1 }} />

        <h5 className="my-3 title-text" >General Rules</h5>
        <ul className='list-group-numbered ul-tag' data-aos="fade-left">
          <li className="list-group-item">Readers are required to deposit their bags / belongings at the counter before entering the library.</li>
          <li className="list-group-item">Write your full name in the register at the counter while entering in the library</li>
          <li className="list-group-item">Take proper care of all library resources</li>
          <li className="list-group-item">Kindly switch off or silent the mobile phone while you visit the library</li>
          <li className="list-group-item">Any personal belongings are not permitted into the library</li>
          <li className="list-group-item">Eatables are not allowed inside the library & reading hall</li>
          <li className="list-group-item">Use student’s computer for searching required book</li>
          <li className="list-group-item">Students should return their book in given time period</li>
          <li className="list-group-item">Keep Silence</li>
        </ul>
      </>
    </>
  )
}


export function AssociateMembership() {
  const tableHeading = [
    'Sr No.', 'Delnet Database', 'No. of Records'
  ]
  const tableData = [
    {
      col1: 'Union CataLogue of Books – CCF',
      col2: '2,66,61,564'
    },
    {
      col1: 'Union List of Current Periodicals',
      col2: '37,847'
    },
    {
      col1: 'Union Catalogue of Periodicals',
      col2: '20,235'
    },
    {
      col1: 'Database of Periodical Articles',
      col2: '9,84,809'
    },
    {
      col1: 'CD-ROM Database',
      col2: '61,750'
    },
    {
      col1: 'Union List of Video Recordings',
      col2: '6,000'
    },
    {
      col1: 'Union List of Sound Recordings',
      col2: '1,025'
    },
    {
      col1: 'Database of Theses and Dissertations',
      col2: '1,30,753'
    },
    {
      col1: 'Database of E-books',
      col2: '1613'
    }
  ]
  return (
    <>
      {/* <h6 className="my-3 fw-bold fs-5 decorated title-text">Associate Memberships</h6> */}
      <div data-aos="fade-left">
        <h5 className="my-3 title-text" >1. British Council Library</h5>
        <p className="my-3 P-tag">We have institutional & Online membership of British Library. Membership type is Access 30 Items from Genral collection of Books/CDs/DVDs/Children`s Books/ Periodicals & e-resources under which we get 10 library cards. British Library provide information, reading and reference facilities to over 13,000 members. Collection has a total stock of over 40,000 books which covers a wide range of subjects including Management, Information Technology, Engineering, Science, Literature, English Language, Social Science and much more.</p>
        <p className="P-tag">British Council Online Membership Provide us 1,00,000 academic e-books + 14,000 e-journals+ digital video, English language Learning courses, children collection and more.</p>
        <p className='P-tag'>Reference Link-<Link to='https://library.britishcouncil.org.in/' target='_blank'> https://library.britishcouncil.org.in/</Link></p>
      </div>
      <Divider sx={{ border: 1 }} />
      <div data-aos="fade-left">
        <h5 className="my-3 title-text" >2. MCCIA – Maratha Chambers of Commerce Industries & Agriculture</h5>
        <p className="my-3 P-tag">MCCIA is one of the few Chambers of Commerce established before independence at a time when industrial activity was yet to take off. MCCIA has been playing a significant role in accelerating the industrial and economic development of Pune region for more than eight decades now. It is a Chamber of the Entrepreneurs and by the Entrepreneurs. It has been responsive to changing times, needs and requirements of the Entrepreneurs. It is one of the most active chambers of Commerce in India and has been instrumental in promoting number of institutions in Pune. MCCIA has continuously driven to make Pune a global business destination and has been a catalyst for economic development of the region. MCCIA embodies Pune’s entrepreneurial spirit. It is instrumental in giving strategic suggestions and advocating Industrial Policies.</p>
        <p className="P-tag">MCCIA membership is an investment for a brighter future which brings diverse growth opportunities to businesses of all sizes from all sectors. Chamber membership conveys credibility at domestic and international markets thus opening doors to greater opportunities.
        </p>
        <p className='P-tag'>Reference Link-<Link to='https://www.mcciapune.com/' target='_blank'> http://www.mcciapune.com/</Link></p>
      </div>
      <Divider sx={{ border: 1 }} />
      <div data-aos="fade-left">
        <h5 className="my-3 title-text" >3. DELNET – Developing Library Network</h5>
        <p className="my-3 P-tag">DELNET was started at the India International Centre Library in January 1988 and was registered as a society in 1992. It was initially supported by the National Information System for Science and Technology (NISSAT), Department of Scientific and Industrial Reseach, Government of India. It was subsequently supported by the National Informatics Centre, Department of Information Technology, Ministry of Communications and Information Technology, Government of India and the Ministry of Culture, Government of India. DELNET has been established with the prime objective of promoting resource sharing among the libraries through the development of a network of libraries. It aims to collect, store, and disseminate information besides offering computerized services to users, to coordinate efforts for suitable collection development and also to reduce unnecessary duplication wherever possible.</p>
        <p className="P-tag">DELNET has been actively engaged with the compilation of various Union Catalogues of the resources available in member-libraries.It has already created the Union Catalogue of Books,Union List of Current Periodicals, Union Catalogue of Periodicals,CD-ROM Database,Database of Indian Specialists, Database of Periodical Articles, Union List of Video Recordings, Urdu Manuscripts’ Database, Database of Theses and Dissertations, sample databases of language publications using GIST technology and several other databases. The data is being updated in these databases and is growing rapidly. All the DELNET databases have been resident on DELSIS, an in-house software developed on BASISPlus, an RDBMS, the product of Information Dimensions Inc. of USA which has been provided to DELNET courtesy National Informatics Centre, New Delhi.</p>
        <p className='P-tag'>
          DELNET provides an array of facilities. DELNET’S relentless efforts in resource sharing have proved extremely effective. It has contributed a lot towards the modernization of libraries in India.
        </p>
        {/* <Membership/> */}
        <div>
          <Paper elevation={1} className='mb-1'>
            <TableComponent tableHeading={tableHeading} tableData={tableData} />
          </Paper>
        </div>
        <p className='P-tag'>Reference Link-<a target='_blank'>http://164.100.247.17/index.html</a></p>
      </div>
      <Divider sx={{ border: 1 }} />
      <div data-aos="fade-left">
        <h5 className="my-3 title-text" >4. Jaykar Knowledge Resource Center, SPPU Pune-</h5>
        <p className="my-3 P-tag">Jayakar Knowledge Resource Centre (formerly Jayakar Library) is central library of Savitribai Phule Pune University. It was established in January 1950, and is named in honor of the first Vice-Chancellor of the university Dr. M. R. Jayakar.</p>
        <p className="P-tag">Salient Features of Jaykar Library</p>
        <ul className='ul-tag'>
          <li> Participates in E-ShodhSindhu program. Under this program access to more than 8000 journals are provided.</li>
          <li>It is a part of Universal Digital Library program since 2002.</li>
          <li>Implementated RFID technology in the library along with smart cards to faculty and students.</li>
          <li>Has internet center for students of the University.</li>
          <li>Holds manuscripts written in Sanskrit, Hindi and Marathi languages.</li>
          <li>Extends inter library loan system facility  to affiliated colleges and recognized institutions.</li>
          <li>Conducts orientation programmes for new students.</li>
          <li>Provides guidance in respect of technical and organizational matters to college libraries.</li>
        </ul>

        <p className='P-tag'>Reference Link-<Link to='http://lib.unipune.ac.in:8002/node/4' target='_blank'>http://lib.unipune.ac.in:8002/node/4</Link></p>
      </div>
      <Divider sx={{ border: 1 }} />
      <div data-aos="fade-left">
        <h5 className="my-3 title-text" >5. ASSOCIATION OF INDIAN MANAGEMENT SCHOOLS (AIMS)</h5>
        <p className="my-3 P-tag">The Association of Indian Management Schools (AIMS) is a networking body of B-Schools in India for professional development of management education and safeguarding the interests of B-Schools in the country. 30 years ago, in the context of the policy implications arising from the National Education Policy of 1986 (which advocated expansion of “Open University System” and recognized “rural university” model), a National Conference of Heads of Management Institutions was held at Bangalore from 28th to 30th April, 1988 where it was resolved that “a Network of Management Institutions offering Post-Graduate Courses be constituted”. Pursuant to this resolution, the Association of Indian Management Schools (AIMS), a non-profit-making society was registered on 27th August, 1988 under the Karnataka Societies Registration Act of 1960.</p>
        <p className="P-tag">As an independent networking body, AIMS can now boast of having many top class institutions like IIMs, ISB, ASCI, Xavier Institutions, Welingkar Institute of Management (WeSchool), NMIMS, ICFAI, MDI, S P Jain and many management departments of Universities as its members. It is the official representative of Indian Management Schools in India as well as in some important international forums. It is one of the largest networking bodies of B-Schools in the world.</p>
        <p className='P-tag'>Reference Link-<Link to='https://aims.org.in/' target='_blank'>https://www.aims.org.in</Link></p>
      </div>

    </>
  )
}



export function Download() {
  return (
    <>
      <h5 className="title-text my-2 mb-4 decorated" style={{ fontWeight: "bold" }}>Syllabus Structure</h5>
      <div className='pdf Brochure' data-aos="fade-up">
        <a href={libraryPdf} target='_blank'><PictureAsPdfIcon /> Download the Syllabus Structure</a>
      </div>
      <div>
        {/* <object
          // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
          data={libraryPdf}
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
        </object> */}
        {/* <p>Instead you can <Link to="https://mibspune.in/pdf/Mandatory%20Discloure%20MIBS%20NEW.pdf"> click here to download the PDF file.</Link></p> */}

      </div>
    </>
  )
}

// -----------------About MIBM library Photos---------------------------

export function LibraryResources() {
  //FIRST TABLE
  const tableHeading = [
  ]
  const tableData = [
    {
      col1: 'Total Number of Books  Volume',
      col2: '7050',
    },
    {
      col1: 'Total Number of Books Titles',
      col2: '2682',
    },
    {
      col1: 'E-Books',
      col2: '4094'
    },
    {
      col1: 'CD',
      col2: '282CD & 101 Titles'
    },
    {
      col1: 'Number of Journals Subscribed',
      col2: 'National – 23, International- 10 , Magazines – 11',
    },
    {
      col1: 'E-Journals',
      col2: '12,308 – 23'
    },
    {
      col1: 'Number of News Papers Subscribed',
      col2: '09 (English 05, Marathi 04)',
    },
    {
      col1: 'Dissertation',
      col2: '519'
    },
    {
      col1: 'Library E-Resources',
      col2: 'EBSCO , J-Gate , DELNET',
    },
  ]
  //FIRST TABLE

  return (
    <>
      <Paper elevation={1}>
        <TableComponent tableHeading={tableHeading} tableData={tableData} />
      </Paper>
      <Divider sx={{ border: 1, marginBottom: 3, marginTop: 4 }} />
      <h5 className="mb-4 title-text text-bold fs-5 decorated">List of National Journals</h5>
      <div>
        <h6 className="my-3 title-text  mx-0" >List of National Journals 2018-2019</h6>
        <Paper elevation={1}>
          <ListOfNationalJournals18_19 />
        </Paper>
      </div>
      <Divider sx={{ border: 1, marginBottom: 3, marginTop: 4 }} />
      <div>
        <h6 className="my-3 title-text mx-0" >List of International Journals 2018-2019</h6>
        <Paper elevation={1}>
          <ListOfInterNationalJournals18_19 />
        </Paper>
      </div>
      <Divider sx={{ border: 1, marginBottom: 3, marginTop: 4 }} />
      <div>
        <h6 className="my-3 title-text mx-0" >List of Magazines 2018-2019</h6>
        <Paper elevation={1}>
          <ListOfMagazines18_19 />
        </Paper>
      </div>
      <Divider sx={{ border: 1, marginBottom: 3, marginTop: 4 }} />
      <div>
        <h6 className="my-3 title-text mx-0" >List of Daily Newspapers</h6>
        <Paper elevation={1}>
          <ListOfDailyNewspaper />
        </Paper>

      </div>
    </>
  );
}


export function EDatabase() {
  const tableHeading = [ 
   
  ]
  const tableData = [
    {
      col1: 'Web OPAC (I.P Access Only)',
      col2: 'http://125.99.41.42/w27/'
    },
    {
    col1: 'Paridnya- The MIBM Research Journal (Online)' ,
    col2:<Link to='http://mibmparidnya.in/index.php/PARIDNYA' target="_blank">'http://mibmparidnya.in/'</Link>
    },
    {
      col1: 'EBSCO Database (Remote access available)',
      col2:<Link to='https://search.ebscohost.com/' target="_blank">'http://search.ebscohost.com/'</Link>
    },
    {
      col1:  'J-Gate (Remote access Available)',
      col2: <Link to='https://jgateplus.com/home/' target="_blank">'https://jgateplus.com/home/'</Link>
    },
    {
      col1:  'DELNET',
      col2:<a target="_blank">'http://164.100.247.17/index.html   (for Login I D & Password contact librarian/library)'</a>
    },
    {
      col1: 'British Online Library & DataBasebase- (e-books, e-journals,ProQuest, JSTOR)',
      col2:<Link to='https://library.britishcouncil.org.in/cgi-bin/koha/opac-user.pl' target="_blank">'https://library.britishcouncil.org.in/cgi-bin/koha/opac-user.pl   (for Login I D & Password contact librarian/library)'</Link>
    },
    {
      col1: 'NPTEL',
      col2:<Link to='https://onlinecourses.nptel.ac.in/' target="_blank">'https://onlinecourses.nptel.ac.in'</Link>
    },
    {
      col1: 'National Digital Library',
      col2:<Link to='https://ndl.iitkgp.ac.in/  ' target="_blank">'https://ndl.iitkgp.ac.in'</Link>
    },
    {
      col1:'SPPU',
      col2:<Link to='http://www.unipune.ac.in/' target="_blank">'www.unipune.ac.in'</Link>
    },
    {
      col1:'SPPU Question papers',
      col2:<Link to='http://exam.unipune.ac.in/Pages/PreviousQuestionPapers.html' target="_blank">'http://exam.unipune.ac.in/Pages/PreviousQuestionPapers.html'</Link>
    }
  ]
  return (
    <>
        <Paper elevation={1} className='mb-1'>
            <TableComponent tableHeading={tableHeading} tableData={tableData} />
          </Paper>
    </>
  );
}

export function Committee() {
  const tableHeading = [
    'Sr No.','Committee Member','Composition'
  ]
  const tableData = [
    {
      col1: 'Prof. Dr. Vijayalakshmi Srinivas, Director' ,
      col2:'Chairman'
    },
    {
      col1: 'Prof. Dr. Nivedita Ekbote, Coordinator' ,
      col2:'Member'
    },
    {
      col1: 'Prof. Dr. Shrawan Owhal, Asst. Professor',
      col2:'Member'
    },
    {
      col1: 'Prof. Rupali More, Asst.Professor',
      col2:'Member'
    },
    {
      col1: 'Bhagyashree Nair, Class Representative',
      col2:'Member'
    },
    {
      col1: 'Pushkar Runwal, Class Representative',
      col2:'Member'
    },
    {
      col1: 'Mr. Nilesh Bagave, Assistant Librarian',
      col2:'Member'
    },
    {
      col1: 'Mr. Sandip Walunj Assistant Librarian',
      col2:'Member'
    },
    {
      col1: 'Mr. Santosh Bagav Librarian',
      col2: 'Secretary'
    },
  ]
  return(
    <>
          <Paper elevation={1} className='mb-1'>
            <TableComponent tableHeading={tableHeading} tableData={tableData} />
          </Paper>
    </>
  )
}