
// ----------------------------------------Management Fest -------------------------------
import MF_13_img1 from '../assets/images/Gallery/ManagementFest/2013-2014/image1.jpg';
import MF_13_img2 from '../assets/images/Gallery/ManagementFest/2013-2014/image2.jpg';
import MF_13_img3 from '../assets/images/Gallery/ManagementFest/2013-2014/image3.jpg';
import MF_13_img4 from '../assets/images/Gallery/ManagementFest/2013-2014/image4.jpg';
import MF_13_img5 from '../assets/images/Gallery/ManagementFest/2013-2014/image5.jpg';
import MF_13_img6 from '../assets/images/Gallery/ManagementFest/2013-2014/image6.jpg';
import MF_13_img7 from '../assets/images/Gallery/ManagementFest/2013-2014/image7.jpg';
import MF_13_img8 from '../assets/images/Gallery/ManagementFest/2013-2014/image8.jpg';
import MF_13_img9 from '../assets/images/Gallery/ManagementFest/2013-2014/image9.jpg';

import MF_14_img1 from '../assets/images/Gallery/ManagementFest/2014-2015/image1.jpg';
import MF_14_img2 from '../assets/images/Gallery/ManagementFest/2014-2015/image2.jpg';
import MF_14_img3 from '../assets/images/Gallery/ManagementFest/2014-2015/image3.jpg';
import MF_14_img4 from '../assets/images/Gallery/ManagementFest/2014-2015/image4.jpg';
import MF_14_img5 from '../assets/images/Gallery/ManagementFest/2014-2015/image5.jpg';
import MF_14_img6 from '../assets/images/Gallery/ManagementFest/2014-2015/image6.jpg';

import MF_15_img1 from '../assets/images/Gallery/ManagementFest/2015-2016/image1.jpg';
import MF_15_img2 from '../assets/images/Gallery/ManagementFest/2015-2016/image2.jpg';
import MF_15_img3 from '../assets/images/Gallery/ManagementFest/2015-2016/image3.jpg';
import MF_15_img4 from '../assets/images/Gallery/ManagementFest/2015-2016/image4.jpg';
import MF_15_img5 from '../assets/images/Gallery/ManagementFest/2015-2016/image5.jpg';
import MF_15_img6 from '../assets/images/Gallery/ManagementFest/2015-2016/image6.jpg';
import MF_15_img7 from '../assets/images/Gallery/ManagementFest/2015-2016/image7.jpg';
import MF_15_img8 from '../assets/images/Gallery/ManagementFest/2015-2016/image8.jpg';
import MF_15_img9 from '../assets/images/Gallery/ManagementFest/2015-2016/image9.jpg';
import MF_15_img10 from '../assets/images/Gallery/ManagementFest/2015-2016/image10.jpg';
import MF_15_img11 from '../assets/images/Gallery/ManagementFest/2015-2016/image11.jpg';

import MF_16_img1 from '../assets/images/Gallery/ManagementFest/2016-2017/image1.jpg';
import MF_16_img2 from '../assets/images/Gallery/ManagementFest/2016-2017/image2.jpg';
import MF_16_img3 from '../assets/images/Gallery/ManagementFest/2016-2017/image3.jpg';
import MF_16_img4 from '../assets/images/Gallery/ManagementFest/2016-2017/image4.jpg';
import MF_16_img5 from '../assets/images/Gallery/ManagementFest/2016-2017/image5.jpg';
import MF_16_img6 from '../assets/images/Gallery/ManagementFest/2016-2017/image6.jpg';
import MF_16_img7 from '../assets/images/Gallery/ManagementFest/2016-2017/image7.jpg';
import MF_16_img8 from '../assets/images/Gallery/ManagementFest/2016-2017/image8.jpg';
import MF_16_img9 from '../assets/images/Gallery/ManagementFest/2016-2017/image9.jpg';
import MF_16_img10 from '../assets/images/Gallery/ManagementFest/2016-2017/image10.jpg';
import MF_16_img11 from '../assets/images/Gallery/ManagementFest/2016-2017/image11.jpg';
import MF_16_img12 from '../assets/images/Gallery/ManagementFest/2016-2017/image12.jpg';
import MF_16_img13 from '../assets/images/Gallery/ManagementFest/2016-2017/image13.jpg';

import MF_18_img1 from '../assets/images/Gallery/ManagementFest/2018-2019/image1.jpg';
import MF_18_img2 from '../assets/images/Gallery/ManagementFest/2018-2019/image2.jpg';
import MF_18_img3 from '../assets/images/Gallery/ManagementFest/2018-2019/image3.jpg';
import MF_18_img4 from '../assets/images/Gallery/ManagementFest/2018-2019/image4.jpg';
import MF_18_img5 from '../assets/images/Gallery/ManagementFest/2018-2019/image5.jpg';
import MF_18_img6 from '../assets/images/Gallery/ManagementFest/2018-2019/image6.jpg';
import MF_18_img7 from '../assets/images/Gallery/ManagementFest/2018-2019/image7.jpg';
import MF_18_img8 from '../assets/images/Gallery/ManagementFest/2018-2019/image8.jpg';
import MF_18_img9 from '../assets/images/Gallery/ManagementFest/2018-2019/image9.jpg';
import MF_18_img10 from '../assets/images/Gallery/ManagementFest/2018-2019/image10.jpg';
// ----------------------------------------Management Fest -------------------------------

// ------------------------------------Conference ---------------------------------
import C_13_img1 from '../assets/images/Gallery/Conference/2013-2014/image1.jpg';
import C_13_img2 from '../assets/images/Gallery/Conference/2013-2014/image2.jpg';
import C_13_img3 from '../assets/images/Gallery/Conference/2013-2014/image3.jpg';
import C_13_img4 from '../assets/images/Gallery/Conference/2013-2014/image4.jpg';
import C_13_img5 from '../assets/images/Gallery/Conference/2013-2014/image5.jpg';
import C_13_img6 from '../assets/images/Gallery/Conference/2013-2014/image6.jpg';
import C_13_img7 from '../assets/images/Gallery/Conference/2013-2014/image7.jpg';
import C_13_img8 from '../assets/images/Gallery/Conference/2013-2014/image8.jpg';

import C_14_img1 from '../assets/images/Gallery/Conference/2014-2015/image1.jpg';
import C_14_img2 from '../assets/images/Gallery/Conference/2014-2015/image2.jpg';
import C_14_img3 from '../assets/images/Gallery/Conference/2014-2015/image3.jpg';
import C_14_img4 from '../assets/images/Gallery/Conference/2014-2015/image4.jpg';
import C_14_img5 from '../assets/images/Gallery/Conference/2014-2015/image5.jpg';
import C_14_img6 from '../assets/images/Gallery/Conference/2014-2015/image6.jpg';

import C_15_img1 from '../assets/images/Gallery/Conference/2015-2016/image1.jpg';
import C_15_img2 from '../assets/images/Gallery/Conference/2015-2016/image2.jpg';
import C_15_img3 from '../assets/images/Gallery/Conference/2015-2016/image3.jpg';
import C_15_img4 from '../assets/images/Gallery/Conference/2015-2016/image4.jpg';
import C_15_img5 from '../assets/images/Gallery/Conference/2015-2016/image5.jpg';
import C_15_img6 from '../assets/images/Gallery/Conference/2015-2016/image6.jpg';
import C_15_img7 from '../assets/images/Gallery/Conference/2015-2016/image7.jpg';
import C_15_img8 from '../assets/images/Gallery/Conference/2015-2016/image8.jpg';
import C_15_img9 from '../assets/images/Gallery/Conference/2015-2016/image9.jpg';
import C_15_img10 from '../assets/images/Gallery/Conference/2015-2016/image10.jpg';

import C_16_img1 from '../assets/images/Gallery/Conference/2016-2017/image1.jpg';
import C_16_img2 from '../assets/images/Gallery/Conference/2016-2017/image2.jpg';
import C_16_img3 from '../assets/images/Gallery/Conference/2016-2017/image3.jpg';
import C_16_img4 from '../assets/images/Gallery/Conference/2016-2017/image4.jpg';
import C_16_img5 from '../assets/images/Gallery/Conference/2016-2017/image5.jpg';
import C_16_img6 from '../assets/images/Gallery/Conference/2016-2017/image6.jpg';
import C_16_img7 from '../assets/images/Gallery/Conference/2016-2017/image7.jpg';
import C_16_img8 from '../assets/images/Gallery/Conference/2016-2017/image8.jpg';
import C_16_img9 from '../assets/images/Gallery/Conference/2016-2017/image9.jpg';
import C_16_img10 from '../assets/images/Gallery/Conference/2016-2017/image10.jpg';
import C_16_img11 from '../assets/images/Gallery/Conference/2016-2017/image11.jpg';
import C_16_img12 from '../assets/images/Gallery/Conference/2016-2017/image12.jpg';
import C_16_img13 from '../assets/images/Gallery/Conference/2016-2017/image13.jpg';
import C_16_img14 from '../assets/images/Gallery/Conference/2016-2017/image14.jpg';
import C_16_img15 from '../assets/images/Gallery/Conference/2016-2017/image15.jpg';

import C_17_img1 from '../assets/images/Gallery/Conference/2017-2018/image1.jpg';
import C_17_img2 from '../assets/images/Gallery/Conference/2017-2018/image2.jpg';
import C_17_img3 from '../assets/images/Gallery/Conference/2017-2018/image3.jpg';
import C_17_img4 from '../assets/images/Gallery/Conference/2017-2018/image4.jpg';
import C_17_img5 from '../assets/images/Gallery/Conference/2017-2018/image5.jpg';
import C_17_img6 from '../assets/images/Gallery/Conference/2017-2018/image6.jpg';
import C_17_img7 from '../assets/images/Gallery/Conference/2017-2018/image7.jpg';
import C_17_img8 from '../assets/images/Gallery/Conference/2017-2018/image8.jpg';
import C_17_img9 from '../assets/images/Gallery/Conference/2017-2018/image9.jpg';
import C_17_img10 from '../assets/images/Gallery/Conference/2017-2018/image10.jpg';
import C_17_img11 from '../assets/images/Gallery/Conference/2017-2018/image11.jpg';
import C_17_img12 from '../assets/images/Gallery/Conference/2017-2018/image12.jpg';
import C_17_img13 from '../assets/images/Gallery/Conference/2017-2018/image13.jpg';
import C_17_img14 from '../assets/images/Gallery/Conference/2017-2018/image14.jpg';
import C_17_img15 from '../assets/images/Gallery/Conference/2017-2018/image15.jpg';
import C_17_img16 from '../assets/images/Gallery/Conference/2017-2018/image16.jpg';

import C_18_img1 from '../assets/images/Gallery/Conference/2018-2019/image1.jpg';
import C_18_img2 from '../assets/images/Gallery/Conference/2018-2019/image2.jpg';
import C_18_img3 from '../assets/images/Gallery/Conference/2018-2019/image3.jpg';
import C_18_img4 from '../assets/images/Gallery/Conference/2018-2019/image4.jpg';
import C_18_img5 from '../assets/images/Gallery/Conference/2018-2019/image5.jpg';
import C_18_img6 from '../assets/images/Gallery/Conference/2018-2019/image6.jpg';
import C_18_img7 from '../assets/images/Gallery/Conference/2018-2019/image7.jpg';
import C_18_img8 from '../assets/images/Gallery/Conference/2018-2019/image8.jpg';
import C_18_img9 from '../assets/images/Gallery/Conference/2018-2019/image9.jpg';
import C_18_img10 from '../assets/images/Gallery/Conference/2018-2019/image10.jpg';
import C_18_img11 from '../assets/images/Gallery/Conference/2018-2019/image11.jpg';
import C_18_img12 from '../assets/images/Gallery/Conference/2018-2019/image12.jpg';
import C_18_img13 from '../assets/images/Gallery/Conference/2018-2019/image13.jpg';
import C_18_img14 from '../assets/images/Gallery/Conference/2018-2019/image14.jpg';
// ------------------------------------Conference ---------------------------------
// -----------------------------------------------Cultural program -----------------------------
import cul_13_img1 from '../assets/images/Gallery/CulturalProgram/2013-2014/img1.jpg';
import cul_13_img2 from '../assets/images/Gallery/CulturalProgram/2013-2014/img2.jpg';
import cul_13_img3 from '../assets/images/Gallery/CulturalProgram/2013-2014/img3.jpg';
import cul_13_img4 from '../assets/images/Gallery/CulturalProgram/2013-2014/img4.jpg';
import cul_13_img5 from '../assets/images/Gallery/CulturalProgram/2013-2014/img5.jpg';
import cul_13_img6 from '../assets/images/Gallery/CulturalProgram/2013-2014/img6.jpg';
import cul_13_img7 from '../assets/images/Gallery/CulturalProgram/2013-2014/img7.jpg';
import cul_13_img8 from '../assets/images/Gallery/CulturalProgram/2013-2014/img8.jpg';
import cul_13_img9 from '../assets/images/Gallery/CulturalProgram/2013-2014/img9.jpg';
import cul_13_img10 from '../assets/images/Gallery/CulturalProgram/2013-2014/img10.jpg';
import cul_13_img11 from '../assets/images/Gallery/CulturalProgram/2013-2014/img11.jpg';

import cul_14_img1 from '../assets/images/Gallery/CulturalProgram/2014-2015/img1.jpg';
import cul_14_img2 from '../assets/images/Gallery/CulturalProgram/2014-2015/img2.jpg';
import cul_14_img3 from '../assets/images/Gallery/CulturalProgram/2014-2015/img3.jpg';
import cul_14_img4 from '../assets/images/Gallery/CulturalProgram/2014-2015/img4.jpg';
import cul_14_img5 from '../assets/images/Gallery/CulturalProgram/2014-2015/img5.jpg';
import cul_14_img6 from '../assets/images/Gallery/CulturalProgram/2014-2015/img6.jpg';
import cul_14_img7 from '../assets/images/Gallery/CulturalProgram/2014-2015/img7.jpg';
import cul_14_img8 from '../assets/images/Gallery/CulturalProgram/2014-2015/img8.jpg';
import cul_14_img9 from '../assets/images/Gallery/CulturalProgram/2014-2015/img9.jpg';
import cul_14_img10 from '../assets/images/Gallery/CulturalProgram/2014-2015/img10.jpg';
import cul_14_img11 from '../assets/images/Gallery/CulturalProgram/2014-2015/img11.jpg';
import cul_14_img12 from '../assets/images/Gallery/CulturalProgram/2014-2015/img12.jpg';
import cul_14_img13 from '../assets/images/Gallery/CulturalProgram/2014-2015/img13.jpg';
import cul_14_img14 from '../assets/images/Gallery/CulturalProgram/2014-2015/img14.jpg';
import cul_14_img15 from '../assets/images/Gallery/CulturalProgram/2014-2015/img15.jpg';

import cul_15_img1  from '../assets/images/Gallery/CulturalProgram/2015-2016/img1.jpg';
import cul_15_img2  from '../assets/images/Gallery/CulturalProgram/2015-2016/img2.jpg';
import cul_15_img3  from '../assets/images/Gallery/CulturalProgram/2015-2016/img3.jpg';
import cul_15_img4  from '../assets/images/Gallery/CulturalProgram/2015-2016/img4.jpg';
import cul_15_img5  from '../assets/images/Gallery/CulturalProgram/2015-2016/img5.jpg';
import cul_15_img6  from '../assets/images/Gallery/CulturalProgram/2015-2016/img6.jpg';
import cul_15_img7  from '../assets/images/Gallery/CulturalProgram/2015-2016/img7.jpg';
import cul_15_img8  from '../assets/images/Gallery/CulturalProgram/2015-2016/img8.jpg';
import cul_15_img9  from '../assets/images/Gallery/CulturalProgram/2015-2016/img9.jpg';
import cul_15_img10 from '../assets/images/Gallery/CulturalProgram/2015-2016/img10.jpg';
import cul_15_img11 from '../assets/images/Gallery/CulturalProgram/2015-2016/img11.jpg';
import cul_15_img12 from '../assets/images/Gallery/CulturalProgram/2015-2016/img12.jpg';
import cul_15_img13 from '../assets/images/Gallery/CulturalProgram/2015-2016/img13.jpg';
import cul_15_img14 from '../assets/images/Gallery/CulturalProgram/2015-2016/img14.jpg';
import cul_15_img15 from '../assets/images/Gallery/CulturalProgram/2015-2016/img15.jpg';

import cul_16_img1  from '../assets/images/Gallery/CulturalProgram/2016-2017/img1.jpg';
import cul_16_img2  from '../assets/images/Gallery/CulturalProgram/2016-2017/img2.jpg';
import cul_16_img3  from '../assets/images/Gallery/CulturalProgram/2016-2017/img3.jpg';
import cul_16_img4  from '../assets/images/Gallery/CulturalProgram/2016-2017/img4.jpg';
import cul_16_img5  from '../assets/images/Gallery/CulturalProgram/2016-2017/img5.jpg';
import cul_16_img6  from '../assets/images/Gallery/CulturalProgram/2016-2017/img6.jpg';
import cul_16_img7  from '../assets/images/Gallery/CulturalProgram/2016-2017/img7.jpg';
import cul_16_img8  from '../assets/images/Gallery/CulturalProgram/2016-2017/img8.jpg';
import cul_16_img9  from '../assets/images/Gallery/CulturalProgram/2016-2017/img9.jpg';
import cul_16_img10 from '../assets/images/Gallery/CulturalProgram/2016-2017/img10.jpg';
import cul_16_img11 from '../assets/images/Gallery/CulturalProgram/2016-2017/img11.jpg';
import cul_16_img12 from '../assets/images/Gallery/CulturalProgram/2016-2017/img12.jpg';
import cul_16_img13 from '../assets/images/Gallery/CulturalProgram/2016-2017/img13.jpg';
import cul_16_img14 from '../assets/images/Gallery/CulturalProgram/2016-2017/img14.jpg';
import cul_16_img15 from '../assets/images/Gallery/CulturalProgram/2016-2017/img15.jpg';
import cul_16_img16  from '../assets/images/Gallery/CulturalProgram/2016-2017/img16.jpg';
import cul_16_img17  from '../assets/images/Gallery/CulturalProgram/2016-2017/img17.jpg';
import cul_16_img18 from '../assets/images/Gallery/CulturalProgram/2016-2017/img18.jpg';
import cul_16_img19 from '../assets/images/Gallery/CulturalProgram/2016-2017/img19.jpg';
import cul_16_img20  from '../assets/images/Gallery/CulturalProgram/2016-2017/img20.jpg';
import cul_16_img21  from '../assets/images/Gallery/CulturalProgram/2016-2017/img21.jpg';
import cul_16_img22  from '../assets/images/Gallery/CulturalProgram/2016-2017/img22.jpg';
import cul_16_img23  from '../assets/images/Gallery/CulturalProgram/2016-2017/img23.jpg';
import cul_16_img24  from '../assets/images/Gallery/CulturalProgram/2016-2017/img24.jpg';
import cul_16_img25  from '../assets/images/Gallery/CulturalProgram/2016-2017/img25.jpg';
import cul_16_img26 from '../assets/images/Gallery/CulturalProgram/2016-2017/img26.jpg';
import cul_16_img27 from '../assets/images/Gallery/CulturalProgram/2016-2017/img27.jpg';
import cul_16_img28 from '../assets/images/Gallery/CulturalProgram/2016-2017/img28.jpg';
import cul_16_img29 from '../assets/images/Gallery/CulturalProgram/2016-2017/img29.jpg';
import cul_16_img30 from '../assets/images/Gallery/CulturalProgram/2016-2017/img30.jpg';
import cul_16_img31 from '../assets/images/Gallery/CulturalProgram/2016-2017/img31.jpg';
import cul_16_img32 from '../assets/images/Gallery/CulturalProgram/2016-2017/img32.jpg';
import cul_16_img33 from '../assets/images/Gallery/CulturalProgram/2016-2017/img33.jpg';
import cul_16_img34 from '../assets/images/Gallery/CulturalProgram/2016-2017/img34.jpg';

import cul_17_img1  from '../assets/images/Gallery/CulturalProgram/2017-2018/img1.jpg';
import cul_17_img2  from '../assets/images/Gallery/CulturalProgram/2017-2018/img2.jpg';
import cul_17_img3  from '../assets/images/Gallery/CulturalProgram/2017-2018/img3.jpg';
import cul_17_img4  from '../assets/images/Gallery/CulturalProgram/2017-2018/img4.jpg';
import cul_17_img5  from '../assets/images/Gallery/CulturalProgram/2017-2018/img5.jpg';
import cul_17_img6  from '../assets/images/Gallery/CulturalProgram/2017-2018/img6.png';
import cul_17_img7  from '../assets/images/Gallery/CulturalProgram/2017-2018/img7.jpg';
import cul_17_img8  from '../assets/images/Gallery/CulturalProgram/2017-2018/img8.jpg';
import cul_17_img9  from '../assets/images/Gallery/CulturalProgram/2017-2018/img9.jpg';
import cul_17_img10 from '../assets/images/Gallery/CulturalProgram/2017-2018/img10.jpg';


import cul_18_img1  from '../assets/images/Gallery/CulturalProgram/2018-2019/img1.jpg';
import cul_18_img2  from '../assets/images/Gallery/CulturalProgram/2018-2019/img2.jpg';
import cul_18_img3  from '../assets/images/Gallery/CulturalProgram/2018-2019/img3.jpg';
import cul_18_img4  from '../assets/images/Gallery/CulturalProgram/2018-2019/img4.jpg';
import cul_18_img5  from '../assets/images/Gallery/CulturalProgram/2018-2019/img5.jpg';
import cul_18_img6  from '../assets/images/Gallery/CulturalProgram/2018-2019/img6.jpg';
import cul_18_img7  from '../assets/images/Gallery/CulturalProgram/2018-2019/img7.jpg';
import cul_18_img8  from '../assets/images/Gallery/CulturalProgram/2018-2019/img8.jpg';
import cul_18_img9  from '../assets/images/Gallery/CulturalProgram/2018-2019/img9.jpg';
import cul_18_img10 from '../assets/images/Gallery/CulturalProgram/2018-2019/img10.jpg';

// -----------------------------------------------Cultural program -----------------------------

// --------------------------------------------industrial visit -----------------
import i_14_img1  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img1.jpg';
import i_14_img2  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img2.jpg';
import i_14_img3  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img3.jpg';
import i_14_img4  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img4.jpg';
import i_14_img5  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img5.jpg';
import i_14_img6  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img6.jpg';
import i_14_img7  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img7.jpg';
import i_14_img8  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img8.jpg';
import i_14_img9  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img9.jpg';
import i_14_img10 from '../assets/images/Gallery/IndustrialVisit/2014-2015/img10.jpg';
import i_14_img11  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img11.jpg';
import i_14_img12  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img12.jpg';
import i_14_img13  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img13.jpg';
import i_14_img14  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img14.jpg';
import i_14_img15  from '../assets/images/Gallery/IndustrialVisit/2014-2015/img15.jpg';

import i_15_img1  from '../assets/images/Gallery/IndustrialVisit/2015-2016/img1.jpg';
import i_15_img2  from '../assets/images/Gallery/IndustrialVisit/2015-2016/img2.jpg';
import i_15_img3  from '../assets/images/Gallery/IndustrialVisit/2015-2016/img3.jpg';
import i_15_img4  from '../assets/images/Gallery/IndustrialVisit/2015-2016/img4.jpg';

import i_16_img1  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img1.jpg';
import i_16_img2  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img2.jpg';
import i_16_img3  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img3.jpg';
import i_16_img4  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img4.jpg';
import i_16_img5  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img5.jpg';
import i_16_img6  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img6.jpg';
import i_16_img7  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img7.jpg';
import i_16_img8  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img8.jpg';
import i_16_img9  from '../assets/images/Gallery/IndustrialVisit/2016-2017/img9.jpg';
import i_16_img10 from '../assets/images/Gallery/IndustrialVisit/2016-2017/img10.jpg';
import i_16_img11 from '../assets/images/Gallery/IndustrialVisit/2016-2017/img11.jpg';
import i_16_img12 from '../assets/images/Gallery/IndustrialVisit/2016-2017/img12.jpg';
import i_16_img13 from '../assets/images/Gallery/IndustrialVisit/2016-2017/img13.jpg';


import i_17_img1  from '../assets/images/Gallery/IndustrialVisit/2017-2018/img1.jpg';
import i_17_img2  from '../assets/images/Gallery/IndustrialVisit/2017-2018/img2.jpg';
import i_17_img3  from '../assets/images/Gallery/IndustrialVisit/2017-2018/img3.jpg';

import i_18_img1  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img1.jpg';
import i_18_img2  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img2.jpg';
import i_18_img3  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img3.jpg';
import i_18_img4  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img4.jpg';
import i_18_img5  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img5.jpg';
import i_18_img6  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img6.jpg';
import i_18_img7  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img7.jpg';
import i_18_img8  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img8.jpg';
import i_18_img9  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img9.jpg';
import i_18_img10 from '../assets/images/Gallery/IndustrialVisit/2018-2019/img10.jpg';
import i_18_img11  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img11.jpg';
import i_18_img12  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img12.jpg';
import i_18_img13  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img13.jpg';
import i_18_img14  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img14.jpg';
import i_18_img15  from '../assets/images/Gallery/IndustrialVisit/2018-2019/img15.jpg';
// --------------------------------------------industrial visit ---------------------------

// -----------------------------Sports--------------------------------------
import sp_13_img1  from '../assets/images/Gallery/Sports/2013-2014/img1.jpg';
import sp_13_img2  from '../assets/images/Gallery/Sports/2013-2014/img2.jpg';
import sp_13_img3  from '../assets/images/Gallery/Sports/2013-2014/img3.jpg';
import sp_13_img4  from '../assets/images/Gallery/Sports/2013-2014/img4.jpg';
import sp_13_img5  from '../assets/images/Gallery/Sports/2013-2014/img5.jpg';
import sp_13_img6  from '../assets/images/Gallery/Sports/2013-2014/img6.jpg';
import sp_13_img7  from '../assets/images/Gallery/Sports/2013-2014/img7.jpg';
import sp_13_img8  from '../assets/images/Gallery/Sports/2013-2014/img8.jpg';
import sp_13_img9  from '../assets/images/Gallery/Sports/2013-2014/img9.jpg';
import sp_13_img10 from '../assets/images/Gallery/Sports/2013-2014/img10.jpg';
import sp_13_img11 from '../assets/images/Gallery/Sports/2013-2014/img11.jpg';
import sp_13_img12 from '../assets/images/Gallery/Sports/2013-2014/img12.jpg';
import sp_13_img13 from '../assets/images/Gallery/Sports/2013-2014/img13.jpg';
import sp_13_img14 from '../assets/images/Gallery/Sports/2013-2014/img14.jpg';
import sp_13_img15 from '../assets/images/Gallery/Sports/2013-2014/img15.jpg';
import sp_13_img16  from '../assets/images/Gallery/Sports/2013-2014/img16.jpg';
import sp_13_img17  from '../assets/images/Gallery/Sports/2013-2014/img17.jpg';
import sp_13_img18 from '../assets/images/Gallery/Sports/2013-2014/img18.jpg';


// -----------------------------Sports--------------------------------------
//---------------------------------Social Work ------------------------------

import sw_14_img1  from '../assets/images/Gallery/SocialWork/2014-2015/img1.jpg';
import sw_14_img2  from '../assets/images/Gallery/SocialWork/2014-2015/img2.png';
import sw_14_img3  from '../assets/images/Gallery/SocialWork/2014-2015/img3.jpg';
import sw_14_img4  from '../assets/images/Gallery/SocialWork/2014-2015/img4.jpg';
import sw_14_img5  from '../assets/images/Gallery/SocialWork/2014-2015/img5.jpg';
import sw_14_img6  from '../assets/images/Gallery/SocialWork/2014-2015/img6.jpg';
import sw_14_img7  from '../assets/images/Gallery/SocialWork/2014-2015/img7.jpg';
import sw_14_img8  from '../assets/images/Gallery/SocialWork/2014-2015/img8.jpg';

import sw_15_img1  from '../assets/images/Gallery/SocialWork/2015-2016/img1.jpg';
import sw_15_img2  from '../assets/images/Gallery/SocialWork/2015-2016/img2.jpg';
import sw_15_img3  from '../assets/images/Gallery/SocialWork/2015-2016/img3.jpg';
import sw_15_img4  from '../assets/images/Gallery/SocialWork/2015-2016/img4.jpg';
import sw_15_img5  from '../assets/images/Gallery/SocialWork/2015-2016/img5.jpg';
import sw_15_img6  from '../assets/images/Gallery/SocialWork/2015-2016/img6.jpg';
import sw_15_img7  from '../assets/images/Gallery/SocialWork/2015-2016/img7.jpg';
import sw_15_img8  from '../assets/images/Gallery/SocialWork/2015-2016/img8.jpg';
import sw_15_img9  from '../assets/images/Gallery/SocialWork/2015-2016/img9.jpg';

import sw_16_img1  from '../assets/images/Gallery/SocialWork/2016-2017/img1.jpg';
import sw_16_img2  from '../assets/images/Gallery/SocialWork/2016-2017/img2.jpg';
import sw_16_img3  from '../assets/images/Gallery/SocialWork/2016-2017/img3.jpg';
import sw_16_img4  from '../assets/images/Gallery/SocialWork/2016-2017/img4.jpg';
import sw_16_img5  from '../assets/images/Gallery/SocialWork/2016-2017/img5.jpg';

import sw_17_img1  from '../assets/images/Gallery/SocialWork/2015-2016/img1.jpg';
import sw_17_img2  from '../assets/images/Gallery/SocialWork/2015-2016/img2.jpg';
import sw_17_img3  from '../assets/images/Gallery/SocialWork/2015-2016/img3.jpg';
import sw_17_img4  from '../assets/images/Gallery/SocialWork/2015-2016/img4.jpg';
import sw_17_img5  from '../assets/images/Gallery/SocialWork/2015-2016/img5.jpg';
import sw_17_img6  from '../assets/images/Gallery/SocialWork/2015-2016/img6.jpg';
import sw_17_img7  from '../assets/images/Gallery/SocialWork/2015-2016/img7.jpg';
import sw_17_img8  from '../assets/images/Gallery/SocialWork/2015-2016/img8.jpg';
import sw_17_img9  from '../assets/images/Gallery/SocialWork/2015-2016/img9.jpg';

import sw_18_img1  from '../assets/images/Gallery/SocialWork/2018-2019/img1.jpg';
import sw_18_img2  from '../assets/images/Gallery/SocialWork/2018-2019/img2.jpg';
import sw_18_img3  from '../assets/images/Gallery/SocialWork/2018-2019/img3.jpg';
import sw_18_img4  from '../assets/images/Gallery/SocialWork/2018-2019/img4.jpg';
import sw_18_img5  from '../assets/images/Gallery/SocialWork/2018-2019/img5.jpg';
import sw_18_img6  from '../assets/images/Gallery/SocialWork/2018-2019/img6.jpg';
import sw_18_img7  from '../assets/images/Gallery/SocialWork/2018-2019/img7.jpg';
import sw_18_img8  from '../assets/images/Gallery/SocialWork/2018-2019/img8.jpg';
import sw_18_img9  from '../assets/images/Gallery/SocialWork/2018-2019/img9.jpg';
import sw_18_img10 from '../assets/images/Gallery/SocialWork/2018-2019/img10.jpg';
import sw_18_img11 from '../assets/images/Gallery/SocialWork/2018-2019/img11.jpg';
import sw_18_img12 from '../assets/images/Gallery/SocialWork/2018-2019/img12.jpg';
//---------------------------------Social Work -----------------------------------
//----------------------------------Training And Placement ------------------------

import f_img1  from '../assets/images/Gallery/TrainingPlacement/Certification_Finance/img1.jpg';
import f_img2  from '../assets/images/Gallery/TrainingPlacement/Certification_Finance/img2.jpg';
import f_img3  from '../assets/images/Gallery/TrainingPlacement/Certification_Finance/img3.jpg';
import f_img4  from '../assets/images/Gallery/TrainingPlacement/Certification_Finance/img4.jpg';
import f_img5  from '../assets/images/Gallery/TrainingPlacement/Certification_Finance/img5.jpg';
import f_img6  from '../assets/images/Gallery/TrainingPlacement/Certification_Finance/img6.jpg';

import m_img1  from '../assets/images/Gallery/TrainingPlacement/CertificationMarketing/img1.jpg';
import m_img2  from '../assets/images/Gallery/TrainingPlacement/CertificationMarketing/img2.jpg';
import m_img3  from '../assets/images/Gallery/TrainingPlacement/CertificationMarketing/img3.jpg';
import m_img4  from '../assets/images/Gallery/TrainingPlacement/CertificationMarketing/img4.jpg';
import m_img5  from '../assets/images/Gallery/TrainingPlacement/CertificationMarketing/img5.jpg';
import m_img6  from '../assets/images/Gallery/TrainingPlacement/CertificationMarketing/img6.jpg';
import m_img7  from '../assets/images/Gallery/TrainingPlacement/CertificationMarketing/img7.jpg';

import HR_img1  from '../assets/images/Gallery/TrainingPlacement/Certification_HumanResource(HR)/img1.jpg';
import HR_img2  from '../assets/images/Gallery/TrainingPlacement/Certification_HumanResource(HR)/img2.jpg';
import HR_img3  from '../assets/images/Gallery/TrainingPlacement/Certification_HumanResource(HR)/img3.jpg';
import HR_img4  from '../assets/images/Gallery/TrainingPlacement/Certification_HumanResource(HR)/img4.jpg';
import HR_img5  from '../assets/images/Gallery/TrainingPlacement/Certification_HumanResource(HR)/img5.jpg';

import ind_img1  from '../assets/images/Gallery/TrainingPlacement/Induction/img1.jpg';
import ind_img2  from '../assets/images/Gallery/TrainingPlacement/Induction/img2.jpg';
import ind_img3  from '../assets/images/Gallery/TrainingPlacement/Induction/img3.jpg';
import ind_img4  from '../assets/images/Gallery/TrainingPlacement/Induction/img4.jpg';
import ind_img5  from '../assets/images/Gallery/TrainingPlacement/Induction/img5.jpg';
import ind_img6  from '../assets/images/Gallery/TrainingPlacement/Induction/img6.jpg';
import ind_img7  from '../assets/images/Gallery/TrainingPlacement/Induction/img7.jpg';


//----------------------------------Training And Placement ------------------------



export const Images = {
    ManagementFest: {
        MF2013_14:[
            MF_13_img1, MF_13_img2, MF_13_img3, MF_13_img4, MF_13_img5, MF_13_img6, MF_13_img7, MF_13_img8, MF_13_img9, MF_14_img5
        ], 
        MF2014_15: [
            MF_14_img1, MF_14_img2, MF_14_img3, MF_14_img4, MF_14_img5, MF_14_img6,  
        ],
        MF2015_16:[
            MF_15_img1, MF_15_img2, MF_15_img3, MF_15_img4 ,MF_15_img5 ,MF_15_img6, MF_15_img7 ,MF_15_img8 , MF_15_img9 , MF_15_img10 , MF_15_img11 
        ],
        MF2016_17:[
            MF_16_img1 , MF_16_img2, MF_16_img3,MF_16_img4 , MF_16_img5 , MF_16_img6 , MF_16_img7 ,MF_16_img8 , MF_16_img9 , MF_16_img10 , MF_16_img11 , MF_16_img12 , MF_16_img13
        ],
        MF2018_19 :[
            MF_18_img1 , MF_18_img2 , MF_18_img3 , MF_18_img4 , MF_18_img5 , MF_18_img6 , MF_18_img7 ,MF_18_img8 , MF_18_img9 , MF_18_img10 
        ]
    }, 
    conference: {
        C_2013_14:[
            C_13_img1, C_13_img2, C_13_img3, C_13_img4, C_13_img5, C_13_img6, C_13_img7, C_13_img8
        ], 
        C_2014_15: [
            C_14_img1, C_14_img2, C_14_img3, C_14_img4, C_14_img5, C_14_img6,
        ],
        C_2015_16:[
            C_15_img1, C_15_img2, C_15_img3, C_15_img4 ,C_15_img5 ,C_15_img6, C_15_img7 ,C_15_img8,C_15_img9 , C_15_img10
        ],
        C_2016_17:[
            C_16_img1 , C_16_img2, C_16_img3,C_16_img4 , C_16_img5 , C_16_img6 , C_16_img7 ,C_16_img8 , C_16_img9 , C_16_img10 , C_16_img11 , C_16_img12 , C_16_img13 ,C_16_img14, C_16_img15 ,
        ],
        C_2017_18 :[
            C_17_img1 , C_17_img2, C_17_img3,C_17_img4 , C_17_img5 , C_17_img6 , C_17_img7 ,C_17_img8 , C_17_img9 , C_17_img10 , C_17_img11 , C_17_img12 , C_17_img13 ,C_17_img14, C_17_img15 , C_17_img16
        ],
        C_2018_19 :[
            C_18_img1 , C_18_img2 , C_18_img3 , C_18_img4 , C_18_img5 , C_18_img6 , C_18_img7 ,C_18_img8 , C_18_img9 , C_18_img10 , C_18_img11 , C_18_img12 , C_18_img13 , C_18_img14
        ]
    },
    cultural: {
        cul_2013_14:[
            cul_13_img1, cul_13_img2, cul_13_img3, cul_13_img4, cul_13_img5, cul_13_img6, cul_13_img7, cul_13_img8, cul_13_img9, cul_13_img10 ,cul_13_img11,
        ], 
        cul_2014_15: [
            cul_14_img1, cul_14_img2, cul_14_img3, cul_14_img4, cul_14_img5, cul_14_img6, cul_14_img7,cul_14_img8 , cul_14_img9 , cul_14_img10 , cul_14_img11 , cul_14_img12 ,cul_14_img13 , cul_14_img14 , cul_14_img15 ,
        ],
        cul_2015_16:[
            cul_15_img1, cul_15_img2, cul_15_img3, cul_15_img4 ,cul_15_img5 ,cul_15_img6, cul_15_img7 ,cul_15_img8 ,cul_15_img9 ,cul_15_img10 ,cul_15_img11 ,cul_15_img12 ,cul_15_img13 ,cul_15_img14 ,cul_15_img15 
        ],
        cul_2016_17:[
            cul_16_img1 , cul_16_img2, cul_16_img3,cul_16_img4 , cul_16_img5 , cul_16_img6 , cul_16_img7 ,cul_16_img8 , cul_16_img9 , cul_16_img10 , cul_16_img11 , cul_16_img12 , cul_16_img13 , cul_16_img14 , cul_16_img15 ,  cul_16_img16 , cul_16_img17 , cul_16_img18 ,  cul_16_img19 , cul_16_img20 , cul_16_img21 ,  cul_16_img22, cul_16_img23 , cul_16_img24 ,  cul_16_img25, cul_16_img26 , cul_16_img27 ,  cul_16_img28, cul_16_img29 , cul_16_img30 ,  cul_16_img31, cul_16_img32 , cul_16_img33 ,  cul_16_img34
        ],
        cul_2017_18 :[
            cul_17_img1 , cul_17_img2 , cul_17_img3 , cul_17_img4 , cul_17_img5 , cul_17_img6 , cul_17_img7 ,cul_17_img8 , cul_17_img9 , cul_17_img10 
        ],
        cul_2018_19 :[
            cul_18_img1 , cul_18_img2 , cul_18_img3 , cul_18_img4 , cul_18_img5 , cul_18_img6 , cul_18_img7 ,cul_18_img8 , cul_18_img9 , cul_18_img10 , 
        ]
    },
    industrial: {
       i_2014_15: [
           i_14_img1,i_14_img2,i_14_img3,i_14_img4,i_14_img5,i_14_img6,i_14_img7,i_14_img8 ,i_14_img9 ,i_14_img10 ,i_14_img11 ,i_14_img12 ,i_14_img13 ,i_14_img14 ,i_14_img15 ,
        ],
       i_2015_16:[
           i_15_img1,i_15_img2,i_15_img3,i_15_img4 
        ],
       i_2016_17:[
           i_16_img1 ,i_16_img2,i_16_img3,i_16_img4  ,i_16_img5 ,i_16_img6 ,i_16_img7 ,i_16_img8 ,i_16_img9 ,i_16_img10 ,i_16_img11 ,i_16_img12 ,i_16_img13
        ],
       i_2017_18 :[
           i_17_img1 ,i_17_img2 ,i_17_img3 
        ],
       i_2018_19 :[
           i_18_img1 ,i_18_img2 ,i_18_img3 ,i_18_img4 ,i_18_img5 ,i_18_img6 ,i_18_img7 ,i_18_img8  ,i_18_img9 ,i_18_img10 , i_18_img11 , i_18_img12 , i_18_img13 , i_18_img14 , i_18_img15
        ]
    },
    sports: {
        sp_2013_14 :[
            sp_13_img1 ,sp_13_img2 ,sp_13_img3 ,sp_13_img4 ,sp_13_img5 ,sp_13_img6 ,sp_13_img7 ,sp_13_img8  ,sp_13_img9 ,sp_13_img10 , sp_13_img11 , sp_13_img12 , sp_13_img13 , sp_13_img14 , sp_13_img15 ,sp_13_img16 , sp_13_img17 , sp_13_img18
         ]
     },
     social: {
        sw_2014_15: [
            sw_14_img1, sw_14_img2, sw_14_img3, sw_14_img4, sw_14_img5, sw_14_img6,sw_14_img7, sw_14_img8 ,
        ],
        sw_2015_16:[
            sw_15_img1, sw_15_img2, sw_15_img3, sw_15_img4 ,sw_15_img5 ,sw_15_img6, sw_15_img7 ,sw_15_img8,sw_15_img9 ,
        ],
        sw_2016_17:[
            sw_16_img1 , sw_16_img2, sw_16_img3,sw_16_img4 , sw_16_img5 ,
        ],
        sw_2017_18 :[
            sw_17_img1 , sw_17_img2, sw_17_img3,sw_17_img4 , sw_17_img5 , sw_17_img6 , sw_17_img7 ,sw_17_img8 , sw_17_img9 , 
        ],
        sw_2018_19 :[
            sw_18_img1 , sw_18_img2 , sw_18_img3 , sw_18_img4 , sw_18_img5 , sw_18_img6 , sw_18_img7 ,sw_18_img8 , sw_18_img9 , sw_18_img10 , sw_18_img11 , sw_18_img12 , 
        ]
    },
    trainingPlacement: {
        finance: [
            f_img1, f_img2, f_img3, f_img4, f_img5, f_img6,
        ],
        marketing:[
            m_img1, m_img2, m_img3, m_img4 ,m_img5 ,m_img6, m_img7
        ],
        HR:[
            HR_img1 , HR_img2, HR_img3,HR_img4 , HR_img5 ,
        ],
        induction :[
            ind_img1 , ind_img2, ind_img3,ind_img4 , ind_img5 , ind_img6 , ind_img7
        ],
    },
}

