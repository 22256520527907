import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { SocialIconSet } from './SvgIcons';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import aicte from "../assets/logo/aicte.png";
import mudra from "../assets/logo/mudra.png";
import puneU from "../assets/logo/Pune-University.png";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Footer() {
  return (
    <>
      {/* Footer */}


      <footer className="default-padding pb-5 pt-5 footDetail"style={{ color: 'white' }}>
        <Grid container spacing={2}>
          <Grid className='forMobileView box' xs={12} md={4} data-aos="fade-right">
            {/* ABOUT US */}
            <div className="footerDiv mb-3">
              <h3 className='mb-3 mx-5 FtUnderline'>ABOUT US</h3>

              {/* Logo Images */}
              <div className="d-flex justify-content-center mt-3 mb-3">
                <img
                  src={aicte}
                  alt="logo"
                  height={90}
                  style={{ margin: '10px', border: '2px solid gray', backgroundColor: 'white', borderRadius: '50%', padding: '5px' }}
                />
                <img
                  src={puneU}
                  alt="logo"
                  height={90}
                  style={{ margin: '10px', border: '2px solid gray', backgroundColor: 'white', borderRadius: '50%', padding: '5px' }}
                />
                <img
                  src={mudra}
                  alt="logo"
                  height={90}
                  style={{ margin: '10px', border: '2px solid gray', backgroundColor: 'white', borderRadius: '50%', padding: '5px' }}
                />
              </div>
             <div className='forMobileViewMX'>
              <SocialIconSet />
             </div>
            </div>

          </Grid>

          <Grid className='forMobileView justifyUsefulLink box' xs={12} md={2} data-aos="fade-up">
            {/* USEFUL LINKS */}
            <div>
              <h3 className='mb-3 FtUnderline'>USEFUL LINKS</h3>
              <ul className="ps-0">
                <li>
                  <Link to="/aboutUs" style={{ color: 'white' }}>
                   <KeyboardDoubleArrowRightIcon /> About Us
                  </Link>
                </li>
                <li>
                  <Link to="/placement/SummerInternshipProgram" style={{ color: 'white' }}>
                  <KeyboardDoubleArrowRightIcon /> Programmes
                  </Link>
                </li>
                <li>
                  <Link to="/placement/TrainingPlacementCell" style={{ color: 'white' }}>
                  <KeyboardDoubleArrowRightIcon /> Placements
                  </Link>
                </li>
                <li>
                  <Link to="/research/Center" style={{ color: 'white' }}>
                  <KeyboardDoubleArrowRightIcon /> Research
                  </Link>
                </li>
                <li>
                  <Link to="/students/Gallery" style={{ color: 'white' }}>
                  <KeyboardDoubleArrowRightIcon /> Gallery
                  </Link>
                </li>
                <li>
                  <Link to="/Contact" style={{ color: 'white' }}>
                  <KeyboardDoubleArrowRightIcon /> Contact
                  </Link>
                </li>
                
              </ul>
            </div>

          </Grid>
          <Grid  xs={12} md={2} className='justifyOpeningHour box' data-aos="fade-up">
            {/* OPENING HOURS */}
            <div className='mx-0'>
              <h3 className='mb-3 FtUnderline'>OPENING HOURS</h3>
              <p>Monday - Saturday</p>
              <p><QueryBuilderIcon className='mb-1'/> 09:00 AM to 05:30 PM</p>
            </div>
          </Grid>
          <Grid className='forMobileView justifyContact mx-5 box' xs={12} md={3} data-aos="fade-left">
            {/* CONTACT INFO */}
            <>
            <h3 className='mb-3 FtUnderline'>CONTACT HERE</h3>
              <Grid container spacing={3}>
                <Grid xs={1}>
                 <LocationOnOutlinedIcon style={{ color: '#A70606' }} />
                </Grid>
                <Grid xs={11}>
                Address: 1186, Shivaji Nagar, Modern College Road, Pune 411005.
                </Grid>
                <Grid xs={1}>
                <EmailOutlinedIcon style={{ color: '#A70606' }} />
                </Grid>
                <Grid xs={11}>
                Email: os.mibm@gmail.com, mibmpune05@gmail.com
                </Grid>
                <Grid xs={1}>
                <CallOutlinedIcon style={{ color: '#A70606' }} />
                </Grid>
                <Grid xs={11}>
                Phone: +91 8237084546 / 8237084547
                </Grid>
              </Grid>
            </>
          </Grid>
          <Grid  xs={12} md={12} data-aos="fade-down">
            {/* OPENING HOURS */}
           {/* COPYRIGHT */}
          <div className="text-center bt-footer mt-5 mb-0">
            <p className="m-1">
              Copyright © 2022. All Rights Reserved by P.E.S.Modern Institute of Business Management.
            </p>
            <p className="m-1">
              Designed &amp; Developed by <Link target='_blank' to="https://www.akronsystems.com" style={{ color: 'white' }}><u>Akron Systems</u></Link>
            </p>
          </div>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}


