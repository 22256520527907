import * as React from 'react';
import { Paper } from "@mui/material";
import './CoursesData.css';
import TabComponent from '../Components/ReusableComponent/TabComponent';
import courses from '../assets/images/HomeDesk/CoursesOffered.png'

export default function CourseData() {
  const tabData = [
    { label: 'MBA', content: <MBA /> },
    { label: 'PHD', content: <PHD /> },
  ];

  return (

    <>
      <div className="box">
        <Paper elevation={2}>
          <div className="text-center py-4"  data-aos="fade-left">
            <img src={courses} height={100}/>
         
          </div>

          <TabComponent tabData={tabData} tabName={sessionStorage.getItem('menuName')} />
        </Paper>
      </div>

    </>
  );
}


export function MBA() {
  return (
    <>
      <div className="m-0">
        <div className="text-center">
          <div>
            <h5 className="text-bold my-2 mb-5" >MAJOR / MINOR (MBA)</h5>

            <div className="d-flex justify-content-center flex-wrap align-items-center gap-5 mb-4" data-aos="fade-left">


              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img1"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Business Analytics</p>
              </div>


              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img4"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Marketing Management</p>
              </div>

              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img5"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Financial Management</p>
              </div>
              <div className='box'>
                <div className="item item2 mt-5 mx-auto">
                  <a href="#">
                    <div className="img img2"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Operations Supply Chain Management (OSCM)</p>
              </div>
              <div className='box'>
                <div className="item item2 mt-5 mx-auto">
                  <a href="#">
                    <div className="img img3"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Human Resources Management</p>
              </div>
            </div>
            <h5 className="text-bold pt-5 mb-4 mt-5 ">ONLY as MINOR Specializations (MBA)</h5>
            <div className="d-flex justify-content-center flex-wrap align-items-center gap-4 my-5" data-aos="fade-left">
              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img6"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Tourism & Hospitality Management (THM)</p>
              </div>

              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img7"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Rural & Agribusiness Management (RABM)</p>
              </div>

              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img8"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">International Business Management (IB)</p>
              </div>
              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img9"></div>
                    <p className="title">MBA</p>
                  </a>
                </div>
                <p className="description">Pharma & Healthcare Management (PHM)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export function PHD() {
  return (
    <>
      <div className="m-0">
        <div className="text-center">
          <div>
            <h5 className="text-bold  mb-5">PHD</h5>
            <div className="d-flex justify-content-center flex-wrap align-items-center gap-4" data-aos="fade-left">
              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img10"></div>
                    <p className="title">PHD</p>
                  </a>
                </div>
                <p className="description">Financial Phd</p>
              </div>
              <div className='box'>
                <div className="item item2 mx-auto">
                  <a href="#">
                    <div className="img img11"></div>
                    <p className="title">PHD</p>
                  </a>
                </div>
                <p className="description">Organizational Phd</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}