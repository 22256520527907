import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

function GalleryComponent ({images}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
	// Gallery
			<div className="d-flex justify-content-center flex-wrap">
				{images.map((image, index) => (
                    <img className="galleryImgBorder"
						src={image}
						onClick={() => openImageViewer(index)}
						width="250"	
						height={200}
					    key={index}
						style={{ margin: "8px"}}
						alt={`Image ${index}`} 
					/>	
				))}

				{isViewerOpen && (
					<ImageViewer
						src={images}
						currentIndex={currentImage}
						onClose={closeImageViewer}
						disableScroll={true}
						backgroundStyle={{
						backgroundColor: "rgba(0,0,0,0.9)"
					}}
						closeOnClickOutside={true}
					/>
				)}
			</div>
  );
}

export default GalleryComponent;