import React from "react";
import Slider from "react-slick";
import testimonial from '../../assets/images/HomeDesk/testimonial.png'

export default function TestimonialSlick() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    return (



        <div data-aos="fade-up">
            <div className="container text-center" style={{ position: 'relative' }}>
              <img src={testimonial} height={100}/>
                <div className="MarginTopBottom" data-aos="zoom-in">
                    {/* <CourseSlick /> */}
                    <div className="testmonialBg">
                        <Slider {...settings}>
                            <div >
                                <div className="w-100 p-5 mx-3 bg-light text-center ">
                                    <q>
                                        I can proudly say that MIBM has put me a step ahead from other MBA graduates like me. I have been placed by MIBM campus to&nbsp;renowned Brand <strong>DSK Benelli,India(Italian Bikes)</strong>&nbsp; as a Retail Sales Champ. I have been awarded as “Most Consistent Student” for which I’m very much thank full to the Institute. &nbsp;MIBM has created life long memories for me.
                                    </q>
                                    <h4 className="mt-5">Gaurav Chandel</h4>
                                    <h6>MBA Marketing, Batch 2014-16</h6>
                                </div>
                            </div>

                            <div>
                                <div className="w-100 p-5 mx-3 bg-light text-center">
                                    <q>
                                        I am grateful for entire team of MIBM for giving me various opportunities to develop in not only academics but also extracurricular activities like annual gathering and management fest. MIBM has very dedicated staff starting from the grass root level to the highest teaching faculty and they have left no stone unturned in their way to build up in us a sense of professionalism, not just in our carriers but in every walk of life. I feel myself very privileged to be the part of this Institute and will strive to cherish its motto throughout my life.
                                    </q>
                                    <h4 className="mt-5">Shailesh Patil</h4>
                                    <h6>MBA Marketing,  Batch 2014-16</h6>
                                </div>
                            </div>

                            <div>
                                <div className="w-100 p-5 mx-3 bg-light text-center">
                                    <q>
                                        I am unable to express my feelings in words when I got my first job through MIBM’s Placement Cell. I have been placed at <strong>Proficient Partners Pvt. Ltd.</strong>&nbsp; It was great journey of two years at MIBM which helped me to learn team building, decision making in different situations, improved my confidence level. Annual Cultural Fest “Darpan” is most memorable event for me as both the years I got prize. My Best Wishes to MIBM !!! Proud to be an alumni of this institute
                                    </q>
                                    <h4 className="mt-5">Anand Gangawane</h4>
                                    <h6>MBA Finance, Batch 2016-18</h6>
                                </div>
                            </div>
                            <div>
                                <div className="w-100 p-5 mx-3 bg-light text-center">
                                    <q>
                                        I took admission at MIBM with the objective of getting Big thanks to placement cell, all my dear teachers and  friends!!!
                                    </q>
                                    <h4 className="mt-5">Gaurav Joshi</h4>
                                    <h6>MBA Supply Chain Mgmt , Batch 2016-18</h6>
                                </div>
                            </div>
                            <div>
                                <div className="w-100 p-5 mx-3 bg-light text-center">
                                    <q>
                                        MIBM nurtured me a lot during two years at campus. I was being awarded as Ace of MIBM. In Avishkar, research competition organized by Government of Maharashtra, I went up till final round where only top 8 projects across all Universities in Maharashtra were selected. Faculties helped me a lot for conducting hypothesis, collection of data &amp; its research presentation. MIBM is motivational, supportive &amp; always  there for the students. Overall it was a great journey with MIBM  &amp; I’m proud to be an MIBM alumni!
                                    </q>
                                    <h4 className="mt-5">Ameya Joshi</h4>
                                    <h6>MBA Marketing, Batch 2015-17</h6>
                                </div>
                            </div><div>
                                <div className="w-100 p-5 mx-3 bg-light text-center">
                                    <q>
                                        Faculty in MIBM during these two years inspired us a lot to learn and implement the learning in practical life. Conferences and seminars on various relevant issues gave us the exposure to think out of the textbooks and interact with  industry insiders. Employability Enhancement Pragram provided an edge and equipped us with skills sought by industry.&nbsp; Case  studies should also be considered as one of the employability enhancement tools.
                                    </q>
                                    <h4 className="mt-5">Gaurav Joshi</h4>
                                    <h6>MBA Supply Chain Mgmt , Batch 2016-18</h6>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}