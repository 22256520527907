import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Breadcrumb from "../Components/Breadcrumb";
import mba_fees from "../assets/pdf/FeeDetails/MBA_FEES.pdf";
import TabComponent from "../Components/ReusableComponent/TabComponent";
import UnderMaintanence from "./UnderMaintanence";

const sidemenus = [
  {
    title: "Eligibility Criteria",
    url: "/admission/Eligibility",
  },
  {
    title: "Admission Procedure",
    url: "/admission/AdmissionProcedure",
  },

  {
    title: "Rules and Regulation",
    url: "/admission/RulesAndRegulation",
  },

  {
    title: "Documents Required",
    url: "/admission/DocumentsRequired",
  },
  {
    title: "Fee Structure",
    url: "/admission/FeeStructure",
  },
];

export default function Admission(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    // window.scrollTo(0, 0);
    window.scrollTo(0, 420);
  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {" "}
                    {menu.title}{" "}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// sub-pages below of about us tab

export function Eligibility() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">Eligibility</h5>
        <Paper elevation={3} className="p-3 text-justify">
          <div data-aos="fade-left">
            <p className="text-bold P-tag">
              As per the norms of Directorate of Technical Education (DTE),
              Maharashtra State, to secure admission to first year of two-year
              full time MBA course, the candidate should fulfill the following
              eligibility criteria.
            </p>
            <ul className="ul-tag">
              <li>The Candidate should be an Indian National</li>
              <li>
                Passed minimum three year duration Bachelor’s Degree awarded by
                any of the Universities recognized by University Grants
                Commission or Association of Indian Universities in any
                discipline with at least 50% marks in aggregate or equivalent (
                at least 45% in case of candidates of backward class categories,
                Economically weaker section and Persons With Disability
                Candidates belonging to Maharashtra State only) or its
                equivalent;{" "}
              </li>
              <li>
                Obtained non zero score in CET conducted by the Competent
                Authority (MAH-MBA/MMS-CET 2024), Indian Institute of Management
                (CAT) or Common Management Aptitude Test Conducted by National
                Testing Agency (CMAT).
              </li>
            </ul>
          </div>
        </Paper>
      </div>
    </>
  );
}
export function Procedure() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decoratedd">
          Admission to the MBA programme is done through a Centralized Admission
          Process (CAP) conducted by the Directorate of Technical Education
          (DTE)
        </h5>
        <Paper elevation={2} className="p-3 text-justify">
          <h5 className="mb-4 text-bold fs-6">
            Steps for Admission Process at a Glance
          </h5>

          <div className="P-tag" data-aos="fade-left">
            <div>
              <p className="text-bold">Step 1 : Entrance Exam</p>
              <p>
                {" "}
                Apply online for MAH-MBA/MMS-CET CAT/CMAT Entrance Exam. The
                Date, Time, Pattern, Marking Scheme, Frequency, etc of every
                entrance exam is different and the information of the same is
                available on website of respective authority.
              </p>
            </div>

            <div>
              <p className="text-bold">
                {" "}
                Step 2 : Report at Facilitation Centre-FC
              </p>
              <p>
                {" "}
                i. Candidates have to report at Facilitation Centre (FC) for
                Document verification. List of Facilitation Centers published on
                the State CET Cell, Maharashtra State website.
              </p>
              <p>
                ii. The Candidates will also be able to fill in Online
                Application Form and upload the scanned copies of the required
                documents through any computer connected to internet from home
                or any other place
              </p>
              <p>
                iii. Candidates are required to fill in all the details as per
                the instructions and Upload scanned images of valid necessary
                original documents as per the requirement of the admission while
                filling online application form.
              </p>
              <p>
                {" "}
                iv. After filling the information and submission of Application
                Form, the Candidates shall verify the data filled and correct,
                take printout of the form and sign it.
              </p>
            </div>

            <div>
              <p className="text-bold">
                {" "}
                Step 3 : Display or Publishing of Provisional merit list,
                Submission of grievances, if any, through the method prescribed
                by the Competent Authority for this purpose and Display or
                Publishing of Final Merit Lists.
              </p>
            </div>

            <div>
              <p className="text-bold">Step 4 : Filling of Option Form</p>
              <p>
                Filling up and Confirmation of Online Option Form having
                preferences of Courses and Institutions prior to respective CAP
                Rounds.
              </p>
              <p> Submit your preference along with choice code.</p>
              <p> DTE Institute Code: MB6631</p>
              <p>1st Shift Choice Code: 663110110</p>
              <p>
                {" "}
                CET Cell, Maharashtra State will allot respective colleges as
                per students score.
              </p>
            </div>
            <div>
              <p className="text-bold"> Step 5 : Report to Scrutiny Centre.</p>
              <p>
                i. Display of Provisional Allotment of respective CAP Round
                indicating allotted institute and Course
              </p>
              <p>
                ii. Reporting and accepting the offered seat at Admission
                Reporting Centre by the Candidate as per the schedule declared
                by the Competent Authority through the method prescribed by the
                Competent Authority for the purpose of accepting the offered
                seat and rectifying the errors as per the clause
              </p>
            </div>
            <div>
              <p className="text-bold">
                {" "}
                Step 6 : Reporting to Allotted College
              </p>
              <p>
                Only after reporting to Scrutiny Centre, the candidate should
                report to the allotted institute for seeking admission on the
                allotted seat as per final allotment in CAP rounds.
              </p>
              <Divider sx={{ border: 1, marginBottom: 1 }} />
              <div>
                <p className="text-bold">
                  Documents required to be uploaded along with “Application Form
                  for Centralized Admission Process
                </p>
                <p>1. SSC (Std.X) mark sheet.</p>
                <p>2. HSC/Diploma mark sheet</p>
                <p>3. Graduation/Qualifying Examination mark sheet.</p>
                <p>4. CET mark sheet/ Score Card.</p>
                <p>5. School Leaving Certificate</p>
                <p>
                  6. Certificate of the Indian Nationality/ Domicile of the
                  candidate
                </p>
                <p>
                  7. Birth/Leaving certificate of candidate Indicating place of
                  Birth in the State of Maharashtra.
                </p>
                <p>8. Caste certificate</p>
                <p>9. Caste validity certificate</p>
                <p>
                  10. Non creamy layer certificate @ valid up to 31st March
                  2025.
                </p>
                <p>
                  CET Cell, Maharashtra State will allot respective colleges as
                  per students score.
                </p>
              </div>
            </div>
            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <p className="text-bold text-dark">
              {" "}
              For More Details, Please Refer Below Link:
            </p>
            <Link
              to="https://cetcell.mahacet.org"
              target="_blank"
              style={{ color: "steelblue" }}
            >
              https://cetcell.mahacet.org
            </Link>

            <p>
              Note : Admission procedure may vary as per the updated rules by
              CET Cell, Maharashtra State.
            </p>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function RulesRegulation() {
  return (
    <>
      <div className="box row">
        <div className="blockquote" data-aos="fade-down">
          <blockquote className="p-3 mt-4 ps-5 fs-6">
            <h6 className="my-1 fw-bold">
              The student taking admission in MIBM, Shivajinagar, Pune, should
              abide by rules mentioned below{" "}
            </h6>
            <p className="my-1 fw-bold">
              – Prof. Dr Vijayalakshmi Srinivas Director MIBM
            </p>
          </blockquote>
        </div>
      </div>

      <Paper elevation={3} className="p-3 text-justify">
        <h5 className="my-3 title-text decorated">Rules</h5>
        <ul className="ul-tag" data-aos="fade-left">
          <li>
            The students should be aware of all the rules and regulations of the
            college.
          </li>
          <li>
            The required documents should be submitted as and when required.
          </li>
          <li>
            Each student shall conduct herself/himself, both within and outside
            the campus of the Institute in a manner befitting a student of a
            prestigious institute. Each student shall show due respect and
            courtesy to the teachers, administrators, staff of the Institute,
            and to the visitors of the Institute, and good behavior to fellow
            students.
          </li>
          <li>
            Lack of courtesy and decorum; unbecoming conduct within and outside
            the Institute; willful damage to Institute property, removal of any
            property belonging to the Institute, fellow students or other
            personnel of the Institute; use of abusive and offensive language:
            disturbing fellow students in their studies; breach of rules and
            regulations of the Institute; adoption of unfair practices in tests,
            quizzes, assignments, or examinations; noisy and unruly behavior
            shall constitute violation of the code of conduct.
          </li>
          <li>
            Loud talking, loitering or congregating, being a source of
            distraction and annoyance to others is not permitted.
          </li>
          <li>
            Students are prohibited to take part in ragging, political activity
            or any activity which is detrimental to the dignity of the
            institute. Any student found violating the rule or bringing
            disrepute to the institute will be expelled from the institute.
          </li>
          <li>
            Students are required to be dressed in the college uniform on the
            days fixed and to be neatly and decently dressed on the other days
            of the week when in campus.
          </li>
          <li>
            All the students must carry I-card everyday to college and shall
            show the same on demand by any faculty/official of the Institute.
          </li>
          <li>
            Use of cell phones is strictly prohibited in all academic areas of
            the campus. Use of cell phones would entail confiscation of the
            handset. It would be returned only: (a) At the end of the course or,
            (b) After payment of fine.
          </li>
          <li>
            Late arrival and early departure at or from a class are recorded as
            absence from the class. Students are not allowed to leave the
            institute during working hours without the written permission of the
            Director/Assistant Coordinator/class Co-ordinator.
          </li>
          <li>
            Students will not operate any machinery / equipment without the
            permission of the office instructor.
          </li>
          <li>
            No responsibility will be accepted by the institute for any injury,
            loss or damage to the personal articles of students.
          </li>
          <li>
            It is compulsory for the students to attend functions /activities
            organized by the institute on various occasions like Independence
            Day, Republic Day, Annual Day, etc whether the function falls on a
            working day or on holidays. Absence from such functions without
            valid reasons will invite disciplinary action.
          </li>
          <li>
            The students will compensate damage to institute property/furniture
            caused by neglect or willful damage. Defacing the walls or institute
            property will be viewed seriously.
          </li>
          <li>
            Students must pay their fee/dues on or before the prescribed
            deadline. The payment norms are as per DTE, Shikshan Shulka Samiti
            and Savitribai Phule Pune University.
          </li>
          <li>
            Candidates have to fulfill the minimum attendance requirements as
            per university norms. No student will absent himself/herself from
            any of the lectures without leave having been obtained previously
            from the Class Co-ordinator and that too for sufficient cause and if
            he/she is irregular in attendance, or fails to show progress or is
            not diligent in studies, it will be considered seriously and may
            invite serious action. In case of a candidate who joined late owing
            to late declaration of result or who sought admission provisionally
            till declaration of the lower examination, the lecture requirements
            will be calculated as per academic rules and provisions.
          </li>
          <li>
            It may please be noted that the student will get the Roll call no.
            after the payment of the Fees and the attendance will be considered
            only after the Roll call .The consideration regarding the Attendance
            will be as per the Pune University norms.
          </li>
          <li>
            Students claiming benefits/ concessions on medical grounds are
            required to submit medical certificate along with the letter from
            the parents. The certificate should be submitted by student
            concerned within a week of rejoining the Institute after illness.
            However, in such cases there will not be any relaxation in the
            minimum attendance requirement to appear in the semester
            examination.
          </li>
          <li>
            All applications must be addressed to the Principal through proper
            channel.
          </li>
          <li>
            The Principal reserves the right to modify any of the Institute
            rules as and when necessary. The decision of the Principal in
            consent with the CDC chairman in all the matters shall be final.
          </li>
        </ul>
      </Paper>
    </>
  );
}

export function Documents() {
  return (
    <>
      <div className="box">
        <h6 className="my-3 fs-5 decorated  title-text">
          Documents required at the time of admission
        </h6>
        <Paper elevation={3} className="p-3 text-justify P-tag">
          <div data-aos="fade-left">
            <h5 className="my-3 title-text fw-bold">
              All original certificates & Attested True copies of the following
            </h5>

            <p className="my-3">1 - Allotment Letter</p>

            <p className="my-3">2 - Undertaking in Proforma – I</p>

            <p className="my-3">3 - LMH – CET Score Card</p>

            <p className="my-3">4 - Statement of Marks of HSC and SSC</p>

            <p className="my-3">
              5 - Statement of Marks for the Bachelor’s Degree Examination
            </p>

            <p className="my-3">6 - Passing Certificate</p>

            <p className="my-3">7 - College Leaving Certificate</p>

            <p className="my-3">8 - Migration Certificate</p>

            <p className="my-3">9 - Gap Certificate (If Applicable)</p>

            <p className="my-3">
              10 - Certificate in Proforma (a,b,c,d,e,f,g) as Per DTE
            </p>

            <p className="my-3">11 - Caste Certificate</p>

            <p className="my-3">12 - Caste Validity Certificate</p>

            <p className="my-3">13 - Certificate of Non – Creamy Layer</p>

            <p className="my-3">14 - Certificate of Handicapped</p>

            <p className="my-3">15 - Domicile Certificate</p>

            <p className="my-3">16 - Certificate of Nationality*</p>
            <Divider sx={{ border: 1 }} />
            <div>
              <h5 className="my-3 fw-bold title-text">Note</h5>
              <h6 className="my-3 fw-bold">
                *In lieu of the “Certificate of Indian Nationality” following
                certificates/ documents will also be acceptable
              </h6>
              <ul>
                <li>
                  The school leaving certificate indicating the Nationality of
                  the candidates as ‘Indian’
                </li>
                <li>
                  Indian Passport in the name of the candidate, issued by
                  appropriate authorities
                </li>
                <li>
                  Birth certificate of the candidate indicating the place of
                  birth in India.
                </li>
              </ul>
              <p
                style={{
                  border: "2px groove #A70606",
                  padding: "10px",
                  borderRadius: "10px",
                  fontSize: "0.8rem",
                }}
              >
                If the candidate fails to produce any one of the above mentioned
                certificate/documents indicating nationality then such
                candidates nationality shall be considered based on the
                undertaking given in Performa – I on Rs. 100 Non-judicial Stamp
                Paper.
              </p>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function MBA() {
  return (
    <div>
      <h5 className="title-text my-2 mb-4">MBA Fees</h5>
      <div>
        <object
          // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
          data={mba_fees}
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>
            Instead you can{" "}
            <Link to={mba_fees}> click here to download the PDF file.</Link>
          </p>
        </object>
      </div>
    </div>
  );
}

export function BBA() {
  window.scrollTo(0, 190);
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f0f0f0",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          margin: "100px auto",
          maxWidth: "600px",
          padding: "20px 50px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h1 style={{ color: "#ff2600" }}>BBA Fees</h1>
        <p style={{ fontSize: "18px" }}>BBA Fees will be informed soon</p>
        <p style={{ fontSize: "18px" }}>Please check back later.</p>
        <div></div>

        <Link to={"/"} style={{ color: "steelblue" }}>
          Go to Home{" "}
        </Link>
      </div>
    </div>
  );
}

export function Phd() {
  window.scrollTo(0, 190);
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f0f0f0",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          margin: "100px auto",
          maxWidth: "600px",
          padding: "20px 50px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h1 style={{ color: "#ff2600" }}>Ph.D Fees</h1>
        <p style={{ fontSize: "18px" }}>Ph.D Fees will be informed soon</p>
        <p style={{ fontSize: "18px" }}>Please check back later.</p>
        <div></div>

        <Link to={"/"} style={{ color: "steelblue" }}>
          Go to Home{" "}
        </Link>
      </div>
    </div>
  );
}

export function FeeStructure() {
  const tabData = [
    { label: "BBA Fees", content: <BBA /> },
    { label: "MBA Fees", content: <MBA /> },
    { label: "Ph.D Fees", content: <Phd /> },
  ];

  return (
    <div className="box">
      <Paper elevation={2}>
        <TabComponent
          tabData={tabData}
          tabName={sessionStorage.getItem("menuName")}
        />
      </Paper>
    </div>
  );
}
