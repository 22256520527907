import { Paper, Typography } from "@mui/material";
import React from "react";
import {
  Images,
  Paragraphs,
} from "./ReusableComponent/ImageAndParagraphsComponent";

const ImageDivComponent = ({ item }) => {
  console.log(item);
  return (
    <Paper
      elevation={1}
      sx={{ backgroundColor: "ButtonFace", padding: "10px", margin: "0%" }}
    >
      <Typography variant="h5" gutterBottom textAlign={"center"}>
        {item.cardTitle}
      </Typography>
      <Typography>
        <Images images={item.cardImages} />{" "}
        {/*import from Img&ParagraphComponent */}
      </Typography>
      <Typography className="mt-2">
        <Paragraphs description={item.cardDescription} />{" "}
        {/*import from Img&ParagraphComponent */}
      </Typography>
    </Paper>
  );
};

export default ImageDivComponent;
