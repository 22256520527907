import { Link } from "react-router-dom";
import { Paper } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { TableComponent3_colm } from "../../Components/ReusableComponent/tableComponent";

import pdf_19_20 from "../../assets/pdf/AQAR/AQAR_19_20/AQAR_2019-2020.pdf";
import pdf20_21_1_1_1 from "../../assets/pdf/AQAR/AQAR_20_21/1_1_1.pdf";
import pdf20_21_1_1_2 from "../../assets/pdf/AQAR/AQAR_20_21/1_1_2.pdf";
import pdf20_21_1_1_4 from "../../assets/pdf/AQAR/AQAR_20_21/1_1_4.pdf";
import pdf20_21_2_2 from "../../assets/pdf/AQAR/AQAR_20_21/2.2 Number of seats earmarked for reserved category as per GOI State Govt. rule during the year.pdf";
import pdf20_21_2_5_1 from "../../assets/pdf/AQAR/AQAR_20_21/2.5.1Concurrent_Evaluation.pdf";
import pdf20_21_2_6_3 from "../../assets/pdf/AQAR/AQAR_20_21/ResultAnalysis-AllSubjects-2019-21Batch.pdf";
import pdf20_21_3_2_1 from "../../assets/pdf/AQAR/AQAR_20_21/3.2.1.pdf";
import pdf20_21_3_2_2 from "../../assets/pdf/AQAR/AQAR_20_21/3.2.2.pdf";
import pdf20_21_3_4_1 from "../../assets/pdf/AQAR/AQAR_20_21/3.4.1.pdf";
import pdf20_21_3_5_1 from "../../assets/pdf/AQAR/AQAR_20_21/3.5.1.pdf";
import pdf20_21_4_2_1 from "../../assets/pdf/AQAR/AQAR_20_21/4.2.1 Library is automated using Integrated Library Management.pdf";
import pdf20_21_5_3_2 from "../../assets/pdf/AQAR/AQAR_20_21/5.3.2.pdf";
import pdf20_21_6_2_3 from "../../assets/pdf/AQAR/AQAR_20_21/6.2.3  Implementation of e-governance in areas of operation.pdf";
import pdf20_21_6_5_2 from "../../assets/pdf/AQAR/AQAR_20_21/6.5.2 The institution reviews.pdf";
import pdf20_21_6_5_3 from "../../assets/pdf/AQAR/AQAR_20_21/6.5.3 Quality assurance initiatives of the institution.pdf";
import pdf20_21_7_1_1 from "../../assets/pdf/AQAR/AQAR_20_21/7_1_1.pdf";
import pdf20_21_7_1_8 from "../../assets/pdf/AQAR/AQAR_20_21/7_1_8.pdf";
import pdf20_21_7_2_1 from "../../assets/pdf/AQAR/AQAR_20_21/7_2_1.pdf";
import pdf20_21_7_1_9 from "../../assets/pdf/AQAR/AQAR_20_21/7.1.9.pdf";
import pdf20_21_7_1_10 from "../../assets/pdf/AQAR/AQAR_20_21/7.1.10.pdf";
import pdf20_21_timeTable from "../../assets/pdf/AQAR/AQAR_20_21/Time Tables -2020-21 - Crit- 4.4.2.pdf";
import pdf_AQAR2020_21 from "../../assets/pdf/AQAR/AQAR_20_21/AQAR_2020-2021.pdf";

import pdf21_22_1_1_1 from "../../assets/pdf/AQAR/AQAR_21_22/1.1.1.pdf";
import pdf21_22_1_1_2 from "../../assets/pdf/AQAR/AQAR_21_22/1.1.2.pdf";
import pdf21_22_1_4_1 from "../../assets/pdf/AQAR/AQAR_21_22/1.4.1ActiontakenReport.pdf";
import pdf21_22_2_2 from "../../assets/pdf/AQAR/AQAR_21_22/2.2 Number of seats earmarked for reserved category as per GOI State Govt. rule during the year.pdf";
import pdf21_22_2_5_1 from "../../assets/pdf/AQAR/AQAR_21_22/2.5.1Concurrent_Evaluation.pdf";
import pdf21_22_2_5_2 from "../../assets/pdf/AQAR/AQAR_21_22/2.5.2Mechanism_boundEfficient.pdf";
import pdf21_22_2_6_2 from "../../assets/pdf/AQAR/AQAR_21_22/2.6.1_POs&COsMBA2019pattern.pdf";
import pdf21_22_2_6_3 from "../../assets/pdf/AQAR/AQAR_21_22/2.6.3StudetnsPassingPercentage.pdf";
import pdf21_22_2_7_1 from "../../assets/pdf/AQAR/AQAR_21_22/2.7.1CriteriaStudentStaisfactionSurvey.pdf";
import pdf21_22_3_1_3 from "../../assets/pdf/AQAR/AQAR_21_22/3.1.3_Research_Project_Funded_by_Govt.pdf";
import pdf21_22_3_2_1 from "../../assets/pdf/AQAR/AQAR_21_22/3.2.1_Ecosystem_for_Innovation.pdf";
import pdf21_22_3_2_2 from "../../assets/pdf/AQAR/AQAR_21_22/3.2.2_Workshop_IPR_RM_ED.pdf";
import pdf21_22_3_3_1 from "../../assets/pdf/AQAR/AQAR_21_22/3.3.1_PhD_registered.pdf";
import pdf21_22_3_4_1 from "../../assets/pdf/AQAR/AQAR_21_22/3.4.1_Extension_Activity.pdf";
import pdf21_22_4_2_1 from "../../assets/pdf/AQAR/AQAR_21_22/4.2.1Library_is_automated_usingIntegratedLibraryManagement.pdf";
import pdf21_22_5_1_1 from "../../assets/pdf/AQAR/AQAR_21_22/5.1.1.pdf";
import pdf21_22_5_1_3 from "../../assets/pdf/AQAR/AQAR_21_22/5.1.3.pdf";
import pdf21_22_5_1_4 from "../../assets/pdf/AQAR/AQAR_21_22/5.1.4(21-22).pdf";
import pdf21_22_5_3_2 from "../../assets/pdf/AQAR/AQAR_21_22/5.3.2.pdf";
import pdf21_22_6_1_1 from "../../assets/pdf/AQAR/AQAR_21_22/6.1.1_vision_and_mission_of_the_institution.pdf";
import pdf21_22_6_1_2 from "../../assets/pdf/AQAR/AQAR_21_22/6.1.2The_effective_leadership.pdf";
import pdf21_22_6_2_1 from "../../assets/pdf/AQAR/AQAR_21_22/6.2.1_The_institutionalStrategic_perspective.pdf";
import pdf21_22_6_2_2 from "../../assets/pdf/AQAR/AQAR_21_22/6.2.2_The_functioning_of_the_institutional.pdf";
import pdf21_22_6_2_3 from "../../assets/pdf/AQAR/AQAR_21_22/6.2.3_Implementation_of_e-governance.pdf";
import pdf21_22_6_3_1 from "../../assets/pdf/AQAR/AQAR_21_22/6.3.1_21_22.pdf";
import pdf21_22_6_3_5 from "../../assets/pdf/AQAR/AQAR_21_22/6.3.5_InstitutionsPerformanceAppraisal.pdf";
import pdf21_22_6_5_1 from "../../assets/pdf/AQAR/AQAR_21_22/6.5.1_21_22.pdf";
import pdf21_22_6_5_2 from "../../assets/pdf/AQAR/AQAR_21_22/6.5.2_21_22.pdf";
import pdf21_22_6_5_3 from "../../assets/pdf/AQAR/AQAR_21_22/6.5.3 Quality assurance initiatives of the institution include.pdf";
import pdf21_22_7_1_1 from "../../assets/pdf/AQAR/AQAR_21_22/7.1.1(21-22).pdf";
import pdf21_22_7_1_9 from "../../assets/pdf/AQAR/AQAR_21_22/7.1.9.pdf";
import pdf21_22_7_1_10 from "../../assets/pdf/AQAR/AQAR_21_22/7.1.10.pdf";
import pdf21_22_7_2_1 from "../../assets/pdf/AQAR/AQAR_21_22/7.2.1.pdf";
import pdf_AQAR2021_22 from "../../assets/pdf/AQAR/AQAR_21_22/AQAR-2021-2022.pdf";

import pdf21_22_aqar21_22 from "../../assets/pdf/AQAR/AQAR_21_22/AQAR-2021-2022-Write up-4.3.1.pdf";
import pdf21_22_MOU from "../../assets/pdf/AQAR/AQAR_21_22/MOUPlaygroundAndbadminton-merged.pdf";
import pdf21_22_SupportiveDocument from "../../assets/pdf/AQAR/AQAR_21_22/SupportiveDocument.pdf";
import pdf21_22_timeTable from "../../assets/pdf/AQAR/AQAR_21_22/TimeTables-4.4.2.pdf";

//2022-23 pdf imports
import pdf22_23_1_1_1 from "../../assets/pdf/AQAR/AQAR_22_23/1.1.1_Academic_Calendar-2022-23.pdf";
import pdf22_23_1_4_1 from "../../assets/pdf/AQAR/AQAR_22_23/1.4.1.pdf";

import pdf22_23_2_2 from "../../assets/pdf/AQAR/AQAR_22_23/2.2_Number_of_seats_earmarked_for_reserved_category_as_per_GOI_State_Govt._rule_during_the_year.pdf";
import pdf22_23_2_5_1 from "../../assets/pdf/AQAR/AQAR_22_23/2.5.1Concurrent_Evaluation_22_23.pdf";
import pdf22_23_2_5_2 from "../../assets/pdf/AQAR/AQAR_22_23/2.5.2_Mechanism_to_deal_with_internal_examination_related_grievances_is_transparent_time_bound_and_efficient.pdf";
import pdf22_23_2_6_2 from "../../assets/pdf/AQAR/AQAR_22_23/2.6.2 _PO_&_CO_MBA_2019_pattern.pdf";
import pdf22_23_2_6_3 from "../../assets/pdf/AQAR/AQAR_22_23/2.6.3_Result_Analysis.pdf";
import excel22_23_2_7 from "../../assets/pdf/AQAR/AQAR_22_23/2.7_Student_Satisfaction_Survey_2022-23.xlsx";

import excel22_23_3_1_1 from "../../assets/pdf/AQAR/AQAR_22_23/3.1.1_Grant_received_DVV_2022_23.xlsx";
import pdf22_23_3_2_1 from "../../assets/pdf/AQAR/AQAR_22_23/3.2.1.pdf";
import pdf22_23_3_2_2 from "../../assets/pdf/AQAR/AQAR_22_23/3.2.2.pdf";
import pdf22_23_3_3_1 from "../../assets/pdf/AQAR/AQAR_22_23/3.3.1.pdf";
import pdf22_23_3_3_2 from "../../assets/pdf/AQAR/AQAR_22_23/3.3.2.pdf";
import pdf22_23_3_4_1 from "../../assets/pdf/AQAR/AQAR_22_23/3.4.1.pdf";
import pdf22_23_3_4_3 from "../../assets/pdf/AQAR/AQAR_22_23/3.4.3.pdf";
import pdf22_23_3_4_4 from "../../assets/pdf/AQAR/AQAR_22_23/3.4.4.pdf";
import pdf22_23_3_5_1 from "../../assets/pdf/AQAR/AQAR_22_23/3.5.1.pdf";
import pdf22_23_3_5_2 from "../../assets/pdf/AQAR/AQAR_22_23/3.5.2.pdf";

import pdf22_23_4_1_2 from "../../assets/pdf/AQAR/AQAR_22_23/4.1.2_Facilites.pdf";
import pdf22_23_4_2_1 from "../../assets/pdf/AQAR/AQAR_22_23/4.2.1_Library_is_automated_using_Integrated_Library_Management.pdf";
import pdf22_23_4_2_4 from "../../assets/pdf/AQAR/AQAR_22_23/4.2.4.pdf";
import pdf22_23_4_3_1 from "../../assets/pdf/AQAR/AQAR_22_23/4.3.1_computer_labs.docx";
import pdf22_23_4_4_2 from "../../assets/pdf/AQAR/AQAR_22_23/4.4.2_Timetable_2022.pdf";

import pdf22_23_5_1_1 from "../../assets/pdf/AQAR/AQAR_22_23/5.1.1.pdf";
import pdf22_23_5_1_3 from "../../assets/pdf/AQAR/AQAR_22_23/5.1.3.pdf";
import pdf22_23_5_3_2 from "../../assets/pdf/AQAR/AQAR_22_23/5.3.2.pdf";
import pdf22_23_5_4_1 from "../../assets/pdf/AQAR/AQAR_22_23/5.4.1.pdf";

import pdf22_23_6_1_1 from "../../assets/pdf/AQAR/AQAR_22_23/6.1.1_The_governance_of_the_institution_is_reflective_of_and_in_tune_with_the_vision_and_mission_of_the_institution.pdf";
import pdf22_23_6_1_2 from "../../assets/pdf/AQAR/AQAR_22_23/6.1.2_The_effective_leadership_is_visible_in_various_institutional_practices_such_as_decentralization_and_participative_management.pdf";
import pdf22_23_6_2_1 from "../../assets/pdf/AQAR/AQAR_22_23/6.2.1_The_institutional_Strategic_perspective_plan_is_effectively_deployed.pdf";
import pdf22_23_6_2_2 from "../../assets/pdf/AQAR/AQAR_22_23/6.2.2_The_functioning_of_the_institutional_bodies_is_effective_and_efficient_as_visible_from_policies_administrative_setup_appointment.pdf";
import pdf22_23_6_2_2_o from "../../assets/pdf/AQAR/AQAR_22_23/6.2.2_Organogram.pdf";
import pdf22_23_6_2_3 from "../../assets/pdf/AQAR/AQAR_22_23/6.2.3_Implementation_of_e-governance_in_areas_of_operation.pdf";
import pdf22_23_6_3_1 from "../../assets/pdf/AQAR/AQAR_22_23/6.3.1_The_institution_has_effective_welfare_measures_for_teaching_and_non-teaching_staff_Provide_the_list_of_existing_welfare_measures.pdf";

import pdf22_23_6_3_4_1 from "../../assets/pdf/AQAR/AQAR_22_23/6.3.4.1_Num_of_teachers_undergoing_online_face-to-face_-(FDP)-during_the_year_Professional_Development_Program.pdf";
import pdf22_23_6_3_5 from "../../assets/pdf/AQAR/AQAR_22_23/6.3.5_Institutions_Performance_Appraisal_System_for_teaching_and_non-teaching_staff.pdf";

import pdf22_23_7_1_1 from "../../assets/pdf/AQAR/AQAR_22_23/7.1.1.pdf";
import pdf22_23_7_1_7 from "../../assets/pdf/AQAR/AQAR_22_23/7.1.7.pdf";
import pdf22_23_7_1_9 from "../../assets/pdf/AQAR/AQAR_22_23/7.1.9.pdf";

//2023-24 pdf imports

import pdf23_24_4_2_1 from "../../assets/pdf/AQAR/AQAR_23_24/4.2.1_Library_is_automated_using_Integrated_Library_Management.pdf";
import pdf23_24_4_2_4 from "../../assets/pdf/AQAR/AQAR_23_24/4.2.4_Number_per_day_usage_of_Library_by_teachers_and_Students.pdf";
import pdf23_24_7_1_1 from "../../assets/pdf/AQAR/AQAR_23_24/7.1.1.pdf";
import pdf23_24_7_1_7 from "../../assets/pdf/AQAR/AQAR_23_24/7.1.7.pdf";
import pdf23_24_7_1_9 from "../../assets/pdf/AQAR/AQAR_23_24/7.1.9.pdf";
import pdf23_24_7_1_10 from "../../assets/pdf/AQAR/AQAR_23_24/7.1.10.pdf";
import GalleryComponent from "../../Components/ReusableComponent/GalleryComponent";

import classroom1 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img1.jpg";
import classroom2 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img2.jpg";
import classroom3 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img3.jpg";
import classroom4 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img4.jpg";
import classroom5 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img5.jpg";
import classroom6 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img6.jpg";
import classroom7 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img7.jpg";
import classroom8 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img8.jpg";
import classroom9 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img9.jpg";
import classroom10 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img10.jpg";
import classroom11 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img11.jpg";
import classroom12 from "../../assets/images/IQAC/AQAR/21-22/4.1.1_img12.jpg";

import cultural1 from "../../assets/images/IQAC/AQAR/21-22/4.1.2_img1.jpg";
import cultural2 from "../../assets/images/IQAC/AQAR/21-22/4.1.2_img2.jpg";
import cultural3 from "../../assets/images/IQAC/AQAR/21-22/4.1.2_img3.jpg";
import cultural4 from "../../assets/images/IQAC/AQAR/21-22/4.1.2_img4.jpg";
import cultural5 from "../../assets/images/IQAC/AQAR/21-22/4.1.2_img5.jpg";

import lab1 from "../../assets/images/IQAC/AQAR/21-22/ComputerLab-1.jpg";
import lab2 from "../../assets/images/IQAC/AQAR/21-22/ComputerLab-2.jpg";
import lab3 from "../../assets/images/IQAC/AQAR/21-22/ComputerLab-3.jpg";

import Breadcrumb from "../../Components/Breadcrumb";
import ExcelLogo from "../../assets/logo/ExcelLogo";

export function AQAR_23_24() {
  const tableHeading = ["Metric No.", "Description", "Documents"];
  const tableData = [
    {
      col1: "1.1.1 Effective Curriculum delivery through a well planned and documented process",
      col2: <></>,
    },
    {
      col1: "1.1.2 Effective Curriculum delivery through a well planned and documented process",
      col2: <></>,
    },
    {
      col1: "1.4.1 Feedback Report 2023-2024",
      col2: <></>,
    },
    {
      col1: "2.2 Number of seats earmarked for reserved category as per GOI State Govt. rule during the year",
      col2: <></>,
    },
    {
      col1: "2.5.1 Criteria for Concurrent Evaluation",
      col2: <></>,
    },
    {
      col1: "2.5.2 Mechanism to deal with internal examination related grievances is transparent, time-bound and efficient",
      col2: <></>,
    },
    {
      col1: "2.6.2 Programme Outcomes and Course Outcomes",
      col2: <></>,
    },
    {
      col1: "2.6.3 Passing percentage of the students during the year",
      col2: <></>,
    },
    {
      col1: "2.7 Students Satisfaction Survey",
      col2: <></>,
    },
    {
      col1: "Number of departments having research project funded by government and non government agencies during the year",
      col2: <></>,
    },
    {
      col1: "3.1.1 Grant Received DVV 2023-24",
      col2: <></>,
    },
    {
      col1: "3.2.1 Institution has created an ecosystem for innovations and has initiatives for creating and transfer of knowledge.",
      col2: <></>,
    },
    {
      col1: "3.2.2 Number of workshops conducted on research methodology, Intellectual property rights and entrepreneurship development during the year",
      col2: <></>,
    },
    {
      col1: "3.3.1 Number of Ph.Ds registered per eligible teacher during the year.",
      col2: <></>,
    },
    {
      col1: "3.3.2 Number of research papers per teachers in the Journals notified on UGC website during the year.",
      col2: <></>,
    },
    {
      col1: "3.4.1 Extension activities are carried out in the neighborhood community, sensitizing students to social issues, for their holistic development, and impact thereof during the year.",
      col2: <></>,
    },
    {
      col1: "3.4.3 Number of extension and outreach Programmes conducted by the institution through NSS/NCC/Government and Government recognized bodied during the year.",
      col2: <></>,
    },
    {
      col1: "3.4.4 Number of Students participating in extension activities at 3.4.3 above during the year.",
      col2: <></>,
    },
    {
      col1: "3.5.1 Number of collaborative activities for research, Faculty Exchange, Student Exchange/ internship during the year",
      col2: <></>,
    },
    {
      col1: "3.5.2 Number of functional MoUs with institutions, other universities, industries, corporate houses, etc. during the year.",
      col2: <></>,
    },
    {
      col1: "4.1.1 LINKS -- CLASSROOM PHOTOS , SEMINAR HALL",
      col2: <></>,
    },
    {
      col1: "4.1.2 LINKS -- CULTURAL PROGRAMS , SPORTS GROUND , YOGA",
      col2: <></>,
    },
    {
      col1: "4.1.2 LINKS -- MOU Badminton, Playground and Yoga Hall",
      col2: <></>,
    },
    {
      col1: "4.1.3 Computer labs",
      col2: <></>,
    },
    {
      col1: "4.2.1 Library is automated using Integrated Library Management",
      col2: (
        <a href={pdf23_24_4_2_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.2.4 Number per day usage of Library by teachers and Students",
      col2: (
        <a href={pdf23_24_4_2_4} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.3.1 Institution frequently updates its IT facilities including Wi-Fi",
      col2: <></>,
    },
    {
      col1: "4.4.2 Time Table",
      col2: <></>,
    },
    {
      col1: "5.1.1 list of students benefited by scholarship and freeships provided by government.",
      col2: <></>,
    },
    {
      col1: "5.1.3 Capacity Enhancement and Skill Enhancement",
      col2: <></>,
    },
    {
      col1: "5.1.4 Number of students benefited by guidance for competitive examinations and career counselling offered by the institution during the year 2023-24",
      col2: <></>,
    },
    {
      col1: "5.3.2 Institution facilitates student`s representation and engagement in various administrative , co-curricular and extracurricular activities",
      col2: <></>,
    },
    {
      col1: "5.4.1 Alumni Association",
      col2: <></>,
    },
    {
      col1: "6.1.1 The governance of the institution is reflective of and in tune with the vision and mission of the institution",
      col2: <></>,
    },
    {
      col1: "6.1.2 The effective leadership is visible in various institutional practices such as decentralization and participative management",
      col2: <></>,
    },
    {
      col1: "6.2.1 The institutional Strategic/ perspective plan is effectively deployed",
      col2: <></>,
    },
    {
      col1: "6.2.2 The functioning of the institutional bodies is effective and efficient as visible from policies, administrative setup, appointment and service rules, procedures, etc",
      col2: <></>,
    },
    {
      col1: "6.2.3. Implementation of e-governance in areas of operation",
      col2: <></>,
    },
    {
      col1: "6.3.1 The institution has effective welfare measures for teaching and non- teaching staff provide the list of existing welfare measures for teaching and non- teaching staff",
      col2: <></>,
    },
    {
      col1: "6.3.3 Number of professional development administrative training programs organized by the institution for teaching and non-teaching staff",
      col2: <></>,
    },
    {
      col1: "6.3.4.1 Number of teachers undergoing onlineface-to-face Faculty development Programmes (FDP) during the year (Professional Development Programmes, Orientation  Induction Programmes, Refresher Course, Short Term Courses)",
      col2: <></>,
    },
    {
      col1: "6.3.5 Institutions Performance Appraisal System for teaching and non- teaching staff",
      col2: <></>,
    },
    {
      col1: "6.5.1 Internal Quality Assurance Cell (IQAC) has contributed significantly for institutionalizing the quality assurance strategies and processes",
      col2: <></>,
    },
    {
      col1: "6.5.2 The institution reviews its teaching learning process, structures & methodologies of operations and learning outcomes at periodic intervals through",
      col2: <></>,
    },
    {
      col1: "6.5.3 Quality assurance initiatives of the institution include",
      col2: <></>,
    },
    {
      col1: "7.1.1 Measures initiated by the Institution for the promotion of gender equity during the year.",
      col2: (
        <a href={pdf23_24_7_1_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.7 The institution has Divyangjan-friendly, barrier free environment.",
      col2: (
        <a href={pdf23_24_7_1_7} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.9 - Sensitization of students and employees of the Institution to the constitutional obligations: values, rights, duties and responsibilities of citizens",
      col2: (
        <a href={pdf23_24_7_1_9} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.10 - The Institution has a prescribed code of conduct for students, teachers, administrators and other staff and conducts periodic programmes in this regard. The Code of Conduct is displayed on the website There is a committee to monitor adherence to the Code of Conduct Institution organizes professional ethics programmes for students, teachers, administrators and other staff 4. Annual awareness programmes on Code of Conduct are organized",
      col2: (
        <a href={pdf23_24_7_1_10} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.2.1 Best Practices Successfully Implemented by the Institution",
      col2: <></>,
    },
    {
      col1: "AQAR 2023-2024",
      col2: <></>,
    },
  ];
  return (
    <>
      <div>
        <Paper elevation={1} className="mb-1" key={0}>
          <TableComponent3_colm
            tableHeading={tableHeading}
            tableData={tableData}
          />
        </Paper>
      </div>
    </>
  );
}

export function AQAR_22_23() {
  const tableHeading = ["Metric No.", "Description", "Documents"];
  const tableData = [
    {
      col1: "1.1.1 Effective Curriculum delivery through a well planned and documented process",
      col2: (
        <a href={pdf22_23_1_1_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "1.1.2 Effective Curriculum delivery through a well planned and documented process",
      col2: <></>,
    },
    {
      col1: "1.4.1 Feedback Report 2022-2023",
      col2: (
        <a href={pdf22_23_1_4_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.2 Number of seats earmarked for reserved category as per GOI State Govt. rule during the year",
      col2: (
        <a href={pdf22_23_2_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.5.1 Criteria for Concurrent Evaluation",
      col2: (
        <a href={pdf22_23_2_5_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.5.2 Mechanism to deal with internal examination related grievances is transparent, time-bound and efficient",
      col2: (
        <a href={pdf22_23_2_5_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.6.2 Programme Outcomes and Course Outcomes",
      col2: (
        <a href={pdf22_23_2_6_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.6.3 Passing percentage of the students during the year",
      col2: (
        <a href={pdf22_23_2_6_3} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.7 Students Satisfaction Survey",
      col2: (
        <a href={excel22_23_2_7} target="_blank">
          <ExcelLogo />
        </a>
      ),
    },
    {
      col1: "Number of departments having research project funded by government and non government agencies during the year",
      col2: <></>,
    },
    {
      col1: "3.1.1 Grant Received DVV 2022-23",
      col2: (
        <a href={excel22_23_3_1_1} target="_blank">
          <ExcelLogo />
        </a>
      ),
    },
    {
      col1: "3.2.1 Institution has created an ecosystem for innovations and has initiatives for creating and transfer of knowledge.",
      col2: (
        <a href={pdf22_23_3_2_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.2.2 Number of workshops conducted on research methodology, Intellectual property rights and entrepreneurship development during the year",
      col2: (
        <a href={pdf22_23_3_2_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.3.1 Number of Ph.Ds registered per eligible teacher during the year.",
      col2: (
        <a href={pdf22_23_3_3_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.3.2 Number of research papers per teachers in the Journals notified on UGC website during the year.",
      col2: (
        <a href={pdf22_23_3_3_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.4.1 Extension activities are carried out in the neighborhood community, sensitizing students to social issues, for their holistic development, and impact thereof during the year.",
      col2: (
        <a href={pdf22_23_3_4_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.4.3 Number of extension and outreach Programmes conducted by the institution through NSS/NCC/Government and Government recognized bodied during the year.",
      col2: (
        <a href={pdf22_23_3_4_3} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.4.4 Number of Students participating in extension activities at 3.4.3 above during the year.",
      col2: (
        <a href={pdf22_23_3_4_4} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.5.1 Number of collaborative activities for research, Faculty Exchange, Student Exchange/ internship during the year",
      col2: (
        <a href={pdf22_23_3_5_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.5.2 Number of functional MoUs with institutions, other universities, industries, corporate houses, etc. during the year.",
      col2: (
        <a href={pdf22_23_3_5_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.1.1 LINKS -- CLASSROOM PHOTOS , SEMINAR HALL",
      col2: (
        <Link to="/ClassroomImages" target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.1.2 LINKS -- CULTURAL PROGRAMS , SPORTS GROUND , YOGA",
      col2: (
        <Link to="/CulturalProgram" target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.1.2 LINKS -- MOU Badminton, Playground and Yoga Hall",
      col2: (
        <a href={pdf22_23_4_1_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.1.3 Computer labs",
      col2: (
        <Link to="/ComputerLab" target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.2.1 Library is automated using Integrated Library Management",
      col2: (
        <a href={pdf22_23_4_2_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.2.4 Number per day usage of Library by teachers and Students",
      col2: (
        <a href={pdf22_23_4_2_4} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.3.1 Institution frequently updates its IT facilities including Wi-Fi",
      col2: (
        <a href={pdf22_23_4_3_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.4.2 Time Table",
      col2: (
        <a href={pdf22_23_4_4_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.1.1 Number of students benefited by scholarships and free ships",
      col2: (
        <a href={pdf22_23_5_1_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.1.3 Capacity building and skills enhancement initiatives taken",
      col2: (
        <a href={pdf22_23_5_1_3} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.1.4 Number of students benefited by guidance for competitive examinations and career counselling offered by the institution during the year 2021-22",
      col2: <></>,
    },
    {
      col1: "5.3.2 Institution facilitates students' representation and engagement in various administrative , co-curricular and extra curricular activities",
      col2: (
        <a href={pdf22_23_5_3_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.4.1 There is registered Alumni that contributes significantly to the development of the institution ",
      col2: (
        <a href={pdf22_23_5_4_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.1.1 The governance of the institution is reflective of and in tune with the vision and mission of the institution",
      col2: (
        <a href={pdf22_23_6_1_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.1.2 The effective leadership is visible in various institutional practices such as decentralization and participative management",
      col2: (
        <a href={pdf22_23_6_1_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.2.1 The institutional Strategic/ perspective plan is effectively deployed",
      col2: (
        <a href={pdf22_23_6_2_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.2.2 The functioning of the institutional bodies is effective and efficient as visible from policies, administrative setup, appointment and service rules, procedures, etc",
      col2: (
        <a href={pdf22_23_6_2_2} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.2.3. Implementation of e-governance in areas of operation",
      col2: (
        <a href={pdf22_23_6_2_3} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.3.1 The institution has effective welfare measures for teaching and non- teaching staff provide the list of existing welfare measures for teaching and non- teaching staff",
      col2: (
        <a href={pdf22_23_6_3_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.3.3 Number of professional development administrative training programs organized by the institution for teaching and non-teaching staff",
      col2: <></>,
    },
    {
      col1: "6.3.4.1 Number of teachers undergoing onlineface-to-face Faculty development Programmes (FDP) during the year (Professional Development Programmes, Orientation  Induction Programmes, Refresher Course, Short Term Courses)",
      col2: (
        <a href={pdf22_23_6_3_4_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.3.5 Institutions Performance Appraisal System for teaching and non- teaching staff",
      col2: (
        <a href={pdf22_23_6_3_5} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.3.4.1 Number of teachers undergoing onlineface-to-face Faculty development Programmes (FDP) during the year (Professional Development Programmes, Orientation Induction Programmes, Refresher Course, Short Term Courses)",
      col2: <></>,
    },
    {
      col1: "6.3.5 Institutions Performance Appraisal System for teaching and non- teaching staff",
      col2: (
        <a href={pdf22_23_6_3_5} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.5.1 Internal Quality Assurance Cell (IQAC) has contributed significantly for institutionalizing the quality assurance strategies and processes",
      col2: <></>,
    },
    {
      col1: "6.5.2 The institution reviews its teaching learning process, structures & methodologies of operations and learning outcomes at periodic intervals through",
      col2: <></>,
    },
    {
      col1: "6.5.3 Quality assurance initiatives of the institution include",
      col2: <></>,
    },
    {
      col1: "7.1.1 Measures initiated by the Institution for the promotion of gender equity during the year.",
      col2: (
        <a href={pdf22_23_7_1_1} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.7 The institution has Divyangjan-friendly, barrier free environment.",
      col2: (
        <a href={pdf22_23_7_1_7} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.9 - Sensitization of students and employees of the Institution to the constitutional obligations: values, rights, duties and responsibilities of citizens",
      col2: (
        <a href={pdf22_23_7_1_9} target="_blank">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.10 - The Institution has a prescribed code of conduct for students, teachers, administrators and other staff and conducts periodic programmes in this regard. The Code of Conduct is displayed on the website There is a committee to monitor adherence to the Code of Conduct Institution organizes professional ethics programmes for students, teachers, administrators and other staff 4. Annual awareness programmes on Code of Conduct are organized",
      col2: <></>,
    },
    {
      col1: "7.2.1 Best Practices Successfully Implemented by the Institution",
      col2: <></>,
    },
    {
      col1: "AQAR 2022-2023",
      col2: <></>,
    },
  ];
  return (
    <>
      <div>
        <Paper elevation={1} className="mb-1" key={0}>
          <TableComponent3_colm
            tableHeading={tableHeading}
            tableData={tableData}
          />
        </Paper>
      </div>
    </>
  );
}

export function AQAR_21_22() {
  const tableHeading = ["Metric No.", "Description", "Documents"];
  const tableData = [
    {
      col1: "1.1.1 Effective Curriculum delivery through a well planned and documented process",
      col2: (
        <a href={pdf21_22_1_1_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "1.1.2 Effective Curriculum delivery through a well planned and documented process",
      col2: (
        <a href={pdf21_22_1_1_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "1.4.1 Feedback Report 2021-2022",
      col2: (
        <a href={pdf21_22_1_4_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.2 Number of seats earmarked for reserved category as per GOI/ State Govt. rule during the year",
      col2: (
        <a href={pdf21_22_2_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.5.1 Criteria for Concurrent Evaluation",
      col2: (
        <a href={pdf21_22_2_5_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.5.2 Mechanism to deal with internal examination related grievances is transparent, time- bound and efficient",
      col2: (
        <a href={pdf21_22_2_5_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.6.2 Programme Outcomes and Course Outcomes",
      col2: (
        <a href={pdf21_22_2_6_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.6.3 Passing percentage of the students during the year",
      col2: (
        <a href={pdf21_22_2_6_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.7.1 Students Satisfaction Survey",
      col2: (
        <a href={pdf21_22_2_7_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "Number of departments having research project funded by government and non government agencies during the year",
      col2: (
        <a href={pdf21_22_3_1_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.2.1 Institution has created an ecosystem for innovation and has initiatives for creation and transfer of knowledge.",
      col2: (
        <a href={pdf21_22_3_2_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.2.2 Number of workshops conducted on research methodology, Intellectual property rights and entrepreneurship development during the year",
      col2: (
        <a href={pdf21_22_3_2_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.3.1 Number of Ph.Ds registered per eligible teacher during the year.",
      col2: (
        <a href={pdf21_22_3_3_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.4.1 Extension activities are carried out in the neighborhood community, sensitizing students to social issues, for their holistic development, and impact thereof during the year.",
      col2: (
        <a href={pdf21_22_3_4_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.1.1 LINKS -- CLASSROOM PHOTOS , SEMINAR HALL",
      col2: (
        <Link to="/ClassroomImages" target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.1.2 LINKS -- CULTURAL PROGRAMS , SPORTS GROUND , YOGA",
      col2: (
        <Link to="/CulturalProgram" target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.1.2 LINKS -- MOU Badminton, Playground and Yoga Hall",
      col2: (
        <a href={pdf21_22_MOU} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.1.3 Computer labs",
      col2: (
        <Link to="/ComputerLab" target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.2.1 Library is automated using the Integrated Library Management System.",
      col2: (
        <a href={pdf21_22_4_2_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.3.1 Institution frequently updates its IT facilities including Wi-Fi",
      col2: (
        <a href={pdf21_22_aqar21_22} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.4.2 Time Table",
      col2: (
        <a href={pdf21_22_timeTable} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.1.1 list of students benefited by scholarship and freeships provided by government.",
      col2: (
        <a href={pdf21_22_5_1_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.1.3 Capacity Enhancement and Skill Enhancement",
      col2: (
        <a href={pdf21_22_5_1_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.1.4 Number of students benefited by guidance for competitive examinations and career counselling offered by the institution during the year 2021-22",
      col2: (
        <a href={pdf21_22_5_1_4} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.3.2 Institution facilitates student`s representation and engagement in various administrative , co-curricular and extracurricular activities",
      col2: (
        <a href={pdf21_22_5_3_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.4.1 Alumni Association",
      col2: (
        <a href={pdf21_22_SupportiveDocument} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.1.1 The governance of the institution is reflective of and in tune with the vision and mission of the institution",
      col2: (
        <a href={pdf21_22_6_1_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.1.2 The effective leadership is visible in various institutional practices such as decentralization and participative management",
      col2: (
        <a href={pdf21_22_6_1_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.2.1 The institutional Strategic/ perspective plan is effectively deployed",
      col2: (
        <a href={pdf21_22_6_2_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.2.2 The functioning of the institutional bodies is effective and efficient as visible from policies, administrative setup, appointment and service rules, procedures, etc",
      col2: (
        <a href={pdf21_22_6_2_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.2.3. Implementation of e-governance in areas of operation",
      col2: (
        <a href={pdf21_22_6_2_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.3.1 The institution has effective welfare measures for teaching and non- teaching staff provide the list of existing welfare measures for teaching and non- teaching staff",
      col2: (
        <a href={pdf21_22_6_3_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.3.5 Institutions Performance Appraisal System for teaching and non- teaching staff",
      col2: (
        <a href={pdf21_22_6_3_5} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.5.1 Internal Quality Assurance Cell (IQAC) has contributed significantly for institutionalizing the quality assurance strategies and processes",
      col2: (
        <a href={pdf21_22_6_5_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.5.2 The institution reviews its teaching learning process, structures & methodologies of operations and learning outcomes at periodic intervals through",
      col2: (
        <a href={pdf21_22_6_5_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.5.3 Quality assurance initiatives of the institution include",
      col2: (
        <a href={pdf21_22_6_5_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.1 Measures initiated by the Institution for the promotion of gender equity during the year.",
      col2: (
        <a href={pdf21_22_7_1_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.9 - Sensitization of students and employees of the Institution to the constitutional obligations: values, rights, duties and responsibilities of citizens",
      col2: (
        <a href={pdf21_22_7_1_9} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.10 - The Institution has a prescribed code of conduct for students, teachers, administrators and other staff and conducts periodic programmes in this regard. The Code of Conduct is displayed on the website There is a committee to monitor adherence to the Code of Conduct Institution organizes professional ethics programmes for students, teachers, administrators and other staff 4. Annual awareness programmes on Code of Conduct are organized",
      col2: (
        <a href={pdf21_22_7_1_10} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.2.1 Best Practices Successfully Implemented by the Institution",
      col2: (
        <a href={pdf21_22_7_2_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "AQAR 2021-2022",
      col2: (
        <a href={pdf_AQAR2021_22} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
  ];
  return (
    <>
      <div>
        <Paper elevation={1} className="mb-1" key={0}>
          <TableComponent3_colm
            tableHeading={tableHeading}
            tableData={tableData}
          />
        </Paper>
      </div>
    </>
  );
}

export function AQAR_20_21() {
  const tableHeading = ["Metric No.", "Description", "Documents"];
  const tableData = [
    {
      col1: "1.1.1.The Institution ensures effective curriculum delivery through a well planned and documented process",
      col2: (
        <a href={pdf20_21_1_1_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "1.1.2.The Institution ensures effective curriculum delivery through a well planned and documented process",
      col2: (
        <a href={pdf20_21_1_1_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "1.1.4 Feedback report 20-21",
      col2: (
        <a href={pdf20_21_1_1_4} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.2 Number of seats earmarked for reserved category as per GOI State Govt. rule during the year",
      col2: (
        <a href={pdf20_21_2_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.5.1 Criteria for Concurrent Evaluation",
      col2: (
        <a href={pdf20_21_2_5_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "2.6.3 additional information result analysis",
      col2: (
        <a href={pdf20_21_2_6_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.2.1 Ecosystem for Innovation 2020-21",
      col2: (
        <a href={pdf20_21_3_2_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.2.2 Workshops semiars conducted on research methodology IPR Entrepreneurship 2020-21",
      col2: (
        <a href={pdf20_21_3_2_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.4.1 Extension Activities 2020-21",
      col2: (
        <a href={pdf20_21_3_4_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "3.5.1 Collaborative activities for research faculty exchange student exchange intership 2020-21",
      col2: (
        <a href={pdf20_21_3_5_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.1.1 LINKS -- CLASSROOM PHOTOS , SEMINAR HALL",
      col2: (
        <Link to={"/ClassroomImages"} target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.1.2 LINKS -- CULTURAL PROGRAMS , SPORTS GROUND , YOGA",
      col2: (
        <Link to={"/CulturalProgram"} target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.2.1 Library is automated using Integrated Library Management",
      col2: (
        <a href={pdf20_21_4_2_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "4.3.1 LINKS -- COMPUTER LABS",
      col2: (
        <Link to={"/ComputerLab"} target="_blank">
          <PictureAsPdfIcon />
        </Link>
      ),
    },
    {
      col1: "4.4.2 Time table",
      col2: (
        <a href={pdf20_21_timeTable} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "5.3.2 Institution facilitates student`s representation and engagement in various administrative, co-curricular and extracurricular activities",
      col2: (
        <a href={pdf20_21_5_3_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.2.3 Implementation of e-governance in areas of operation",
      col2: (
        <a href={pdf20_21_6_2_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.5.2 The institution reviews its teaching learning process, structures & methodologies of operations and learning outcomes at periodic interval",
      col2: (
        <a href={pdf20_21_6_5_2} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "6.5.3 Quality assurance initiatives of the institution",
      col2: (
        <a href={pdf20_21_6_5_3} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.1 Specfic facilities provided for women in terms of Safety & Security & Common Rooms Safety & Security for women (Gender Sensitization)",
      col2: (
        <a href={pdf20_21_7_1_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.8 Institution’s efforts in providing an inclusive environment i.e. tolerance and harmony towards cultural, regional, linguistic, communal, socio-economic and other diversities.",
      col2: (
        <a href={pdf20_21_7_1_8} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.1.9 - Sensitization of students and employees of the Institution to the constitutional obligations: values, rights, duties and responsibilities of citizens",
      col2: (
        <a href={pdf20_21_7_1_9} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },

    {
      col1: "7.1.10 - The Institution has a prescribed code of conduct for students, teachers, administrators and other staff and conducts periodic programmes in this regard. The Code of Conduct is displayed on the website There is a committee to monitor adherence to the Code of Conduct Institution organizes professional ethics programmes for students, teachers, administrators and other staff 4. Annual awareness programmes on Code of Conduct are organized.",
      col2: (
        <a href={pdf20_21_7_1_10} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "7.2.1 Best Practices Successfully Implemented By the Institution.",
      col2: (
        <a href={pdf20_21_7_2_1} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
    {
      col1: "AQAR 2020 - 2021",
      col2: (
        <a href={pdf_AQAR2020_21} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
  ];

  return (
    <>
      <div>
        <Paper elevation={1} className="mb-1" key={1}>
          <TableComponent3_colm
            tableHeading={tableHeading}
            tableData={tableData}
          />
        </Paper>
      </div>
    </>
  );
}

export function AQAR_19_20() {
  const tableHeading = ["Metric No.", "Description", "Documents"];
  const tableData = [
    {
      col1: (
        <a href={pdf_19_20} target="_blank" rel="noreferrer">
          AQAR 2019 -2020
        </a>
      ),
      col2: (
        <a href={pdf_19_20} target="_blank" rel="noreferrer">
          <PictureAsPdfIcon />
        </a>
      ),
    },
  ];
  return (
    <>
      <div>
        <Paper elevation={1} className="mb-1">
          <TableComponent3_colm
            tableHeading={tableHeading}
            tableData={tableData}
          />
        </Paper>
      </div>
    </>
  );
}

// ---------------------4.1.1 data of AQAR_21_22 --------------------

export function ClassroomPhotos() {
  window.scrollTo(0, 180);

  const Images = [
    classroom1,
    classroom2,
    classroom3,
    classroom4,
    classroom5,
    classroom6,
    classroom7,
    classroom8,
    classroom9,
    classroom10,
    classroom11,
    classroom12,
  ];
  return (
    <>
      <div className="py-0 backgroundImg">
        <div className="py-5">
          <GalleryComponent images={Images} />
        </div>
      </div>
    </>
  );
}

export function CulturalProgram() {
  window.scrollTo(0, 180);

  const Images = [cultural1, cultural2, cultural3, cultural4, cultural5];
  return (
    <>
      <div className="py-0 backgroundImg">
        <div className="py-5">
          <GalleryComponent images={Images} />
        </div>
      </div>
    </>
  );
}

// ---------------------4.1.1 data of AQAR_21_22 --------------------
// ---------------------4.1.3 data of AQAR_21_22 --------------------

export function ComputerLab() {
  window.scrollTo(0, 180);

  const Images = [lab1, lab2, lab3];
  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={"ComputerLab"} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={"ComputerLab"} />
      </div>
      <div className="py-0 backgroundImg">
        <div className="py-5">
          <GalleryComponent images={Images} />
        </div>
      </div>
    </>
  );
}

// ---------------------4.1.3 data of AQAR_21_22 --------------------
