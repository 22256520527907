import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// const Breadcrumbs = ({ tabData, selectedTabIndex }) => {
//   const selectedTab = tabData[selectedTabIndex];
//   return <div>{selectedTab.label}</div>;
// };

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

const TabComponent = ({ tabData, Breadcrumbs, tabName }) => {
  let id = 0;
  tabName === "AQAR-2019-20"
    ? (id = 0)
    : tabName === "AQAR-2020-21"
    ? (id = 1)
    : tabName === "AQAR-2021-22"
    ? (id = 2)
    : tabName === "AQAR-2022-23"
    ? (id = 3)
    : tabName === "AQAR-2023-24"
    ? (id = 4)
    : tabName === "About IQAC"
    ? (id = 0)
    : tabName === "Accreditation Certificate"
    ? (id = 1)
    : tabName === "Extension Activities Rejoice"
    ? (id = 0)
    : tabName === "Collaborations"
    ? (id = 1)
    : tabName === "Management Fest"
    ? (id = 2)
    : tabName === "Student Cultural & Sport Activities"
    ? (id = 3)
    : tabName === "Alumni-Meet-2018"
    ? (id = 0)
    : tabName === "Alumni-Meet-2017"
    ? (id = 1)
    : tabName === "Alumni-Meet-2016"
    ? (id = 2)
    : tabName === "MBA"
    ? (id = 0)
    : tabName === "Ph.D"
    ? (id = 1)
    : tabName === "About MIBM Library"
    ? (id = 0)
    : tabName === "Library Rules"
    ? (id = 1)
    : tabName === "Library Resources"
    ? (id = 2)
    : tabName === "Associate Memberships"
    ? (id = 3)
    : tabName === "Library Committee"
    ? (id = 4)
    : tabName === "E-Database"
    ? (id = 5)
    : tabName === "Downloads"
    ? (id = 6)
    : tabName === "BBA Fees"
    ? (id = 0)
    : tabName === "MBA Fees"
    ? (id = 1)
    : tabName === "Ph.D Fees"
    ? (id = 2)
    : (id = 0);

  const [value, setValue] = useState(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* <Breadcrumb tabData={tabData} selectedTabIndex={value} /> */}
      <Box
        sx={{ borderBottom: 0, borderColor: "divider", paddingBottom: "10px" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ backgroundColor: "ButtonFace", padding: 0 }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
          textColor="inherit"
          indicatorColor="primary"
        >
          {tabData.map((tab, index) => (
            <Tab className="mx-auto" key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {tabData.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default TabComponent;
