import FDP_2020_21_1 from "../assets/images/FDP/2020_21/fdp_2020_21_1.jpeg";
import FDP_2020_21_2 from "../assets/images/FDP/2020_21/fdp_2020_21_2.jpeg";
import FDP_2020_21_3 from "../assets/images/FDP/2020_21/fdp_2020_21_3.jpeg";
import FDP_2020_21_4 from "../assets/images/FDP/2020_21/fdp_2020_21_4.jpeg";
import FDP_2020_21_5 from "../assets/images/FDP/2020_21/fdp_2020_21_5.jpeg";

import session_1 from "../assets/images/FDP/2021_22/session_1.jpeg";
import session_2 from "../assets/images/FDP/2021_22/session_2.jpeg";
import session_3 from "../assets/images/FDP/2021_22/session_3.jpeg";

// -----------------------------reserach center table data-------------------------------------------

export const FDP_2020_21 = {
  cardTitle:
    "Faculty and Student Development Program on Monday, 6th July 2020, 1pm onwards",
  cardImages: [
    FDP_2020_21_1,
    FDP_2020_21_2,
    FDP_2020_21_3,
    FDP_2020_21_4,
    FDP_2020_21_5,
  ],
  cardDescription: [
    "P. E. Society's Modern Institute of Business Management organized one day Online Faculty and Student Development Program on 'Unlocking the Power of Happiness and Well-being' on 6th July 2020 through Google Meet and Facebook live.",
    "The program received overwhelming response with more than 400 registrations which comprised of 60% faculties 40% students and executive and employees from corporates. The participants were from various renowned institutions from Maharashtra and from across of India.",
    "Eminent experts from various fields were invited to share their insights and discuss with the participants about the concept of Happiness and Well-being. In this program various speakers covered important practical aspects like Happiness Index, Emotional Well-being, and Positivity in life.",
    "The program began with the chief guest address by Prof. Dr. Gajanan Ekbote, Sir, Chairman, Progressive Education society addressing the audience on importance of physical health and mental health. Sir told the participants on how to maintain good health during the pandemic and gave practical insights.",
    "The first speaker Ms. Vibha Deshpande, Founder, Director – Tailwinds Works, spoke on a state of Happiness and Well-being. She covered important aspects of human well-being and happiness related aspects in detail.",
    "The second speaker Ms. Sheetal Pungaliya, Corporate Trainer, Youth Mentor, Asset Management Consultant, Art of Living Faculty spoke on Emotional Well-being. She covered important aspects related to physical, mental health and happiness.",
    "Participants questions were also shared with the speakers and both speakers addressed the questions very well.",
    "The valedictory function was addressed by Prof. Shamkant Deshmukh, Sir, Secretary, Progressive Education Society. Sir guided on the important of fit body and mind and shared his thoughts with the participants.",
    "All the participants were issued electronic certificates after receiving the online feedback.",
  ],
};

export const FDP_2021_22 = {
  cardTitle:
    "Faculty Development Programme On “Gateway To Academic Excellence” 16th March 2022",
  cardImages: [session_1, session_2, session_3],
  cardDescription: [
    "The Faculty Development Programme (FDP) intends to provide assistance to facilitate up-gradation of knowledge, skill and intends to provide opportunities for induction training to teachers employed in disciplines like Management, Engineering & Technology, Pharmacy, Hotel Management & Catering Technology, Architecture, Town Planning and Applied Arts & Crafts.",
    "The FDP was organized against this backdrop to provide a much-needed opportunity to the faculty to familiarize and equip themselves with various technological tools and platforms for efficient conduct of virtual learning, explore the usage of online courses for learning, teaching and professional development and also build their capability to plan, design and implement online courses that are in tune with international standards.",
    "Inaugural of the course: The FDP started with an inaugural session on the 16th March,2022 in the august presence of Dr. Vijayalakshmi Srinivas(Director- Progressive Education Society’s Modern Institute of Business Management) and Dr. Nivedita Ekbote(Coordinator- MIBM). Session began with Dr. Harsha Thorve welcoming all the faculty members present on the day for the programmeand also discussed the rationale of the FDP and its key components.",
    "Dr. Vijayalakshmi Srinivas addressed the faculty with her words of wisdom and expressed that teachers being the role models and warriors, have the ability and responsibility to change themselves to changing technology landscape. She added that she is fully confident that teachers are always ready to and would successfully cope up with new challenges for the benefit of students.",
    "1st Guest: Dr. Santosh Bhave- Director HR& IR at Bharat Forge Ltd.",
    "This session was focused on Case Study Methodology-a strong accommodation in P.G. The ppt explained and demonstrated was on the logics, foundation and judgements, he explained that how all these are interlinked with eachother stating; logics are connected-foundation is similar hence judgements are different. Case study is a learning technique which faculty member is expected to use while teaching in class. Organizational behaviour plays a very important role in the firms. Value addition comes when one has power in the particular technique based syllabus. His second focus was on the Power & Politicswhere he addressed that students should know the intriquisite of the syllabus. Power and politicsbehavior are natural processes in any groups/organizations. “Power corrupts and absolute power corrupts absolutely”. Stages- Conceptual(top) Managerial(middle) Operational(bottom). He stated the bases of power into 2 groups as (1) Formal power & (2) Coercive Power which were classified further. Here research suggests that personal sources of power are most effective. Formal power and Coercive Power can actually backfire in that it is negatively related to employee satisfaction and commitment. Concept of Made In India to Make In India was briefly discussed. What are the tactics used here- Powertactics is a way in which individuals translate power bases into specific action. Research says that the rational persuasion helps in reaching to the correct decision. At the end of the session 2 case-studies were discussed with the faculty members where question and answers were exchanged.",
    "2nd Session: Dr. Archana Singh-",
    "This session was focused on Research Publication- In high Indexed Journals. The session was started by explaining the 3 components of research cycle- (1) Identify the RESEARCH PROBLEMà(2) Work on the ACTUAL RESEARCHà(3) PUBLICATIONS. She explained few terms regarding the research which were like while identifying the research problem one can identify the gap from the existing literature, review of the literature should be around last one decade only, measure quality in terms of fee. The problem selected should be remarkable, practical, feasable, researchable and should have originality to it. The format of writing journals/paperwas discussed in the session like the index citation should be APA 7th edition, should start from the introduction(3/4 paragraphs max).The introduction part can include background- national, global scenario, significance of study, research problem, gap and objectives.Gap- can even be a questionnairein the paper. References taken for the research should be of papers from top journals. The abstract part should include the purpose of the research, methodology used, findings taken from, and must have the originality value to the research.Discussion is also provided for the participants to ask freely about their queries and doubts about the session and assignments.",
    "3rd Session: Dr. Roshan Kazi-(I/C)Director of Allana Institute of Management Sciences(AIMS)",
    "The session was about the Simple and Easy Tools for Data Analysis for Research. He started the session by acknowledging everyone present in the programme. He explained the concepts of offering new knowledge, proving your knowledge and journalizing your finance which plays a very important role in the research. The term validity was explained as the extent to which one is able to prove. EPISTEMOLOGY-the theory of knowledge, especially with regard to its methods, validity, and scope, and the distinction between justified belief and opinion; here external: it means where one is able to generalize, internal: what extent one can generalize their research. In research terms it is divided into 2 portions- POSITIVISM(advocates evidence, based on numbers) CONSTRUCTIVISM(numbers are not enough here, qualitative research is included, used in indirect and structure way). He also explained the concept of Regression which is the study on productivity. In regression Training increases equally when Productivity increases throughout the income. Y cap equations were formulated with the helpof graphs and applied on the research questions. He also discussed the Hierarchical Analysis/Regression as the sample should be the replica of the population, one must use the random sampling procedure, a single sample gives the approximate value of standard errors, standard error is the standard deviation of sample error. At the end of the session one-on-one discussion was placed with the speaker and the faculty members.",
    "The session ended with a Vote of Thanks by Dr.Harsha Thorve and with a hope that participants will use the skills and tools learnt and engage in programme enabled teaching learning in their day-to-day teaching activity and make the process dynamic and student-centric.",
  ],
};
