import * as React from 'react';
import InfoCard from '../Components/ReusableComponent/InfoCard';
import IconCard from '../Components/ReusableComponent/CardwithDescription';

import img1 from '../assets/images/AboutUs/VijayalakshmiShrinivas.png';
import img2 from '../assets/images/AboutUs/NiveditaEkbote.png'
import CssBaseline from '@mui/material/CssBaseline';
import vision from '../assets/images/AboutUs/vision.png';
import mission from '../assets/images/AboutUs/mission.png'
import SpeOffered from '../assets/images/AboutUs/offered.png'
import policyMIBM from '../assets/images/AboutUs/policy.png'


import Kanitkar from '../assets/images/PESociety/Guruvarya Shankarrao Kanitkar.jpg'
import GajananREkbote from '../assets/images/PESociety/GajananREkbote.png'


import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function AboutUsMIBM() {

  return (
    <div className="text-justify mt-5">
      <div>
        <InfoCard
          imageSrc={img1}
          title="Dr. Vijayalakshmi Shrinivas"
          info="P.E. Society Modern Institute of Business Management (MIBM) was established on 27th September 2010 under the able guidance of our Hon. Chairman Prof Dr Gajanan R. Ekbote. Our vision is “To impart quality based education to be self reliant and self sustaining person through inventive systematic education. Its strength lies in the integration of different paths, design thinking, experiencing the traditional teaching also engaging in real time corporate challenges”"
          info2="MIBM nurtures its students to develop a holistic approach, a curious mindset and the ability to think differently and innovatively to harness the opportunities emerging from challenges. In the recent year, MIBM has taken several steps in its pursuit of excellence. Some of the specific steps taken during the last one year : Recruitment of new faculty, Industry Institute Interface."
          />
        <CssBaseline />
        <InfoCard
          imageSrc={img2}
          title="Prof. Dr. Nivedita G Ekbote"
          head='Warm Greetings and Hearty Welcome to MIBM Website!!!'
          info="
      India has now been able to occupy the centre stage in the world’s economic scenario. Businesses are becoming global and fast-paced changes are taking place at the technological front, hence businesses are becoming more volatile in nature. The role of the manager, now-a-days, is getting complicated and they are required to have with innovative skills and greater knowledge base."
          info2="
      Progressive Education Society’s Modern Institute of Business Management is committed to provide the necessary skill sets to bridge the supply and demand gap of professionally trained managers. MIBM believes in providing high-standard teaching learning process."
        />
      </div>
    </div>
  );
}

export function  PESocietyCard() {
  return (
      <div className="text-justify"> 
        <h4 style={{fontWeight:'bolder'}}>Leadership</h4>
      <InfoCard
        imageSrc={Kanitkar}
        title="Guruvarya Shankarrao Kanitkar"
        info="Late Shri Shankarrao Kanitkar founded the Progressiv Education Society in 1934 with a great foresight. He was the fountain head of inspiration to members who joined hands with him in implementing the idea of starting a new era of education system in Pune.
        He was a strict disciplinarian, and an exemplary teacher.He was forthright, straightforward and a perfect educationist to the core.
        He was the man behind the great edifice of today’s Progressive Education Society’s glorious achievements."
      />
      <CssBaseline />
      <InfoCard
        imageSrc={GajananREkbote}
        title="Dr Gajanan R. Ekbote"
        info="Technology is advancing day by day. New institutions are launched every year in and around Pune City. Every institute is trying its best to give the best possible facilities to the students. However it is our commitment to the students and parents that Modern Institute of Business Management will definitely honor two words which are important to the management of “Progressive” and “Modern”.
        The future probably belongs to people who dare to dream big. It belongs to those who make way through the clouds of oblivion with the force of their determination.
        Modern Institute of Business Management is recently started in 2010. The Fee Structure of MIBM is well within the reach of poor and middle class students."
      />
    </div>
    );
}

export function Vision() {

  return (
    <>
      <Grid container spacing={2} className='mt-5'>
        <Grid item xs={12} md={6}>
          <div className="text-justify">
            <div>
              <IconCard
                imageSrc={vision}
                title="Vision"
                info="We aspire to be recognized as a leading management institution dedicated to serving the world of business through professional, technological, social and intellectual contributions made by research oriented and committed teaching fraternity."
              />
              <CssBaseline />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text-justify">
            <div>
              <IconCard
                imageSrc={mission}
                title="Mission"
                info="To excel in the world of knowledge by generating dynamic and ethical business leaders with the ability to think globally, act rationally and face challenges and emerge victorious in the changing business environment. To create “Progressive”, “Modern” and responsible professionals accountable to the society globally."
              />
              <CssBaseline />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text-justify">
            <div>
              <IconCard
                imageSrc={policyMIBM}
                title="Quality Policy of MIBM"
                info="To strive to impart quality education and skills through innovative learning methodologies for holistic development of responsible professionals, making them accountable globally."
              />
              <CssBaseline />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text-justify">
            <div>
              <IconCard
                imageSrc={SpeOffered}
                title="Specializations offered"
                list1='1.Marketing Management (MKT)'
                list2='2.Financial Management (FIN)'
                list3='3.Human Resources Management (HRM)'
                list4='4.Operations & Supply Chain Management (OSCM)'
                list5='5.Business Analytics (BA)'
              />
              <CssBaseline />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}


