import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import management from '../assets/images/Gallery/management-fest.jpg';
import conference from '../assets/images/Gallery/conference.jpg';
import cultural from '../assets/images/Gallery/cultural-program.jpg';
import industrialVisit from '../assets/images/Gallery/industrialVisit.jpg';
import placement from '../assets/images/Gallery/placement.jpg';
import social from '../assets/images/Gallery/social-work.jpg';
import sports from '../assets/images/Gallery/sports.jpg';
import infrastructure from '../assets/images/Gallery/infrastructureGallery.jpg';
import GalleryCard from '../Components/ReusableComponent/GalleryCard';


const Info =[
    {
        title: 'Management Fest',
        image: [management],
        link: <Link to='/students/Gallery/managementFest' className='CardLink'>VIEW ALL</Link>
    },
    {
        title: 'Conference',
        image: [conference],
        link: <Link to='/students/Gallery/conference' className='CardLink'>VIEW ALL</Link>
    },
    {
        title: 'Industrial Visit',
        image: [industrialVisit],
        link: <Link to='/students/Gallery/industrialVisit' className='CardLink'>VIEW ALL</Link>
    },
    {
        title: 'Cultural Program',
        image: [cultural],
        link: <Link to='/students/Gallery/cultural' className='CardLink'>VIEW ALL</Link>
    },
    {
        title: 'Sports',
        image: [sports],
        link: <Link to='/students/Gallery/sports' className='CardLink'>VIEW ALL</Link>
    },
    {
        title: 'Social Work',
        image: [social],
        link: <Link to='/students/Gallery/socialwork' className='CardLink'>VIEW ALL</Link>
    },
    {
        title: 'Training And Placement',
        image: [placement],
        link: <Link to='/students/Gallery/trainingPlacement' className='CardLink'>VIEW ALL</Link>
    },
    {
        title: 'Infrastructure',
        image: [infrastructure],
        link: <Link to='/students/Infrastructure' className='CardLink'>VIEW ALL</Link>
    },
]

export default function GalleryMIBM_Page() {
    return (
        <>
           <h5 className='title-text text-bold text-center my-3 fs-4 mb-3'>GALLERY</h5>

            <Box>
                <Grid container>
            {
                Info.map((items, index)=>{
                    return(
                        <Grid item sm={4} className='mx-auto'>
                            <GalleryCard data={items} key={index}/>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Box>
        </>
    );
}

