import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const TableComponent = (props) => {
    return (
        <div>
            <TableContainer component={Paper} elevation={0} >
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ borderBottom: 2, borderTop: 2 }}>  
                            {
                                props.tableHeading.map((item, index) => {
                                return <TableCell align='left' className='mx-auto' key={index}>{item}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tableData && props.tableData.map((item, index) => (
                            <TableRow key={index} hover>
                                <TableCell className='mx-auto' align="left">{index+1}</TableCell>
                                <TableCell className='mx-auto' align="left">{item.col1}</TableCell>
                                <TableCell className='mx-auto' align="left">{item.col2}</TableCell>
                                <TableCell className='mx-auto' align="left">{item.col3}</TableCell>
                                <TableCell className='mx-auto' align="left">{item.col4}</TableCell>
                                <TableCell className='mx-auto' align="left">{item.col5}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default TableComponent

// for table height maximum 350
export function ScrollableTable(props) {
    return (
        <div>
        <TableContainer component={Paper} className='mb-5' sx={{ maxHeight: 350 }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table sticky" stickyHeader>
            <TableHead>
              <TableRow>
                 {
                     props.tableHeading.map((item, index) => {
                     return <TableCell align='left' key={index}>{item}</TableCell>
                     })
                 }
              </TableRow>
            </TableHead>
            <TableBody>
                        {props.tableData && props.tableData.map((item, index) => (
                            <TableRow key={index} hover>
                                <TableCell align="left">{index+1}</TableCell>
                                <TableCell align="left">{item.col1}</TableCell>
                                <TableCell align="left">{item.col2}</TableCell>
                                <TableCell align="left">{item.col3}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
          </Table>
        </TableContainer>

        </div>
    )
}


// for table height maximum 250
export function ScrollableTableMaxHeight_250(props) {
    return (
        <div>
        <TableContainer component={Paper} className='mb-5' sx={{ maxHeight: 250 }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table sticky" stickyHeader>
            <TableHead>
              <TableRow>
                 {
                     props.tableHeading.map((item, index) => {
                     return <TableCell align='left' key={index}>{item}</TableCell>
                     })
                 }
              </TableRow>
            </TableHead>
            <TableBody>
                        {props.tableData && props.tableData.map((item, index) => (
                            <TableRow key={index} hover>
                                <TableCell align="left">{index+1}</TableCell>
                                <TableCell align="left">{item.col1}</TableCell>
                                <TableCell align="left">{item.col2}</TableCell>
                                <TableCell align="left">{item.col3}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
          </Table>
        </TableContainer>

        </div>
    )
}

export function TableComponent3_colm(props) {
    return (
        <div>
            <TableContainer component={Paper} elevation={0} >
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ borderBottom: 2, borderTop: 2 }}>  
                            {
                                props.tableHeading.map((item, index) => {
                                return <TableCell align='left' className='mx-auto' key={index}>{item}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tableData && props.tableData.map((item, index) => (
                            <TableRow key={index} hover>
                                <TableCell className='mx-auto' align="left">{index+1}</TableCell>
                                <TableCell className='mx-auto' align="left">{item.col1}</TableCell>
                                <TableCell className='mx-auto' align="center">{item.col2}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export function TableComponent2_colm(props) {
    return (
        <div>
            <TableContainer component={Paper} elevation={0} >
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ borderBottom: 2, borderTop: 2 }}>  
                            {
                                props.tableHeading.map((item, index) => {
                                return <TableCell align='left' className='mx-auto' key={index}>{item}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tableData && props.tableData.map((item, index) => (
                            <TableRow key={index} hover>
                                <TableCell className='mx-auto' align="left">{index+1}</TableCell>
                                <TableCell className='mx-auto' align="left">{item.col1}</TableCell>
                                <TableCell className='mx-auto' align="center">{item.col2}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}