import React from 'react'
import { UpcomingData } from '../../Data/UpcomingEventsData';
import { Grid, ImageList, ImageListItem, Paper } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Blink from 'react-blink-text';
import imgH from '../../assets/images/Web1920.jpg'
import EventIcon from '@mui/icons-material/Event';
import './ViewMore.css'
import Breadcrumb from '../../Components/Breadcrumb';
import { UpcomingIcon } from '../../Components/SvgIcons';

const data = UpcomingData;

const UpcomingEventPage = () => {
  window.scrollTo(0, 270);
  return (
    <>
    <div className="bredDivMob">
      <Breadcrumb pageTitle={<h3 className='fs-3 mt-0 text-bold decorated text-light'>Upcoming Event</h3>} />
      </div>
      <div className="bredDivDesk">
      <Breadcrumb pageTitle={<h3 className='fs-3 mt-0 text-bold decorated text-light'>Upcoming Event</h3>} />
      </div>
      <div className='bg-light py-3'>
      <Paper elevation={0} className='my-5 mx-5 bg-light'>
      {data.map((items, index) => (
        <div className="upcoming-texts" key={index}>
        <div className="home-card-content">
          <Grid container>
             <Grid xs={1.5} md={0.5}>
             <div className='upcomingDate' >
               <EventIcon /> {items.date}
             </div>
             </Grid>
             <Grid xs={10.5} md={11.5}>
             <h5 className="home-card-title g-0 fs-5"> {items.title}</h5>
          <div className="home-card-body fs-6 mt-3 mx-lg-3" >{items.description}</div> {/* Render news body */}
          <div className="home-card-body fs-6 mt-3 mx-lg-3" >{items.description2}</div> {/* Render news body */}

          <div>
          <div  className='fs-5 g-0'>
           { 
           items.Doc.pdf.length !== 0 &&
           <a href={items.Doc.pdf} target='_blank' className='fs-6 g-3 mt-2 mx-lg-3'> <PictureAsPdfIcon className='pdficon'/>   view more...</a>}
          </div>
           <ImageList sx={{ width: 500}}cols={2} gap={10} className='mt-3 mb-2 mx-lg-4'>

                   {items.Doc.image.map((image, imageIndex) => (
                  <ImageListItem  key={imageIndex} >
                  <img className='rounded'
                  src={image}
                  alt={`Image ${imageIndex + 1}`}
                  />
                  </ImageListItem>
                 ))}
          </ImageList>
          </div>
          
          <div className="Application home-card-Footer mt-3 mx-1">
            <Blink color="gray" text={items.updatedDate} fontSize={15}></Blink>
          </div>
             </Grid>

          </Grid>
         
        </div>
      </div>
      ))}
    </Paper>
    </div>
    </>
  )
}

export default UpcomingEventPage;
