import React, { useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

export function Images({ images }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <div key={images}>
      <Grid container spacing={1} className="justify-content-center">
        {images.map((image, index) => (
          <Grid item container xs={6} md={4} key={index}>
            <img
              src={image}
              width={250}
              height={200}
              className="MobViewImgSize All-Images"
              key={index}
              onClick={() => openImageViewer(index)}
              alt={`Image ${index}`}
            />
          </Grid>
        ))}
      </Grid>
      {isViewerOpen && (
        <ImageViewer
          key={images}
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
}

export function Paragraphs({ description }) {
  return (
    <div>
      {description.map((text, index) => (
        <Typography key={index} variant="body1" fontWeight={500} description>
          {text}
        </Typography>
      ))}
    </div>
  );
}
