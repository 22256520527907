export const menuList = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "#",
    submenu: [
      {
        title: "AboutUS",
        url: "aboutUs",
      },
      {
        title: `P. E. Society`,
        url: "aboutUs/PESociety",
      },
      {
        title: `Core-Values`,
        url: "aboutUs/CoreValues",
      },
      {
        title: `Chairman's Desk`,
        url: "aboutUs/ChairmansDesk",
      },
      {
        title: `Director's Desk`,
        url: "aboutUs/DirectorsDesk",
      },
      {
        title: `Coordinator's Desk`,
        url: "aboutUs/CoordinatorsDesk",
      },

      {
        title: "Governance",
        url: "aboutUs/Governance",
      },
      {
        title: "Mandatory Disclosure",
        url: "aboutUs/MandatoryDisclosure",
      },
      {
        title: `EOA AICTE`,
        url: "/aboutUs/EOA-AICTE",
      },
    ],
  },

  {
    title: "Admission",
    url: "#",
    submenu: [
      {
        title: "Eligibility Criteria",
        url: "admission/Eligibility",
      },
      {
        title: "Admission Procedure",
        url: "admission/AdmissionProcedure",
      },
      {
        title: "Rules & Regulation",
        url: "admission/RulesAndRegulation",
      },
      {
        title: "Documents Required",
        url: "admission/DocumentsRequired",
      },
      {
        title: "Fee Structure",
        url: "#",
        submenu: [
          {
            title: "BBA Fees",
            url: "admission/FeeStructure",
          },

          {
            title: "MBA Fees",
            url: "admission/FeeStructure",
          },

          {
            title: "Ph.D Fees",
            url: "admission/FeeStructure",
          },
        ],
      },
    ],
  },
  {
    title: "Academics",
    url: "#",
    submenu: [
      {
        title: "Courses Offered",
        url: "#",
        submenu: [
          {
            title: "MBA",
            url: "/academics/courses",
          },
          {
            title: "Ph.D",
            url: "/academics/courses",
          },
        ],
      },
      {
        title: "Faculty  & Publications",
        url: "/academics/FacultyAndPublications",
      },
      {
        title: "Academic Calendar",
        url: "/academics/AcademicCalendar",
      },
      {
        title: "Library",
        url: "#",
        submenu: [
          {
            title: "About MIBM Library",
            url: "/academics/library",
          },
          {
            title: "Library Rules",
            url: "/academics/library",
          },
          {
            title: "Library Resources",
            url: "/academics/library",
          },
          {
            title: "Associate Memberships",
            url: "/academics/library",
          },
          {
            title: "Library Committee",
            url: "/academics/library",
          },
          {
            title: "E-Database",
            url: "/academics/library",
          },
          {
            title: "Downloads",
            url: "/academics/library",
          },
        ],
      },
      {
        title: "Interactive Teaching and Learnig (ICT)",
        url: "/academics/ICT",
      },
    ],
  },
  {
    title: "Research & Innovation",

    url: "#",

    submenu: [
      {
        title: "Research Center",

        url: "research/Center",
      },

      {
        title: "Paridnya",

        url: "Research/Paridnya",
      },

      {
        title: "Research Paper Published",

        url: "Research/PaperPublished",
      },

      {
        title: "Code Of Ethics",

        url: "Research/CodeOfEthics",
      },

      {
        title: "IPR Cell",

        url: "Research/IPRCell",
      },

      {
        title: "National Conferences",

        url: "Research/NationalConferences",
      },

      {
        title: "FDP",

        url: "Research/FDP",
      },

      {
        title: "State level seminar",

        url: "Research/StateLevelSeminar",
      },

      // {

      //   title: 'III Cell',

      //   url: 'Research/IIICell'

      // },
    ],
  },
  {
    title: "Placements",
    url: "#",
    submenu: [
      {
        title: "About Training & Placement Cell",
        url: "placement/TrainingPlacementCell",
      },
      {
        title: "Summer Internship Program",
        url: "placement/SummerInternshipProgram",
      },
      {
        title: "Our Recruiters",
        url: "placement/OurRecruiters",
      },
      {
        title: "Placement Brochure",
        url: "placement/PlacementBrochure",
      },
      {
        title: "Employability Enhancement Program",
        url: "placement/EmployabilityEnhancementProgram",
      },
      {
        title: "Student Industry Academia Initiatives",
        url: "placement/StudentIndustryAcademiaInitiatives",
      },
    ],
  },
  {
    title: "Alumni",

    url: "#",

    submenu: [
      {
        title: "About Alumni Cell",

        url: "alumni/AlumniCell",
      },

      {
        title: "Alumni Achievers",

        url: "alumni/AlumniAchievers",
      },

      {
        title: "Alumni Registrations",

        url: "alumni/Registrations",
      },

      {
        title: "Alumni Downloads",

        url: "alumni/Downloads",
      },

      {
        title: "Alumni Meet",

        url: "#",

        submenu: [
          {
            title: "Alumni-Meet-2018",

            url: "alumni/AlumniMeet",
          },

          {
            title: "Alumni-Meet-2017",

            url: "alumni/AlumniMeet",
          },

          {
            title: "Alumni-Meet-2016",

            url: "alumni/AlumniMeet",
          },
        ],
      },

      {
        title: "Testimonials",

        url: "alumni/Testimonials",
      },
    ],
  },
  {
    title: "IQAC",

    url: "#",

    submenu: [
      {
        title: "NAAC",

        url: "#",

        submenu: [
          {
            title: "About IQAC",

            url: "IQAC/NAAC",
          },
          // {

          //   title:'NAAC Peer Team Visit',

          //   url:'IQAC/NAAC'

          // },

          {
            title: "Accreditation Certificate",

            url: "IQAC/NAAC",
          },
        ],
      },

      {
        title: "AQAR",

        url: "#",

        submenu: [
          {
            title: "AQAR-2019-20",

            url: "IQAC/AQAR",
          },

          {
            title: "AQAR-2020-21",

            url: "IQAC/AQAR",
          },

          {
            title: "AQAR-2021-22",

            url: "IQAC/AQAR",
          },

          {
            title: "AQAR-2022-23",

            url: "IQAC/AQAR",
          },
          {
            title: "AQAR-2023-24",

            url: "IQAC/AQAR",
          },
          {
            title: "AQAR 2023-24",

            url: "IQAC/AQAR",
          },
        ],
      },
    ],
  },
  {
    title: "Students Life @ MIBM",
    url: "#",
    submenu: [
      {
        title: "MBA Induction",
        url: "students/MbaInduction ",
      },
      {
        title: "Industrial Visit",
        url: "students/IndustrialVisit",
      },
      {
        title: "Students Participation & Events",
        url: "#",
        submenu: [
          {
            title: "Extension Activities Rejoice",
            url: "students/ParticipationEvents",
          },
          {
            title: "Collaborations",
            url: "students/ParticipationEvents",
          },
          {
            title: "Management Fest",
            url: "students/ParticipationEvents",
          },
          {
            title: "Student Cultural & Sport Activities",
            url: "students/ParticipationEvents",
          },
        ],
      },
      {
        title: "Student Newsletter",
        url: "students/StudentNewsletter",
      },
      {
        title: "Student Achievement",
        url: "students/StudentAchievement",
      },
      {
        title: "Infrastructure",
        url: "students/Infrastructure",
      },
      {
        title: "Anti-Ragging Cell",
        url: "students/AntiRaggingCell",
      },
      {
        title: "Internal Complaint Committee",
        url: "students/InternalComplaintCommittee",
      },
      {
        title: "Book Review",
        url: "students/BookReview",
      },
      {
        title: "Eco Club",
        url: "students/EcoClubActivity",
      },
      {
        title: "Trade Fair",
        url: "students/TradeFair",
      },
      {
        title: "Student Grievance Redressal Cell",
        url: "students/StudentGrievanceRedressalCell",
      },
      {
        title: "Gallery",
        url: "students/Gallery",
      },
    ],
  },
];
