import FlipCard from '../pages/FlipCard';
import Breadcrumb from "../Components/Breadcrumb";
import { Paper } from "@mui/material";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Contact() {
  window.scrollTo(0, 420);
  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={'CONTACT US'} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={'CONTACT US'} />
      </div>
    <div className='backgroundImg py-3'>
      <Paper elevation={3} className="p-3 text-justify mx-5 my-5">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid xs={12} md={6} className='mt-3'>
              <Item elevation={1} className='mx-3'>   <section>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.0820167783218!2d73.84635901489916!3d18.52519547386773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07fa2d23fa3%3A0x16d5964cf3a67033!2sModern%20Institute%20Of%20Business%20Management!5e0!3m2!1sen!2sin!4v1642161476372!5m2!1sen!2sin" height={390} style={{ border: 0, maxWidth: '530px' }} allowfullscreen="" loading="lazy" className="w-100"></iframe>
              </section></Item>
            </Grid>
            <Grid xs={12} md={6}>
              <>
                <FlipCard />
              </>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      </div>
    </>

  )
}
