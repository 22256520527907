import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// ------------Alumni 16 --------------------
import img1 from "../assets/images/Alumni/AlumniMeet/Meet2016/AlumniMeet1.jpg";
import img2 from "../assets/images/Alumni/AlumniMeet/Meet2016/AlumniMeet2.jpg";
import img3 from "../assets/images/Alumni/AlumniMeet/Meet2016/AlumniMeet3.jpg";
// ------------Alumni 16 --------------------

// ------------Alumni 17 --------------------
import img4 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni1.jpg";
import img5 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni2.jpg";
import img6 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni3.jpg";
import img7 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni4.jpg";
import img8 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni5.jpg";
import img9 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni6.jpg";
import img10 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni7.jpg";
import img11 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni8.jpg";
import img12 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni9.jpg";
import img13 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni10.jpg";
import img14 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni11.jpg";
import img15 from "../assets/images/Alumni/AlumniMeet/Meet2017/Alumni12.jpg";
// ------------Alumni 17 --------------------

// ------------Alumni 18 --------------------
import img16 from "../assets/images/Alumni/AlumniMeet/Meet2018/Alumni1.jpg";
import img17 from "../assets/images/Alumni/AlumniMeet/Meet2018/Alumni2.jpg";
import img18 from "../assets/images/Alumni/AlumniMeet/Meet2018/Alumni3.jpg";
import img19 from "../assets/images/Alumni/AlumniMeet/Meet2018/Alumni4.jpg";
import img20 from "../assets/images/Alumni/AlumniMeet/Meet2018/Alumni5.jpg";
import img21 from "../assets/images/Alumni/AlumniMeet/Meet2018/Alumni6.jpg";
import img22 from "../assets/images/Alumni/AlumniMeet/Meet2018/Alumni7.jpg";
// ------------Alumni 18 --------------------
import ImageCarouselComponent from "../Components/ReusableComponent/CarouselComponent";
import { Paper } from "@mui/material";

//----------------------------------------------------------------

//Achievers Data
export const Data = [
  {
    quote:
      "Glad to inform that I got placed in Wantstats Research and Media Pvt. Ltd., by MIBM’s Placement Cell and currently working as a Research Associate. It’s been awesome experience doing MBA at MIBM, met new friends, teachers who taught what life would be after MBA and guided us in a proper way, and cherished many memories with everyone. MIBM had given us right platform time to time to participate in various circular and extracurricular activities which developed confidence among us. Institute had taken nice initiative to enhance our employability by arranging certification programs. I would urge all the students to grab all the opportunities to learn, given by the institute.",
    author: " Mayur Thorat, MBA Marketing Batch 2016-18",
  },
  {
    quote:
      "I took admission at MIBM with the objective of getting best learning experience and a placement opportunity. And today I can proudly say that both the objectives got fulfilled. MIBM has provided me immense knowledge of market and thereby I could learn various management fundamentals. I have been placed at DACHSER India Pvt. Ltd. in the 4th semester only. What more you can ask for?  As a fresher this is the best opportunity which I got from institute. Big thanks to placement cell, all my dear teachers and friends!",
    author: "Gaurav Joshi, MBA Supply Chain Management Batch 2016-18",
  },
  {
    quote:
      "I am placed in Weikfield Foods Pvt. Ltd. as ‘Management Trainee-Sales’. My interest was in FMCG so I can happily say that college placement assistance took me in the industry where I wanted to work. Also I am feeling proud as recipient of “Pride of MIBM” award. Thanks to MIBM!!! Faculty in MIBM during these two years inspired us a lot to learn and Implement the learning in practical life. Conferences and seminars on various relevant issues gave us the exposure to think out of the textbox and interact with the industry insiders. Employability Enhancement Program provided an edge and equipped us with skills sought by industry. Case studies should also be considered as one of the employability enhancement tools. I want to suggest that placement should be framed with clear cut responsibility and tasks assigned to individuals in the cell. Periodic review will make sure that it functions efficiently. Training what we received was very good and enriching but only one thing I want to suggest is that before 4th semester the entire training program should get completed which will enable students to utilize them in interviews. As a student I just only want to say that we should observe how the theory which we read in various courses during MBA is implemented in the industry.",
    author: "Saksham Rawat , MBA Marketing Batch 2015-17",
  },
  {
    quote:
      "Two years of journey at MIBM was simply majestic. MIBM encouraged and motivated me to set higher goals and achieve them with confidence. Everyone in my batch enjoyed a lot. Rejoice, Mangalya and Darpan helped us to grow and focused our qualities apart from studies. Each one has its own inputs foe our own growth, development and embracing us in every possible way. Employability Enhancement Program taught us how to present ourselves in the real world. Many points such as resume, communication, mock interviews word and excel training, digital marketing certification course helped us and is still helping us to tackle work hurdles. Placement cell is working hard for 100% placement results, however I recommend for more no. of campus drives at our college along with current activities. MIBM nurtured a lot me during two years at campus. I was being awarded as Ace of MIBM. In Avishkar, research competition organized by Government of Maharashtra, I went up till final round where only top eight projects across all universities in Maharashtra were selected. Faculties helped me a lot for conducting hypothesis, collection of data and its research presentation. MIBM is motivational, supportive and always there for the students. Overall it was great journey with MIBM and I am proud to be MIBM alumni!",
    author: "Ameya Joshi, MBA Marketing Batch 2015-2017",
  },
  {
    quote:
      "Two years of MBA has been a great journey at MIBM. I got placed through campus in Modelcam Technologies Pvt. Ltd. as a business development executive. I was awarded for “Best Summer Internship Project” in Marketing management. I am grateful for entire team of MIBM for giving me various opportunities to develop in not only academics but also extracurricular activities like annual gathering and management fest. MIBM has very dedicated staff starting from the grass route level to the highest teaching faculty and they have left no stone unturned in their way to build up in us a sense of professionalism, not just in our carrier but in every walk of life.I feel myself very privileged to be the part of this institute and will strive to cherish its moto throughout my life.",
    author: "Shailesh Patil,MBA Marketing Batch 2014-16",
  },
  {
    quote:
      "Its been an honor to be a part of MIBM. With blend of brilliant faculty members, excellent infrastructure, its unique course curricula and sound learning ambience. MIBM have successful produced some of the finest minds around. MIBM is an immense knowledge house. MIBM helped me identify the course of carrier. I can proudly say that MIBM has put me a step ahead from other MBA graduates like me. I have been placed in MIBM campus to renowned brand DSK Benelli, India (Italian Bikes) as a retail sales champ. I have been awarded as “Most Consistent student” for which I am very thankful to the institute. MIBM has created life long memories for me.",
    author: "Gaurav Chandel,MBA Marketing Batch 2014-16",
  },
  {
    quote:
      "Being associated with MIBM was one of the greatest possessions along with holding an MBA degree. My two years of MBA has not only been an academic enhancement but here at MIBM, it was development of my skills and professionals competencies his transformed me from student to a professional. To survive in corporate world MIBM teaching staff has played a significant role of development of my knowledged. I would like to thank the placement cell team which rolled out sessions on soft skill like interview etiquettes, resume writing, campus to corporate etc. Extensive Excel training which made me vigilant about my corporate career. Special thanks to entire teaching staff for their constant guidance which helped me to get my interviews and I got placed in a reputed organization Eclerx Services Ltd. in financial domain. Being general secretary of our annual management fest helped me developed leadership, teamwork and also being awarded foe “Best Summer Internship Project” were my greatest achievement and I owe it to my professors and entire staff of MIBM.",
    author: "Madhuri Chaudhari, MBA Finance Batch 2013-15",
  },
  {
    quote:
      "Its actually difficult to put my feelings into words about overall experience and quality time spend at MIBM, as each single day was memorable and taught me something which is actually very useful. I can say this confidently as I am actually applying my MIBM’s experience and lessons in my professional life. I got placed at Serco Global Services Pune as Business HR and I am very much satisfied with my job profile and company. Special  thanks to both placement coordinators as they always encouraged us and very active regarding placements of MIBM students. Ones again thanks to all members who always motivated us in academics as well as extracurricular activities.",
    author: "Sayali Baradkar, MBA HR Batch 2013-15",
  },

  {
    quote:
      "MIBM is a place where you are surrounded by positive and healthy environment all the time. The Institute provides us with good facilities and very supportive faculties. In MIBM not only teaching faculties but director, coordinator and non-teaching staff are also very supportive which is rarely seen at other places. MIBM also encourages me and gives me various opportunities to participate in extracurricular activities. ‘Mangalya, Darpan, Kaladarpan, Rejoice etc. all this events helped me to improve my strength and to overcome from my weaknesses which is really very crucial for me. This event gives us a fabulous learning environment. The college actually helps us to maintain a perfect a balance between the studies and our hobbies.",
    author: " Pushkar Runwal, MBA Marketing Batch 2017-19",
  },
  {
    quote:
      "MIBM is a place where you are surrounded by positive and healthy environment all the time. The Institute provides us with good facilities and very supportive faculties. In MIBM not only teaching faculties but director, coordinator and non-teaching staff are also very supportive which is rarely seen at other places. MIBM also encourages me and gives me various opportunities to participate in extracurricular activities. ‘Mangalya, Darpan, , Rejoice etc. all this events helped me to improve my strength and to overcome from my weaknesses which is really very crucial for me. This event gives us a fabulous learning environment. The college actually helps us to maintain a perfect a balance between the studies and our hobbies.",
    author: "Tejas Shinde, MBA Marketing Batch 2017-19",
  },
];
//Achievers data
//----------------------------------------------------------------------------------------------------

export function AlumniMeetCarousel18() {
  const images = [img16, img17, img18, img19, img20, img21, img22];
  return (
    <div className="Image-Carousel">
      <div className="forMobileView">
        <Paper elevation={3}>
          <ImageCarouselComponent images={images} />
        </Paper>
      </div>
    </div>
  );
}

export function AlumniMeetCarousel17() {
  const images2 = [
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
  ];
  return (
    <div className="Image-Carousel">
      <div className="forMobileView">
        <Paper elevation={3}>
          <ImageCarouselComponent images={images2} />
        </Paper>
      </div>
    </div>
  );
}

export function AlumniMeetCarousel16() {
  const images3 = [img1, img2, img3];
  return (
    <div className="Image-Carousel">
      <div className="forMobileView">
        <Paper elevation={3}>
          <ImageCarouselComponent images={images3} />
        </Paper>
      </div>
    </div>
  );
}
