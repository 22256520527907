import Marquee from "react-fast-marquee";
import MenuItems from "./MenuItems";
import { menuList } from "./menuList";
import { useState } from "react";
import AdmissionModal from "./AdmissionModal";
import admissionmodalpopupimage from "../assets/images/MBA_BBA_POPUP.jpg";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        //style={{backgroundColor:'#DAFFFB',F5D0D0}}
        style={{ backgroundColor: "#F5D0D0" }}
        className="marquee-container"
      >
        <Marquee
          width="100%"
          loop="infinite"
          speed="60"
          behavior="scroll"
          direction="left"
          pauseOnHover
          style={{ padding: "4px", color: "#232D3F" }}
        >
          {/* <span style={{fontSize:'16px'}}>Institute level and Against Cap seats Admissions open!!! &nbsp; &nbsp;| &nbsp;&nbsp; </span>  
        <span style={{fontSize:'16px'}}> <a style={{color:'black'}} href="https://forms.gle/UuNfmjmPvssztmBz6" target="_blank"> <b> APPLY</b></a>&nbsp;&nbsp; | &nbsp;&nbsp; </span>
        <span style={{fontSize:'16px'}}> <a href={notice}  target="_blank"><b> NOTICE</b></a>&nbsp;&nbsp; | &nbsp;&nbsp; </span>
        <span style={{fontSize:'16px'}}> <a href={provisional} target="_blank"><b>PROVISIONAL MERIT LIST </b></a></span> */}
          {/* <span style={{fontSize:'16px', paddingRight:'300px'}}>  <b> MBA/MMS CET 2024 Common Entrance Test will be conducted on 9th , 10th and 11th March, 2024 at various examination centres within and outside Maharashtra , visit <a style={{color:'steelblue'}} href='https://cetcell.mahacet.org' target="_blank"> https://cetcell.mahacet.org </a>  </b></span> */}
          <span style={{ fontSize: "20px", paddingRight: "300px" }}>
            {" "}
            <b>
              {" "}
              Visit{" "}
              <a
                style={{ color: "steelblue" }}
                href="https://cetcell.mahacet.org"
                target="_blank"
                rel="noreferrer"
              >
                https://cetcell.mahacet.org
              </a>{" "}
              for further details related to common entrance
            </b>
          </span>
          <span style={{ fontSize: "20px", paddingRight: "300px" }}>
            {" "}
            <b>
              <a
                style={{ color: "steelblue" }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Admissions Open
              </a>
            </b>
          </span>
        </Marquee>
      </div>
      {/* ---------Navbar-------------- */}

      <nav
        id="navbar_top"
        className="navbar navbar-expand-lg navbar-light-R"
        // style={{ backgroundColor: "#494A4B" }}
        //style={{ backgroundColor: "#A70606"rgba(37, 37, 37,0.8)"" }}
        style={{ backgroundColor: "#4a4a4a" }}
      >
        <div className="container">
          <button
            style={{ backgroundColor: "#9BA4B5", width: "fit-content" }}
            className="navbar-toggler"
            type="button"
            id="navActive"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse " id="navbarNavDropdown">
            <ul className="navbar-nav">
              {menuList.map((item, index) => {
                const depthLevel = 0;
                return (
                  <MenuItems items={item} key={index} depthLevel={depthLevel} />
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
      <AdmissionModal
        open={open}
        setOpen={setOpen}
        src={admissionmodalpopupimage}
      />
    </>
  );
}
