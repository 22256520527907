import * as React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ICT1 from "../assets/images/Academics/ICT/img1.png";
import ICT2 from "../assets/images/Academics/ICT/img2.png";
import ICT3 from "../assets/images/Academics/ICT/img3.png";
import ICT4 from "../assets/images/Academics/ICT/img4.png";
import Soon from "../assets/images/ComingSoon.png";
import { Paper } from "@mui/material";
import pdf1 from "../assets/pdf/MIBM_Orientation_Program_2023_24.pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LibraryData from "../Data/Library/LibraryData";
import CourseData from "../Data/CoursesData";
import Breadcrumb from "../Components/Breadcrumb";
import { Images } from "../Components/ReusableComponent/ImageAndParagraphsComponent";
import academicCalendar20_21 from "../assets/pdf/AcademicCalendar/Academic_calendar 2020-2021.pdf";
import academicCalendar19_20 from "../assets/pdf/AcademicCalendar/Academic_calendar-2019-2020.pdf";
import academicCalendar22_23 from "../assets/pdf/AcademicCalendar/Academic_calendar-2022-2023.pdf";
import academicCalendar21_22 from "../assets/pdf/AcademicCalendar/AcademicCalnderREVISED2021.pdf";
import academicCalendar23_24 from "../assets/pdf/AcademicCalendar/Academic_calendar-2023-2024.pdf";
const sidemenus = [
  {
    title: "Courses Offered",
    url: "/academics/Courses",
  },
  {
    title: "Faculty and Publications",
    url: "/academics/FacultyAndPublications",
  },
  {
    title: "Academic Calendar",
    url: "/academics/AcademicCalendar",
  },

  {
    title: "Library",
    url: "/academics/library",
  },

  {
    title: "Interactive Teaching and Learnig (ICT)",
    url: "/academics/ICT",
  },
];

export default function Academics(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    // window.scrollTo(0, 0);
    window.scrollTo(0, 420);
  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {" "}
                    {menu.title}{" "}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// sub-pages below of about us tab

export function Courses() {
  return (
    <div className="box">
      <CourseData />
    </div>
  );
}

export function Publications() {
  return (
    <>
      <div className="box">
        <h5 className="my-3 title-text decorated">Faculty And Publications</h5>
        <Paper elevation={3} className="p-3 text-justify">
          <div>
            {/* <AccordianTableComponent data={TableInfo}/> */}
            <img src={Soon} height={400} width={400} className="mx-5" />
          </div>
        </Paper>
      </div>
    </>
  );
}

export function Calendar() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">Academic Calendar</h5>
        <Paper
          elevation={3}
          className="p-3 textWrap"
          style={{ minHeight: "300px" }}
        >
          <div data-aos="fade-up">
            <a href={academicCalendar23_24} target="_blank" className="pdf">
              <PictureAsPdfIcon />
              Academic Calendar 2023-24
            </a>
            <a href={academicCalendar22_23} target="_blank" className="pdf">
              <PictureAsPdfIcon />
              Academic Calendar 2022-23
            </a>
            <a href={academicCalendar21_22} target="_blank" className="pdf">
              <PictureAsPdfIcon />
              Academic Calendar 2021-22
            </a>
            <a href={academicCalendar20_21} target="_blank" className="pdf">
              <PictureAsPdfIcon />
              Academic Calendar 2020-21
            </a>
            <a href={academicCalendar19_20} target="_blank" className="pdf">
              <PictureAsPdfIcon />
              Academic Calendar 2019-20
            </a>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function Library() {
  return (
    <>
      {/* <LibraryData /> */}
      <Paper
        elevation={0}
        className="textWrap text-justify box"
        style={{ minHeight: "300px" }}
      >
        <LibraryData />
      </Paper>
    </>
  );
}
export function ICT() {
  const images = [ICT1, ICT2, ICT3, ICT4];
  return (
    <>
      <div className="box text-justify">
        <h5 className="mb-4 title-text decorated">
          Interactive Teaching and Learnig (ICT)
        </h5>

        <Paper elevation={3} className="p-3" sx={{ minHeight: 300 }}>
          <div className="text-bold my-2 mb-4 P-tag" data-aos="fade-left">
            All Faculty members use state of the art ICT enabled tools and
            classrooms for effective teaching. Students and faculty members are
            provided with Microsoft teams and online video interactions during
            online classes. Institute campus is Wi-Fi enabled. There are twelve
            ICT enabled classrooms and four smart classrooms. There number of
            e-resources in the form of e-journals, EBSCO, SPSS, Microsoft teams
            and Google classrooms etc. available for smooth functioning of
            teaching learning process.
          </div>
          <div>
            <Images images={images} />
          </div>
        </Paper>
      </div>
    </>
  );
}
