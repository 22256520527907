import img1 from '../../assets/images/Library/Library1.jpg';
import img2 from '../../assets/images/Library/Library2.jpg';
import img3 from '../../assets/images/Library/Library3.jpg';
import img4 from '../../assets/images/Library/Library4.jpg';
import img5 from '../../assets/images/Library/Library5.jpg';
import img6 from '../../assets/images/Library/Library6.jpg';
import img7 from '../../assets/images/Library/Library7.jpg';

import * as React from 'react';
import TableComponent, { ScrollableTableMaxHeight_250 } from '../../Components/ReusableComponent/tableComponent';
import { MedicationLiquidOutlined } from '@mui/icons-material';


export const Images = [
    img1, img2, img3, img4, img5, img6, img7
]


//TABLES  in Library Resources
export function ListOfNationalJournals18_19() {
    const tableHeading = [
      "Sr No.", "Journal", '', ''
    ]
    const tableData = [
      {
        col1: 'Asian Journal of Professional & Management'
      },
      {
        col1: 'Asian Journal of Professional Ethics & Management'
      },
      {
        col1: 'Business India'
      },
      {
        col1: 'Banking Finance'
      },
      {
        col1: 'Competition Success Review -English'
      },
      {
        col1: 'Current Labour Reports'
      },
      {
        col1: 'Dalal Street (Investment Journal)'
      },
      {
        col1: 'Forbes India'
      },
      {
        col1: 'Harvard Business Review -South Asia Print'
      },
      {
        col1: 'Human Capital'
      },
      {
        col1: 'IIMB Management Review'
      },
      {
        col1: 'India Business Journal'
      },
      {
        col1: 'Indian  Journal of Advanced Operations Management'
      },
      {
        col1: 'Indian Journal of  Finance'
      },
      {
        col1: 'Indian Journal of  Marketing'
      },
      {
        col1: 'Indian Journal of Research In Capital Markets'
      },
      {
        col1: 'Indian Journal of Training & Development'
      },
      {
        col1: 'Indian Management'
      },
      {
        col1: 'Labour Law Reporter'
      },
      {
        col1: 'Mutual Fund Insight'
      },
      {
        col1: 'Prabandhan : Indian  Journal of  Management'
      },
      {
        col1: 'University News'
      },
      {
        col1: 'Vikalpa'
      },
     
    ]
    return (
      <>
        <ScrollableTableMaxHeight_250 tableHeading={tableHeading} tableData={tableData} />
      </>
    )
  }
  
export function ListOfInterNationalJournals18_19() {
   
    const tableHeading = [
      "Sr No.", "Journal", '', ''
    ]
    const tableData = [
    
      {
        col1: 'International Journal of Applied Finance'
      },
      {
        col1: 'International Journal of Entrepreneurship Development'
      },
      {
        col1: 'International Journal of Logistics and Planning Supply Chain Management'
      },
      {
        col1: 'International Journal Of Marketing & Human resource Management'
      },
      {
        col1: 'International Journal Of Marketing & Trade Policy'
      },
      {
        col1: 'IUP Journal of Organizational Behaviour'
      },
      {
        col1: 'International Journal Of  Operations and Quantitative Management'
      },
      {
        col1: 'International Journal Of Operations Research & Management Science'
      },
      {
        col1: 'IUP Journal of Soft skills'
      },
      {
        col1: 'Journal Of Human Values'
      },
    ]
    return (
      <>
        <ScrollableTableMaxHeight_250 tableHeading={tableHeading} tableData={tableData} />
      </>
    )
  }
  
export function ListOfMagazines18_19() {
   
    const tableHeading = [
      "Sr No.", "Journal", '', ''
    ]
    const tableData = [
      {
        col1: 'Business Today'
      },
      {
        col1: 'BW Business World'
      },
      {
        col1: 'Capital Market'
      },
      {
        col1: 'Economic and Political Weekly'
      },
      {
        col1: 'India Today English'
      },
      {
        col1: 'PC Quest'
      },
      {
        col1: 'Readers Digest'
      },
      {
        col1: 'The Economist'
      },
      {
        col1: 'The Smart Manager'
      },
      {
        col1: 'The Week'
      },
      {
        col1: 'Time Magazine'
      },
    ]
    return (
      <>
        <ScrollableTableMaxHeight_250 tableHeading={tableHeading} tableData={tableData} />
      </>
    )
  }
  
export function ListOfDailyNewspaper(){
    const tableHeading = [
      "Sr No.", "Journal", '', ''
    ]
    const tableData = [
    
      {
        col1: 'Business Standard'
      },
      {
        col1: 'Economics Times'
      },
      {
        col1: 'The Times of India'
      },
      {
        col1: 'The Indian Express'
      },
      {
        col1: 'Pune Mirror'
      },
      {
        col1: 'Sakal'
      },
      {
        col1: 'Maharashtra Times'
      },
      {
        col1: 'Lokmat'
      },
      {
        col1: 'Loksatta'
      }
      
    ]
    return(
      <>
        <ScrollableTableMaxHeight_250 tableHeading={tableHeading} tableData={tableData} />
      </>
    )
  }
  //TABLES in Library Resources