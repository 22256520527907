import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import GalleryComponent from './GalleryComponent'; // Assuming you have a GalleryComponent

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const GalleryTabs = ({ tabs }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ backgroundColor: 'ButtonFace' ,padding:0}}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
          textColor="inherit"
          indicatorColor="primary"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} className="mx-auto" label={tab.label} style={{paddingTop:"0%", paddingBottom:'0%'}} />
          ))}
        </Tabs>
      </Box>
      <Paper elevation={0} className="p-3 mt-1 textWrap">
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            <div>
              <ImageList
                sx={{ maxWidth: 'auto', height: 450 }}
                cols={1}
                rowHeight={164}
              >
                <GalleryComponent images={tab.images} />
              </ImageList>
            </div>
          </CustomTabPanel>
        ))}
      </Paper>
    </Box>
  );
};

export default GalleryTabs;