import React from 'react';
import img from '../assets/images/nofound/underCode.jpg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UnderMaintanence = () => {
  window.scrollTo(0, 190);
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f0f0f0', textAlign: 'center' , padding:'20px'}}>
      <div style={{ margin: '100px auto', maxWidth: '600px', padding: '20px 50px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        <h1 style={{ color: '#ff2600' }}>Website Under Maintenance</h1>
        <p style={{ fontSize: '18px' }}>We're sorry, but our website is currently undergoing maintenance.</p>
        <p style={{ fontSize: '18px' }}>Please check back later.</p>
        <div>
        <img src={img} style={{ height: '50px', width: '50px' }} alt="Maintenance Image" />
        </div>
  
         <Link to={'/'} style={{color:'steelblue'}}>Go to Home </Link> 
    
      
      </div>
      
    </div>
    
  );
};

export default UnderMaintanence;
