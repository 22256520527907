import { Link } from "react-router-dom";
import newsicon from '../assets/images/HomeDesk/events/Icon/news-announcements.png';
import noticeicon from '../assets/images/HomeDesk/events/Icon/Notice-board.png';
import upcomingicon from '../assets/images/HomeDesk/events/Icon/upcoming-Events.png';
import Tooltip from '@mui/material/Tooltip';


export const FacebookIcon = () => {
    return (
        <svg id="facebook" width="16px" height="16px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 12.067C0 18.0335 4.33333 22.9944 10 24V15.3333H7V12H10V9.33332C10 6.33332 11.9333 4.66666 14.6667 4.66666C15.5333 4.66666 16.4667 4.79999 17.3333 4.93332V7.99999H15.8C14.3333 7.99999 14 8.73332 14 9.66666V12H17.2L16.6667 15.3333H14V24C19.6667 22.9944 24 18.0335 24 12.067C24 5.43017 18.6 0 12 0C5.4 0 0 5.43017 0 12.067Z"></path>
        </svg>
    );
}

export const TwitterIcon = () => {
    return (
        <svg id="twitter" width="16px" height="16px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg" >
            <path d="M24,4.5c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.8,8,4.2,6,1.8,3.1C1.4,3.8,1.1,4.7,1.1,5.6c0,1.7,0.9,3.2,2.2,4.1 c-0.8,0-1.6-0.2-2.2-0.6c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1 c0.6,2,2.4,3.4,4.6,3.4c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14 c0-0.2,0-0.4,0-0.6C22.5,6.3,23.3,5.4,24,4.5z"></path>
        </svg>
    );
}

export const LinkedInIcon = () => {
    return (
        <svg id="linkedIn" width="16px" height="16px" viewBox="0 0 32 32" fill="#fff" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.26 27.271h-4.733v-7.427c0-1.771-0.037-4.047-2.475-4.047-2.468 0-2.844 1.921-2.844 3.916v7.557h-4.739v-15.271h4.552v2.083h0.061c0.636-1.203 2.183-2.468 4.491-2.468 4.801 0 5.692 3.161 5.692 7.271v8.385zM7.115 9.912c-1.527 0-2.751-1.235-2.751-2.756 0-1.516 1.229-2.749 2.751-2.749s2.755 1.233 2.755 2.749c0 1.521-1.233 2.756-2.755 2.756zM9.489 27.271h-4.749v-15.271h4.749zM29.636 0h-27.276c-1.303 0-2.36 1.031-2.36 2.307v27.387c0 1.276 1.057 2.307 2.36 2.307h27.271c1.301 0 2.369-1.031 2.369-2.307v-27.387c0-1.276-1.068-2.307-2.369-2.307z"></path>
        </svg>
    );
}

export const RectangleBlock = (prop) => {
    return (
        <svg className="d-block w-100" height={prop.height} xmlns="http://www.w3.org/2000/svg" role="img">
            <rect width="100%" height="100%" fill="#D1D1D1" />
            <text x="40%" y="50%" fill="#555" dy=".5em">{prop.imageName}</text>
        </svg>
    )
}

export const SquareBlock = (prop) => {
    return (
        <svg className="d-block" width={prop.width} height={prop.height} xmlns="http://www.w3.org/2000/svg" role="img">
            <rect width="100%" height="100%" fill="#e4f0ff" />
            <text fill="#555" dx="35%" dy="50%" fontWeight={700}>{prop.imageName}</text>
        </svg>
    )
}

export const SocialIconSet = () => {

    return (
        <div className="d-flex gap-3 justify-content-center social-icons">
            <Tooltip title="Facebook">
                <Link className="facebook forMobileViewMX">
                    <svg width="22px" style={{ backgroundColor: 'white', borderRadius: '20%', padding: '2px' }} height="22px" viewBox="0  0 24 24" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M0 12.067C0 18.0335 4.33333 22.9944 10 24V15.3333H7V12H10V9.33332C10 6.33332 11.9333 4.66666 14.6667 4.66666C15.5333 4.66666 16.4667 4.79999 17.3333 4.93332V7.99999H15.8C14.3333 7.99999 14 8.73332 14 9.66666V12H17.2L16.6667 15.3333H14V24C19.6667 22.9944 24 18.0335 24 12.067C24 5.43017 18.6 0 12 0C5.4 0 0 5.43017 0 12.067Z"></path></svg>
                </Link>
            </Tooltip>
            <Tooltip title="Twitter">
                <Link className="twitter forMobileViewMX">
                    <svg width="22px" style={{ backgroundColor: 'white', borderRadius: '20%', padding: '2px' }} height="22px" viewBox="0  0 24 24" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M24,4.5c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.8,8,4.2,6,1.8,3.1C1.4,3.8,1.1,4.7,1.1,5.6c0,1.7,0.9,3.2,2.2,4.1 c-0.8,0-1.6-0.2-2.2-0.6c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1 c0.6,2,2.4,3.4,4.6,3.4c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14 c0-0.2,0-0.4,0-0.6C22.5,6.3,23.3,5.4,24,4.5z"></path></svg>
                </Link>
            </Tooltip>
            <Tooltip title="Linkedin ">
                <Link className="linkedin forMobileViewMX">
                    <svg width="22px" style={{ backgroundColor: 'white', borderRadius: '20%', padding: '2px' }} height="22px" viewBox="0  0 32 32" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M27.26 27.271h-4.733v-7.427c0-1.771-0.037-4.047-2.475-4.047-2.468 0-2.844 1.921-2.844 3.916v7.557h-4.739v-15.271h4.552v2.083h0.061c0.636-1.203 2.183-2.468 4.491-2.468 4.801 0 5.692 3.161 5.692 7.271v8.385zM7.115 9.912c-1.527 0-2.751-1.235-2.751-2.756 0-1.516 1.229-2.749 2.751-2.749s2.755 1.233 2.755 2.749c0 1.521-1.233 2.756-2.755 2.756zM9.489 27.271h-4.749v-15.271h4.749zM29.636 0h-27.276c-1.303 0-2.36 1.031-2.36 2.307v27.387c0 1.276 1.057 2.307 2.36 2.307h27.271c1.301 0 2.369-1.031 2.369-2.307v-27.387c0-1.276-1.068-2.307-2.369-2.307z"></path></svg>
                </Link>
            </Tooltip>
            <Tooltip title="Instagram">
                <Link className="instagram forMobileViewMX">
                    <svg width="22px" style={{ backgroundColor: 'white', borderRadius: '20%', padding: '2px' }} height="22px" viewBox="0 0 300 300" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M38.52,0.012h222.978C282.682,0.012,300,17.336,300,38.52v222.978c0,21.178-17.318,38.49-38.502,38.49 H38.52c-21.184,0-38.52-17.313-38.52-38.49V38.52C0,17.336,17.336,0.012,38.52,0.012z M218.546,33.329 c-7.438,0-13.505,6.091-13.505,13.525v32.314c0,7.437,6.067,13.514,13.505,13.514h33.903c7.426,0,13.506-6.077,13.506-13.514 V46.854c0-7.434-6.08-13.525-13.506-13.525H218.546z M266.084,126.868h-26.396c2.503,8.175,3.86,16.796,3.86,25.759 c0,49.882-41.766,90.34-93.266,90.34c-51.487,0-93.254-40.458-93.254-90.34c0-8.963,1.37-17.584,3.861-25.759H33.35v126.732 c0,6.563,5.359,11.902,11.916,11.902h208.907c6.563,0,11.911-5.339,11.911-11.902V126.868z M150.283,90.978 c-33.26,0-60.24,26.128-60.24,58.388c0,32.227,26.98,58.375,60.24,58.375c33.278,0,60.259-26.148,60.259-58.375 C210.542,117.105,183.561,90.978,150.283,90.978z"></path></svg>
                </Link>
            </Tooltip>

            <Tooltip title="Youtube">
                <Link className="youtube forMobileViewMX">
                    <svg width="22px" style={{ backgroundColor: 'white', borderRadius: '20%', padding: '2px' }} height="22px" viewBox="0 -5 34 34" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="m13.544 16.419 9.216-4.762-9.216-4.818zm3.524-16.419q3.2 0 6.181.086t4.371.181l1.39.08q.019 0 .32.029c.167.013.317.033.465.061l-.026-.004q.134.029.448.086c.205.035.388.088.561.158l-.018-.006q.229.095.534.248c.225.114.419.238.599.377l-.008-.006c.203.158.383.323.549.502l.003.003c.102.107.199.222.288.342l.007.01c.202.318.388.686.537 1.071l.015.044c.24.559.417 1.207.501 1.885l.004.035q.152 1.219.24 2.6t.105 2.16v3.352c.001.078.001.171.001.263 0 1.853-.125 3.678-.367 5.465l.023-.208c-.086.704-.255 1.344-.498 1.946l.018-.051c-.162.447-.368.834-.62 1.186l.01-.015-.266.32c-.169.182-.349.347-.542.497l-.01.008c-.171.134-.365.254-.571.353l-.019.008q-.305.143-.534.24c-.156.064-.338.117-.527.15l-.016.002q-.314.057-.457.086t-.438.057-.314.029q-4.775.372-11.937.372-3.943-.038-6.848-.124t-3.819-.143l-.934-.08-.686-.08c-.404-.05-.757-.117-1.102-.204l.062.013c-.376-.118-.697-.253-1.003-.415l.032.015c-.417-.205-.772-.467-1.075-.78l-.001-.001c-.102-.107-.199-.222-.288-.342l-.007-.01c-.202-.318-.388-.686-.537-1.071l-.015-.044c-.24-.559-.417-1.207-.501-1.885l-.004-.035q-.152-1.219-.24-2.6t-.105-2.16v-3.352c-.001-.078-.001-.171-.001-.263 0-1.853.125-3.678.367-5.465l-.023.208c.086-.704.255-1.344.498-1.946l-.018.051c.162-.447.368-.834.62-1.186l-.01.015.266-.32c.169-.182.349-.347.542-.497l.01-.008c.172-.134.365-.257.57-.362l.021-.01q.305-.152.534-.248c.156-.064.338-.117.527-.15l.016-.002q.314-.057.448-.086c.121-.024.271-.044.424-.056l.015-.001q.305-.029.32-.029 4.783-.35 11.946-.35z"></path></svg>
                </Link>
            </Tooltip>




        </div>
    )
}

export const FbIcon = () => {
    return (
        <svg width="20px" height="20px" viewBox="0  0 24 24" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M0 12.067C0 18.0335 4.33333 22.9944 10 24V15.3333H7V12H10V9.33332C10 6.33332 11.9333 4.66666 14.6667 4.66666C15.5333 4.66666 16.4667 4.79999 17.3333 4.93332V7.99999H15.8C14.3333 7.99999 14 8.73332 14 9.66666V12H17.2L16.6667 15.3333H14V24C19.6667 22.9944 24 18.0335 24 12.067C24 5.43017 18.6 0 12 0C5.4 0 0 5.43017 0 12.067Z"></path></svg>
    )
}
export const InstaIcon = () => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 300 300" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M38.52,0.012h222.978C282.682,0.012,300,17.336,300,38.52v222.978c0,21.178-17.318,38.49-38.502,38.49 H38.52c-21.184,0-38.52-17.313-38.52-38.49V38.52C0,17.336,17.336,0.012,38.52,0.012z M218.546,33.329 c-7.438,0-13.505,6.091-13.505,13.525v32.314c0,7.437,6.067,13.514,13.505,13.514h33.903c7.426,0,13.506-6.077,13.506-13.514 V46.854c0-7.434-6.08-13.525-13.506-13.525H218.546z M266.084,126.868h-26.396c2.503,8.175,3.86,16.796,3.86,25.759 c0,49.882-41.766,90.34-93.266,90.34c-51.487,0-93.254-40.458-93.254-90.34c0-8.963,1.37-17.584,3.861-25.759H33.35v126.732 c0,6.563,5.359,11.902,11.916,11.902h208.907c6.563,0,11.911-5.339,11.911-11.902V126.868z M150.283,90.978 c-33.26,0-60.24,26.128-60.24,58.388c0,32.227,26.98,58.375,60.24,58.375c33.278,0,60.259-26.148,60.259-58.375 C210.542,117.105,183.561,90.978,150.283,90.978z"></path></svg>
    )
}
export const YtubeIcon = () => {
    return (
        <svg width="20px" height="20px" viewBox="0 -5 34 34" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="m13.544 16.419 9.216-4.762-9.216-4.818zm3.524-16.419q3.2 0 6.181.086t4.371.181l1.39.08q.019 0 .32.029c.167.013.317.033.465.061l-.026-.004q.134.029.448.086c.205.035.388.088.561.158l-.018-.006q.229.095.534.248c.225.114.419.238.599.377l-.008-.006c.203.158.383.323.549.502l.003.003c.102.107.199.222.288.342l.007.01c.202.318.388.686.537 1.071l.015.044c.24.559.417 1.207.501 1.885l.004.035q.152 1.219.24 2.6t.105 2.16v3.352c.001.078.001.171.001.263 0 1.853-.125 3.678-.367 5.465l.023-.208c-.086.704-.255 1.344-.498 1.946l.018-.051c-.162.447-.368.834-.62 1.186l.01-.015-.266.32c-.169.182-.349.347-.542.497l-.01.008c-.171.134-.365.254-.571.353l-.019.008q-.305.143-.534.24c-.156.064-.338.117-.527.15l-.016.002q-.314.057-.457.086t-.438.057-.314.029q-4.775.372-11.937.372-3.943-.038-6.848-.124t-3.819-.143l-.934-.08-.686-.08c-.404-.05-.757-.117-1.102-.204l.062.013c-.376-.118-.697-.253-1.003-.415l.032.015c-.417-.205-.772-.467-1.075-.78l-.001-.001c-.102-.107-.199-.222-.288-.342l-.007-.01c-.202-.318-.388-.686-.537-1.071l-.015-.044c-.24-.559-.417-1.207-.501-1.885l-.004-.035q-.152-1.219-.24-2.6t-.105-2.16v-3.352c-.001-.078-.001-.171-.001-.263 0-1.853.125-3.678.367-5.465l-.023.208c.086-.704.255-1.344.498-1.946l-.018.051c.162-.447.368-.834.62-1.186l-.01.015.266-.32c.169-.182.349-.347.542-.497l.01-.008c.172-.134.365-.257.57-.362l.021-.01q.305-.152.534-.248c.156-.064.338-.117.527-.15l.016-.002q.314-.057.448-.086c.121-.024.271-.044.424-.056l.015-.001q.305-.029.32-.029 4.783-.35 11.946-.35z"></path></svg>
    )
}
export const TwitIcon = () => {
    return (
        <svg width="20px" height="20px" viewBox="0  0 24 24" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M24,4.5c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.8,8,4.2,6,1.8,3.1C1.4,3.8,1.1,4.7,1.1,5.6c0,1.7,0.9,3.2,2.2,4.1 c-0.8,0-1.6-0.2-2.2-0.6c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1 c0.6,2,2.4,3.4,4.6,3.4c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14 c0-0.2,0-0.4,0-0.6C22.5,6.3,23.3,5.4,24,4.5z"></path></svg>
    )
}
export const LnIcon = () => {
    return (
        <svg width="20px" height="20px" viewBox="0  0 32 32" fill="#555" xmlns="http://www.w3.org/2000/svg"><path d="M27.26 27.271h-4.733v-7.427c0-1.771-0.037-4.047-2.475-4.047-2.468 0-2.844 1.921-2.844 3.916v7.557h-4.739v-15.271h4.552v2.083h0.061c0.636-1.203 2.183-2.468 4.491-2.468 4.801 0 5.692 3.161 5.692 7.271v8.385zM7.115 9.912c-1.527 0-2.751-1.235-2.751-2.756 0-1.516 1.229-2.749 2.751-2.749s2.755 1.233 2.755 2.749c0 1.521-1.233 2.756-2.755 2.756zM9.489 27.271h-4.749v-15.271h4.749zM29.636 0h-27.276c-1.303 0-2.36 1.031-2.36 2.307v27.387c0 1.276 1.057 2.307 2.36 2.307h27.271c1.301 0 2.369-1.031 2.369-2.307v-27.387c0-1.276-1.068-2.307-2.369-2.307z"></path></svg>
    )
}


export const NewsIcon = () => {
    return (
        <img src={newsicon} width="40px" height="55px" />
    )
}

export const NoticeIcon = () => {
    return (
        <img src={noticeicon} width="40px" height="55px" />
    )
}

export const UpcomingIcon = () => {
    return (
        <img src={upcomingicon} width="40px" height="55px" style={{ color: 'white' }} />
    )
}