import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import Soon from '../assets/images/ComingSoon.png';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Data , AlumniMeetCarousel18, AlumniMeetCarousel16, AlumniMeetCarousel17} from '../Data/AlumniData';
import { Paper } from "@mui/material";
import Divider from '@mui/material/Divider';
import TestimonialSlick from '../Components/Home.component/TestimonialSlick';
import Breadcrumb from "../Components/Breadcrumb";
import TabComponent from "../Components/ReusableComponent/TabComponent";
import { Figure } from "react-bootstrap";
import AdsClickIcon from '@mui/icons-material/AdsClick';

const sidemenus = [
    {
        title: 'About Alumni Cell',
        url: "/alumni/AlumniCell",
    },
    {
        title: 'Alumni Achievers',
        url: "/alumni/AlumniAchievers",
    },
    {
        title: 'Alumni Registrations',
        url: "/alumni/Registrations"
    },
    {
        title: 'Alumni Downloads',
        url: "/alumni/Downloads"
    },
    {
        title: 'Alumni Meet',
        url: '/alumni/AlumniMeet',
    },
    {
        title: 'Testimonials',
        url: '/alumni/Testimonials',
    },
];

export default function Alumni(props) {
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class', 'active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });

        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        // window.scrollTo(0, 0);
        window.scrollTo(0, 420);
    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={menuName} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={menuName} />
            </div>
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu, index) => {
                                return (
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu, index) => {
                                return (
                                    <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


//sub-pages below of about us tab    
export function AlumniCell() {
    return (
        <>
            <div className="box text-justify text-justify">
                <h5 className="title-text my-2 mb-4 decorated">About Alumni Cell</h5>
                <Paper elevation={3} className="p-3">
                    <div className="P-tag">
                        <div data-aos="fade-left">
                            <p>MIBM Alumni Association maintains a lifelong connection between the Alumni and the Institute. The alumni are important stakeholders and valuable assets of the institute. The Alumni Association works to connect alumni, support students and build an unforgettable Institute experience through annual alumni meet – “Confluence”.</p>

                            <p>The main motto behind the Alumni Association is to promote strong bond between alumni, students and the Institute and all the other stakeholders of our institute by creating a network which will in turn enable alumni to remain engaged with their alma mater. An Alumni Association stands for its core values of excellence, lifelong relationships, lifelong learning, inclusiveness and diversity, global citizenship, advocacy of the institute at a local and global platform.</p>

                            <p>Industry being the most important stakeholder of any business school, MIBM strives to continuously connect Industry with for the professional development and high-quality careers for its students. Alumni can play a role of catalyst in building great interactions and relations with Industry.</p>

                            <p>The annual alumni meet is called as Confluence, which actually means convergence two rivers. We are trying to capture the moment of summit of past students and current students through CONFLUENCE. The Alumni association has been successfully working to bridge the gap between Academic world and Professional world through such activities. The Alumni Association continues to seek innovative ways to serve alumni by providing social, educational, and professional opportunities. We appeal to all the enthusiastic alumni to contribute towards growth and progress of their Alma mater aligned towards the motto of being “Progressive” with “Modern” approach.</p>
                        </div>
                        <Divider sx={{ border: 1, marginBottom: 1 }} />
                        <div>
                            <h5 className="title-text my-2 mb-4">Objectives of Mibm Alumni Association</h5>
                            <ul className="ul-tag" data-aos="fade-left">
                                <li>To establish a mutually beneficial relationship and strong bonding between MIBM and its alumni through “Confluence” Meet.</li>
                                <li>To help Alumni Connect with the Institute and the students through various seminars, conferences and social activities and other academic programs.</li>
                                <li>To involve alumni in the process of recruiting; mentoring, modeling, and guest teaching; career planning of students.</li>
                                <li>To improve the quality of teaching and learning at MIBM by continuously staying touch with practical world through alumni.</li>
                                <li>To help the faculties and doctoral students of MIBM to get face-to-face access to the leading practitioners and thinkers from Industrial background through structured learning with valuable professional networking.</li>
                            </ul>
                        </div>
                    </div>
                </Paper>
            </div>
        </>
    );
}

export function Achievers() {
    return (
        <>
            <div className="box text-justify text-justify">
              <Paper elevation={0} className="p-3">

                <h5 className="title-text my-2 mb-4 decorated">Alumni Achievers</h5>
                {Data.map((items, index) => (
                    <div className="blockquote" data-aos="fade-left">
                        <blockquote className="p-3 mt-4 ps-5 fs-6">
                            <p>{items.quote}</p>
                            <p className="text-bold">– {items.author}</p>
                        </blockquote>
                    </div>
                ))}
                </Paper>
                {/* {Data.map((items, index) => (
                        <div key={index} className="box text-justify row">
                            <div className="col-lg-1 col-sm-1">
                                <FormatQuoteIcon className="rotated-icon" />
                            </div>
                            <div className="col-lg-11">
                            <p>{items.quote}</p>
                            <p className="text-bold">– {items.author}</p>
                            </div>
                        </div>
                  ))} */} 
            </div>
        </>
    );
}

export function Registrations() {
    return (
        <>
          <Paper elevation={3} className="p-3" style={{ minHeight: '400px' }}>
            <div className="box text-justify">
             <h5 className="title-text my-2 mb-4 decorated">Alumni Registrations</h5>
                 <Link to={'https://docs.google.com/forms/d/1-9FPe0rXl06wJHPIGh0Ja1PTuc2On0xuDuYH6h_FgyE/edit#settings'} target="_blank" className="pdf Brochure w-100"><AdsClickIcon /> Alumni Registrations</Link>
            </div>
          </Paper>
        </>
    );
}
export function Downloads() {
    return (
        <>
            <div className="box text-justify">
                <Paper elevation={3} className="p-3" style={{ minHeight: '400px' }}>
                    <h5 className="title-text my-2 mb-4 decorated">Download MIBM Alumni Association Registration Certificate</h5>
                     <Figure>
                          <img src={Soon} height={400} minWidth={400}/>
                     </Figure>  
                </Paper>
            </div>
        </>
    );
}
export function AlumniMeet() {
    const tabData = [
        { label: 'Alumni Meet 2018', content: <AlumniMeetCarousel18 /> },
        { label: 'Alumni Meet 2017', content: <AlumniMeetCarousel17 /> },
        { label: 'Alumni Meet 2016', content: <AlumniMeetCarousel16 />},
    ];
    return (
        <>
            <div className="box text-justify">
                <Paper elevation={3} sx={{ minHeight: '450px' }} className='w-100'>
                    <TabComponent tabData={tabData} tabName={sessionStorage.getItem('menuName')}/>
                </Paper>
            </div>
        </>
    );
}


export function Testimonials() {
    return (
        <>
            <div className="box text-justify">

                <Paper elevation={3} className="p-3">
                    <div className="my-5">
                        <TestimonialSlick />
                    </div>
                </Paper>
            </div>

        </>
    );
}

