
import * as React from 'react';
import { Button, Grid, Paper } from "@mui/material";
import { Link } from 'react-router-dom';
import GalleryTabs from '../../Components/ReusableComponent/ScrollabelGalleryComponent'
import Breadcrumb from '../../Components/Breadcrumb';
import {Images} from '../../Data/MibmGalleryImagesData';


export function ManagementFest() {
    const tabs = [
        { label: '2018 - 2019', images: Images.ManagementFest.MF2018_19 },
        { label: '2016 - 2017', images: Images.ManagementFest.MF2016_17 },
        { label: '2015 - 2016', images: Images.ManagementFest.MF2015_16 },
        { label: '2014 - 2015', images: Images.ManagementFest.MF2014_15 },
        { label: '2013 - 2014', images: Images.ManagementFest.MF2013_14 },
        {label:<Link to='/students/Gallery' style={{padding:'12px'}}> Back </Link>}
      ];

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={'ManagementFest'} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={'ManagementFest'} />
            </div>
            <Paper elevation={3} className="p-0 mt-1 textWrap mx-5 mt-5">
                   <h2 className='title-text text-center py-4'>Management Fest</h2>
                   <GalleryTabs tabs={tabs}/>
            </Paper>
        </>
    );
}

export function Conference() {
    const tabs = [
        { label: '2018 - 2019', images: Images.conference.C_2018_19 },
        { label: '2017 - 2018', images: Images.conference.C_2017_18 },
        { label: '2016 - 2017', images: Images.conference.C_2016_17 },
        { label: '2015 - 2016', images: Images.conference.C_2015_16 },
        { label: '2014 - 2015', images: Images.conference.C_2014_15 },
        { label: '2013 - 2014', images: Images.conference.C_2013_14 },
        {label:<Link to='/students/Gallery' style={{padding:'12px'}}> Back </Link>}
      ];

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={'CONFERENCE'} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={'CONFERENCE'} />
            </div>
            <Paper elevation={3} className="p-0 mt-1 textWrap mx-5 mt-5">
                <h2 className='title-text text-center py-4'>Conference</h2>
                <GalleryTabs tabs={tabs} />
            </Paper>
        </>
    );
}

export function Cultural() {
    const tabs = [
        { label: '2018 - 2019', images: Images.cultural.cul_2018_19 },
        { label: '2017 - 2018', images: Images.cultural.cul_2017_18 },
        { label: '2016 - 2017', images: Images.cultural.cul_2016_17 },
        { label: '2015 - 2016', images: Images.cultural.cul_2015_16 },
        { label: '2014 - 2015', images: Images.cultural.cul_2014_15 },
        { label: '2013 - 2014', images: Images.cultural.cul_2013_14 },
        {label:<Link to='/students/Gallery' style={{padding:'12px'}}> Back </Link>}
      ];

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={'Cultural'} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={'Cultural'} />
            </div>
            <Paper elevation={3} className="p-0 mt-1 textWrap mx-5 mt-5">
                <h2 className='title-text text-center py-4'>Cultural Program</h2>
                <GalleryTabs tabs={tabs} />
            </Paper>
        </>
    );
}

export function Industry() {
    const tabs = [
        { label: '2018 - 2019', images: Images.industrial.i_2018_19 },
        { label: '2017 - 2018', images: Images.industrial.i_2017_18 },
        { label: '2016 - 2017', images: Images.industrial.i_2016_17 },
        { label: '2015 - 2016', images: Images.industrial.i_2015_16 },
        { label: '2014 - 2015', images: Images.industrial.i_2014_15 },
        {label:<Link to='/students/Gallery' style={{padding:'12px'}}> Back </Link>}
      ];

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={'Cultural'} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={'Cultural'} />
            </div>
            <Paper elevation={3} className="p-0 mt-1 textWrap mx-5 mt-5">
                <h2 className='title-text text-center py-4'>Industrial Visit</h2>
                <GalleryTabs tabs={tabs} />
            </Paper>
        </>
    );
}

export function Sports() {
    const tabs = [
        { label: '2013 - 2014', images: Images.sports.sp_2013_14 },
        {label:<Link to='/students/Gallery' style={{padding:'12px'}}> Back </Link>}
      ];

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={'Sports'} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={'Sports'} />
            </div>
            <Paper elevation={3} className="p-0 mt-1 textWrap mx-5 mt-5">
                <h2 className='title-text text-center py-4'>Sports</h2>
                <GalleryTabs tabs={tabs} />
            </Paper>
        </>
    );
}

export function Social() {
    const tabs = [
        { label: '2018 - 2019', images: Images.social.sw_2018_19 },
        { label: '2017 - 2018', images: Images.social.sw_2017_18 },
        { label: '2016 - 2017', images: Images.social.sw_2016_17 },
        { label: '2015 - 2016', images: Images.social.sw_2015_16 },
        { label: '2014 - 2015', images: Images.social.sw_2014_15 },
        {label:<Link to='/students/Gallery' style={{padding:'12px'}}> Back </Link>}
      ];

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={'Social Work'} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={'Social Work'} />
            </div>
            <Paper elevation={3} className="p-0 mt-1 textWrap mx-5 mt-5">
                <h2 className='title-text text-center py-4'>Social Work</h2>
                <GalleryTabs tabs={tabs} />
            </Paper>
        </>
    );
}

export function TrainingPlacement() {
    const tabs = [
        { label: 'Certification - Finance', images: Images.trainingPlacement.finance },
        { label: 'Certification - Marketing', images: Images.trainingPlacement.marketing },
        { label: 'Certification - Human Resource(HR)', images: Images.trainingPlacement.HR },
        { label: 'Induction', images: Images.trainingPlacement.induction },
        {label:<Link to='/students/Gallery' style={{padding:'12px'}}> Back </Link>}
      ];

    return (
        <>
            <div className="bredDivMob">
                <Breadcrumb pageTitle={'Training And Placement'} />
            </div>
            <div className="bredDivDesk">
                <Breadcrumb pageTitle={'Training And Placement'} />
            </div>
            <Paper elevation={3} className="p-0 mt-1 textWrap mx-5 mt-5">
                <h2 className='title-text text-center py-4'>Training And Placement</h2>
                <GalleryTabs tabs={tabs} />
            </Paper>
        </>
    );
}