import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import * as React from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import { Paper } from "@mui/material";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import pdf1 from '../assets/pdf/MIBM_Orientation_Program_2023_24.pdf'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import StudentIndustryTable1, { StudentIndustryTable2, StudentIndustryTable3, StudentIndustryTable4, StudentIndustryTable5 } from '../Data/PlacementData.js'
import TabComponent from "../Components/ReusableComponent/TabComponent.js";
import TableComponent from "../Components/ReusableComponent/tableComponent.js";
import RulesRegulation from "../assets/pdf/TrainingAndPlacement/Training&PlacementRulesandRegulations2022-23.pdf";
import brochure1 from '../assets/pdf/placementBrochures/MIBM Placement Brochure 14-15.pdf';
import brochure2 from '../assets/pdf/placementBrochures/MIBM Placement Brochure 15-16.pdf';
import brochure3 from '../assets/pdf/placementBrochures/MIBM Placement Brochure 16-17.pdf';
import brochure4 from '../assets/pdf/placementBrochures/MIBM Placement Brochure 17-18.pdf';
import brochure5 from '../assets/pdf/placementBrochures/MIBM Placement Brochure 18-19.pdf';
import brochure6 from '../assets/pdf/placementBrochures/MIBM Placement Brochure 19-20.pdf';
import brochure7 from '../assets/pdf/placementBrochures/MIBM Placement Brochure 20-21.pdf';

import enhancementProgram1 from '../assets/pdf/EmploybilityEnhancement/Employability enhancement.pdf';
import enhancementProgram2 from '../assets/pdf/EmploybilityEnhancement/Employability Enhancement Report.pdf';


const sidemenus = [
  {
    title: 'About Training & Placement Cell',
    url: '/placement/TrainingPlacementCell',
  },
  {
    title: 'Summer Internship Program',
    url: '/placement/SummerInternshipProgram',
  },
  {
    title: 'Our Recruiters',
    url: '/placement/OurRecruiters',
  },
  {
    title: 'Placement Brochure',
    url: '/placement/PlacementBrochure',
  },
  {
    title: 'Employability Enhancement Program',
    url: '/placement/EmployabilityEnhancementProgram',
  },
  {
    title: 'Student Industry Academia Initiatives',
    url: '/placement/StudentIndustryAcademiaInitiatives',
  },
];



export default function Placement(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute('class', 'active-menu');

    // Add d-none class for all divs
    sidemenus.forEach(element => {
      let id = element.title.split(' ').join('').toString();
      document.getElementById(id).classList.add('d-none');
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(' ').join('').toString();
    document.getElementById(divId).classList.remove('d-none');

    // scroll top
    // window.scrollTo(0, 0);
    window.scrollTo(0, 420);

  }, [menuName, menuId]);

  const handleClick = event => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(
      document.querySelectorAll(".sideMenu a")
    );

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });

  };

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div id={menu.title.split(' ').join('').toString()} key={index}>
                    {props.pageContent}
                  </div>
                );
              })}

            </div>
          </div>
        </div>
      </div>

    </>
  )
}


// sub-pages below of about us tab

export function AboutTnPCell() {
  const heading = [
     'Sr No.','Contact Person', 'Email Id','Contact Number'
  ]
  const data = [
    {
      col1:'Dr. Vijayalakshmi Srinivas',
      col2:'prasannapuranik.mibm@gmail.com',
      col3:8237084547
    },
    {
      col1:'Prof. Prasanna Puranik',
      col2:'placements.mibm@gmail.com',
      col3:9850743008
    }
  ]
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">Training And Placements</h5>
        <Paper elevation={3} className="p-3 text-justify">
          <div className="P-tag">
            <h5 className="title-text mb-3 mt-3">Placements</h5>
            <div data-aos="fade-left">

              <p>Progressive Education society’s Modern Institute of Business Management was established under the visionary leadership of Prof. Dr. Gajanan Ekbote in the year 2010 with a mission to develop competent, committed and compassionate leaders who will make a difference to the people among whom they work. MIBM nurtures its students to develop a holistic approach, a curious mindset and the ability to think differently and innovatively to harness the opportunities emerging from challenges. The overall role of the Training & Placement Cell is of a facilitator and counselor for placement related activities The Training & Placement cell was set up to impart right skills on students and to assist them with right opportunities for employment.</p>
              <p>T&P cell is headed by two Placement Co-ordinators, five Student representative co-ordinators, coordinated by Director and Assistant Co-ordinator of MIBM.</p>
            </div>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text mb-3 mt-3">Objectives</h5>
            <ul className="ul-tag" data-aos="fade-left">
              <li>To provide assistance in order to achieve maximum possible placement for students by providing ample employment opportunities.</li>
              <li>To assist students in getting Maximum Summer Internship Opportunities and short term opportunities for first year students.</li>
              <li>To guide students on soft skills and interview techniques, group discussion, aptitude tests etc.</li>
              <li>To train students according to Industry Needs.</li>
              <li>To collate and maintain students information.</li>
              <li>To tie up with corporate for campus recruitment and thereby develop industry – institute interface.</li>
            </ul>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text mb-3 mt-3">Responsibilities of Training & Placement Co-ordinators</h5>
            <ul className="ul-tag" data-aos="fade-left">
              <li>Preparing a Corporate Presentation of the institute.</li>
              <li>Preparing the plan regarding proposed activities for placements</li>
              <li>To prepare placement brochures and send it to prospective companies by 31st July every year.</li>
              <li>To correspond with various companies for interviews and other schedules.</li>
              <li>To arrange for training of students and make necessary arrangements.</li>
              <li>To arrange for pre placement talks and campus recruitments at the institute.</li>
              <li>Preparing the Ranking of Companies based on – Job Profile & growth Prospects, Package offered, past record of recruitment, Feedback from the students regarding company.</li>
              <li>Preparing a report on completed placement activities.</li>
            </ul>
          </div>
          <Divider sx={{ border: 1 }} />
          <div className="mb-4">
            <h5 className="title-text mb-3 mt-3">Placement Committee</h5>
            <h6 className="text-bold mb-3 mx-3 P-tag" data-aos="fade-left"> - Training And Placement Officers</h6>
            <div>
              <Paper elevation={1}>
                <TableComponent tableHeading={heading} tableData={data} />
              </Paper>
            </div>
          </div>

          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text mb-3 mt-3">Responsibilities of student representatives for placement</h5>
            <ul className="ul-tag" data-aos="fade-left"> 
              <li>To collect students information and collate it in excel format.</li>
              <li>To prepare a database of prospective students, call them and obtain the contact details of Human resource Managers.</li>
              <li>Keeping follow-up with the companies for final placements and summer internships.</li>
              <li>Maintaining a register to record details about calls made to various companies and its response.</li>
              <li>To make an arrangement for Pre Placement Talks with company officials.</li>
              <li>To arrange for facilities and coordinate at the time of campus recruitment.</li>
              <li>To distribute the Placement Brochures to prospective companies.</li>
              <li>To co-ordinate with students about campus recruitment programs.</li>
              <li>To prepare the report on campus recruitment drives conducted on Campus.</li>
              <li>Collecting Resumes in standard format from all the eligible students.</li>
            </ul>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text mb-3 mt-3">Required Skills for Students Coordinator and Representatives</h5>
            <p className="P-tag">
              Convincing Power, Good Communication Skills, Capacity of Co-ordination, Good Academic Background, and Presentable, Willing to devote time for placements.
            </p>
          </div>
          <Divider sx={{ border: 1 }} />
          <div>
            <h5 className="title-text mb-3 mt-3">Required Skills for Students Coordinator and Representatives</h5>
            <h6 className="text-bold mb-3 pdf Brochure w-100 mx-0"> <a href={RulesRegulation} target="_blank"><PictureAsPdfIcon />Final Declaration about Rules and Regulations 2021</a></h6>
            <p className="P-tag" data-aos="fade-left">
              The Institute has designed few rules & regulations regarding Final Placement of Students. All students are bound by these rules. These rules are like basic eligibility criteria which must be fulfilled by all the students who are seeking placement assistance from institute.
            </p>
          </div>
          <Divider sx={{ border: 1 }} />
           <h5 className="mb-3 mt-3 text-bold">The rules of Final Placement are as follows :</h5>
           <div data-aos="fade-left">
            <p className="my-3 mx-3">1 - Students having consistent <b>attendance record of 75% </b>and above only will be eligible for campus placement assistance.</p>

            <p className="my-3 mx-3">2 - Students having more than <b>two backlogs will not be eligible</b> for placement assistance.</p>

            <p className="my-3 mx-3">3 - Organizations which are recruiting students having backlog, will be free to reject the students if they wish to do so.</p>

            <p className="my-3 mx-3">4 - Students are <b>not allowed to approach the company directly or refuse an offer</b> by the company or discuss salary and other details, after the Pre-Placement talk or during the interview.</p>

            <p className="my-3 mx-3">5 - It is the responsibility of the students and not of Institute or placement cell to keep themselves update about whole information related to Placement activities.</p>

            <p className="my-3 mx-3">6 - Students will be debarred from placement activities if they found guilty of misconduct or misinformation or purposely ‘Under Performing’ during the selection process.</p>

            <p className="my-3 mx-3">7 - Each student will be allowed <b>only two placement offers</b> after which he/ she will not be considered for further opportunities.</p>

            <p className="my-3 mx-3">8 - Once is offer is signed and accepted, the candidate will not be eligible for any further placement opportunity.</p>

            <p className="my-3 mx-3">9 - After accepting an offer, the student must ensure that he/ she joins the company on the date required (after the date of academic session), failing which the Institute will not be responsible for any misconduct.</p>

            <p className="my-3 mx-3">10 - <b>Misconduct of any kind </b> by the students in the company <b> during the interview or Pre-placement Talk will lead to disqualification of the students</b> from the final placement program. This will be done after proper verification.</p>

            <p className="my-3 mx-3">11 - The student once placed with the companies will have to maintain exemplary behavior and work ethics failing to which the institute will hold them morally responsible as it can result into company not participating in the subsequent Campus recruitment program.</p>

            <p className="my-3 mx-3">12 - In case of any situation / conflict final decision will be taken by Director, Assistant co-ordinator and Placement Co-ordinators of the Institute.</p>
          </div>
        </Paper>
      </div>
    </>
  )
}
export function SummerIntern() {
  return (
    <>
      <div className="box">

        <h5 className="title-text mb-3 mt-3 decorated">Summer Internship Program</h5>
        <Paper elevation={3} className="p-3 text-justify" style={{ minHeight: '350px' }}>
          <ul className="ul-tag" data-aos="fade-left">
            <li>Data which is given in SPPU syllabus for SIP</li>
            <li>Revised for preparation of project report</li>
          </ul>
        </Paper>
      </div>
    </>
  );
}

export function OurRecruiters() {
  const LeftSideData = [
    'AMBEDO Digitals', 'Automatic Infotech', 'BDO (India) LLP', 'Biojobz', 'Courtyard Marriot', 'eClerx Services Ltd.', 'Edelweiss Finance', 'DSK Benelli', 'Elite Celebrations', 'H & R Block', 'Huntsmen & Barons', 'Infosys Ltd', 'Jaro Education', 'Just Dial Ltd', 'Kadence Pvt. Ltd.', 'Kay Communications', 'Maersk Line', 'Mahindra holidays', 'Mak Media Pvt. Ltd.', 'Markets & Markets', 'Peoplefy', 'Sanghvi Brands', 'Setu Advertising', 'SKP Group', 'Sunguard', 'Syntel', 'Tech Mahindra', 'The Chopras', 'Matrimonydirectory.com'
  ]
  
  const RightSideData = [
    'Gallagher Offshore Support Services Pvt. Ltd.', 'Hemant Shah and Associates – Chartered Accountants', 'Bridgestone India Pvt. Ltd', 'Dharne Systems Pvt. Ltd.', 'D-Mart (Avenue Supermarts Ltd.)', 'eFicaz Consultants Pvt. Ltd.', 'Home Revise Publications', 'Innate Research Services Pvt. Ltd.', 'Kay Communications', 'Metro Services (Metro Group)', 'Mindscript Technologies', 'Money Plant Consultancy', 'Moon SEZ Pvt. Ltd.', 'Nyaasa KPO Pvt. Ltd.', 'Remunance System Pvt. Ltd.', 'Seasons – an apartment hotel', 'Vodafone India Ltd', 'Zenith Management Consultants', 'Volkswagen India Private Ltd', 'WizPro-Tek Consultants', 'XL Dynamics Pvt. Ltd.', 'Transcend Market research', 'ThyssenKrupp', 'ModelCam Technologies Pvt. Ltd.', 'Umbrelladeal', 'Kirtane Pandit Chartered Accountants', 'MITCON Consulting & Engineering Services',
  ]
  return (
    <>
      <div className="box">

        <h5 className="title-text mb-3 mt-3 decorated">Our Recruiters</h5>

        <Paper elevation={3} className="p-3 text-justify">
          <Box>
            <Grid container>
              <Grid container justifyContent="space-around">
                <Grid item xs={12} sm={5} className="text-wrap d-flex flex-column">
                  {LeftSideData.map((Ldata, index) => (
                    <Tooltip data-aos="fade-left" className="my-3" sx={{ borderLeft: 3, backgroundColor: "ButtonHighlight", padding: 2, minWidth: 300 }} placement="left-start">
                      <Button className="text-secondary">{Ldata}</Button>
                    </Tooltip>
                  ))}
                </Grid>
                {/* Right */}
                <Grid item container xs={12} sm={6} alignItems="flex-start" direction="column">
                  <Grid item>
                    {RightSideData.map((Rdata, index) => (
                      <Tooltip data-aos="fade-left" className="my-3 placement-ourRec" sx={{ borderLeft: 3, backgroundColor: "ButtonHighlight", padding: 2, minWidth: 300 }} sm={{ minWidth: 400 }} placement="right-start">
                        <Button className="text-secondary">{Rdata}</Button>
                      </Tooltip>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Box>
        </Paper>
      </div>
    </>
  );
}

export function PlacementBrochure() {
  return (
    <>
      <div className="box">
        <h5 className="title-text mb-3 mt-3 decorated">Placement Brochures</h5>
        <Paper elevation={3} className="p-3 textWrap" style={{ minHeight: '300px' }}>
          <div data-aos="fade-up" >
            <a href={brochure1} target="_blank" className="pdf Brochure"><PictureAsPdfIcon />Placement Brochure 14-15.pdf</a>
            <a href={brochure2} target="_blank" className="pdf  Brochure"><PictureAsPdfIcon />Placement Brochure 15-16.pdf</a>
            <a href={brochure3} target="_blank" className="pdf  Brochure"><PictureAsPdfIcon />Placement Brochure 16-17.pdf</a>
            <a href={brochure4} target="_blank" className="pdf  Brochure"><PictureAsPdfIcon />Placement Brochure 17-18.pdf</a>
            <a href={brochure5} target="_blank" className="pdf  Brochure"><PictureAsPdfIcon />Placement Brochure 18-19.pdf</a>
            <a href={brochure6} target="_blank" className="pdf  Brochure"><PictureAsPdfIcon />Placement Brochure 19-20.pdf</a>
            <a href={brochure7} target="_blank" className="pdf  Brochure"><PictureAsPdfIcon />Placement Brochure 20-21.pdf</a>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function EmployabilityEnhancement() {
  return (
    <>
      <div className="box">
        <h5 className="title-text mb-3 mt-3 decorated">Employability Enhancement</h5>
        <Paper elevation={3} className="p-3 textWrap" style={{ minHeight: '300px' }}>
          <div data-aos="fade-up">
            <a href={enhancementProgram1} target="_blank" className="pdf Brochure"><PictureAsPdfIcon />Employability Enhancement Program 1</a>
            <a href={enhancementProgram2} target="_blank" className="pdf  Brochure"><PictureAsPdfIcon />Employability Enhancement Program 2</a>
          </div>
        </Paper>
      </div>
    </>
  );
}


export function StudIndustryInitiatives() {
  const tabData = [
    { label: '2018-2019', content: <StudentIndustryTable1 /> },
    { label: '2017-2018', content: <StudentIndustryTable2 /> },
    { label: '2016-2017', content: <StudentIndustryTable3 /> },
    { label: '2015-2016', content: <StudentIndustryTable4 /> },
    { label: '2014-2015', content: <StudentIndustryTable5 /> },
  ];
  return (
    <>
    <Paper elevation={3} sx={{minHeight:'350px'}} className='w-100'>
       <TabComponent tabData={tabData}/>
    </Paper>
    </>
  );
}

