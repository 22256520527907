import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Breadcrumb from "../Components/Breadcrumb";
import AccordianTableComponent from "../Components/ReusableComponent/AccordianTableComponent";
import Soon from "../assets/images/ComingSoon.png";
import {
  TableInfo,
  data,
  heading,
} from "../Data/ResearchInnovation/ResearchData";
import TableComponent from "../Components/ReusableComponent/tableComponent";
import {
  IPRdata,
  StateLevelSeminarData,
} from "../Data/ResearchInnovation/ResearchAndInnovationData";
import TabComponent from "../Components/ReusableComponent/TabComponent";
import { FDP_2020_21, FDP_2021_22 } from "../Data/FDPData";
import ImageDivComponent from "../Components/ImageDivComponent";

const sidemenus = [
  {
    title: "Research Center",
    url: "/research/Center",
  },
  {
    title: "Paridnya",
    url: "/research/Paridnya",
  },

  {
    title: "Research Paper Published",
    url: "/research/PaperPublished",
  },

  {
    title: "Code Of Ethics",
    url: "/research/CodeOfEthics",
  },
  {
    title: "IPR Cell",
    url: "/research/IPRCell",
  },
  {
    title: "National Conferences",
    url: "/research/NationalConferences",
  },
  {
    title: "FDP",
    url: "/research/FDP",
  },
  {
    title: "State Level Seminar",
    url: "/research/StateLevelSeminar",
  },
];

export default function Research(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    // window.scrollTo(0, 0);
    window.scrollTo(0, 420);
  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <div className="bredDivMob">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="bredDivDesk">
        <Breadcrumb pageTitle={menuName} />
      </div>
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {" "}
                    {menu.title}{" "}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// sub-pages below of about us tab

export function R_Center() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">Research Center</h5>
        <Paper elevation={3} className="p-3 text-justify">
          <div className="P-tag" data-aos="fade-left">
            <p>
              Progressive Education Society’s Modern Institute of Business
              Management, is recognized as research center which affiliated to
              the Savitribai Phule Pune University and offers a Doctoral
              Programme in the area of Management such as Marketing Management,
              Organization Management and Financial Management. This has been
              possible on account of continuous support, motivation and guidance
              received from our Hon’ble Chairman, Progressive Education Society
              Prof. Dr. Gajanan Ekbote Sir.
            </p>
            <p>
              The Research center provides guidance to Doctoral Students through
              experienced research guides associated with the center. The
              Research center is equipped with adequate infrastructure and
              electronic facilities. The library which plays a vital supportive
              role for research scholars has rich collection of books, journals,
              e journals and Databases and a Plagiarism Check software. The
              REsearch center also has a dedicated Research Lab for the Scholars
              which has latest SPSS software installed. PES MIBM has a
              membership with Maratha Chambers of Commerce Industry and Trade.
            </p>
          </div>
          <Divider sx={{ border: 1, marginBottom: 1 }} />
          <h5 className="title-text my-4 text-bold">
            Names of Guides Associated With the Research Center
          </h5>
          <div>
            <Paper elevation={1}>
              <TableComponent tableHeading={heading} tableData={data} />
            </Paper>
          </div>
        </Paper>
      </div>
    </>
  );
}
export function Paridnya() {
  return (
    <>
      <div className="box">
        <h5 className="title-text my-2 mb-4 decorated">Paridnya</h5>
        <Paper elevation={3} className="p-3 mt-1 text-justify">
          <div data-aos="fade-left" className="P-tag">
            <p>
              MIBM has its annual research journal entitled as Paridnya-The MIBM
              Research Journal. It was launched on 27th September 2013. Paridnya
              ( Print ISSN: 2347-2405 and eISSN: 2457-0281) is a peer reviewed
              journal that publishes papers on diverse areas of management such
              as Marketing Management, Financial Management, Human Resources
              Management, Information Technology, General Management, etc.
            </p>
            <p>
              The contents in this journal are educative and knowledge
              enhancing. The objective of the journal is transfer and exchange
              knowledge with all the stakeholder of education sector.
            </p>
          </div>
          <Divider sx={{ border: 1, marginBottom: 1 }} />
          <div data-aos="fade-up">
            <h6 className="my-2 mb-3 mt-4" style={{ fontWeight: "bold" }}>
              The Mibm Research Journal
            </h6>
            <Link
              className="mx-4"
              style={{ color: "steelblue" }}
              target="_blank"
              to={"http://www.mibmparidnya.in/index.php/PARIDNYA"}
            >
              {" "}
              - E Journal Paridnya - Visit Now
            </Link>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function Published() {
  return (
    <>
      <div className="box">
        <h5 className="my-3 title-text decorated">RESEARCH PAPER PUBLISHED</h5>
        <Paper elevation={3} className="p-3 text-justify">
          <div>
            <AccordianTableComponent data={TableInfo} />
          </div>
        </Paper>
      </div>

      {/* <img src={Soon} height={400} width={400} className="mx-5" /> */}
    </>
  );
}

export function CodeOfEthics() {
  return (
    <>
      <div className="box">
        <h5 className="my-3 title-text decorated">
          Code of Ethics to Check Malpractices and Plagiarism
        </h5>
        <Paper elevation={3} className="p-3 text-justify">
          <div data-aos="fade-left">
            <p className="P-tag">
              Modern Institute of Business Management ensures significant
              academic standards of publishing for different forms of research
              work. It believes in promoting healthy and ethical research
              culture. The institutes keep timely check on ethical practices in
              research and provide guidance appropriately. Institute condemns
              cheating, fraud, malpractice, misconduct, and violation of code or
              any other dishonest behavior. Institute constantly works on
              ensuring value based research among researchers.
            </p>
          </div>
          <Divider sx={{ border: 1, marginBottom: 1 }} />
          <div data-aos="fade-left">
            <h5 className="my-3 fw-bold title-text">Objectives</h5>
            <p className="P-tag">
              To create an academic responsiveness amongst the students,
              researchers and academicians about ethical code of conduct for
              research publications by developing academic integrity. To create
              academic awareness about responsible conduct of research,
              promotion of academic integrity and prevention of misconduct
              including plagiarism in academic writing. To establish
              institutional mechanism through education and training to
              facilitate responsible conduct of research for promotion of
              academic integrity and deterrence from plagiarism. To develop
              systems to detect plagiarism and to set up mechanisms to prevent
              plagiarism.
            </p>
          </div>
          <Divider sx={{ border: 1, marginBottom: 1 }} />
          <div data-aos="fade-left">
            <h5 className="my-3 fw-bold title-text">
              Institutional Academic Integrity Panel
            </h5>
            <p className="P-tag">
              Institutional Academic Integrity Panel (IAIP) is established with
              the purpose of ensuring quality research and it strictly
              discourages dishonest and unethical practice in research, by
              taking in view a mandatory check of all research. IAIP panel shall
              take care and be responsible of the said process.
            </p>

            <p className="P-tag">
              Following process is followed by the Institute:
            </p>

            <p className="P-tag">
              The Institute has implemented plagiarism detection software,
              ‘Urkund’, to ensure that the research publications are free of
              plagiarism at the time of submission. Every researcher shall
              submit a declaration indicating that the research work is original
              and has been prepared by him/her only. The undertaking shall be
              duly supported by the plagiarism report generated through the
              plagiarism detection software. Institute shall take action
              considering the severity of the Plagiarism.
            </p>
          </div>
          <Divider sx={{ border: 1, marginBottom: 1 }} />
          <div data-aos="fade-left">
            <h5 className="my-3 fw-bold title-text">IAIP panel members</h5>
            <ul className="ul-tag">
              <li>Dr VIjayalakshmi Srinivas</li>
              <li>Dr Nivedita Ekbote</li>
              <li>Dr Shrawan Ovhal</li>
              <li>Mr Nilesh Bagwe</li>
            </ul>
          </div>
        </Paper>
      </div>
    </>
  );
}

export function IPR_Cell() {
  return (
    <>
      <div className="box">
        <IPRdata />
      </div>
    </>
  );
}
export function Conferences() {
  return (
    <>
      <div className="box">
        <h5 className="my-3 title-text decorated">National Conferences</h5>
        <Paper
          elevation={3}
          sx={{ minHeight: "400px" }}
          className="p-3 text-justify"
        >
          <div>
            <img src={Soon} height={400} width={400} className="mx-5" />
          </div>
        </Paper>
      </div>
    </>
  );
}

export function FDP() {
  const tabData = [
    { label: "FDP 2021-22", content: <ImageDivComponent item={FDP_2021_22} /> },
    { label: "FDP 2020-21", content: <ImageDivComponent item={FDP_2020_21} /> },
  ];
  return (
    <>
      <div className="box">
        <h5 className="my-3 title-text decorated">FDP</h5>
        <Paper
          elevation={3}
          sx={{ minHeight: "400px" }}
          className="p-3 text-justify"
        >
          <TabComponent tabData={tabData} />
        </Paper>
      </div>
    </>
  );
}

export function StateLevelSeminar() {
  return (
    <>
      <div className="box">
        <StateLevelSeminarData />
      </div>
    </>
  );
}

export function III_Cell() {
  return (
    <>
      <div className="box">
        <img src={Soon} height={400} width={400} className="mx-5" />
      </div>
    </>
  );
}
