import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  AccordianCardComponent,
  AccordianTabsComponent,
} from "../../Components/ReusableComponent/AccordianCardComponent";
import { InfoIPR23, InfoIPR24, InfoStateSeminar } from "./ResearchData";
import TabComponent from "../../Components/ReusableComponent/TabComponent";

export function IPRdata() {
  const tabData = [
    {
      label: "Activity",
      content: (
        <>
          <AccordianCardComponent data={InfoIPR24} />
          <AccordianCardComponent data={InfoIPR23} />
        </>
      ),
    },
  ];
  return (
    <>
      <h5 className="my-3 title-text decorated">IPR CELL</h5>
      <Paper
        elevation={1}
        sx={{ padding: "0px", minHeight: 450 }}
        className="text-justify"
      >
        <TabComponent tabData={tabData} />
      </Paper>
    </>
  );
}

//------------------------This is IPR & Seminar-----------------------------

export function StateLevelSeminarData() {
  const tabData = [
    {
      label: "Seminar",
      content: <AccordianTabsComponent data={InfoStateSeminar} />,
    },
  ];

  return (
    <>
      <h5 className="my-3 title-text decorated">State Level Seminar</h5>
      <Paper
        elevation={1}
        sx={{ padding: "0px", minHeight: 450 }}
        className="text-justify"
      >
        <TabComponent tabData={tabData} />
      </Paper>
    </>
  );
}
